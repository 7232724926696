<div class="row">
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white ">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">Report Approval Details</h5>
               </div>
               <div class="headerButtons">
                  <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backNAV()"
                     class="saveimgbtn_inpatinfo" />
                  <img src="../../../assets/ui_icons/buttons/Recollect_button.svg" *ngIf="newbutton"
                     [hidden]="recollectflag" data-bs-toggle="modal" data-bs-target="#recollectModal"
                     class="saveimgbtn_inpatinfo mx-2" />
                  <img src="../../../assets/ui_icons/buttons/Recheck_button.svg" *ngIf="newbutton"
                     data-bs-toggle="modal" data-bs-target="#recheckModal" class="saveimgbtn_inpatinfo" />
                  <img src="../../../assets/ui_icons/buttons/approve_button.svg" *ngIf="newbutton"
                     (click)="approveReport()" class="saveimgbtn_inpatinfo" />
                  <!-- <button class="btn btn-primary mx-2" data-bs-toggle="modal"
                     data-bs-target="#recollectModal">Recollect</button>
                  <button class="btn btn-secondary mx-2" data-bs-toggle="modal"
                     data-bs-target="#recheckModal">Recheck</button> -->
                  <!-- <button (click)="patientHistory()" class="btn mx-2">Patient History</button> -->
               </div>
            </div>
         </mdb-card-header>
         <mdb-card-body>
            <div *ngIf="detailedViewFlag">
               <div class="modal fade" id="recollectModal" tabindex="-1" aria-labelledby="recollectModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog">
                     <div class="modal-content">
                        <div class="modal-header">
                           <h5 class="modal-title" id="recollectModalLabel">Reason For Recollecting</h5>
                           <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                           <textarea #matInput class="ipcss txtareaheight" maxlength="50"
                              [(ngModel)]="recollect_reason"></textarea>
                        </div>
                        <div class="modal-footer">
                           <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                              (click)="rejectReport('recollect')">Save</button> -->
                           <img src="../../../assets/ui_icons/buttons/save_button.svg" data-bs-dismiss="modal"
                              (click)="rejectReport('recollect')" class="saveimgbtn_inpatinfo" />
                           <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal fade" id="recheckModal" tabindex="-1" aria-labelledby="recheckModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog">
                     <div class="modal-content">
                        <div class="modal-header">
                           <h5 class="modal-title" id="recheckModalLabel">Reason For Rechecking</h5>
                           <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                           <textarea #matInput class="ipcss txtareaheight" maxlength="50"
                              [(ngModel)]="recheck_reason"></textarea>
                        </div>
                        <div class="modal-footer">
                           <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                              (click)="rejectReport('recheck')">Save</button> -->
                           <img src="../../../assets/ui_icons/buttons/save_button.svg" data-bs-dismiss="modal"
                              (click)="rejectReport('recheck')" class="saveimgbtn_inpatinfo" />
                           <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                        </div>
                     </div>
                  </div>
               </div>

               <div class="row">

                  <div class="col-12">
                     <div class="row" style="padding: 10px;">
                        <div class="cover_div">
                           <div class="header_lable">Patient Details</div>
                           <div class="content_cover">
                              <div class="row">
                                 <div class="col-3">
                                    <table style="width: 100%;" class="head-table">
                                       <tbody>
                                          <tr class="head-tr">
                                             <td class="head-td"
                                                style="vertical-align: top;width: 100px; font-weight: 400;"><strong>MR
                                                   No</strong></td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{patientID}}</td>
                                          </tr>
                                          <tr class="head-tr">
                                             <td class="head-td"
                                                style="vertical-align: top;width: 100px; font-weight: 400;">
                                                <strong>Name</strong>
                                             </td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{clientName}}</td>
                                          </tr>
                                          <tr class="head-tr">
                                             <td class="head-td"
                                                style="vertical-align: top;width: 100px; font-weight: 400;"><strong>Age
                                                   /
                                                   Gender</strong></td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{clientAge}} / {{clientGender}}</td>
                                          </tr>
                                          <tr class="head-tr">
                                             <td class="head-td"
                                                style="vertical-align: top;width: 100px; font-weight: 400;">
                                                <strong>Mobile</strong>
                                             </td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{mobile}}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                                 <div class="col-3">
                                    <table style="width: 100%;" class="head-table">
                                       <tbody>
                                          <tr class="head-tr">
                                             <td class="head-td"
                                                style="vertical-align: top;width: 100px; font-weight: 400;">
                                                <strong>Patient type</strong>
                                             </td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{patient_type}}</td>
                                          </tr>
                                          <tr class="head-tr">
                                             <td class="head-td"
                                                style="vertical-align: top;width: 100px; font-weight: 400;">
                                                <strong>Doctor</strong>
                                             </td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{doctorname}}</td>
                                          </tr>
                                          <tr class="head-tr" *ngIf="report_type != 'radiology'">
                                             <td class="head-td"
                                                style="vertical-align: top;width: 100px; font-weight: 400;">
                                                <strong>Specimen</strong>
                                             </td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{sample_type}}</td>
                                          </tr>
                                          <tr class="head-tr" *ngIf="report_type != 'radiology'">
                                             <td class="head-td"
                                                style="vertical-align: top;width: 100px; font-weight: 400;">
                                                <strong>Sample
                                                   ID</strong>
                                             </td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{sample_id}}</td>
                                          </tr>
                                          <tr class="head-tr" *ngIf="report_type == 'radiology'">
                                             <td class="head-td"
                                                style="vertical-align: top;width: 100px; font-weight: 400;">
                                                <strong>Visited date & time</strong>
                                             </td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{visited_date_time}}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                                 <div class="col-4">
                                    <table style="width: 100%;" class="head-table">
                                       <tbody>
                                          <tr class="head-tr" *ngIf="report_type != 'radiology'">
                                             <td class="head-td"
                                                style="vertical-align: top;width: 100px; font-weight: 400;">
                                                <strong>Collected at</strong>
                                             </td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{sampleCollectedTime}}</td>
                                          </tr>
                                          <tr class="head-tr">
                                             <td class="head-td"
                                                style="vertical-align: top; width: 100px; font-weight: 400;">
                                                <strong>Date
                                                   & Time</strong>
                                             </td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{date}} {{time}}</td>
                                          </tr>
                                          <tr class="head-tr">
                                             <td class="head-td"
                                                style="vertical-align: top; width: 100px; font-weight: 400;">
                                                <strong>Address1</strong>
                                             </td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{address1}}</td>
                                          </tr>
                                          <tr class="head-tr">
                                             <td class="head-td"
                                                style="vertical-align: top; width: 100px; font-weight: 400;">
                                                <strong>Location</strong>
                                             </td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{clntlocation}}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                                 <div class="col-2">
                                    <table style="width: 100%;" class="head-table">
                                       <tbody>
                                          <tr class="head-tr">
                                             <td class="head-td"
                                                style="vertical-align: top; width: 80px; font-weight: 400;">
                                                <strong>City</strong>
                                             </td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{clntdistrict}}</td>
                                          </tr>
                                          <tr class="head-tr">
                                             <td class="head-td"
                                                style="vertical-align: top; width: 80px; font-weight: 400;">
                                                <strong>State</strong>
                                             </td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{clntstate}}</td>
                                          </tr>
                                          <tr class="head-tr">
                                             <td class="head-td"
                                                style="vertical-align: top; width: 80px; font-weight: 400;">
                                                <strong>Country</strong>
                                             </td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{clntcountry}}</td>
                                          </tr>
                                          <tr class="head-tr">
                                             <td class="head-td"
                                                style="vertical-align: top; width: 80px; font-weight: 400;">
                                                <strong>Zipcode</strong>
                                             </td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{clntzipcode}}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>

                           </div>
                        </div>
                     </div>

                  </div>

               </div>
               <div class="row d-flex flex-row justify-content-end align-items-center">
                  <div style="margin-top: 5px;width: auto;" class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2">
                     <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">Approved by</mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 mx-2">
                     <select disableOptionCentering class="ipcss" [(ngModel)]="selected_approver">
                        <option *ngFor="let approver of approverList" value={{approver.doc_reg_id}}>
                           {{approver.first_name}} {{approver.last_name}}</option>
                     </select>
                  </div>
               </div>
               <div [hidden]="true" style="margin-top: 37px;">
                  <p *ngIf="showtestname_flag" class="tervys_heading clinical col-12"><b>{{test_category}}</b></p>
                  <div class="row">
                     <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="showtestname_flag">
                        <mat-label *ngIf="subtestFlag" class="matlabel"><b>Test Name: {{subTest}}</b>
                        </mat-label>
                     </div>
                     <div *ngIf="!template_flag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="dig_table_overflow">
                           <div class="table-responsive">
                              <table class="table table-hover table-dynamic" id="card_tbl">
                                 <thead>
                                    <tr>
                                       <th style="width:400px; text-align: left;" *ngIf="!subtestFlag">Test Name</th>
                                       <th style="width:400px; text-align: left;" *ngIf="subtestFlag">Subtest Name</th>
                                       <th style="width:200px; text-align: left;">Reading</th>
                                       <th style="width:150px; text-align: left;">Unit</th>
                                       <th style="text-align: left;">Referred value</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr *ngFor="let subtest of tempTestList">
                                       <td colspan="8" *ngIf="subtest.tests != undefined && !subtest.egfr_flag">
                                          <div style="padding: 20px;">
                                             <div class="d-flex flex-row">
                                                <!-- <p style="margin-top: 5px; margin-bottom: 0px;">{{subtest.id}}</p> -->
                                                <h2 class="subtest-head">{{subtest.sub_test_name}}</h2>
                                             </div>
                                             <table style="width: 100%;">
                                                <tbody>
                                                   <tr *ngFor="let sub of subtest.tests let i = index">
                                                      <!-- <td>{{i + 1}}</td> -->
                                                      <td style="width:370px;">{{sub.sub_test_name}}
                                                         <span *ngIf="sub.test_method != ''"
                                                            class="test_method">Method:&nbsp;
                                                            <div [innerHtml]="sub.test_method"></div>
                                                         </span>
                                                      </td>
                                                      <td style="width:200px;">{{sub.reading}}</td>
                                                      <td style="width: 165px;">{{sub.unit}}</td>
                                                      <td>{{sub.range}}</td>
                                                   </tr>
                                                </tbody>
                                             </table>
                                          </div>
                                       </td>
                                       <td colspan="8" *ngIf="subtest.tests == undefined && subtest.egfr_flag">
                                          <div style="padding: 20px;">
                                             <table style="width: 100%;">
                                                <tbody>
                                                   <tr>
                                                      <td style="width:370px;">Creatinine</td>
                                                      <td style="width:250px;">{{subtest.reading}}</td>
                                                      <td style="width: 150px;"> {{subtest.unit}}</td>
                                                      <td>{{subtest.test_range}}</td>
                                                   </tr>
                                                   <tr>
                                                      <td style="width:370px;">eGFR</td>
                                                      <td style="width:250px;">{{subtest.egfr_value}}</td>
                                                      <td>mL/min/1.73m2</td>
                                                      <td> - </td>
                                                   </tr>
                                                </tbody>
                                             </table>
                                          </div>
                                       </td>
                                       <td style='text-align: left;'
                                          *ngIf="subtest.tests == undefined && !subtest.egfr_flag">
                                          <strong>
                                             <p>{{subtest.sub_test_name}}</p>
                                          </strong>
                                          <span *ngIf="subTestMedFlag" class="test_method">Method:&nbsp; <div
                                                [innerHtml]="subtest.test_method"></div></span>
                                       </td>
                                       <td *ngIf="subtest.is_Reading_normal && subtest.tests == undefined && !subtest.egfr_flag"
                                          style='text-align: left; color: rgb(1, 167, 1); font-weight: 500;'>
                                          {{subtest.reading}}</td>
                                       <td *ngIf="subtest.is_Reading_Abnormal && subtest.tests == undefined && !subtest.egfr_flag"
                                          style='text-align: left; color: orange; font-weight: 500;'>
                                          {{subtest.reading}}*</td>
                                       <td *ngIf="subtest.is_Reading_Critical && subtest.tests == undefined && !subtest.egfr_flag"
                                          style='text-align: left; color: red; font-weight: 500;'>
                                          {{subtest.reading}}**</td>
                                       <td *ngIf="!subtest.is_Reading_Critical && !subtest.is_Reading_Abnormal && !subtest.is_Reading_normal && subtest.tests == undefined && !subtest.egfr_flag"
                                          style='text-align: left; font-weight: 500;'>
                                          {{subtest.reading}}</td>
                                       <!-- <td *ngIf="subtest.reading_Flag && subtest.tests == undefined">{{subtest.reading}}</td> -->
                                       <td *ngIf="subtest.tests == undefined && !subtest.egfr_flag">{{subtest.unit}}
                                       </td>
                                       <td style='text-align: left;'
                                          *ngIf="subtest.tests == undefined && !subtest.egfr_flag">
                                          <div [innerHtml]="subtest.test_range | safeHtml"
                                             style="position: relative;top: 6px;"></div>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                     <div *ngIf="template_flag" [innerHtml]="template | safeHtml">
                     </div>
                  </div>
               </div>
               <div [hidden]="tableViewTestFlag" style="margin-top: 37px;">
                  <p *ngIf="showtestname_flag" class="tervys_heading clinical col-12"><b>{{test_category}}</b></p>
                  <div class="row">
                     <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="showtestname_flag">
                        <mat-label *ngIf="subtestFlag" class="matlabel"><b>Test Name: {{subTest}}</b>
                        </mat-label>
                     </div>
                     <div *ngIf="!template_flag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="dig_table_overflow">
                           <div class="table-responsive">
                              <table class="table table-hover table-dynamic" id="card_tbl">
                                 <thead>
                                    <tr>
                                       <th style="width:70px; text-align: left;">S.No</th>
                                       <th style="width:400px; text-align: left;" *ngIf="!subtestFlag">Test Name</th>
                                       <th style="width:400px; text-align: left;" *ngIf="subtestFlag">Subtest Name</th>
                                       <th style="width:200px; text-align: left;">Reading</th>
                                       <th style="width:150px; text-align: left;">Unit</th>
                                       <th style="text-align: left;">Referred value</th>
                                       <th style="text-align: left;"></th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr *ngFor="let subtest of tempTestList" style="height: 45px;">
                                       <td colspan="8" *ngIf="subtest.tests != undefined && !subtest.egfr_flag">
                                          <div style="padding: 20px;">
                                             <div class="d-flex flex-row justify-content-between">
                                                <!-- <p style="margin-top: 5px; margin-bottom: 0px;">{{subtest.id}}</p> -->
                                                <h2 class="subtest-head">
                                                   <span>{{subtest.s_no}}&nbsp;&nbsp;</span>{{subtest.sub_test_name}}
                                                </h2>
                                                <mdb-checkbox [(ngModel)]="subtest.isSelected"></mdb-checkbox>
                                             </div>
                                             <table style="width: 100%;">
                                                <tbody>
                                                   <tr *ngFor="let sub of subtest.tests let i = index"
                                                      style="height: 45px;">
                                                      <!-- <td>{{i + 1}}</td> -->
                                                      <td style="width:70px;"></td>
                                                      <td style="width:370px;">{{sub.sub_test_name}}
                                                         <span *ngIf="sub.test_method != ''"
                                                            class="test_method">Method:&nbsp;
                                                            <div [innerHtml]="sub.test_method"></div>
                                                         </span>
                                                      </td>
                                                      <td style="width:200px; height: 45px;">
                                                         <input placeholder="reading" class="input__email"
                                                            [(ngModel)]="sub.reading" matInput
                                                            (blur)="checkRef(sub.reading, sub)"
                                                            [ngStyle]="{ 'background-color': sub.inputBgColor }">
                                                      </td>
                                                      <td style="width: 165px;"><div [innerHtml]="sub.unit"></div></td>
                                                      <td>{{sub.range}}</td>
                                                   </tr>
                                                </tbody>
                                             </table>
                                          </div>
                                       </td>
                                       <td colspan="8" *ngIf="subtest.tests == undefined && subtest.egfr_flag">
                                          <div style="padding: 20px;">
                                             <div class="d-flex flex-row justify-content-end">
                                                <mdb-checkbox [(ngModel)]="subtest.isSelected"></mdb-checkbox>
                                             </div>
                                             <table style="width: 100%;">
                                                <tbody>
                                                   <tr style="height: 45px;">
                                                      <td style="width:70px;"></td>
                                                      <td style="width:370px;">Creatinine</td>
                                                      <td style="width:200px;">
                                                         <input placeholder="reading" class="input__email"
                                                            (blur)="checkRef(subtest.reading, subtest)"
                                                            [(ngModel)]="subtest.reading" matInput>
                                                      </td>
                                                      <td style="width: 150px;"> {{subtest.unit}}</td>
                                                      <td>{{subtest.test_range}}</td>
                                                   </tr>
                                                   <tr style="height: 45px;">
                                                      <td style="width:70px;"></td>
                                                      <td style="width:370px;">eGFR</td>
                                                      <td style="width:200px;">
                                                         <input placeholder="reading" class="input__email"
                                                            [(ngModel)]="subtest.egfr_value" matInput>
                                                      </td>
                                                      <td>mL/min/1.73m2</td>
                                                      <td> - </td>
                                                   </tr>
                                                </tbody>
                                             </table>
                                          </div>
                                       </td>
                                       <td style='text-align: left;'
                                          *ngIf="subtest.tests == undefined && !subtest.egfr_flag">
                                          {{subtest.s_no}}
                                       </td>
                                       <td style='text-align: left;'
                                          *ngIf="subtest.tests == undefined && !subtest.egfr_flag">
                                          <strong>
                                             <p>{{subtest.sub_test_name}}</p>
                                          </strong>
                                          <span *ngIf="subTestMedFlag" class="test_method">Method:&nbsp; <div
                                                [innerHtml]="subtest.test_method"></div></span>
                                       </td>
                                       <td *ngIf=" subtest.tests == undefined && !subtest.egfr_flag">
                                          <input placeholder="reading" class="input__email"
                                             [(ngModel)]="subtest.reading" matInput
                                             (blur)="checkRef(subtest.reading, subtest)"
                                             [ngStyle]="{ 'background-color': subtest.inputBgColor }">
                                       </td>
                                       <!-- <td *ngIf="subtest.is_Reading_Abnormal && subtest.tests == undefined && !subtest.egfr_flag"
                                          style='text-align: left; color: orange; font-weight: 500;'>
                                          {{subtest.reading}}*</td>
                                       <td *ngIf="subtest.is_Reading_Critical && subtest.tests == undefined && !subtest.egfr_flag"
                                          style='text-align: left; color: red; font-weight: 500;'>
                                          {{subtest.reading}}**</td>
                                       <td *ngIf="!subtest.is_Reading_Critical && !subtest.is_Reading_Abnormal && !subtest.is_Reading_normal && subtest.tests == undefined && !subtest.egfr_flag"
                                          style='text-align: left; font-weight: 500;'>
                                          {{subtest.reading}}</td> -->
                                       <!-- <td *ngIf="subtest.reading_Flag && subtest.tests == undefined">{{subtest.reading}}</td> -->
                                       <td *ngIf="subtest.tests == undefined && !subtest.egfr_flag"><div [innerHtml]="subtest.unit"></div>
                                       </td>
                                       <td style='text-align: left;'
                                          *ngIf="subtest.tests == undefined && !subtest.egfr_flag">
                                          <div [innerHtml]="subtest.test_range | safeHtml"
                                             style="position: relative;top: 6px;"></div>
                                       </td>
                                       <td *ngIf="subtest.tests == undefined && !subtest.egfr_flag">
                                          <mdb-checkbox [(ngModel)]="subtest.isSelected"></mdb-checkbox>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                     <div *ngIf="template_flag" [innerHtml]="template | safeHtml">
                     </div>
                  </div>
               </div>
               <div [hidden]="paraViewReportTypeFlag">
                  <div class="row">
                     <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top:13px;">
                        <mat-label class="matlabel"><strong>Report type :</strong> {{reportType}}</mat-label>
                     </div> -->
                     <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top:5px;">
                        <mat-label class="matlabel"><strong>Test name :</strong> {{testName}}</mat-label>
                     </div>
                     <div *ngIf="value_flag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        style="margin-top:5px;">
                        <p class="tervys_heading clinical">Sequences</p>
                     </div>
                     <div *ngIf="value_flag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="matlabel" style="padding-left: 7px;" [innerHtml]="sequence | safeHtml">
                        </div>
                     </div>
                     <div *ngIf="value_flag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <p class="tervys_heading clinical">Impression</p>
                     </div>
                     <div *ngIf="value_flag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="matlabel" style="padding-left: 7px;" [innerHtml]="impression | safeHtml">
                        </div>
                     </div>
                     <!-- <div *ngIf="cultureFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <p class="tervys_heading clinical">Report details</p>
                     </div>
                     <div *ngIf="cultureFlag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="matlabel" style="padding-left: 7px;" [innerHtml]="culture | safeHtml">
                        </div>
                     </div> -->
                     <div [hidden]="doctorFlag" class="col-12">
                        <mat-card>
                           <mat-card-title class="tervys_heading">Attachment</mat-card-title>
                           <mat-card-content>
                              <img alt="report image" src="{{report_img}}" (click)="clickDocument()"
                                 class="report_img" />
                           </mat-card-content>
                        </mat-card>
                     </div>
                     <div *ngIf="template_flag" [innerHtml]="template | safeHtml">
                     </div>
                     <div [hidden]="microbiology_flag" style="margin-top: 15px; margin-left: 24px;">
                        <div class="container-fluid" style="margin-bottom: 8px;">
                           <!-- <div class="row">
                              <div class="col-12 mb-3" style="margin-bottom: 8px;">
                                 <div>
                                    <p><strong>Gram Stain: </strong> {{gram_stain_desc}}</p>
                                    <p><strong>Culture Report: </strong> {{culture_report_type_desc}}</p>
                                    <p><strong>Organism Isolated 1: </strong> {{oraganismIso1}}</p>
                                    <p><strong>Organism Isolated 2: </strong> {{oraganismIso2}}</p>
                                    <p><strong>Organism Isolated 3: </strong> {{oraganismIso3}}</p>
                                 </div>
                              </div>
                              <div *ngIf="colonyCountArray.length != 0" class="col-12 mb-3"
                                 style="margin-bottom: 8px; ">
                                 <table cellpadding='0' cellspacing='0' style="width:50%; border:1px solid #747575;">
                                    <thead>
                                       <tr>
                                          <th style="text-align: left; border: 1px solid #000">
                                             Drug</th>
                                          <th style="text-align: left; border: 1px solid #000">
                                             Sensitivity</th>
                                          <th style="text-align: left; border: 1px solid #000">
                                             MM</th>
                                          <th style="text-align: left; border: 1px solid #000">
                                             MIC</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr *ngFor="let colony of colonyCountArray" style="border: 1px solid #000;">
                                          <td style='padding-left: 20px;text-align: left; border: 1px solid #000;'>
                                             {{colony.drug_name}}</td>
                                          <td style='padding-left: 20px;text-align: left; border: 1px solid #000;'>
                                             {{colony.sensitivity_desc}}</td>
                                          <td style='padding-left: 20px;text-align: left; border: 1px solid #000;'>
                                             {{colony.mm}}</td>
                                          <td style='padding-left: 20px;text-align: left; border: 1px solid #000;'>
                                             {{colony.mic}}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                              <div *ngIf="ot_listFlag" class="col-12 mb-3" style="margin-bottom: 8px; ">
                                 <table cellpadding='0' cellspacing='0' style="width:50%; border:1px solid #747575;">
                                    <thead>
                                       <tr>
                                          <th style="text-align: left;">
                                             Area</th>
                                          <th style="text-align: left;">
                                             Result</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr *ngFor="let OT of ot_sterile">
                                          <td style='padding-left: 20px;text-align: left; '>
                                             {{OT.area}}</td>
                                          <td style='padding-left: 20px;text-align: left; '>
                                             {{OT.result}}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                              <div class="col-12 mb-2">
                                 <h2 style="font-size: 18px;">Culture Report</h2>
                                 <p>{{culture_report_desc}}</p>
                              </div>
                              <div class="col-12 mb-2">
                                 <h2 style="font-size: 18px;">Technical Notes</h2>
                                 <p>{{technical_note}}</p>
                              </div>
                              <div class="col-12 mb-2">
                                 <h2 style="font-size: 18px;">Comments</h2>
                                 <p>{{comment}}</p>
                              </div>
                           </div> -->
                           <div class="row">
                              <!-- <table style="width: 35%; box-shadow:none;" >
                                 <tbody>  
                                    <tr *ngIf="org_iso1_cc_flag">
                                       <td style="font-weight: 500; font-size: 15px;">Colony count</td>
                                       <td> : {{org_iso_1_cc_desc}}</td>
                                    </tr>
                                    <tr *ngIf="org_iso1_cr_flag">
                                       <td style="font-weight: 500; font-size: 15px;">Culture report</td>
                                       <td> : {{org_iso1_cr_desc}}</td>
                                    </tr>
                                    <tr *ngIf="org_iso1_growth_type_flag">
                                       <td style="font-weight: 500; font-size: 15px;">Gram stain</td>
                                       <td> : {{org_iso1_growth_type_desc}}</td>
                                    </tr>
                                 </tbody>
                              </table> -->
                              <div class="row d-flex flex-row justify-content-start mb-3">
                                 <div class="col-2">
                                    <p *ngIf="org_iso1_cc_flag" class="mb-1" style="font-weight: 500; font-size: 15px;">
                                       Colony count : </p>
                                    <p *ngIf="org_iso1_cr_flag" class="mb-1" style="font-weight: 500; font-size: 15px;">
                                       Culture report : </p>
                                    <!-- <p *ngIf="org_iso1_growth_type_flag" class="mb-1"
                                       style="font-weight: 500; font-size: 15px;">Gram stain : </p> -->
                                 </div>
                                 <div class="col-9" style="margin-left: -85px;">
                                    <p *ngIf="org_iso1_cc_flag" class="mb-1"> {{org_iso_1_cc_desc}}</p>
                                    <p *ngIf="org_iso1_cr_flag" class="mb-1"> {{org_iso1_cr_desc}}</p>
                                    <!-- <p *ngIf="org_iso1_growth_type_flag" class="mb-1"> {{org_iso1_growth_type_desc}}</p> -->
                                 </div>
                              </div>
                              <div class="col-4 mb-3" style="margin-bottom: 8px;" *ngIf="org_iso1_flag">
                                 <div>
                                    <div class="row d-flex flex-row justify-content-start align-items-center">
                                       <div class="col-5">
                                          <h2 style="font-weight: 500; margin-bottom: 2px; font-size: 16px;">
                                             {{org_iso_heading}}</h2>
                                       </div>
                                       <div class="col-7">
                                          <h4 style="margin: 0 0 5px;">{{org_iso_1_code}} &nbsp; {{org_iso_1_desc}}</h4>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-12 mb-3" style="margin-bottom: 8px;  margin-top: 5px;">
                                    <table cellpadding='0' cellspacing='0'
                                       style="width:100%; border:1px solid #747575;">
                                       <thead>
                                          <tr>
                                             <th style="text-align: left; ">
                                                Antibiotics</th>
                                             <th style="text-align: left; ">
                                                Sensitivity</th>
                                             <th *ngIf="mic_iso1_flag" style="text-align: left;">
                                                MIC</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr *ngFor="let medicine of org_iso1_antibiotics">
                                             <td style='padding-left: 20px;text-align: left; '>
                                                {{medicine.drug_name}}</td>
                                             <td style='padding-left: 20px;text-align: left; '>
                                                {{medicine.sensitivity_desc}}</td>
                                             <td *ngIf="mic_iso1_flag" style='padding-left: 20px;text-align: left; '>
                                                {{medicine.mic}}</td>
                                          </tr>
                                          <!-- <tr>
                                             <td style='padding-left: 20px;text-align: left;'>
                                                Sample drug name 1</td>
                                             <td style='padding-left: 20px;text-align: left; '>
                                                Sensitive</td>
                                             <td style='padding-left: 20px;text-align: left; '>
                                                Sample MIC 1</td>
                                          </tr>
                                          <tr>
                                             <td style='padding-left: 20px;text-align: left;'>
                                                Sample drug name 1</td>
                                             <td style='padding-left: 20px;text-align: left; '>
                                                Sensitive</td>
                                             <td style='padding-left: 20px;text-align: left; '>
                                                Sample MIC 1</td>
                                          </tr>
                                          <tr>
                                             <td style='padding-left: 20px;text-align: left;'>
                                                Sample drug name 1</td>
                                             <td style='padding-left: 20px;text-align: left; '>
                                                Sensitive</td>
                                             <td style='padding-left: 20px;text-align: left; '>
                                                Sample MIC 1</td>
                                          </tr> -->
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                              <div class="col-4 mb-3" style="margin-bottom: 8px;" *ngIf="org_iso2_flag">
                                 <div>
                                    <div class="row d-flex flex-row justify-content-start align-items-center">
                                       <div class="col-5">
                                          <h2 style="font-weight: 500; margin-bottom: 2px; font-size: 16px;">Organism
                                             Isolated 2</h2>
                                       </div>
                                       <div class="col-7">
                                          <h4 style="margin: 0 0 5px;">{{org_iso_2_code}} &nbsp; {{org_iso_2_desc}}</h4>
                                       </div>
                                    </div>
                                 </div>

                                 <!-- <div class="row d-flex flex-row justify-content-start">
                                    <div class="col-3">
                                       <p class="mb-1"><strong>Colony count : </strong></p>
                                       <p class="mb-1"><strong>Culture report : </strong></p>
                                       <p class="mb-1"><strong>Gram stain : </strong></p>
                                    </div>
                                    <div class="col-9">
                                       <p class="mb-1"> {{org_iso_2_cc_desc}}</p>
                                       <p class="mb-1"> {{org_iso2_cr_desc}}</p>
                                       <p class="mb-1"> {{org_iso2_growth_type_desc}}</p>
                                      
                                    </div>
                                 </div> -->
                                 <div class="col-12 mb-3" style="margin-bottom: 8px;  margin-top: 5px;">
                                    <table cellpadding='0' cellspacing='0'
                                       style="width:100%; border:1px solid #747575;">
                                       <thead>
                                          <tr>
                                             <th style="text-align: left; ">
                                                Antibiotics</th>
                                             <th style="text-align: left; ">
                                                Sensitivity</th>
                                             <th *ngIf="mic_iso2_flag" style="text-align: left;">
                                                MIC</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr *ngFor="let medicine of org_iso2_antibiotics">
                                             <td style='padding-left: 20px;text-align: left; '>
                                                {{medicine.drug_name}}</td>
                                             <td style='padding-left: 20px;text-align: left; '>
                                                {{medicine.sensitivity_desc}}</td>
                                             <td *ngIf="mic_iso2_flag" style='padding-left: 20px;text-align: left; '>
                                                {{medicine.mic}}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                              <div class="col-4 mb-3" style="margin-bottom: 8px;" *ngIf="org_iso3_flag">
                                 <div>
                                    <div class="row d-flex flex-row justify-content-start align-items-center">
                                       <div class="col-5">
                                          <h2 style="font-weight: 500; margin-bottom: 2px; font-size: 16px;">Organism
                                             Isolated 3</h2>
                                       </div>
                                       <div class="col-7">
                                          <h4 style="margin: 0 0 5px;">{{org_iso_3_code}} &nbsp; {{org_iso_3_desc}}</h4>
                                       </div>
                                    </div>
                                 </div>
                                 <!-- <div class="row d-flex flex-row justify-content-start">
                                    <div class="col-3">
                                       <p class="mb-1"><strong>Colony count : </strong></p>
                                       <p class="mb-1"><strong>Culture report : </strong></p>
                                       <p class="mb-1"><strong>Gram stain : </strong></p>
                                    </div>
                                    <div class="col-9">
                                       <p class="mb-1"> {{org_iso_3_cc_desc}}</p>
                                       <p class="mb-1"> {{org_iso3_cr_desc}}</p>
                                       <p class="mb-1"> {{org_iso3_growth_type_desc}}</p>
                                      
                                    </div>
                                 </div> -->
                                 <div class="col-12 mb-3" style="margin-bottom: 8px;  margin-top: 5px;">
                                    <table cellpadding='0' cellspacing='0'
                                       style="width:100%; border:1px solid #747575;">
                                       <thead>
                                          <tr>
                                             <th style="text-align: left; ">
                                                Antibiotics</th>
                                             <th style="text-align: left; ">
                                                Sensitivity</th>
                                             <th *ngIf="mic_iso3_flag" style="text-align: left;">
                                                MIC</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr *ngFor="let medicine of org_iso3_antibiotics">
                                             <td style='padding-left: 20px;text-align: left; '>
                                                {{medicine.drug_name}}</td>
                                             <td style='padding-left: 20px;text-align: left; '>
                                                {{medicine.sensitivity_desc}}</td>
                                             <td *ngIf="mic_iso3_flag" style='padding-left: 20px;text-align: left; '>
                                                {{medicine.mic}}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>

                              <div class="col-12 mb-2" *ngIf="technical_noteFlag">
                                 <h2 style="font-size: 15px; font-weight: 500; margin-bottom: 5px;">Technical Notes</h2>
                                 <p>{{technical_note}}</p>
                              </div>
                              <div class="col-12 mb-2" *ngIf="commentsFlag">
                                 <h2 style="font-size: 15px; font-weight: 500; margin-bottom: 5px;">Comments</h2>
                                 <p>{{comment}}</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div *ngIf="testMethod != undefined && !subTestMedFlag" class="row" style="margin-top: 10px;">
                  <div class="col-1" style="min-width: fit-content;">
                     <mat-label class="matlabel"><b>Test Method :</b></mat-label>
                  </div>
                  <div class="col-10">
                     <div *ngIf="testMethod != undefined && !subTestMedFlag" class="col-12"
                        [innerHtml]="testMethod | safeHtml"></div>
                  </div>
               </div>
               <hr [hidden]="revieSigSection || createdBy == undefined" style="border: 1px solid #51b0e7;">
               <!-- <div class="row" style="margin-top:5px;">
                  <div class="col-6">
                     <div class="row" [hidden]="createdBy == undefined">
                        <div [hidden]="createdBy == undefined" class="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12">
                           <mat-label class="matlabel"><strong>Lab technician :</strong> {{createdBy}}
                           </mat-label>
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12 ">
                           <mat-label class="matlabel"><strong>Signature :</strong>
                           </mat-label>
                        </div>
                     </div>
                  </div>
                  <div class="col-6">
                     <div class="row" [hidden]="revieSigSection">
                        <div [hidden]="reviewFlag" class="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12">
                           <mat-label class="matlabel"><strong>(Chief) Pathologist :</strong> {{reviewBy}}
                           </mat-label>
                        </div>
                        <div [hidden]="signFlag" class="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12 ">
                           <mat-label class="matlabel"><strong>Signature :</strong>
                           </mat-label>
                           <img alt="" src="{{reviewSign}}" class="sign_img" />
                        </div>
                     </div>
                  </div>
               </div> -->
               <div class="row" style='padding: 0px 22px; margin-top: 20px;'>
                  <table>
                     <tr>
                        <td style="width: 90%;text-align: center;">
                           <div class="row" [hidden]="createdBy == undefined">
                              <div [hidden]="createdBy == undefined" class="col-4">
                                 <mat-label class="matlabel"><strong>Lab
                                       technician
                                       :</strong> {{createdBy}}
                                 </mat-label>
                              </div>
                              <div class="col-4">
                                 <mat-label class="matlabel"><strong>{{consultant}}:</strong> {{approver_name}}
                                 </mat-label>
                              </div>
                              <div class="col-4">
                                 <mat-label class="matlabel"><strong>Quality Head
                                       :</strong> {{qualityHead_name}}
                                 </mat-label>
                              </div>
                           </div>
                        </td>
                     </tr>
                  </table>
               </div>
               <div class="mt-4">
                  <mat-accordion class="mataccordion mt-4" [hidden]='patientHistoryList.length==0'>
                     <mat-expansion-panel class="expandpanel " [expanded]="false">
                        <mat-expansion-panel-header class="exppanel width">
                           <mat-panel-title class="title">
                              <strong>Test History</strong>
                           </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row ">
                           <div class="col-12">
                              <div>
                                 <!-- <p class="tervys_heading clinical col-12"><b>Patient Test History</b></p> -->
                                 <table *ngIf="patientHistoryList.length">
                                    <thead>
                                       <tr>
                                          <th style="width: 20%;">Date</th>
                                          <th style="width: 20%;">Test Name</th>
                                          <th style="width: 30%;">Subtest Name</th>
                                          <th style="width: 20%;">Reading</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr *ngFor="let pat of patientHistoryList">
                                          <td style="text-align: left;">
                                             {{pat.appointment_date}}
                                          </td>
                                          <td style="text-align: left;">{{ pat.test_name }}</td>
                                          <td style="text-align: left;">{{ pat.sub_test_name }}</td>
                                          <td style="text-align: left;">{{ pat.reading }}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </mat-expansion-panel>
                  </mat-accordion>
               </div>
            </div>
         </mdb-card-body>
      </mdb-card>
   </div>
</div>