import { Component, OnInit } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Doc_Helper } from '../Doc_Helper';
import { ipaddress } from '../../ipaddress';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Date_Formate, Time_Formate } from '../../../assets/js/common';
import { FrontDesk_Helper } from 'src/app/FrontDesk_module/FrontDesk_Helper';
import { Master_Helper } from '../Master_Helper';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-inpat-patient-list',
  templateUrl: './inpat-patient-list.component.html',
  styleUrls: ['./inpat-patient-list.component.css']
})
export class InpatPatientListComponent implements OnInit {
  public cancel_checkbox: boolean;
  public CurrentDatetime;
  public selectvalue;
  public mobileflag;
  public mobile;
  public currentTime;
  public currentDate;
  public currentMaxDate;
  public currentYear;
  public get_date;
  public docRegId;
  public mrnoflag;
  public nameflag;
  public locationflag;
  public fdateflag;
  public todateflag;
  public mrno;
  public name;
  public location;
  public fromDate;
  public toDate;
  public floorvalue;
  public floorarray;
  public count;
  public tabular_format:boolean=false;
  public graphicalformat:boolean=false;
  public graph_defaultimg:boolean=false;
  public view_type="graph";
  public cancel_foooter: boolean;
  public doc_reg_id;
  public hospital_id;
  public spl_name: string;
  public header_footer_flag: boolean;
  public ismobile: boolean;
  public loader: boolean;
  public nodapp: boolean;
  public patientList: any[] = [];
  public userinfo: any;
  public user_id: any;
  public first_name: string;
  public last_name: string;
  public hospital_logo;
  public hospital_name;
  public hospital_details;
  public messages;
  public msgflag: boolean = false;
  public notifications;
  public notifyflag: boolean = false;
  public profile_image: string;
  public submenu_flag: boolean = true;
  public submenu_flag1: boolean = false;
  dtOptions: any = {};
  persons: any = [];
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any;
  public patientType;
  public display;
  public hospital_array;
  public hptl_clinic_id;
  public hospitalFlag: boolean;
  public ynopt1: boolean = true;
  public provider;
  doctorList: any;
  doctorid: any;
  public is_admin:boolean = false;
  user_type: any;
  user_ids: any;
  public adminDoc: boolean = false;
  editbuttondie: boolean = false;
  newbuttondie: boolean = false;
  deletebuttondie: boolean = false;
  printbuttondie: boolean = false;
  viewbuttondie: boolean = false;
  public filterbasedurl;
  public disTimeLineList = [];
  public show_disbut: boolean= true;

  constructor(private http: HttpClient, private routes: ActivatedRoute, private router: Router, public toastr: ToastrService, public messageservice: MenuViewService) {
    this.cancel_checkbox = true; // hide cancel checkbox
    this.cancel_foooter = true; // hide cancel_foooter
    Doc_Helper.setClient_id(null);
    Helper_Class.setInpatientFlow(undefined);
    Doc_Helper.setClient_type(undefined);
  }

  ngOnInit(): void {
    this.filterbasedurl = ipaddress.getIp + "inpat/gibf ";
    this.mrno=""
    this.selectvalue="mrno"
    this.floorvalue="All";
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      iDisplayLength: 2,
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[2, 'asc']],
      "columnDefs": [
        { "type": "date", "orderable": false, "targets": [0] },
        { "targets": 4, "type": "date-eu" },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search patients"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-end text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    
    this.userinfo = Helper_Class.getInfo();    
    if(this.userinfo.discharge_timeline == "0") {
      this.show_disbut = true;
    } else {
      this.show_disbut = false;
    }
    this.doc_reg_id = this.user_id;
    this.hospital_array = Helper_Class.getHospital();
    console.log("this.hospital_array"+JSON.stringify(this.hospital_array))
    this.hospital_id = this.hospital_array[0].hptl_clinic_id;
    this.hospitalFlag = this.hospital_array.length > 1 ? true : false;
    this.cancel_checkbox = true; // cancel checkbox
    this.cancel_foooter = true; // hide cancel_foooter

    this.patientType = "self";
    this.provider = Helper_Class.getConsultant();
    var modulelist;
    if(this.userinfo.user_type=="front-desk") {
      modulelist = FrontDesk_Helper.getmodules();
    } else {
      modulelist = Helper_Class.getmodulelist();
    }
   
    
   
    if (modulelist != undefined) {
      for (var i = 0; i < modulelist.length; i++) {
        if (modulelist[i].module_id == "1") {
          
          if (modulelist[i].edit == "1" ) {
            this.editbuttondie = true;
          }
          if (modulelist[i].create == "1"  ) {
            this.newbuttondie = true;
          }
          if (modulelist[i].delete == "1") {
            this.deletebuttondie = true;
          }
          if (modulelist[i].print == "1") {
            this.printbuttondie = true; 
          }
          if (modulelist[i].view == "1" ) {
            this.viewbuttondie = true;
          }
        }
      }
    
    }   
    this.viewChange(this.view_type);
    this.Filterbydata(this.selectvalue)
    this.changeProviderType();
    if(Helper_Class.getInfo().user_type != 'Admin'){
    this.getPatients();
    }
    this.getCurrentDate();
    if(Helper_Class.getInfo().user_type == 'Admin'){
      this.doc_reg_id = this.userinfo.user_id;
      this.user_id = this.doc_reg_id;
      this.change_type('','no')
    }
  }
  getCurrentDate() {
    this.CurrentDatetime = Master_Helper.getMasterCurrentDate().current_date;
    this.currentDate = Master_Helper.getMasterCurrentDate().current_date;
    this.currentTime = Master_Helper.getMasterCurrentDate().current_time;
   
    this.get_date =
      Master_Helper.getMasterCurrentDate().current_date.split('-');
    this.currentYear = this.get_date[0];
    Helper_Class.setKey(
      Master_Helper.getMasterCurrentDate().provider +
      Master_Helper.getMasterCurrentDate().clientid +
      Master_Helper.getMasterCurrentDate().messageid
    );
    Helper_Class.setIV(Master_Helper.getMasterCurrentDate().random);
    this.fromDate =  (this.currentDate);
    this.toDate =  (this.currentDate);
  
  }

  cancel_app_click() {
    this.cancel_checkbox = true; // hide cancel checkbox
    this.cancel_foooter = true; // hide cancel_foooter
  }

 getPatients() {
    this.loader = false;
    this.patientList = [];
    var data = null;
    var send_data;
    send_data = {
      doc_reg_id: this.user_id,
      hptl_clinic_id: this.hospital_id,
      country: ipaddress.country_code.toString(),
      imei: Helper_Class.getIPAddress(),
      type: "doctor",
      spl_name: this.spl_name,
      inpat_type: this.patientType,
      floor:this.floorvalue
    };

    if (this.provider != undefined) {
      send_data.client_base = this.provider;
    }
    console.log("INPATIENT send_data " + JSON.stringify(send_data))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gpat', (send_data),
      { headers: headers })
      .subscribe(
        response => {
          let timeStart: number = performance.now();
          var data = JSON.parse(JSON.stringify(response));
          this.floorarray=[];
          this.patientList=[];
          console.log("check datta "+JSON.stringify(data))
          if(data != undefined){          
          if(data.floors != undefined){
            this.floorarray.push("All")
            for(var k=0;k<data.floors.length;k++){
              this.floorarray.push(data.floors[k])
            }
           
          }
          if (data.patients != undefined) {
            this.count = data.patients.length;
            this.nodapp = false;
            for (var i = 0; i < data.patients.length; i++) {
              var values = data.patients[i];
              var pname, fname, lname, mname;
              if (values.middle_name != undefined) {
                if (values.middle_name != "") {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.middle_name) + " " + encrypt_decript.Decript(values.last_name);
                  mname = encrypt_decript.Decript(values.middle_name);
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                } else {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                  mname = "";
                }
              } else {
                pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                fname = encrypt_decript.Decript(values.first_name);
                lname = encrypt_decript.Decript(values.last_name);
                mname = "";
              }
              var ward_text;
              var wardname = "";
              if (values.ward_name != undefined) {
                wardname = values.ward_name;
              }else{
                wardname = "Bed Not allocated"
              }

              var bedno = "";
              if (values.bed_no != undefined && values.bed_no != "") {
                bedno = values.bed_no;
                ward_text = wardname + " - Bed:" + bedno;
              } else {
                bedno = "";
                ward_text = wardname
              }

              var cdate;
              if (values.created_date != undefined) {
                var cdval = values.created_date.split('-');
                cdate = cdval[2] + "-" + cdval[1] + "-" + cdval[0]
              } else {
                cdate = "";
              }
              var ctime;
              if (values.created_time != undefined) {
                ctime = Time_Formate(values.created_time);
              } else {
                ctime = "";
              }
              var image
              if (values.profile_image != undefined) {
                if (values.profile_image == "default") {
                  image = "../../../assets/img/default.jpg";

                } else {
                  image = ipaddress.Ip_with_img_address.toString() + values.profile_image;
                }
              }
              var gender,age,docname;
              if(values.age != undefined){
                age = values.age
              }
              if(values.doc_name != undefined){
                docname = "Dr." + values.doc_name
              }else{
                docname =""
              }
              if(values.gender != undefined){
                gender = encrypt_decript.Decript(values.gender)
              }
              var gender_age,address1,address2,location,district,zipcode,state,country,locationcheck,districtcheck;
              if(age != undefined && gender != undefined){
                gender_age = age +" / "+ gender
              }else{
                if(age == undefined ){
                  gender_age = gender
                }else{
                  gender_age = age
                }
              }

              if (values.address2 != null && values.address2 != undefined && values.address1 != undefined) {
                address1 = encrypt_decript.Decript(values.address1)+ ",";
                if (values.address2 != " " && values.address2 != "null") {
                  if (encrypt_decript.Decript(values.address2) == "") {
                    address2 == "";
                  } else {
                    address2 = encrypt_decript.Decript(values.address2)+",";
                  }
                }
              } else if (values.address1 != undefined) {
                  address1 = encrypt_decript.Decript(values.address1)+",";
              } else if (values.address2 != undefined) {
                  address2 = encrypt_decript.Decript(values.address2)+",";
              }
              
              if(values.location != undefined) {
                locationcheck = values.location
              }
              if(values.location != undefined){
                location = values.location + ',';
              }

              if(values.district != undefined){
                districtcheck = values.district
              } 
              if(values.district != undefined){
                district = values.district + ' -';
              }

              if(values.zipcode != undefined) {
                zipcode = encrypt_decript.Decript(values.zipcode)+",";
              }
    
              if (values.state != undefined) {
                state = values.state + " -";
              }   
              
              if (values.country != undefined) {
                country = values.country+".";
              }
              
              var salutation_desc;
              if(values.salutation_desc != undefined){
                salutation_desc = values.salutation_desc + ".";
              } else {
                salutation_desc = " ";
              }
              var admission_date,relation_id;
              if (values.admission_date != undefined) {
                admission_date = values.admission_date;
              }
              if (values.relation_id != undefined) {
                relation_id = values.relation_id;
              }
              this.patientList.push({
                client_reg_id: values.client_reg_id,
                bed_img: '../../../assets/ui_icons/bed_icon.svg',
                patient_name: salutation_desc + pname,
                admit_date: cdate,
                admit_time: ctime,
                admitdatetime: cdate+" "+ctime,
                admission_date: admission_date,
                relation_id: relation_id,
                ward_name: wardname,
                bed_no: bedno,
                mobile: values.mobile,
                profile_image: image,
                first_name: fname,
                last_name: lname,
                middle_name: mname,
                ward_text: ward_text,
                inpatient_id: values.inpatient_id,
                hosp_inp_id: values.hosp_inp_id,  
                speciality: values.speciality,          
                relationship_name: values.relationship_name,  
                disflag: false,
                ward_id: values.ward_id,
                doctor_name: docname,
                doc_reg_id: values.doc_reg_id,
                gender_age: gender_age,
                address1: address1,
                address2: address2, 
                locationcheck: locationcheck,
                location: location,
                districtcheck: districtcheck,    
                district: district,
                zipcode: zipcode,
                state: state,
                country: country,                      
                hosp_inp_idvalue: values.patient_id,   
                patient_namevalue: pname,
                mobilenumber: encrypt_decript.Decript(values.mobile),
                patient_id: values.patient_id,
                mlc:values.mlc_type
              })
              console.log("this.patientListthis.patientList"+JSON.stringify(this.patientList))
              Helper_Class.setinpatientfilterdata(this.patientList)
            }
            this.loader = true;

          } else {
            this.loader = true;
            this.nodapp = true;
          }
        }
        },
        error => {
          this.loader = true;
        });
  }

  changePatientType(type) {
    this.patientType = type;
    this.getPatients();
  }

  create_patient() {
  
    Doc_Helper.setFirstname(null);
    Doc_Helper.setMiddlename(null);
    Doc_Helper.setLastname(null);
    Helper_Class.setInpatientFlow("doctor");
    Doc_Helper.setSearchFlag("true");
    Doc_Helper.setClient_type("New");
    Doc_Helper.setMobile_no(null);
    Doc_Helper.setInpatientId(null);

    this.messageservice.sendMessage("inPatientViewInfo");
  }

  view_patient(inpatient_id, mobile, regid, array, fname, mname, lname, relid, relname) {
    Doc_Helper.setFirstname(fname);
    Doc_Helper.setMiddlename(mname);
    Doc_Helper.setLastname(lname);
    Doc_Helper.setClient_id(regid);
    Doc_Helper.setRel_id(relid);
    Doc_Helper.setrelationName(relname);

    Doc_Helper.setSearchFlag("true");
    Doc_Helper.setClient_type("Existing");
    Doc_Helper.setMobile_no(mobile);
    Doc_Helper.setInpatientId(inpatient_id);
    Doc_Helper.setInpatientFlow("list");
    Helper_Class.setInpatientFlow("doctor");
    this.messageservice.sendMessage("inPatientViewInfo");
  }

  //remove duplicates
  remove_duplicates(arr) {
    let obj = {};
    for (let i = 0; i < arr.length; i++) {
      obj[arr[i]] = true;
    }
    arr = [];
    for (let key in obj) {
      arr.push(key);
    }
    return arr;
  }

  checkUncheckAll() {
    this.checklist = this.patientList;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
  }

  isAllSelected() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    });
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].patient_id);
    }
    if (this.checklist.length == this.checkedList.length) {
      this.toastr.success('Thank you...', 'All rows are selected!');
    } else if (this.checkedList.length != 0) {
      this.toastr.success('Thank you...', `selected Id: ${this.checkedList}`);
    } else {
      this.checkedList = `No row seleted`;
      this.toastr.success('Thank you...', 'No row seleted!');
    }
  }

  clearAll() {
    this.checklist = this.patientList;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  }

  change_type(id, value) {
    if (value == "yes") {
      this.ynopt1 = true;
      this.patientType = "self";
      this.getPatients();
    } else {
      this.ynopt1 = false;
      this.patientType = "others";
      this.getPatients();
    }
  }
  //for super admin
  changeProviderType() {
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
      this.user_ids = Helper_Class.getInfo().user_id;
      this.is_admin = true;
      this.getDoctors();
    }
    else{
      this.user_id = this.userinfo.user_id;
    }
  }
  changedoc(){
    this.user_id = this.doctorid;
   
    this.getPatients();
  }
  getDoctors() {
    this.doctorList = [];
    var send_data = {
      country: 'IN',
      doc_reg_id: this.userinfo.user_id,
      type: this.userinfo.user_type,
      hptl_clinic_id: this.hptl_clinic_id,
    };
    console.log(
      'check---' +
        JSON.stringify({
          country: 'IN',
          doc_reg_id: this.userinfo.user_id,
          type: this.userinfo.user_type,
          hptl_clinic_id: this.hptl_clinic_id,
        })
    );
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp + 'adm/docbyhosp', send_data, { headers: headers })
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log('check---' + JSON.stringify(obj));
          if (obj != null) {
             for (var i = 0; i < obj.doctors.length; i++) {
              var doctorName = '';
              var fname, mname, lname;
              if (
                obj.doctors[i].middle_name != '' &&
                obj.doctors[i].middle_name != undefined
              ) {
                doctorName =
                  obj.doctors[i].first_name +
                  ' ' +
                  obj.doctors[i].middle_name +
                  ' ' +
                  obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name;
                mname = obj.doctors[i].middle_name;
                lname = obj.doctors[i].last_name;
              } else {
                doctorName =
                  obj.doctors[i].first_name + ' ' + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name;
                lname = obj.doctors[i].last_name;
              }

              this.doctorList.push({
                Doc_Name: doctorName,
                Doc_ID: obj.doctors[i].prov_id,
                type: this.userinfo.user_type,
              });
            }

            this.doctorid = this.doctorList[0].Doc_ID;
            this.user_id = this.userinfo.user_id;
            for (var i = 0; i < this.doctorList.length; i++) {
              if (this.doctorList[i].Doc_ID == this.userinfo.user_id) {
      
              }
            }
          }
        },
       
      );
  }


  viewChange(value){
    if(value == "graph"){
      this.tabular_format = false;
      this.graphicalformat = false;
      this.graph_defaultimg=true;

    }else if(value == "tabular"){
      this.graphicalformat = false;
      this.graph_defaultimg=false;
      this.tabular_format = true;
      // this.dischargebutton=true;
      // this.wardTransfer_flag=false;   
    }else{
      this.graphicalformat = true;
      this.graph_defaultimg=false;
      this.tabular_format = false;
      // this.dischargebutton=false;
      // this.wardTransfer_flag=false;
    }
  }
  changefloorvalue(value){
    this.floorvalue =value;
    this.getPatients();
  }
  
  isInpatSelected(list) {
    if(list.selected == true){
      for(var i=0;i<this.patientList.length;i++){
        if(list.inpatient_id == this.patientList[i].inpatient_id){
          this.patientList[i].selected=false;
        }
      }

    } else {
      for(var i=0;i<this.patientList.length;i++){
        if(list.inpatient_id == this.patientList[i].inpatient_id){
          this.patientList[i].selected=true;
        }
      }
    }
  }

  openDiscahrgeTimeLinePage() {
    if (this.patientList != undefined && this.patientList.length != 0) {
      this.disTimeLineList = [];
      for (var i = 0; i < this.patientList.length; i++) {
        if (this.patientList[i].selected == true) {
          this.disTimeLineList.push(this.patientList[i].inpatient_id)
        }
      }
      if (this.disTimeLineList != undefined && this.disTimeLineList.length == 1) {
        this.messageservice.sendMessage('discharge_timeline');
        FrontDesk_Helper.setDischargeTimeLineInpatientId(undefined);
        FrontDesk_Helper.setDischargeTimeLineInpatientId(this.disTimeLineList[0]);
      } else if (this.disTimeLineList.length == 0) { 
        this.toastr.error("Please select atleast one patient");
      } else {
        this.toastr.error("Please select maximum one patient");
      }  
    }
  }

  Filterbydata(data){
    this.mrno="";
    this.name="";
    this.location=""
    console.log("check data"+JSON.stringify(Helper_Class.getinpatientfilterdata()))
    if(data == "mrno"){
      this.mrnoflag =true;
      this.locationflag=false;
      this.nameflag =false;
      this.fdateflag =false;
      this.todateflag = false;
    }
    if(data == "name"){
      this.mrnoflag=false;
      this.nameflag =true;
      this.locationflag=false;
      this.fdateflag =false;
      this.todateflag = false;
    }
    if(data == "location"){
      this.mrnoflag=false;
      this.nameflag =false;
      this.locationflag =true;
      this.fdateflag =false;
      this.todateflag = false;
    }
    if(data == "date"){
      this.fdateflag =true;
      this.todateflag = true;
      this.mrnoflag=false;
      this.nameflag =false;
      this.locationflag =false;
      this.filtercheck()
    }
    this.patientList =Helper_Class.getinpatientfilterdata();
  
   }
   
   selectFromDate(e) {
    this.fromDate = e;   
  }
  
  selectToDate(e) {
    this.toDate = e;
  }
  fnameToUpper() {
    if (this.name != null)
      this.name = this.name.toLocaleUpperCase();
  }
  fnameToUpperlocation(){
    if (this.location != null)
    this.location = this.location.toLocaleUpperCase();
  }
  nambebased() {
      this.name = this.name.toLocaleUpperCase();
  }
 
  filtercheck() {
    if (this.nameflag == true) {
      let filteredArray = this.patientList.filter(obj => obj.patient_namevalue.toLowerCase().includes(this.name.toLowerCase()));
      console.log("filteredArray ***  --" + JSON.stringify(filteredArray))
      
     
    
      this.filtermethod("name", this.name, '')
    }
    if (this.mobileflag == true) {
      let filteredArray = this.patientList.filter(obj => obj.mobilenumber == this.mobile);
      console.log("filteredArray ***  --" + JSON.stringify(filteredArray))
     
  
   
      this.filtermethod("mobile", this.mobile, '')
      // this.mrno
    }


    if (this.locationflag == true) {
      let filteredArray1 = this.patientList.filter(obj => obj.locationcheck.trim().toLowerCase() === this.location.trim().toLowerCase());
      console.log("filteredArray loctaion  --" + JSON.stringify(filteredArray1))
     
      
     
      this.filtermethod("location", this.location, '')
      // this.mrno
    }

    if (this.mrnoflag == true) {

      let filteredArray = this.patientList.filter(obj => obj.hosp_inp_idvalue.toLowerCase() === this.mrno.toLowerCase());

      console.log("filteredArray mrno  --" + JSON.stringify(filteredArray))
      this.patientList = [];
      this.patientList = filteredArray;
      this.filtermethod("mr_no", this.mrno, '')
      // this.mrno
    }
    if (this.fdateflag == true && this.todateflag == true) {

      let fromDateObj = new Date(this.fromDate.split("-").reverse().join("-"));
      let toDateObj = new Date(this.toDate.split("-").reverse().join("-"));

      let filteredArraycheck = this.patientList.filter(obj => {
        // Convert admit_date to a Date object
        let admitDateObj = new Date(obj.admit_date.split("-").reverse().join("-"));

        // Check if admit_date is between fromDate and toDate
        return (admitDateObj >= fromDateObj && admitDateObj <= toDateObj);
      });
      console.log("filteredArray date   --" + JSON.stringify(filteredArraycheck))
    
  
    
      this.filtermethod("date", '', '')
      // this.mrno
    }

  }
  filtercheck1() {
    console.log("chekc value  --"+JSON.stringify(this.patientList))
    if(this.nameflag == true){
      this.getdatavalue('')
    
     // this.mrno
    }else{

    }
    if(this.mrnoflag == true){
      this.getdatavaluemrno(name)
    }
    if(this.locationflag == true){
      this.getdatavaluelocation(name)
   
     // this.mrno
    }
    
  
    if(this.fdateflag == true && this.todateflag == true){
  
        let fromDateObj = new Date(this.fromDate.split("-").reverse().join("-"));
        let toDateObj = new Date(this.toDate.split("-").reverse().join("-"));
  
        let filteredArraycheck = this.patientList.filter(obj => {
            // Convert admit_date to a Date object
            let admitDateObj = new Date(obj.admit_date.split("-").reverse().join("-"));
  
            // Check if admit_date is between fromDate and toDate
            return (admitDateObj >= fromDateObj && admitDateObj <= toDateObj);
        });
        console.log("filteredArray date   --"+JSON.stringify(filteredArraycheck))
        this.patientList=[];
        this.patientList=filteredArraycheck;
     // this.mrno
    }
  
  }
  filtermethod(type, name, name1) {
    var senddata;
    if (type == "date") {
      senddata = {
        filter: type,
        fdate: (this.fromDate),
        tdate: (this.toDate),hptl_clinic_id: this.hospital_id
      }
    }
    
    if (type == "name") {
      senddata = {
        filter: type,
        fname: encrypt_decript.Encript(this.name).toString(),hptl_clinic_id: this.hospital_id
      }
    }
    if (type == "mobile") {
      senddata = {
        filter: type,
        mobile:  encrypt_decript.Encript(this.mobile).toString(),
        hptl_clinic_id: this.hospital_id
        // tdate:Date_Formate(this.toDate)
      }
    }
    if (type == "mr_no") {
      senddata = {
        filter: type,
        mr_no: this.mrno, hptl_clinic_id: this.hospital_id
        // tdate:Date_Formate(this.toDate)
      }
    }
    console.log("filter basedheck ---" + JSON.stringify(senddata))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.filterbasedurl, senddata,
      { headers: headers }).subscribe(
        response => {
          var data = JSON.parse(JSON.stringify(response));
          console.log("check ---" + JSON.stringify(data))
          this.patientList = [];
          if (data.patients != undefined && data.patients.length != 0) {
            this.count = data.patients.length;
            for (var i = 0; i < data.patients.length; i++) {
              var values = data.patients[i];
              var pname, fname, lname, mname, fullname;
              if (values.middle_name != undefined) {
                if (values.middle_name != "") {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.middle_name) + " " + encrypt_decript.Decript(values.last_name);
                  fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.middle_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                  mname = encrypt_decript.Decript(values.middle_name);
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                } else {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                  fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                  mname = "";
                }
              } else {
                pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                fname = encrypt_decript.Decript(values.first_name);
                lname = encrypt_decript.Decript(values.last_name);
                mname = "";
              }
              var wardname = "", salutation_desc;
              if (values.salutation_desc != undefined) {
                salutation_desc = values.salutation_desc;
              }
              else {
                salutation_desc = " "
              }
              var docname
              var ward_text;
              var wardname = "";
              if (values.ward_name != undefined) {
                wardname = values.ward_name;
              }
              else {
                wardname = "Bed Not allocated"
              }
              var bedno = "";
              if (values.bed_no != undefined && values.bed_no != "") {
                bedno = values.bed_no;

                ward_text = wardname + " - " + bedno;
              } else {
                bedno = "";
                ward_text = wardname
              }

              var surdate = "";
              if (values.surgery_date != undefined) {
                var sdate = values.surgery_date.split('-');
                surdate = sdate[2] + "-" + sdate[1] + "-" + sdate[0];
              } else {
                sdate = "";
              }
              // var disflag;
              // if(values.discharge_date !=undefined){
              //   disflag = true;
              // } else {
              //   disflag = false;
              // }
              var cdate;
              if (values.created_date != undefined) {
                var cdval = values.created_date.split('-');
                cdate = cdval[2] + "-" + cdval[1] + "-" + cdval[0]
              } else {
                cdate = "";
              }
              var ctime;
              if (values.created_time != undefined) {
                ctime = Time_Formate(values.created_time);
              } else {
                ctime = "";
              }
              var image
              if (values.profile_image != undefined) {
                if (values.profile_image == "default") {
                  image = "../../../assets/img/default.jpg";

                } else {
                  image = ipaddress.Ip_with_img_address.toString() + values.profile_image;


                }
              }
              if (values.doc_name != undefined) {
                docname = "Dr." + values.doc_name
              } else {
                docname = ""
              }
              var gender, age, location, district;
              if (values.age != undefined) {
                age = values.age
              }
              if (values.gender != undefined) {
                gender = encrypt_decript.Decript(values.gender)
              }
              var gender_age, locationcheck, districtcheck;
              if (age != undefined && gender != undefined) {
                gender_age = age + "/" + gender
              } else {
                if (age == undefined) {
                  gender_age = gender
                } else {
                  gender_age = age
                }
              }
              if (values.location != undefined) {
                locationcheck = values.location
              }
              if (values.district != undefined) {
                districtcheck = values.district
              }
              if (values.hosp_inp_id != undefined) {
                var hosp_inp_id1 = values.hosp_inp_id.split("-");
                var hosp_inp_id2 = hosp_inp_id1[0] + hosp_inp_id1[1];
              }
              // if (this.wardTranfer == 'wardTransfer') {
              //   this.wardTransfer_flag = true;
              // } else if (this.wardTranfer == 'Patients') {
              //   this.wardTransfer_flag = false;
              // }
          
              this.patientList.push({
                client_reg_id: values.client_reg_id,
                age:age,
                bed_img: '../../../assets/ui_icons/bed_icon.svg',
                patient_name: salutation_desc + " " + pname,
                patient_namevalue: pname,
                fullname: fullname,
                admit_date: cdate,
                admit_time: ctime,
                admitdatetime: cdate + " " + ctime,
                ward_name: wardname,
                bed_no: bedno,
                mobile: values.mobile,

                profile_image: image,
                first_name: fname,
                last_name: lname,
                middle_name: mname,
                ward_text: ward_text,
                inpatient_id: values.inpatient_id,
                hosp_inp_id: values.hosp_inp_id,
                speciality: values.speciality,
                surgery_date: surdate,
                disflag: false,
                ward_id: values.ward_id,
                doctor_name: docname,
                gender_age: gender_age,
                mlc:values.mlc_type,
                districtcheck: districtcheck, locationcheck: locationcheck, hosp_inp_idvalue: hosp_inp_id2, mobilenumber: encrypt_decript.Decript(values.mobile)
              });
              //Helper_Class.setinpatientfilterdata(this.patientList)
              console.log("this.patientListthis.patientList" + JSON.stringify(this.patientList))
            }
          } else {
           // this.count = "No patient(s)"
           this.count = "0"
          }
          // var obj = JSON.parse(JSON.stringify(data));
          // if (obj.specializations != null) 
          //   this.splList = obj.specializations;
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
  }
  
  getdatavalue(value){
    console.log("filteredArray ***  --"+JSON.stringify(this.name))

    if (!this.name || this.name.trim() === '') {
      console.log('Input value is empty');
      this.patientList = Helper_Class.getinpatientfilterdata();
  
      // Handle the empty value case
    } else {
      this.patientList=[]
      this.filtermethod("name", this.name, '')
      // Your logic for non-empty value
    }
  }
 
  getdatavaluemrno(value){
    console.log("check mr nooooo")
    this.patientList=[];
    if (!this.mrno || this.mrno.trim() === '') {
      console.log('Input value is empty');
      this.patientList = Helper_Class.getinpatientfilterdata();
  
      // Handle the empty value case
    } else {
      this.patientList=[];
      if(this.mrnoflag == true){
        this.filtermethod("mr_no", this.mrno, '')

      }
    }
  
  }
  getdatavaluelocation(value){
    this.patientList=[];
    console.log("loc check "+this.location)
    if (!this.location || this.location.trim() === '') {
      console.log('Input value is empty');
      this.patientList = Helper_Class.getinpatientfilterdata();
  
      // Handle the empty value case
    } else {
      this.patientList =[]
      this.filtermethod("location", this.location, '')

      // Your logic for non-empty value
    }
  }
}
