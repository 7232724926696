import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Helper_Class } from '../../helper_class';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Chart } from 'chart.js';
import { DatePipe } from '@angular/common';
import { Date_Formate, Time_Formate } from 'src/assets/js/common';
import { NgxBarcodeModule } from 'ngx-barcode';

@Component({
  selector: 'app-diagnosis-report-upload',
  templateUrl: './diagnosis-report-upload.component.html',
  styleUrls: ['./diagnosis-report-upload.component.scss']
})
export class DiagnosisReportUploadComponent implements OnInit {
  public address1;
  public doctorname;
  public address2;
  public location;
  public state;
  public district;
  public country;
  public zipcode;
  dtOptions: DataTables.Settings = {};
  public pendingLists: any = [];
  public outSrcBills: any = [];
  public pendingData;
  public cbt_Template: string;
  public cbt_Template_list: any = [];
  public template_flag: boolean = true;
  public uploaded_file: any;

  //Pending
  reportView: boolean;
  updateReport: boolean;
  subTestValue: any;
  testObj: any = [];
  type: any;
  preTestID: any;
  sendInfo: any = [];
  testType: any;
  sequence: string;
  impression: any;
  culture: any;
  allow: boolean;
  updateArray: any = [];
  updateListArray: any;
  sendObj: any = [];
  testArray: any = [];
  reportArray: any = [];
  subTestID: any;
  subTestInfo: any;
  testInfo: any;
  sendObject: any = [];
  imgStr: any;
  locationArray: any = [];
  selecteLocation: any;
  diagLocation: any;
  subTestView2: boolean;
  subTestView: boolean;
  presTest: any;
  public subTestName;
  Clnt_App_ID: any;
  clientName1: any;
  clientName: any;
  subTestArray: any = [];
  testID: any;
  testDataArray: any = [];
  getClientArray: any;
  clientNameArray: any = [];
  clientDe: any;
  clientData: any;
  sendData: any = [];
  date: any = [];
  currentDate: any;
  selectedCenterArray: any = [];
  locationInfomation: any;
  public userID = "diagnosis";
  public chooseFileFlag: boolean;
  public UserType;
  public gender;
  public age;
  public SubtestRangeArray = [];
  public show = false;
  public clientRegID: string;
  public relationID: string;
  public subRelID: string;
  public fileType: string;

  public userInfo;
  public Select = "Select";
  public diagUserType;
  public created_by;
  public show_culture_test: boolean;
  public pendinglistFlag: boolean = true;
  public backBtnFlag: boolean = false;
  public saveFlag: boolean = false;
  public reporttype: string;
  public testgrp;

  // modules flags
  public newbutton: boolean = false
  public editbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public moduleList: any = [];

  public patient_historyList: any = [];

  fileName: string | null = null;

  //Editor
  @ViewChild('editor', { static: false }) editor: ElementRef;
  content;
  htmlString: string = '';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
      [{ 'direction': 'rtl' }], // text direction
      [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean'], // remove formatting button
    ],
  };
  editorStyle = {
    height: '300px;',
  };
  sampletype_data: any[];
  microBioTempData: any;
  patientName: any;
  patAge: any;
  patGender: any;
  dateList: any[];
  readinglist: any[];
  inputBgColor: string;
  subTestViewNew: boolean;
  template_flagNew: boolean;
  dropvalflag: boolean = false;

  user_id: any;
  diagid: any;
  is_admin: boolean;
  diag_centre_id: any;
  user_type: any;
  public mrno: string;
  public collectdate: string;
  public mobile: string;
  public testIds: any = [];
  public testdefval: any = [];
  private testtype: string;
  private reportType;
  private diagAppID;
  private presTestID;
  private presDiagID;
  private reportFlag;
  private testName;
  public reportDetails
  public reportImges
  public dicomDoctor: boolean
  public getretrivelArray: any = [];
  public createdBy;
  public subTestFlag: boolean;
  public diagTestReadingArray: any = [];
  public subTestNameFlag
  public testsName
  public testMethod
  clntcountry: any;
  patient_type: any;
  saveFlag2: boolean;
  public grouping_flag: boolean;
  specimen_id: any;

  // lipid profile related variables
  public totchole: any;
  public trigs: any;
  public hdl: any;
  public ldl: any;
  vldl: number;
  cho_hdl_ratio: number;
  ldl_hdl_ratio: number;
  // lipid profile related variables

  // Filter variables
  public filter_type: any = 'date';
  public searchInput: any;
  public from_date: any;
  public to_date: any;
  public mr_no_nameFlag: boolean = false;
  public date_Flag: boolean = true;
  public filter_placeholder: string;
  public filteredPendingList: any = [];

  format: '' | 'CODE128' | 'CODE128A' | 'CODE128B' | 'CODE128C' | 'EAN' | 'UPC' | 'EAN8' | 'EAN5' | 'EAN2' | 'CODE39' | 'ITF14' | 'MSI' | 'MSI10' | 'MSI11' | 'MSI1010' | 'MSI1110' | 'pharmacode' | 'codabar';

  elementType: 'svg';
  lineColor = '#000000';
  width = 0.9;
  barcodeheight = 23;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 11;
  background = '#ffffff';
  margin = 2;
  marginTop = 2;
  marginBottom = 2;
  marginLeft = 2;
  marginRight = 2;

  public sample_id: any = [];
  patient_name: any;
  mrval: any;
  sample_print_array: any[];
  hbA1c: any;
  mean_bloodGlucose: any;
  improbable_array: any = [];

  bilitubin_total: any;
  bilirubin_direct: any;
  bilirubin_indirect: any;
  total_Protein: any;
  albumin: any;
  globulin: any;
  ag_ratio: any;

  public countFlag: boolean = true;
  patHistCanvas: any;

  constructor(public messageservice: MenuViewService, public service: CommonDataService, public toastr: ToastrService, public http: Http, public router: Router, private cdr: ChangeDetectorRef, private datePipe: DatePipe) {
    this.gender = "0";
    this.age = "00";
    this.show = this.type == "Scan" ? true : false;
  }

  ngOnInit(): void {

    this.moduleList = Helper_Class.getmodulelist();
    this.reporttype = Diagnosis_Helper.getReportType();
    //this.reportType = Diagnosis_Helper.getReportUpload().type; //navParams.get("type");
    //this.diagAppID = Diagnosis_Helper.getReportUpload().diag_app_id; // navParams.get("diag_app_id");
    //this.presTestID = Diagnosis_Helper.getReportUpload().pres_test_id;  //navParams.get("pres_test_id");
    //this.presDiagID = Diagnosis_Helper.getReportUpload().pres_diag_id; // navParams.get("pres_diag_id");
    //this.reportFlag = Diagnosis_Helper.getReportUpload().Diag_report_flag; // navParams.get("Diag_report_flag");
    //this.testName = Diagnosis_Helper.getReportUpload().test_name;

    if (Helper_Class.getInfo().user_type == 'Admin') {
      // this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.userID = Helper_Class.getInfo().user_id;
      this.user_id = Helper_Class.getInfo().user_id;
      this.user_type = Helper_Class.getInfo().user_type;
      this.diag_centre_id = Helper_Class.getInfo().diag_centre_id;
      this.diagid = Helper_Class.getInfo().diag_centre_id;
      this.is_admin = false;
      // this.getNurseList();
      this.getDate();
      // this.getPendingList();

    } else {
      this.user_id = Diagnosis_Helper.getUserID_Type().user_id;
      this.diagid = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.getDate();
    }

    if (this.moduleList != undefined) {
      for (var i = 0; i < this.moduleList.length; i++) {
        if (this.moduleList[i].module_id == "103" && this.reporttype == "pathology" || this.moduleList[i].module_id == "107" && this.reporttype == "radiology" || this.moduleList[i].module_id == "111" && this.reporttype == "microbiology") {
          console.log(JSON.stringify(this.moduleList[i]));
          if (this.moduleList[i].edit == "1") {
            this.editbutton = true;//update button
          }
          if (this.moduleList[i].create == "1") {
            this.newbutton = true;
          }
          if (this.moduleList[i].delete == "1") {
            this.deletebutton = true;
          }
          if (this.moduleList[i].print == "1") {
            this.printbutton = true;
          }
          if (this.moduleList[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }

    this.userInfo = Helper_Class.getInfo();
    if (this.userInfo.first_name != null) {
      if (this.userInfo.middle_name != null && this.userInfo.middle_name != "")
        this.created_by = this.userInfo.first_name + " " + this.userInfo.middle_name + " " + this.userInfo.last_name;
      else
        this.created_by = this.userInfo.first_name + " " + this.userInfo.last_name;
    }
    this.diagUserType = Helper_Class.getInfo().user_type;
    this.subTestView = false;
    this.subTestViewNew = false;
    this.subTestView2 = false;
    this.show_culture_test = false;

    this.chooseFileFlag = true;
    this.getLocation();
    // this.getDate();
    this.service.get_diag_location();

    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[0, 'asc']],
      "columnDefs": [
        // { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search patient name, test type, test etc,."
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
  }

  getLocation() {
    this.clientNameArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/diagloc/", JSON.stringify({
      diag_name: this.diag_centre_id,
      flag: "name",
      imei: Helper_Class.getIPAddress(),
      prov_id: this.user_id,
      type: this.UserType,
    }), { headers: headers }).subscribe(
      data => {
        if (data.json().diag_loc != null) {
          this.locationInfomation = data.json().diag_loc;
          this.selectedCenterArray = [];

          for (var i = 0; i < this.locationInfomation.length; i++) {
            this.selectedCenterArray = [{
              name: this.locationInfomation[0].diag_centre_name,
              id: this.locationInfomation[0].diag_centre_id,
              address1: this.locationInfomation[0].address1,
              address2: this.locationInfomation[0].address2,
              loc: this.locationInfomation[0].diag_loc,
              state: this.locationInfomation[0].state,
              city: this.locationInfomation[0].city,
              country: this.locationInfomation[0].country,
              zipcode: this.locationInfomation[0].zipcode,
              telephone: this.locationInfomation[0].telephone
            }];
            this.diagLocation = this.locationInfomation[0];

            if (this.selecteLocation == this.locationInfomation[i].diag_centre_id) {
              this.selectedCenterArray = [{
                name: this.locationInfomation[i].diag_centre_name,
                id: this.locationInfomation[i].diag_centre_id,
                address1: this.locationInfomation[i].address1,
                address2: this.locationInfomation[i].address2,
                loc: this.locationInfomation[i].diag_loc,
                state: this.locationInfomation[i].state,
                city: this.locationInfomation[i].city,
                country: this.locationInfomation[i].country,
                zipcode: this.locationInfomation[i].zipcode,
                telephone: this.locationInfomation[i].telephone
              }];
              this.diagLocation = this.locationInfomation[i];
            }
          }

          if (this.selecteLocation == undefined) {
            for (var i = 0; i < this.locationInfomation.length; i++) {
              this.locationArray = {
                diag_centre_id: this.locationInfomation[0].diag_centre_id,
                diag_centre_name: this.locationInfomation[0].diag_centre_name,
                loc: this.locationInfomation[0].diag_loc
              };
            }
          }
        }
      }, error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }

  getDateFormate(d) {
    var month = (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1);
    var day = (d.getDate() < 10 ? '0' : '') + d.getDate();
    var temp = d.getFullYear() + '-' + month + '-' + day;
    return temp;
  }

  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "adm/curdate", JSON.stringify({
      country: ipaddress.country_code
    }), { headers: headers }).subscribe(
      data => {
        if (data.json().current_date != null) {
          this.currentDate = data.json().current_date;
          var d = new Date();
          d.setDate(d.getDate() - 7);
          var e = this.getDateFormate(d);
          this.from_date = e;
          // this.from_date = data.json().current_date;
          this.to_date = data.json().current_date;
          var new_day = this.currentDate.split('-');
          var current_date_data = new_day[2] + "-" + new_day[1] + "-" + new_day[0]
          this.date = [{
            day: current_date_data
          }];
          this.getPendingList();
        }
      }, error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });

    this.testArray = [];
    this.sendObject = [];
    this.reportArray = [];
  }

  getClient(i, data) {
    this.selecteLocation = i.diag_centre_id;
    var utype;
    if (this.userInfo.user_type == "diagnosis") {
      utype = "diagnosis";
    } else {
      utype = "diag_user";
    }
    if (this.clientName.length >= 3) {
      this.sendData = {
        diag_reg_id: this.user_id,
        location: i.diag_loc,
        first_name: this.clientName.toLocaleUpperCase(),
        diag_user: utype
      }
      this.clientNameArray = [];
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/getdiaapp/", JSON.stringify(
        this.sendData
      ), { headers: headers }).subscribe(
        data => {

          if (data.json().appointment_id != null) {
            this.clientData = data.json().appointment_id;
            console.log("this.clientData", this.clientData)
            for (var i = 0; i < this.clientData.length; i++) {
              if (this.clientData[i].first_name != undefined) {
                if (this.clientData[i].last_name != undefined) {
                  if (encrypt_decript.Decript(this.clientData[i].first_name) != "" && encrypt_decript.Decript(this.clientData[i].last_name) != "") {
                    this.clientDe = encrypt_decript.Decript(this.clientData[i].first_name) + " " + encrypt_decript.Decript(this.clientData[i].last_name)
                  }
                } else {
                  this.clientDe = encrypt_decript.Decript(this.clientData[i].first_name)
                }
              } else {
                this.clientDe = "";
              }
              if ((this.clientData[i].last_name) != undefined && (this.clientData[i].first_name)) {
                this.clientDe = encrypt_decript.Decript(this.clientData[i].first_name) + " " + encrypt_decript.Decript(this.clientData[i].last_name);
              }

              this.clientNameArray.push({
                name: this.clientDe,
                id: this.clientData[i].app_id,
                client_reg_id: this.clientData[i].client_reg_id,
                rel_id: this.clientData[i].relation_id,
                sub_rel_id: this.clientData[i].sub_rel_id
              });
            }
            console.log("this.clientNameArray", this.clientNameArray);
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
    }
  }

  onTemplateChanged = (event) => {
    this.cbt_Template = event.html
  }

  changeClient(info) {
    this.clientName1 = info;
    this.clientName = info.id + " - " + info.name;
    this.clientNameArray = [];
    this.subTestView = false;
    this.subTestView2 = false;
    this.show_culture_test = false;
    this.presTest = undefined;
    this.subTestName = undefined;
    this.testID = info.id;
    this.clientRegID = info.client_reg_id;
    this.relationID = info.rel_id;
    this.subRelID = info.sub_rel_id;

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "prescription/diapresdetappid/",
      JSON.stringify({ appointment_id: info.id }), { headers: headers }).subscribe(
        data => {
          if (data.json() != null) {
            this.getClientArray = data.json();
            if (data.json().gender != null) {
              this.gender = encrypt_decript.Decript(data.json().gender.toString());
            }
            if (data.json().age != null) {
              this.age = data.json().age;
            }
            this.testDataArray = [];
            this.subTestArray = [];
            this.getClientArray.tests.forEach(element => {
              if (element == "blood_test") {
                this.testDataArray.push({
                  Data: "Blood Test",
                  Normal: element
                });
              } else if (element == "urine_test") {
                this.testDataArray.push({
                  Data: "Urine Test",
                  Normal: element
                });
              } else if (element == "scan_test") {
                this.testDataArray.push({
                  Data: "Scan Test",
                  Normal: element
                });
              } else if (element == "faeces_test") {
                this.testDataArray.push({
                  Data: "Faeces Test",
                  Normal: element
                });
              } else if (element == "xray_test") {
                this.testDataArray.push({
                  Data: "Xray Test",
                  Normal: element
                });
              } else if (element == "ultrasound_test") {
                this.testDataArray.push({
                  Data: "Ultra Sound",
                  Normal: element
                });
              } else if (element == "culture_test") {
                this.testDataArray.push({
                  Data: "Culture",
                  Normal: element
                });
              } else if (element == "biopsy_test") {
                this.testDataArray.push({
                  Data: "Biopsy",
                  Normal: element
                });
              } else {
                this.testDataArray.push({
                  Data: element,
                  Normal: element
                });
              }
            });
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        })
  }

  changeClientTest(test) {
    this.subTestName = undefined;
    this.testInfo = test;

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "prescription/dtestsbyappid/", JSON.stringify({ appointment_id: this.testID, group: test.Normal }), { headers: headers }).subscribe(
      data => {
        if (data.json().tests != null) {
          this.subTestArray = data.json().tests; this.subTestArray = data.json().tests;
        }
      }, error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }

  changeSubTestNew(tests_data) {
    this.countFlag = false;
    this.grouping_flag = true;
    this.saveFlag = true;
    this.SubtestRangeArray = [];
    this.cbt_Template_list = [];
    this.clientRegID = tests_data.client_reg_id;
    this.relationID = tests_data.relation_id;
    this.subRelID = tests_data.sub_rel_id;
    // this.saveFlag = false;
    this.backBtnFlag = true;
    this.patientName = tests_data.patient_name;
    this.patAge = tests_data.age;
    this.patGender = tests_data.gender;
    this.mrno = tests_data.dmrno;
    this.collectdate = tests_data.sample_collect_d_t;
    this.mobile = tests_data.mobile;
    var diagId = tests_data.diag_appointment_id;
    this.testInfo = tests_data.department;
    this.address1 = tests_data.address1;
    this.address2 = tests_data.address2;
    this.doctorname = tests_data.doctor_name;
    this.location = tests_data.location;
    this.state = tests_data.state;
    this.district = tests_data.district;
    this.country = tests_data.country;
    this.zipcode = tests_data.zipcode;
    this.patient_type = tests_data.pat_type;
    this.location = tests_data.clntlocation;
    this.district = tests_data.clntdistrict;
    this.state = tests_data.clntstate;
    this.clntcountry = tests_data.clntcountry;
    this.specimen_id = tests_data.sample_id;

    if (tests_data.doctor_name != undefined) {
      this.doctorname = tests_data.doctor_name;
    }


    console.log(tests_data)
    for (var i = 0; i < tests_data.tests.length; i++) {
      var dropvalflag = false;
      if (diagId != null) {
        if (tests_data.tests[i].template_id != undefined) {
          this.cbt_Template_list.push({
            template_desc: tests_data.tests[i].template_desc
          });
          this.template_flagNew = false;
          this.subTestViewNew = true;

        } else {
          this.template_flagNew = true;
          if (this.testInfo == "laboratory") {
            if (tests_data.tests[i].test_type == "test") {
              if (tests_data.tests[i].subtests != undefined && tests_data.tests[i].subtests.length == 1) {
                var refrange = "";
                var dropvalflag1 = false;
                this.testdefval = [];
                var min_nor_value = "", max_nor_value = "", min_abnor_value = "", max_abnor_value = "", min_crit_value = "", max_crit_value = "";
                if (tests_data.tests[i].subtests[0].reference_value != undefined) {
                  if (tests_data.tests[i].subtests[0].reference_value[0].min_nor_value) {
                    refrange = tests_data.tests[i].subtests[0].reference_value[0].min_nor_value + " - " + tests_data.tests[i].subtests[0].reference_value[0].max_nor_value;
                    min_nor_value = tests_data.tests[i].subtests[0].reference_value[0].min_nor_value;
                    max_nor_value = tests_data.tests[i].subtests[0].reference_value[0].max_nor_value
                    min_abnor_value = tests_data.tests[i].subtests[0].reference_value[0].min_abnor_value;
                    max_abnor_value = tests_data.tests[i].subtests[0].reference_value[0].max_abnor_value;
                    min_crit_value = tests_data.tests[i].subtests[0].reference_value[0].min_crit_value;
                    max_crit_value = tests_data.tests[i].subtests[0].reference_value[0].max_crit_value;
                  } else {
                    refrange = "";
                  }
                }

                if (tests_data.tests[i].subtests[0].conduction_format != undefined && tests_data.tests[i].subtests[0].conduction_format == "4") {
                  dropvalflag1 = true;
                }

                if (tests_data.tests[i].subtests[0].drop_default != undefined) {
                  var defval1 = tests_data.tests[i].subtests[0].drop_default.split(",");
                  for (var l = 0; l < defval1.length; l++) {
                    this.testdefval.push(defval1[l]);
                  }
                }

                this.testIds.push(tests_data.tests[i].diag_test_id)
                this.SubtestRangeArray.push({
                  id: i + 1,
                  sub_test_name: tests_data.tests[i].subtests[0].sub_test_name,
                  test_range: refrange,
                  unit: tests_data.tests[i].subtests[0].unit,
                  sub_test_id_data: tests_data.tests[i].subtests[0].tests_list_id,
                  test_id: tests_data.tests[i].subtests[0].sub_test_id,
                  main_test_id: tests_data.tests[i].test_id,
                  min_nor_value: min_nor_value,
                  max_nor_value: max_nor_value,
                  min_abnor_value: min_abnor_value,
                  max_abnor_value: max_abnor_value,
                  min_crit_value: min_crit_value,
                  max_crit_value: max_crit_value,
                  range_value: "Normal",
                  tests: [],
                  drop_default: this.testdefval,
                  dropvalflag: dropvalflag1,
                  test_type: tests_data.tests[i].test_type,
                  test_range_value: tests_data.tests[i].subtests[0].reading,
                  is_disabled: false,
                });


              } else if (tests_data.tests[i].subtests != undefined && tests_data.tests[i].subtests.length > 1) {
                var subtest_array = [];
                for (var j = 0; j < tests_data.tests[i].subtests.length; j++) {
                  var refrange = "";
                  var dropvalflag2 = false;
                  this.testdefval = [];
                  var min_nor_value = "", max_nor_value = "", min_abnor_value = "", max_abnor_value = "", min_crit_value = "", max_crit_value = "";
                  if (tests_data.tests[i].subtests[j].reference_value != undefined) {
                    if (tests_data.tests[i].subtests[j].reference_value[0].min_nor_value) {
                      refrange = tests_data.tests[i].subtests[j].reference_value[0].min_nor_value + " - " + tests_data.tests[i].subtests[j].reference_value[0].max_nor_value;
                      min_nor_value = tests_data.tests[i].subtests[j].reference_value[0].min_nor_value;
                      max_nor_value = tests_data.tests[i].subtests[j].reference_value[0].max_nor_value
                      min_abnor_value = tests_data.tests[i].subtests[j].reference_value[0].min_abnor_value;
                      max_abnor_value = tests_data.tests[i].subtests[j].reference_value[0].max_abnor_value;
                      min_crit_value = tests_data.tests[i].subtests[j].reference_value[0].min_crit_value;
                      max_crit_value = tests_data.tests[i].subtests[j].reference_value[0].max_crit_value;
                    } else {
                      refrange = "";
                    }
                  }

                  if (tests_data.tests[i].subtests[j].conduction_format != undefined && tests_data.tests[i].subtests[j].conduction_format == "4") {
                    dropvalflag2 = true;
                  }

                  if (tests_data.tests[i].subtests[j].drop_default != undefined) {
                    var defval2 = tests_data.tests[i].subtests[j].drop_default.split(",");
                    for (var m = 0; m < defval2.length; m++) {
                      this.testdefval.push(defval2[m]);
                    }
                  }
                  this.testIds.push(tests_data.tests[i].diag_test_id)
                  subtest_array.push({
                    id: j + 1,
                    sub_test_name: tests_data.tests[i].subtests[j].sub_test_name,
                    test_range: refrange,
                    unit: tests_data.tests[i].subtests[j].unit,
                    sub_test_id_data: tests_data.tests[i].subtests[j].tests_list_id,
                    test_id: tests_data.tests[i].subtests[j].sub_test_id,
                    main_test_id: tests_data.tests[i].test_id,
                    min_nor_value: min_nor_value,
                    max_nor_value: max_nor_value,
                    min_abnor_value: min_abnor_value,
                    max_abnor_value: max_abnor_value,
                    min_crit_value: min_crit_value,
                    max_crit_value: max_crit_value,
                    range_value: "Normal",
                    drop_default: this.testdefval,
                    dropvalflag: dropvalflag2,
                    test_type: tests_data.tests[i].test_type,
                    test_range_value: tests_data.tests[i].subtests[j].reading,
                    is_disabled: false,
                  });
                }

                this.SubtestRangeArray.push({
                  id: i + 1,
                  sub_test_name: tests_data.tests[i].test_name,
                  test_range: refrange,
                  unit: tests_data.tests[i].subtests[0].unit,
                  sub_test_id_data: tests_data.tests[i].subtests[0].tests_list_id,
                  test_id: tests_data.tests[i].subtests[0].sub_test_id,
                  main_test_id: tests_data.tests[0].test_id,
                  min_nor_value: min_nor_value,
                  max_nor_value: max_nor_value,
                  min_abnor_value: min_abnor_value,
                  max_abnor_value: max_abnor_value,
                  min_crit_value: min_crit_value,
                  max_crit_value: max_crit_value,
                  range_value: "Normal",
                  tests: subtest_array,
                  drop_default: this.testdefval,
                  dropvalflag: dropvalflag2,
                  test_type: tests_data.tests[i].test_type,
                  is_disabled: false,
                });
              }
            } else if (tests_data.tests[i].test_type == "profile") {
              for (var j = 0; j < tests_data.tests[i].subtests.length; j++) {
                var profile_subtest_array = [];
                if (tests_data.tests[i].subtests[j].tests != undefined) {
                  var sub_dropvalflag = false;
                  for (var k = 0; k < tests_data.tests[i].subtests[j].tests.length; k++) {
                    // alert(tests_data.tests[i].subtests[j].tests[k].sub_test_name)
                    var sub_min_nor_value = "", sub_max_nor_value = "", sub_min_abnor_value = "", sub_max_abnor_value = "", sub_min_crit_value = "", sub_max_crit_value = "", sub_refrange = "";
                    if (tests_data.tests[i].subtests[j].tests[k].reference_value != undefined) {
                      if (tests_data.tests[i].subtests[j].tests[k].reference_value[0].min_nor_value != undefined) {
                        sub_refrange = tests_data.tests[i].subtests[j].tests[k].reference_value[0].min_nor_value + " - " +
                          tests_data.tests[i].subtests[j].tests[k].reference_value[0].max_nor_value;
                        sub_min_nor_value = tests_data.tests[i].subtests[j].tests[k].reference_value[0].min_nor_value;
                        sub_max_nor_value = tests_data.tests[i].subtests[j].tests[k].reference_value[0].max_nor_value
                        sub_min_abnor_value = tests_data.tests[i].subtests[j].tests[k].reference_value[0].min_abnor_value;
                        sub_max_abnor_value = tests_data.tests[i].subtests[j].tests[k].reference_value[0].max_abnor_value;
                        sub_min_crit_value = tests_data.tests[i].subtests[j].tests[k].reference_value[0].min_crit_value;
                        sub_max_crit_value = tests_data.tests[i].subtests[j].tests[k].reference_value[0].max_crit_value;
                      } else {
                        sub_refrange = "";
                      }
                    }

                    if (tests_data.tests[i].subtests[j].tests[k].conduction_format != undefined && tests_data.tests[i].subtests[j].tests[k].conduction_format == "4") {
                      sub_dropvalflag = true;
                    }

                    var test_defval_sub = [];

                    if (tests_data.tests[i].subtests[j].tests[k].drop_default != undefined) {
                      var defval_sub = tests_data.tests[i].subtests[j].tests[k].drop_default.split(",");
                      for (var l = 0; l < defval_sub.length; l++) {
                        test_defval_sub.push(defval_sub[l]);
                      }
                    }


                    profile_subtest_array.push({
                      sub_test_id: tests_data.tests[i].subtests[j].tests[k].sub_test_id,
                      sub_test_name: tests_data.tests[i].subtests[j].tests[k].sub_test_name,
                      unit: tests_data.tests[i].subtests[j].tests[k].unit,
                      conduction_format: tests_data.tests[i].subtests[j].tests[k].conduction_format,
                      tests_list_id: tests_data.tests[i].subtests[j].tests[k].tests_list_id,
                      drop_default: test_defval_sub,
                      dropvalflag: sub_dropvalflag,
                      min_nor_value: sub_min_nor_value,
                      max_nor_value: sub_max_nor_value,
                      min_abnor_value: sub_min_abnor_value,
                      max_abnor_value: sub_max_abnor_value,
                      min_crit_value: sub_min_crit_value,
                      max_crit_value: sub_max_crit_value,
                      test_range_value: tests_data.tests[i].subtests[j].tests[k].reading,
                      range_value: "Normal",
                      test_range: sub_refrange,
                      test_type: tests_data.tests[i].test_type,
                      is_disabled: false,
                    })
                  }
                }

                var refrange = "";
                var dropvalflag3 = false;
                this.testdefval = [];
                var min_nor_value = "", max_nor_value = "", min_abnor_value = "", max_abnor_value = "", min_crit_value = "", max_crit_value = "";
                if (tests_data.tests[i].subtests[j].reference_value != undefined) {
                  if (tests_data.tests[i].subtests[j].reference_value[0].min_nor_value) {
                    refrange = tests_data.tests[i].subtests[j].reference_value[0].min_nor_value + " - " + tests_data.tests[i].subtests[j].reference_value[0].max_nor_value;
                    min_nor_value = tests_data.tests[i].subtests[j].reference_value[0].min_nor_value;
                    max_nor_value = tests_data.tests[i].subtests[j].reference_value[0].max_nor_value
                    min_abnor_value = tests_data.tests[i].subtests[j].reference_value[0].min_abnor_value;
                    max_abnor_value = tests_data.tests[i].subtests[j].reference_value[0].max_abnor_value;
                    min_crit_value = tests_data.tests[i].subtests[j].reference_value[0].min_crit_value;
                    max_crit_value = tests_data.tests[i].subtests[j].reference_value[0].max_crit_value;
                  } else {
                    refrange = "";
                  }
                }

                if (tests_data.tests[i].subtests[j].conduction_format != undefined && tests_data.tests[i].subtests[j].conduction_format == "4") {
                  dropvalflag3 = true;
                }

                if (tests_data.tests[i].subtests[j].drop_default != undefined) {
                  var defval3 = tests_data.tests[i].subtests[j].drop_default.split(",");
                  for (var n = 0; n < defval3.length; n++) {
                    this.testdefval.push(defval3[n]);
                  }
                }

                this.testIds.push(tests_data.tests[i].diag_test_id)
                this.SubtestRangeArray.push({
                  id: j + 1,
                  sub_test_name: tests_data.tests[i].subtests[j].sub_test_name,
                  test_range: refrange,
                  unit: tests_data.tests[i].subtests[j].unit,
                  sub_test_id_data: tests_data.tests[i].subtests[j].tests_list_id,
                  test_id: tests_data.tests[i].subtests[j].sub_test_id,
                  profile_id: tests_data.tests[i].test_id,
                  min_nor_value: min_nor_value,
                  max_nor_value: max_nor_value,
                  min_abnor_value: min_abnor_value,
                  max_abnor_value: max_abnor_value,
                  min_crit_value: min_crit_value,
                  max_crit_value: max_crit_value,
                  range_value: "Normal",
                  tests: profile_subtest_array,
                  drop_default: this.testdefval,
                  dropvalflag: dropvalflag3,
                  test_type: tests_data.tests[i].test_type,
                  test_range_value: tests_data.tests[i].subtests[j].reading,
                  is_disabled: false,
                });

              }
            }

            // var refrange;
            // if (tests_data.tests[i].min_nor_value != undefined) {
            //   refrange = tests_data.tests[i].min_nor_value + " - " + tests_data.tests[i].max_nor_value;
            // } else {
            //   refrange = "";
            // }

            // this.testIds.push(tests_data.tests[i].diag_test_id)
            // this.SubtestRangeArray.push({
            //   sub_test_name: tests_data.tests[i].test_name,
            //   test_range: refrange,
            //   unit: tests_data.tests[i].unit,
            //   sub_test_id_data: tests_data.tests[i].diag_test_id,

            //   min_nor_value: tests_data.tests[i].min_nor_value,
            //   max_nor_value: tests_data.tests[i].max_nor_value,
            //   min_abnor_value: tests_data.tests[i].min_abnor_value,
            //   max_abnor_value: tests_data.tests[i].max_abnor_value,
            //   min_crit_value: tests_data.tests[i].min_crit_value,
            //   max_crit_value: tests_data.tests[i].max_crit_value,
            //   range_value: "Normal",
            // });

            console.log("this.SubtestRangeArray", this.SubtestRangeArray)
            console.log("this.SubtestRangeArray-stringified", JSON.stringify(this.SubtestRangeArray));
            this.subTestViewNew = true;
            this.pendinglistFlag = false;
          }
        }
      }
    }
  }

  changeSubTest(diagId, testName, testId, data, test_data) {
    this.countFlag = false;
    this.grouping_flag = false;
    this.uploaded_file = '';
    this.doctorname = data.doctor_name;
    this.clntcountry = data.clntcountry;
    this.patient_type = data.pat_type;
    this.specimen_id = data.sample_id;

    if (test_data.conduct_format != undefined && test_data.conduct_format == 3) {
      this.SubtestRangeArray = [];
      this.subTestValue = "subtest";
      this.subTestInfo = testId;
      this.pendinglistFlag = false;
      this.backBtnFlag = true;
      this.saveFlag = true;
      this.clientRegID = data.client_reg_id;
      this.relationID = data.relation_id;
      this.subRelID = data.sub_rel_id;
      this.testtype = test_data.test_type;
      var micro_savedata;
      micro_savedata = {
        diag_app_id: diagId,
        group: "lab_test",
        app_id: this.subTestInfo,
        client_reg_id: this.clientRegID,
        created_by: this.created_by,
        relation_id: this.relationID,
        sub_rel_id: this.subRelID,
        country: ipaddress.country_code,
        test_id: testId,
        test_details: data,
        test_type: data.tests[0].test_type,
        mrno: data.dmrno,
        patient_name: data.patient_name,
        age: data.age,
        gender: data.gender,
        mobile: data.mobile,
        pat_type: data.pat_type,
        doctor_name: data.doctor_name,
        address: data.address1,
        location: data.clntlocation,
        district: data.clntdistrict,
        state: data.clntstate,
        clnt_country: data.clntcountry,
        zipcode: data.zipcode,
        test_name: test_data.test_name,
        template_id: test_data.template_id,
        template_name: test_data.template_name,
        template_desc: test_data.template_desc,
        specimen_id: data.sample_id,
        sensitive_id: test_data.sensitive_id,
      }

      Diagnosis_Helper.setMicrobiologyUploadData(micro_savedata)
      this.messageservice.sendMessage("specimen-report-template");

    } else {
      this.SubtestRangeArray = [];
      this.subTestValue = "subtest";
      this.subTestInfo = testId;
      this.pendinglistFlag = false;
      this.backBtnFlag = true;
      this.saveFlag = true;
      this.clientRegID = data.client_reg_id;
      this.relationID = data.relation_id;
      this.subRelID = data.sub_rel_id;

      this.address1 = data.address1;
      this.location = data.clntlocation;
      this.district = data.clntdistrict;
      this.state = data.clntstate;
      this.country = data.clntcountry;
      this.zipcode = data.zipcode;

      this.getPatientHistory(data, test_data.diag_test_id);
      this.inputBgColor = '#ffffff';

      var testTypeName = data.department;
      console.log("changeSubTest---data", data)
      console.log(JSON.stringify({
        app_id: diagId,
        group: testTypeName,
        pres_diag_id: testId,
        age: data.age,
        gender: data.gender,
        test_type: test_data.test_type,
      }))

      this.patientName = data.patient_name;
      this.patAge = data.age;
      this.patGender = data.gender;
      this.testInfo = testTypeName;
      this.mrno = data.dmrno;
      this.mobile = data.mobile;
      this.testtype = test_data.test_type;
      if (diagId != null) {
        if (test_data.template_id != undefined) {
          this.cbt_Template = test_data.template_desc;
          this.template_flag = false;

        } else {
          this.template_flag = true;
          if (testTypeName == "laboratory") {
            this.chooseFileFlag = true;
            this.subTestView2 = false;
            this.show_culture_test = false;
            this.Clnt_App_ID = diagId;

            var headers = new Headers();
            headers.append('Content-Type', 'application/json');
            this.http.post(ipaddress.getIp.toString() + "prescription/dsubtests/",
              JSON.stringify({
                app_id: diagId,
                group: testTypeName,
                pres_diag_id: testId,
                age: data.age,
                gender: data.gender,
                test_type: test_data.test_type,
              }), { headers: headers }).subscribe(
                data => {
                  console.log("data.json()", JSON.stringify(data.json()))
                  var subtests = data.json().sub_tests;

                  if (data.json() != undefined && data.json() != null) {
                    if (data.json().sub_tests != null) {
                      for (var i = 0; i < subtests.length; i++) {
                        this.testdefval = [];
                        var dropvalflag = false;
                        if (subtests[i].reference_value != undefined) {
                          var refrange;
                          var min_nor_value, max_nor_value, min_abnor_value, max_abnor_value, min_crit_value, max_crit_value
                          if (subtests[i].reference_value[0].min_nor_value != undefined) {
                            refrange = subtests[i].reference_value[0].min_nor_value + " - " +
                              subtests[i].reference_value[0].max_nor_value;
                            min_nor_value = subtests[i].reference_value[0].min_nor_value;
                            max_nor_value = subtests[i].reference_value[0].max_nor_value
                            min_abnor_value = subtests[i].reference_value[0].min_abnor_value;
                            max_abnor_value = subtests[i].reference_value[0].max_abnor_value;
                            min_crit_value = subtests[i].reference_value[0].min_crit_value;
                            max_crit_value = subtests[i].reference_value[0].max_crit_value;
                          } else {
                            refrange = "";
                          }
                        } else {
                          refrange = "";
                          min_nor_value = "";
                          max_nor_value = "";
                          min_abnor_value = "";
                          max_abnor_value = "";
                          min_crit_value = "";
                          max_crit_value = "";
                        }

                        if (subtests[i].conduction_format != undefined && subtests[i].conduction_format == "4") {
                          dropvalflag = true;
                        }

                        if (subtests[i].drop_default != undefined) {
                          var defval = subtests[i].drop_default.split(",");
                          for (var j = 0; j < defval.length; j++) {
                            this.testdefval.push(defval[j]);
                          }
                        }

                        var subtest_array = [];
                        if (subtests[i].tests != undefined) {
                          for (var s = 0; s < subtests[i].tests.length; s++) {
                            // subtests[i].tests[s].test_range_value = subtests[i].tests[s].reading;
                            var test_defval_sub = [];

                            if (subtests[i].tests[s].drop_default != undefined) {
                              var defval_sub = subtests[i].tests[s].drop_default.split(",");
                              for (var l = 0; l < defval_sub.length; l++) {
                                test_defval_sub.push(defval_sub[l]);
                              }
                            }


                            var sub_min_nor_value, sub_max_nor_value, sub_min_abnor_value, sub_max_abnor_value, sub_min_crit_value, sub_max_crit_value, sub_refrange;
                            if (subtests[i].tests[s].reference_value != undefined) {
                              if (subtests[i].tests[s].reference_value[0].min_nor_value != undefined) {
                                sub_refrange = subtests[i].tests[s].reference_value[0].min_nor_value + " - " +
                                  subtests[i].tests[s].reference_value[0].max_nor_value;
                                sub_min_nor_value = subtests[i].tests[s].reference_value[0].min_nor_value;
                                sub_max_nor_value = subtests[i].tests[s].reference_value[0].max_nor_value
                                sub_min_abnor_value = subtests[i].tests[s].reference_value[0].min_abnor_value;
                                sub_max_abnor_value = subtests[i].tests[s].reference_value[0].max_abnor_value;
                                sub_min_crit_value = subtests[i].tests[s].reference_value[0].min_crit_value;
                                sub_max_crit_value = subtests[i].tests[s].reference_value[0].max_crit_value;
                              } else {
                                sub_refrange = "";
                                sub_min_nor_value = "";
                                sub_max_nor_value = "";
                                sub_min_abnor_value = "";
                                sub_max_abnor_value = "";
                                sub_min_crit_value = "";
                                sub_max_crit_value = "";
                              }
                            }

                            subtest_array.push({
                              sub_test_id: subtests[i].tests[s].sub_test_id,
                              sub_test_name: subtests[i].tests[s].sub_test_name,
                              unit: subtests[i].tests[s].unit,
                              conduction_format: subtests[i].tests[s].conduction_format,
                              tests_list_id: subtests[i].tests[s].tests_list_id,
                              drop_default: test_defval_sub,
                              min_nor_value: sub_min_nor_value,
                              max_nor_value: sub_max_nor_value,
                              min_abnor_value: sub_min_abnor_value,
                              max_abnor_value: sub_max_abnor_value,
                              min_crit_value: sub_min_crit_value,
                              max_crit_value: sub_max_crit_value,
                              test_range_value: subtests[i].tests[s].reading,
                              range_value: "Normal",
                              test_range: sub_refrange,
                              is_disabled: false,
                            })
                          }
                        }

                        this.SubtestRangeArray.push({
                          id: i + 1,
                          sub_test_name: subtests[i].sub_test_name,
                          test_range: refrange,
                          unit: subtests[i].unit,
                          sub_test_id_data: subtests[i].tests_list_id,
                          test_id: subtests[i].sub_test_id,
                          reading: subtests[i].reading,
                          min_nor_value: min_nor_value,
                          max_nor_value: max_nor_value,
                          min_abnor_value: min_abnor_value,
                          max_abnor_value: max_abnor_value,
                          min_crit_value: min_crit_value,
                          max_crit_value: max_crit_value,
                          drop_default: this.testdefval,
                          dropvalflag: dropvalflag,
                          range_value: "Normal",
                          test_range_value: subtests[i].reading,
                          tests: subtest_array,
                          is_disabled: false,
                        });

                        // console.log("this.testdefval-check",JSON.stringify(this.SubtestRangeArray))

                        if (subtests[i].reading != undefined) {
                          for (var j = 0; j < this.SubtestRangeArray.length; j++) {
                            if (this.SubtestRangeArray[j].sub_test_id_data == subtests[i].sub_test_id) {
                              this.checkRef(subtests[i].reading, this.SubtestRangeArray[j])
                            }
                          }
                        }
                      }
                      console.log("this.SubtestRangeArray", this.SubtestRangeArray)
                      this.subTestView = true;
                    }
                  }
                }, error => {
                  this.toastr.error(Message_data.getNetworkMessage());
                });
          } else {
            this.show = testTypeName != "laboratory" ? true : false;
            this.chooseFileFlag = false;
            this.subTestView = false;
            if (testTypeName == "culture") {
              this.subTestView2 = false;
              this.show_culture_test = true;
            } else {
              this.subTestView2 = true;
              this.show_culture_test = false;
            }
          }
        }
      }
      this.getReportDetails(diagId, testName, testId, data, test_data)
    }
  }

  changeSubTest2(subtest) {
    this.SubtestRangeArray = [];
    this.subTestValue = subtest;
    this.subTestInfo = subtest;
    this.pendinglistFlag = false;

    console.log(subtest)

    if (subtest != null) {
      if (this.presTest.Normal == "blood_test" || this.presTest.Normal == "urine_test" || this.presTest.Normal == "faeces_test") {
        this.chooseFileFlag = true;
        this.subTestView2 = false;
        this.show_culture_test = false;
        this.Clnt_App_ID = this.clientName1.id;

        console.log("changeSubTest2", {
          app_id: this.Clnt_App_ID,
          group: this.presTest.Normal,
          pres_diag_id: subtest.test_id
        })

        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + "prescription/dsubtests/",
          JSON.stringify({
            app_id: this.Clnt_App_ID,
            group: this.presTest.Normal,
            pres_diag_id: subtest.test_id
          }), { headers: headers }).subscribe(
            data => {
              if (data.json() != undefined && data.json() != null) {
                if (data.json().sub_tests != null) {
                  for (var i = 0; i < data.json().sub_tests.length; i++) {

                    this.SubtestRangeArray.push({
                      sub_test_name: data.json().sub_tests[i].sub_test_name,
                      test_range: data.json().sub_tests[i].range,
                      unit: data.json().sub_tests[i].unit,
                      sub_test_id_data: data.json().sub_tests[i].sub_test_id,
                    });
                  }
                  this.subTestView = true;
                }
              }
            }, error => {
              this.toastr.error(Message_data.getNetworkMessage());
            });
      } else {
        this.show = this.presTest.Normal == "scan_test" ? true : false;
        this.chooseFileFlag = false;
        this.subTestView = false;
        if (this.presTest.Normal == "culture_test") {
          this.subTestView2 = false;
          this.show_culture_test = true;
        } else {
          this.subTestView2 = true;
          this.show_culture_test = false;
        }
      }
    }
  }

  //UPLOAD FILE 
  fileChange($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    var file = inputValue.files[0];
    var fileInput = inputValue.target;
    var myReader: FileReader = new FileReader();

    var fileval = file.type.split('/');
    this.fileType = fileval[1] == "jpeg" ? "jpg" : fileval[1];

    if (file.type == "image/jpeg" || file.type == "image/png" || file.type == "video/mp4") {
      myReader.onloadend = (e) => {
        var fileSize = Math.round(inputValue.files[0].size / 1024);
        if (fileSize >= 1024) {
          this.toastr.error(Message_data.maxOnembImg);
          $("#file").val('');
          this.imgStr = null;
          fileInput.value = '';
          this.fileName = null;

        } else {
          this.imgStr = myReader.result;
          $('#profile_img').attr('src', myReader.result as string);
        }
      }

      myReader.readAsDataURL(inputValue.files[0]);
    } else {
      this.toastr.error(Message_data.appSupportsJPGorPng);
    }
    this.cdr.detectChanges();
  }

  uploadData() {

    console.log("subtestrangearray",JSON.stringify(this.SubtestRangeArray))
    // clearing ldl calculation variables
    this.totchole = undefined;
    this.hdl = undefined;
    this.trigs = undefined;
    this.ldl = undefined;
    this.vldl = undefined;
    this.cho_hdl_ratio = undefined;
    this.ldl_hdl_ratio = undefined;

    // clearing globulin and bilirubin calculation variables
    this.bilitubin_total = undefined;
    this.bilirubin_direct = undefined;
    this.bilirubin_indirect = undefined;
    this.total_Protein = undefined;
    this.albumin = undefined;
    this.globulin = undefined;
    this.ag_ratio = undefined;

    if (this.grouping_flag == false) {
      var testGrp;
      var flag: boolean = true;
      if (this.testInfo != undefined) {
        this.testArray = [];
        this.subTestArray.forEach(element => {
          this.subTestID = element.test_id;
        });
        this.testgrp = this.testInfo

        if (this.cbt_Template == "" || this.cbt_Template == undefined) {
          this.sendObject = {
            diag_app_id: this.Clnt_App_ID,
            group: testGrp,
            app_id: this.testIds,
            client_reg_id: this.clientRegID,
            created_by: this.created_by,
            relation_id: this.relationID,
            sub_rel_id: this.subRelID,
            country: ipaddress.country_code,
            test_id: this.subTestInfo,
            test_type: this.testtype,
          };

          if (this.testgrp == "laboratory") {
            var subtest_array = [];
            for (var i = 0; i < this.SubtestRangeArray.length; i++) {
              // alert(this.SubtestRangeArray[i].test_range_value)
              if ((this.SubtestRangeArray[i].test_range_value != undefined && this.SubtestRangeArray[i].test_range_value != null && this.SubtestRangeArray[i].test_range_value != "") && (this.SubtestRangeArray[i].tests.length == 0)) {
                const currentTime = new Date();
                const formattedTime = this.datePipe.transform(currentTime, 'hh:mm:ss');
                const currentDate = new Date();

                subtest_array.push({
                  s_no: this.SubtestRangeArray[i].id,
                  sub_test_id: this.SubtestRangeArray[i].sub_test_id_data,
                  reading: this.SubtestRangeArray[i].test_range_value,
                  reject_reason: this.SubtestRangeArray[i].rejected_reason,
                })

              } else {
                if (this.SubtestRangeArray[i].tests != undefined && this.SubtestRangeArray[i].tests.length != 0) {
                  for (var j = 0; j < this.SubtestRangeArray[i].tests.length; j++) {
                    if (this.SubtestRangeArray[i].tests[j].test_range_value != undefined && this.SubtestRangeArray[i].tests[j].test_range_value != null && this.SubtestRangeArray[i].tests[j].test_range_value != "") {
                      subtest_array.push({
                        s_no: j + 1,
                        sub_test_id: this.SubtestRangeArray[i].tests[j].tests_list_id,
                        reading: this.SubtestRangeArray[i].tests[j].test_range_value,
                        reject_reason: this.SubtestRangeArray[i].tests[j].rejected_reason,
                      })
                    } else {
                      flag = false;
                    }
                  }
                } else {
                  flag = false;
                }
              }
            }

            this.testArray.push({
              test_id: this.SubtestRangeArray[0].test_id,
              sub_tests: subtest_array
            });

            if (this.testgrp == "laboratory") {
              this.testObj = { "lab_test": this.testArray }
            }

          } else {
            this.testArray = [];
            if (this.testgrp == "radiology") {
              this.testArray.push({
                test_id: this.subTestInfo,
                impression: this.impression,
                sequences: this.sequence
              });

            } else if (this.testgrp == "culture_test") {
              this.testArray.push({
                test_id: this.subTestInfo,
                culture: this.microBioTempData,
              });

            } else {
              this.testArray.push({
                test_id: this.subTestInfo,
                impression: this.impression,
                observation: this.sequence
              });
            }

            if (this.testgrp == "radiology") {
              this.testObj = { "radio_test": this.testArray }
            }
          }
          console.log("this.testObj" + JSON.stringify(this.testObj));
          console.log("this.testArray", this.testArray)

        } else {
          this.sendObject = {
            diag_app_id: this.Clnt_App_ID,
            group: testGrp,
            app_id: [this.subTestInfo],
            client_reg_id: this.clientRegID,
            created_by: this.created_by,
            relation_id: this.relationID,
            sub_rel_id: this.subRelID,
            country: ipaddress.country_code,
            test_id: this.subTestInfo,
            test_template: this.cbt_Template,
          };

          if (this.testgrp == "laboratory") {
            this.testObj = { "lab_test": [] }
          }

          if (this.testgrp == "radiology") {
            this.testObj = { "radio_test": [] }
          }
        }

        var imgpath;
        if (this.imgStr != null) {
          imgpath = this.imgStr.split(',')[1];
        }

        this.reportArray = {
          image_type: this.fileType,
          image: imgpath,
        };

        this.sendObj = Object.assign({}, this.sendObject, this.reportArray, this.testObj);
        console.log("SEND OBJECT VALUES " + JSON.stringify(this.sendObj))
        if (flag == true) {
          var headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/savediagreport/", JSON.stringify(this.sendObj), { headers: headers }).subscribe(
            data => {
              var obj = JSON.parse(data["_body"]);
              if (obj.key != null && obj.key == "1") {
                this.toastr.success(Message_data.rptUploaddSuccess);
                // this.clientName1 = undefined;
                this.improbable_array = [];
                this.backBtnFlag = false;
                this.saveFlag = false;
                this.pendinglistFlag = true;
                this.subTestView = false;

                this.presTest = undefined;
                this.subTestName = undefined;
                this.SubtestRangeArray = [];

                if (this.subTestView2 == true) {
                  this.subTestView2 = false;
                }

                if (this.show_culture_test == true) {
                  this.show_culture_test = false;
                }

                if (this.impression != undefined) {
                  this.impression = undefined;
                }

                if (this.sequence != undefined) {
                  this.sequence = undefined;
                }

                if (this.culture != undefined) {
                  this.culture = undefined;
                }

                if (this.imgStr != null) {
                  $("#file").val('');
                  this.imgStr = null;
                }

                this.template_flag = true;

                // this.reporttype = Diagnosis_Helper.getReportType();

                if (this.reporttype == 'pathology') {
                  this.messageservice.sendMessage("pathpending");
                } else if (this.reporttype == 'radiology') {
                  this.messageservice.sendMessage("radiopending");
                } else if (this.reporttype == 'microbiology') {
                  this.messageservice.sendMessage("micropending");
                }
              } else {
                this.toastr.error(Message_data.unableToUploadRpt);
              }
            });
        } else {
          this.toastr.error(Message_data.enterTestReading);
        }
      } else {
        this.toastr.error(Message_data.fillAllFields);
      }
    } else {
      var testGrp;
      var flag: boolean = true;
      if (this.testInfo != undefined) {
        this.testArray = [];
        this.subTestArray.forEach(element => {
          this.subTestID = element.test_id;
        });
        this.testgrp = this.testInfo

        if (this.cbt_Template == "" || this.cbt_Template == undefined) {
          if (this.testgrp == "laboratory") {
            var subtest_array = [];
            for (var i = 0; i < this.SubtestRangeArray.length; i++) {
              if (this.SubtestRangeArray[i].test_type == 'test') {
                if (this.SubtestRangeArray[i].tests != undefined && this.SubtestRangeArray[i].tests.length != 0) {
                  for (var j = 0; j < this.SubtestRangeArray[i].tests.length; j++) {
                    if (this.SubtestRangeArray[i].tests[j].test_range_value != undefined && this.SubtestRangeArray[i].tests[j].test_range_value != null && this.SubtestRangeArray[i].tests[j].test_range_value != "") {
                      subtest_array.push({
                        s_no: j + 1,
                        test_id: this.SubtestRangeArray[i].tests[j].test_id,
                        sub_test_id: this.SubtestRangeArray[i].tests[j].sub_test_id_data,
                        reading: this.SubtestRangeArray[i].tests[j].test_range_value,
                        reject_reason: this.SubtestRangeArray[i].tests[j].rejected_reason,
                      })
                    } else {
                      flag = false;
                    }
                  }
                } else {
                  if (this.SubtestRangeArray[i].test_range_value != undefined && this.SubtestRangeArray[i].test_range_value != null && this.SubtestRangeArray[i].test_range_value != "") {
                    subtest_array.push({
                      s_no: i + 1,
                      test_id: this.SubtestRangeArray[i].test_id,
                      sub_test_id: this.SubtestRangeArray[i].sub_test_id_data,
                      reading: this.SubtestRangeArray[i].test_range_value,
                      reject_reason: this.SubtestRangeArray[i].rejected_reason,
                    })
                  } else {
                    flag = false;
                  }
                }
              } else {
                if (this.SubtestRangeArray[i].tests != undefined) {
                  for (var j = 0; j < this.SubtestRangeArray[i].tests.length; j++) {
                    if (this.SubtestRangeArray[i].tests[j].test_range_value != undefined && this.SubtestRangeArray[i].tests[j].test_range_value != null && this.SubtestRangeArray[i].tests[j].test_range_value != "") {
                      subtest_array.push({
                        s_no: j + 1,
                        profile_id: this.SubtestRangeArray[i].profile_id,
                        test_id: this.SubtestRangeArray[i].test_id,
                        sub_test_id: this.SubtestRangeArray[i].tests[j].tests_list_id,
                        reading: this.SubtestRangeArray[i].tests[j].test_range_value,
                        reject_reason: this.SubtestRangeArray[i].tests[j].rejected_reason,
                      })
                    } else {
                      flag = false;
                    }
                  }
                }
                if ((this.SubtestRangeArray[i].test_range_value != undefined && this.SubtestRangeArray[i].test_range_value != null && this.SubtestRangeArray[i].test_range_value != "") &&  this.SubtestRangeArray[i].tests.length == 0){
                  subtest_array.push({
                    s_no: i + 1,
                    profile_id: this.SubtestRangeArray[i].profile_id,
                    test_id: this.SubtestRangeArray[i].test_id,
                    sub_test_id: this.SubtestRangeArray[i].sub_test_id_data,
                    reading: this.SubtestRangeArray[i].test_range_value,
                    reject_reason: this.SubtestRangeArray[i].rejected_reason,
                  })
                }
              }
            }

            // this.testArray.push({
            //   test_id: this.SubtestRangeArray[0].test_id,
            //   sub_tests: subtest_array
            // });

            // if (this.testgrp == "laboratory") {
            //   this.testObj = { "lab_test": this.testArray }
            // }

          }

          this.sendObject = {
            created_by: this.created_by,
            tests: subtest_array
          };
        }
        console.log("SEND OBJECT VALUES " + JSON.stringify(this.sendObject))
        if (flag == true) {
          var headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/sdrb", JSON.stringify(this.sendObject), { headers: headers }).subscribe(
            data => {
              var obj = JSON.parse(data["_body"]);
              if (obj.key != null && obj.key == "1") {
                this.toastr.success(Message_data.rptUploaddSuccess);
                // this.clientName1 = undefined;
                this.backBtnFlag = false;
                this.saveFlag = false;
                this.pendinglistFlag = true;
                this.subTestViewNew = false;

                this.presTest = undefined;
                this.subTestName = undefined;
                this.SubtestRangeArray = [];

                if (this.subTestView2 == true) {
                  this.subTestView2 = false;
                }

                if (this.show_culture_test == true) {
                  this.show_culture_test = false;
                }

                if (this.imgStr != null) {
                  $("#file").val('');
                  this.imgStr = null;
                }

                this.template_flag = true;

                // this.reporttype = Diagnosis_Helper.getReportType();

                if (this.reporttype == 'pathology') {
                  this.messageservice.sendMessage("pathpending");
                } else if (this.reporttype == 'radiology') {
                  this.messageservice.sendMessage("radiopending");
                } else if (this.reporttype == 'microbiology') {
                  this.messageservice.sendMessage("micropending");
                }
              } else {
                this.toastr.error(Message_data.unableToUploadRpt);
              }
            });
        } else {
          this.toastr.error(Message_data.enterTestReading);
        }
      } else {
        this.toastr.error(Message_data.fillAllFields);
      }

    }
  }


  changeLocation(id) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/gtedit/", JSON.stringify({ diag_centre_id: id.diag_centre_id }), { headers: headers }).subscribe(
      data => {
        if (data.json().test_edit != null) {
          this.updateListArray = data.json().test_edit;
          this.updateListArray.forEach(element => {

            this.updateArray.push({
              first_name: encrypt_decript.Decript(element.first_name),
              last_name: encrypt_decript.Decript(element.last_name),
              id: element.diag_app_id,
              type: element.test_type,
              test_id: element.pres_test_id
            })
          });
        }
      }, error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }

  reportUploadView() {
    this.updateReport = false;
    this.reportView = true;
  }

  reportModifyView() {
    this.updateReport = true;
    this.reportView = false;
  }

  onSelectionChanged = (event) => { }
  onContentChanged = (event) => {
    this.sequence = event.html;
  }

  onContentChanged2 = (event) => {
    this.impression = event.html;
  }

  editorCreated(quill: any) { }

  getPendingList() {
    var patientName, doctorname, address1, address2, clntlocation, clntdistrict, mobile, patientFirstName;
    var clntstate, clntcountry, zipcode;
    var report_status;

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/gtbdn",
      JSON.stringify({
        diag_centre_id: this.diagid,
        type: this.reporttype,
        from_date: this.from_date,
        to_date: this.to_date,
      }), { headers: headers }).subscribe(
        data => {
          this.pendingLists = [];
          this.filteredPendingList = [];
          console.log("pending SaveData", data)
          console.log("this.pendingData", JSON.stringify({
            diag_centre_id: this.diagid,
            type: this.reporttype,
            from_date: this.from_date,
            to_date: this.to_date,
          }))

          if (data.json().test_list != null) {
            this.pendingData = data.json().test_list;

            console.log("this.pendingData ****", JSON.stringify(this.pendingData))
            for (var i = 0; i < this.pendingData.length; i++) {
              var pat_salutation = '';
              if (this.pendingData[i].salutation != undefined) {
                pat_salutation = this.pendingData[i].salutation;
              }

              if (this.pendingData[i].first_name != undefined && this.pendingData[i].middle_name != undefined && this.pendingData[i].last_name != undefined) {
                patientName = pat_salutation + ". " + encrypt_decript.Decript(this.pendingData[i].first_name) + " " + encrypt_decript.Decript(this.pendingData[i].middle_name) + " " + encrypt_decript.Decript(this.pendingData[i].last_name);
                patientFirstName = encrypt_decript.Decript(this.pendingData[i].first_name);
              } else if (this.pendingData[i].first_name != undefined && this.pendingData[i].last_name != undefined) {
                patientName = pat_salutation + ". " + encrypt_decript.Decript(this.pendingData[i].first_name) + " " + encrypt_decript.Decript(this.pendingData[i].last_name);
                patientFirstName = encrypt_decript.Decript(this.pendingData[i].first_name);
              } else {
                patientName = pat_salutation + ". " + encrypt_decript.Decript(this.pendingData[i].first_name);
                patientFirstName = encrypt_decript.Decript(this.pendingData[i].first_name);
              }
              console.log("this.pendingData ****", JSON.stringify(this.pendingData[i].dr_middle_name))

              if (this.pendingData[i].dr_middle_name != undefined) {
                doctorname = "Dr " + this.pendingData[i].dr_first_name + " " + this.pendingData[i].dr_middle_name + " " + this.pendingData[i].dr_last_name;
              } else if (this.pendingData[i].dr_first_name != undefined && this.pendingData[i].dr_last_name != undefined) {
                doctorname = "Dr " + this.pendingData[i].dr_first_name + " " + this.pendingData[i].dr_last_name;
              }
              // else {
              //   doctorname = encrypt_decript.Decript(this.pendingData[i].dr_first_mame)+" "+encrypt_decript.Decript(this.pendingData[i].dr_last_mame);
              // }
              // if (this.pendingData[i].address1 != undefined) {
              //   address1 = encrypt_decript.Decript(this.pendingData[i].address1);
              // } 
              if (this.pendingData[i].address2 != undefined) {
                address1 = encrypt_decript.Decript(this.pendingData[i].address1) + "" + encrypt_decript.Decript(this.pendingData[i].address2);
              } else {
                address1 = encrypt_decript.Decript(this.pendingData[i].address1);
              }


              if (this.pendingData[i].mobile != undefined) {
                mobile = encrypt_decript.Decript(this.pendingData[i].mobile);
              }

              if (this.pendingData[i].location != undefined) {
                clntlocation = (this.pendingData[i].location);
              }
              if (this.pendingData[i].district != undefined) {
                clntdistrict = (this.pendingData[i].district);
              }
              if (this.pendingData[i].state != undefined) {
                clntstate = (this.pendingData[i].state);
              }
              if (this.pendingData[i].country != undefined) {
                clntcountry = (this.pendingData[i].country);
              }
              if (this.pendingData[i].zipcode != undefined) {
                zipcode = encrypt_decript.Decript(this.pendingData[i].zipcode);
              }
              if (this.pendingData[i].status == "0") {
                report_status = "Completed";
              } else {
                report_status = "Upload";
              }

              // var subtest_array = [];
              // if (this.pendingData[i].tests != undefined){
              //   for (var j = 0; j < this.pendingData[i].tests.length; j++) {
              //   }
              // }

              var test_array = [];
              if (this.pendingData[i].tests != undefined) {
                for (var j = 0; j < this.pendingData[i].tests.length; j++) {
                  var billDate = this.pendingData[i].tests[j].bill_time != undefined ? Date_Formate(this.pendingData[i].tests[j].bill_date) : "";
                  var billTime = this.pendingData[i].tests[j].bill_time != undefined ? Time_Formate(this.pendingData[i].tests[j].bill_time) : "";
                  var sample_collect_date = this.pendingData[i].tests[j].sample_collect_date != undefined ? Date_Formate(this.pendingData[i].tests[j].sample_collect_date) : "";
                  var sample_collect_time = this.pendingData[i].tests[j].sample_collected_time != undefined ? Time_Formate(this.pendingData[i].tests[j].sample_collected_time) : "";
                  var assert_date = this.pendingData[i].tests[j].assertion_date != undefined ? Date_Formate(this.pendingData[i].tests[j].assertion_date) : ""
                  var assert_time = this.pendingData[i].tests[j].assertion_time != undefined ? Time_Formate(this.pendingData[i].tests[j].assertion_time) : "";
                  var visited_date = this.pendingData[i].tests[j].visited_date != undefined ? Date_Formate(this.pendingData[i].tests[j].visited_date) : ""
                  var visited_time = this.pendingData[i].tests[j].visited_time != undefined ? Time_Formate(this.pendingData[i].tests[j].visited_time) : "";

                  test_array.push({
                    id: j + 1,
                    diag_test_id: this.pendingData[i].tests[j].diag_test_id,
                    test_id: this.pendingData[i].tests[j].test_id,
                    test_cat: this.pendingData[i].tests[j].test_cat,
                    test_type_name: this.pendingData[i].tests[j].test_type_name,
                    test_name: this.pendingData[i].tests[j].test_name,
                    status: this.pendingData[i].tests[j].status,
                    bill_date: billDate,
                    bill_time: billTime,
                    sample_collect_d_t: sample_collect_date + ' ' + sample_collect_time,
                    assert_d_t: assert_date + ' ' + assert_time,
                    visited_d_t: visited_date + ' ' + visited_time,
                    template_id: this.pendingData[i].tests[j].template_id,
                    template_name: this.pendingData[i].tests[j].template_name,
                    template_desc: this.pendingData[i].tests[j].template_desc,
                    conduct_format: this.pendingData[i].tests[j].conduct_format,
                    min_nor_value: this.pendingData[i].tests[j].min_nor_value,
                    max_nor_value: this.pendingData[i].tests[j].max_nor_value,
                    min_abnor_value: this.pendingData[i].tests[j].min_abnor_value,
                    max_abnor_value: this.pendingData[i].tests[j].max_abnor_value,
                    min_crit_value: this.pendingData[i].tests[j].min_crit_value,
                    max_crit_value: this.pendingData[i].tests[j].max_crit_value,
                    unit: this.pendingData[i].tests[j].unit,
                    test_type: this.pendingData[i].tests[j].test_type,
                    mobile: mobile,
                    subtests: this.pendingData[i].tests[j].sub_tests,
                    sensitive_id: this.pendingData[i].tests[j].sensitive_id,
                    sample_id: this.pendingData[i].tests[j].sample_id,
                    specimen_type: this.pendingData[i].tests[j].specimen_type,
                  })
                }
              }

              this.pendingLists.push({
                s_no: 1 + i,
                diag_appointment_id: this.pendingData[i].diag_appointment_id,
                approved_date: this.pendingData[i].approved_date,
                pat_type: this.pendingData[i].pat_type,
                dmrno: this.pendingData[i].dmrno,
                patient_name: patientName,
                patient_first_name: patientFirstName,
                doctor_name: doctorname,
                mrno: this.pendingData[i].mrno,
                sample_collected_time: this.pendingData[i].sample_collect_time,
                collected_date: this.pendingData[i].collected_date,
                sample_collect_d_t: sample_collect_date + ' ' + sample_collect_time,
                status: report_status,
                // test_cat: this.pendingData[i].test_cat,
                // test_id: this.pendingData[i].test_id,
                // test_name: this.pendingData[i].test_name,
                test_type_name: this.pendingData[i].test_type_name,
                diag_test_id: this.pendingData[i].diag_test_id,
                client_reg_id: this.pendingData[i].client_reg_id,
                relation_id: this.pendingData[i].relation_id,
                sub_rel_id: this.pendingData[i].sub_rel_id,
                test_no: this.pendingData[i].test_no,
                // specimen_type: this.pendingData[i].specimen_type,
                // template_id: this.pendingData[i].template_id,
                // tempate_name: this.pendingData[i].tempate_name,
                // template_desc: this.pendingData[i].template_desc,
                // conduct_format: this.pendingData[i].conduct_format,
                department: this.pendingData[i].department,
                tests: test_array,
                age: this.pendingData[i].age,
                gender: encrypt_decript.Decript(this.pendingData[i].gender),
                address1: address1, address2: address2, clntlocation: clntlocation,
                clntdistrict: clntdistrict,
                clntstate: clntstate,
                clntcountry: clntcountry,
                zipcode: zipcode,
                mobile: mobile,
                sample_id: this.pendingData[i].sample_id
                //clntstate,clntcountry,zipcode
              })
            }
            console.log("this.pendingLists", this.pendingLists)
            this.filteredPendingList = this.pendingLists;
          }
          if (this.reporttype != "radiology") {
            this.getSampleTypes();
          }

        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getSampleTypes() {
    this.sampletype_data = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gstbd ', JSON.stringify({ diag_centre_id: Helper_Class.getInfo().hospitals[0].hptl_clinic_id }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("obj----getSampleTypes", obj)
          var sampleTypesData = obj.all_samples;

          if (sampleTypesData != undefined && sampleTypesData.length != 0) {
            for (var i = 0; i < sampleTypesData.length; i++) {
              this.sampletype_data.push({
                sample_type_id: sampleTypesData[i].specimen_type_id,
                sample_desc: sampleTypesData[i].specimen_desc,
                tube_type_id: sampleTypesData[i].tube_type_id,
                tube_color: sampleTypesData[i].tube_color,
              }
              )
            }
          }

          var specimenId_array = [];

          if (this.pendingLists.length != 0) {
            for (var j = 0; j < this.pendingLists.length; j++) {
              if (this.pendingLists[j].specimen_type != undefined) {
                specimenId_array = this.pendingLists[j].specimen_type.split(',')
                var specimen_names = [];
                for (var k = 0; k < specimenId_array.length; k++) {
                  for (var l = 0; l < this.sampletype_data.length; l++) {
                    if (specimenId_array[k] == this.sampletype_data[l].sample_type_id) {
                      specimen_names.push(this.sampletype_data[l].sample_desc)
                    }
                  }
                }
                this.pendingLists[j].specimen_names = specimen_names.join(', ');
              }
            }
          }
        }, error => { });
  }

  back() {
    this.backBtnFlag = false;
    this.saveFlag = false;
    this.pendinglistFlag = true;
    this.subTestView = false;
    this.subTestView2 = false;
    this.subTestViewNew = false;
    this.show_culture_test = false;
    this.template_flag = true;
    this.cbt_Template = undefined;
    this.countFlag = true;

    if (this.impression != undefined) {
      this.impression = undefined;
    }

    if (this.sequence != undefined) {
      this.sequence = undefined;
    }

    this.totchole = undefined;
    this.hdl = undefined;
    this.trigs = undefined;
    this.ldl = undefined;
    this.vldl = undefined;
    this.cho_hdl_ratio = undefined;
    this.ldl_hdl_ratio = undefined;
    this.improbable_array = [];

    this.bilitubin_total = undefined;
    this.bilirubin_direct = undefined;
    this.bilirubin_indirect = undefined;
    this.total_Protein = undefined;
    this.albumin = undefined;
    this.globulin = undefined;
    this.ag_ratio = undefined;
  }

  getSpecimenReport() {
    this.messageservice.sendMessage("specimen-report-template");
    console.log("specimen-report-template")
  }

  getPatientHistory(data, testId) {
    this.patient_historyList = [];
    var send_data = {
      client_reg_id: data.client_reg_id,
      relation_id: data.relation_id,
      sub_rel_id: data.sub_rel_id,
      diag_test_id: testId
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/ghrbtc', JSON.stringify(send_data),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("obj----getPatientHistory", obj)
          this.dateList = [];
          this.readinglist = [];
          var date_val;
          var final_date;
          if (obj.test_history != undefined) {
            for (var i = 0; i < obj.test_history.length; i++) {
              if (obj.test_history[i].entry_date != undefined) {
                date_val = obj.test_history[i].entry_date.split("-");
                final_date = date_val[2] + "-" + date_val[1] + "-" + date_val[0];
                this.dateList.push(obj.test_history[i].entry_date);
                this.readinglist.push(obj.test_history[i].reading);
              } else {
                final_date = "";
              }

              this.patient_historyList.push({
                appointment_date: obj.test_history[i].appointment_date,
                diag_tests_id: obj.test_history[i].diag_tests_id,
                test_name: obj.test_history[i].test_name,
                reading: obj.test_history[i].reading,
                entry_date: final_date,
              })
            }
            // this.patient_historyList = obj.test_history;
            console.log("this.dateList", this.dateList)
            console.log("this.readinglist", this.readinglist)

            const ctx = document.getElementById('myChart');

            new Chart(ctx, {
              type: 'line',
              data: {
                labels: this.dateList,
                datasets: [{
                  label: 'Previous Readings',
                  data: this.readinglist,
                  borderWidth: 1
                }]
              },

            });
          }
        }, error => { });
  }

  getPatientHistory_new(testId) {
    // alert(testId)
    this.patient_historyList = [];
    this.dateList = [];
    this.readinglist = [];
  
    var send_data = {
      client_reg_id: this.clientRegID,
      relation_id: this.relationID,
      sub_rel_id: this.subRelID,
      diag_test_id: testId
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/ghrbtc', JSON.stringify(send_data),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
        
          var date_val;
          var final_date;
          if (obj.test_history != undefined) {
            for (var i = 0; i < obj.test_history.length; i++) {
              if (obj.test_history[i].entry_date != undefined) {
                date_val = obj.test_history[i].entry_date.split("-");
                final_date = date_val[2] + "-" + date_val[1] + "-" + date_val[0];
                this.dateList.push(obj.test_history[i].entry_date);
                this.readinglist.push(obj.test_history[i].reading);
              } else {
                final_date = "";
              }

              this.patient_historyList.push({
                appointment_date: obj.test_history[i].appointment_date,
                diag_tests_id: obj.test_history[i].diag_tests_id,
                test_name: obj.test_history[i].test_name,
                reading: obj.test_history[i].reading,
                entry_date: final_date,
              })
            }
            // this.patient_historyList = obj.test_history;
           
            setTimeout(() => {
              this.openPatHistoryGraph(this.dateList, this.readinglist);
            }, 200)
          }
        }, error => { });
  }

  openPatHistoryGraph(date, reading) {

    if (this.patHistCanvas) {
      this.patHistCanvas.destroy();
    }

    var ctx = document.getElementById('myChart');

    this.patHistCanvas = new Chart(ctx, {
      type: 'line',
      data: {
        labels: date,
        datasets: [{
          label: 'Previous Readings',
          data: reading,
          borderColor: '#FF6384',
          borderWidth: 1
        }]
      },

    });
  }
      
  changeSeverity(data) {
    // console.log("changeSeverity",data);
    if (data.range_value != 'Min-impro' && data.range_value != 'Max-impro') {
      if (this.improbable_array.length != 0) {
        for (var m = 0; m < this.improbable_array.length; m++) {
          if (this.improbable_array[m].sub_test_id != undefined && data.sub_test_id != undefined) {
            if (this.improbable_array[m].sub_test_id == data.sub_test_id) {
              this.improbable_array.splice(m, 1);
            }
          } else if (this.improbable_array[m].sub_test_id_data != undefined && data.sub_test_id_data != undefined) {
            if (this.improbable_array[m].sub_test_id_data == data.sub_test_id_data) {
              this.improbable_array.splice(m, 1);
            }
          }
        }
      }
      if (this.improbable_array.length == 0) {
        this.saveFlag = true;
      }
    } else {
      var flag = false;
      for (var m = 0; m < this.improbable_array.length; m++) {
        if (this.improbable_array[m].sub_test_id != undefined && data.sub_test_id != undefined) {
          if (this.improbable_array[m].sub_test_id == data.sub_test_id) {
            flag = true;
            break
          }
        } else if (this.improbable_array[m].sub_test_id_data != undefined && data.sub_test_id_data != undefined) {
          if (this.improbable_array[m].sub_test_id_data == data.sub_test_id_data) {
            flag = true;
            break
          }
        }
      }
      if (flag == false) {
        this.improbable_array.push(data)
      }
      this.saveFlag = false;
    }
  }

  calculateLDL(reading, testtype_name, subData) {
    if (testtype_name == 'totCholesterol') {
      this.totchole = reading;

    } else if (testtype_name == 'hdlCholesterol') {
      this.hdl = reading;

    } else if (testtype_name == 'ldlCholesterol') {
      this.ldl = reading;

    } else if (testtype_name == 'triglycerides') {
      this.trigs = reading;
    }

    if (this.totchole != undefined && this.hdl != undefined && this.trigs != undefined && this.ldl != undefined) {
      // this.ldl = parseFloat(this.totchole) - (parseFloat(this.hdl) + (parseFloat(this.trigs) / 5))
      this.vldl = parseFloat(this.trigs) / 5;
      this.cho_hdl_ratio = parseFloat(this.totchole) / parseFloat(this.hdl);
      this.ldl_hdl_ratio = parseFloat(this.ldl) / parseFloat(this.hdl);

      for (var i = 0; i < this.SubtestRangeArray.length; i++) {
        if (this.SubtestRangeArray[i].sub_test_name.split(" ").join("").toLowerCase().includes("cho/hdlratio")) {
          if (Number.isNaN(this.cho_hdl_ratio) != true) {
            this.SubtestRangeArray[i].test_range_value = this.cho_hdl_ratio.toFixed(2);
            this.SubtestRangeArray[i].is_disabled = true;
          } else {
            this.SubtestRangeArray[i].test_range_value = 0;
          }
        }
        if (this.SubtestRangeArray[i].sub_test_name.split(" ").join("").toLowerCase().includes("ldl/hdlratio")) {
          if (Number.isNaN(this.ldl_hdl_ratio) != true) {
            this.SubtestRangeArray[i].test_range_value = this.ldl_hdl_ratio.toFixed(2);
            this.SubtestRangeArray[i].is_disabled = true;
          } else {
            this.SubtestRangeArray[i].test_range_value = 0;
          }
        }
        if (this.SubtestRangeArray[i].sub_test_name.toLowerCase().includes("vldl")) {
          if (Number.isNaN(this.vldl) != true) {
            this.SubtestRangeArray[i].test_range_value = this.vldl.toFixed(2);
            this.SubtestRangeArray[i].is_disabled = true;
          } else {
            this.SubtestRangeArray[i].test_range_value = 0;
          }
        }

        if (this.SubtestRangeArray[i].tests != undefined) {
          if (this.SubtestRangeArray[i].tests.length != 0) {
            for (var j = 0; j < this.SubtestRangeArray[i].tests.length; j++) {
              if (this.SubtestRangeArray[i].tests[j].sub_test_name.split(" ").join("").toLowerCase().includes("cho/hdlratio")) {
                if (Number.isNaN(this.cho_hdl_ratio) != true) {
                  this.SubtestRangeArray[i].tests[j].test_range_value = this.cho_hdl_ratio.toFixed(2);
                  this.SubtestRangeArray[i].tests[j].is_disabled = true;
                } else {
                  this.SubtestRangeArray[i].tests[j].test_range_value = 0;
                }
              }
              if (this.SubtestRangeArray[i].tests[j].sub_test_name.split(" ").join("").toLowerCase().includes("ldl/hdlratio")) {
                if (Number.isNaN(this.ldl_hdl_ratio) != true) {
                  this.SubtestRangeArray[i].tests[j].test_range_value = this.ldl_hdl_ratio.toFixed(2);
                  this.SubtestRangeArray[i].tests[j].is_disabled = true;
                } else {
                  this.SubtestRangeArray[i].tests[j].test_range_value = 0;
                }
              }
              if (this.SubtestRangeArray[i].tests[j].sub_test_name.toLowerCase().includes("vldl")) {
                if (Number.isNaN(this.vldl) != true) {
                  this.SubtestRangeArray[i].tests[j].test_range_value = this.vldl.toFixed(2);
                  this.SubtestRangeArray[i].tests[j].is_disabled = true;
                } else {
                  this.SubtestRangeArray[i].tests[j].test_range_value = 0;
                }
              }
            }
          }
        }
      }
    }
  }

  calculateA1cMean(reading, subData) {
    console.log(subData)
    this.hbA1c = reading;
    this.mean_bloodGlucose = parseFloat(this.hbA1c) * 28.7 - 46.7;

    for (var i = 0; i < this.SubtestRangeArray.length; i++) {
      if (this.SubtestRangeArray[i].sub_test_name.split(" ").join("").toLowerCase().includes("meanbloodglucose")) {
        if (Number.isNaN(this.mean_bloodGlucose) != true) {
          this.SubtestRangeArray[i].test_range_value = Math.ceil(this.mean_bloodGlucose);
          this.SubtestRangeArray[i].is_disabled = true;
        } else {
          this.SubtestRangeArray[i].test_range_value = 0;
        }
      }

      if (this.SubtestRangeArray[i].tests != undefined) {
        if (this.SubtestRangeArray[i].tests.length != 0) {
          for (var j = 0; j < this.SubtestRangeArray[i].tests.length; j++) {
            if (this.SubtestRangeArray[i].tests[j].sub_test_name.split(" ").join("").toLowerCase().includes("meanbloodglucose")) {
              if (Number.isNaN(this.mean_bloodGlucose) != true) {
                this.SubtestRangeArray[i].tests[j].test_range_value = Math.ceil(this.mean_bloodGlucose);
                this.SubtestRangeArray[i].tests[j].is_disabled = true;
              } else {
                this.SubtestRangeArray[i].tests[j].test_range_value = 0;
              }
            }
          }
        }
      }
    }

  }

  calculateBilirubin(reading, testtype_name, subData) {
    this.bilitubin_total, this.bilirubin_direct, this.bilirubin_direct;

    if (testtype_name == 'bilirubinTotal') {
      this.bilitubin_total = reading;

    } else if (testtype_name == 'bilirubinDirect') {
      this.bilirubin_direct = reading;

    }

    if (this.bilitubin_total != undefined && this.bilirubin_direct != undefined) {
      this.bilirubin_indirect = parseFloat(this.bilitubin_total) - parseFloat(this.bilirubin_direct)

      for (var i = 0; i < this.SubtestRangeArray.length; i++) {
        if (this.SubtestRangeArray[i].sub_test_name.split(" ").join("").toLowerCase().includes("bilirubin-indirect")) {
          if (Number.isNaN(this.bilirubin_indirect) != true) {
            this.SubtestRangeArray[i].test_range_value = this.bilirubin_indirect.toFixed(2);
            this.SubtestRangeArray[i].is_disabled = true;
          } else {
            this.SubtestRangeArray[i].test_range_value = 0;
          }
        }

        if (this.SubtestRangeArray[i].tests != undefined) {
          if (this.SubtestRangeArray[i].tests.length != 0) {
            for (var j = 0; j < this.SubtestRangeArray[i].tests.length; j++) {
              if (this.SubtestRangeArray[i].tests[j].sub_test_name.split(" ").join("").toLowerCase().includes("bilirubin-indirect")) {
                if (Number.isNaN(this.bilirubin_indirect) != true) {
                  this.SubtestRangeArray[i].tests[j].test_range_value = this.bilirubin_indirect.toFixed(2);
                  this.SubtestRangeArray[i].tests[j].is_disabled = true;
                } else {
                  this.SubtestRangeArray[i].tests[j].test_range_value = 0;
                }
              }
            }
          }
        }
      }
    }
  }

  calculateGlobulin(reading, testtype_name, subData) {
    this.total_Protein, this.albumin, this.globulin, this.ag_ratio;

    if (testtype_name == 'totalProtein') {
      this.total_Protein = reading;

    } else if (testtype_name == 'albumin') {
      this.albumin = reading;

    }

    if (this.total_Protein != undefined && this.albumin != undefined) {
      this.globulin = parseFloat(this.total_Protein) - parseFloat(this.albumin)

      if (this.globulin != undefined && Number.isNaN(this.globulin) != true && parseFloat(this.globulin) != 0.00) {
        this.ag_ratio = parseFloat(this.albumin) / parseFloat(this.globulin)
      } else {
        this.globulin = 0.00
        this.ag_ratio = 0.00
      }

      for (var i = 0; i < this.SubtestRangeArray.length; i++) {
        if (this.SubtestRangeArray[i].sub_test_name.split(" ").join("").toLowerCase().includes("globulin")) {
          if (Number.isNaN(this.globulin) != true) {
            this.SubtestRangeArray[i].test_range_value = this.globulin.toFixed(2);
            this.SubtestRangeArray[i].is_disabled = true;

          } else {
            this.SubtestRangeArray[i].test_range_value = 0;
          }
        }

        if (this.SubtestRangeArray[i].sub_test_name.split(" ").join("").toLowerCase().includes("a/gratio")) {
          if (Number.isNaN(this.ag_ratio) != true) {
            this.SubtestRangeArray[i].test_range_value = this.ag_ratio.toFixed(2);
            this.SubtestRangeArray[i].is_disabled = true;
          } else {
            this.SubtestRangeArray[i].test_range_value = 0;
          }
        }

        if (this.SubtestRangeArray[i].tests != undefined) {
          if (this.SubtestRangeArray[i].tests.length != 0) {
            for (var j = 0; j < this.SubtestRangeArray[i].tests.length; j++) {
              if (this.SubtestRangeArray[i].tests[j].sub_test_name.split(" ").join("").toLowerCase().includes("globulin")) {
                if (Number.isNaN(this.globulin) != true) {
                  this.SubtestRangeArray[i].tests[j].test_range_value = this.globulin.toFixed(2);
                  this.SubtestRangeArray[i].tests[j].is_disabled = true;
                } else {
                  this.SubtestRangeArray[i].tests[j].test_range_value = 0;
                }
              }

              if (this.SubtestRangeArray[i].tests[j].sub_test_name.split(" ").join("").toLowerCase().includes("a/gratio")) {
                if (Number.isNaN(this.ag_ratio) != true) {
                  this.SubtestRangeArray[i].tests[j].test_range_value = this.ag_ratio.toFixed(2);
                  this.SubtestRangeArray[i].tests[j].is_disabled = true;
                } else {
                  this.SubtestRangeArray[i].tests[j].test_range_value = 0;
                }
              }
            }
          }
        }
      }
    }
  }


  checkRef(e, subData) {
    // console.log("subData", subData)
    console.log(subData.is_disabled)

    if (subData.sub_test_name != undefined) {
      var reading;
      if (e != undefined) {
        reading = e.toString();

        var testtype_name;
        var subtest_name = subData.sub_test_name.split(" ").join("").toLowerCase();
        // alert((subtest_name.includes("totalcholesterol")) + "   " + subData.sub_test_name.toLowerCase())
        if ((subtest_name.includes("totalcholesterol")) || (subtest_name.includes("hdl-cholesterol")) || (subtest_name.includes("triglycerides")) || (subtest_name.includes("ldl-cholesterol"))) {
          if ((subtest_name.includes("totalcholesterol"))) {
            testtype_name = "totCholesterol"
            this.calculateLDL(reading, testtype_name, subData);
            // alert("Tot cholestrol")
          } else if ((subtest_name.includes("hdl-cholesterol"))) {
            testtype_name = "hdlCholesterol"
            this.calculateLDL(reading, testtype_name, subData);
            // alert("hdlcholesterol")
          } else if ((subtest_name.includes("ldl-cholesterol"))) {
            testtype_name = "ldlCholesterol"
            this.calculateLDL(reading, testtype_name, subData);
          } else {
            testtype_name = "triglycerides"
            this.calculateLDL(reading, testtype_name, subData);
            // alert("triglycerides")
          }
        } else if ((subtest_name.includes("hba1c"))) {
          this.calculateA1cMean(reading, subData);
        } else if ((subtest_name.includes("bilirubin-total")) || (subtest_name.includes("bilirubin-direct")) || (subtest_name.includes("bilirubin-indirect"))) {
          if (subtest_name.includes("bilirubin-total")) {
            testtype_name = "bilirubinTotal"
            this.calculateBilirubin(reading, testtype_name, subData);

          } else if (subtest_name.includes("bilirubin-direct")) {
            testtype_name = "bilirubinDirect"
            this.calculateBilirubin(reading, testtype_name, subData);
          }
        } else if ((subtest_name.includes("totalprotein")) || (subtest_name.includes("albumin"))) {
          if (subtest_name.includes("totalprotein")) {
            testtype_name = "totalProtein"
            this.calculateGlobulin(reading, testtype_name, subData);

          } else if (subtest_name.includes("albumin")) {
            testtype_name = "albumin"
            this.calculateGlobulin(reading, testtype_name, subData);

          }
        }
      }
    }

    // alert(e)
    this.inputBgColor = '#ffffff';
    if (e != undefined) {
      if (e.length >= 1) {
        var inputTestVal = e.toString();

        if (this.improbable_array.length == 0) {
          this.saveFlag = true;
        }

        if (((subData.min_nor_value != undefined || subData.min_nor_value != "") && (subData.max_nor_value != undefined || subData.max_nor_value != ""))
          && ((subData.min_abnor_value == undefined || subData.min_abnor_value == "") && (subData.max_abnor_value == undefined || subData.max_abnor_value == ""))
          && ((subData.min_crit_value == undefined || subData.min_crit_value == "") && (subData.max_crit_value == undefined || subData.max_crit_value == ""))) {
          //check only normal on else show alert for abnormal value

          console.log('First condition', subData)
          if (parseInt(inputTestVal) >= parseInt(subData.min_nor_value) && inputTestVal <= parseInt(subData.max_nor_value)) {
            this.inputBgColor = '#ffffff';
            // this.saveFlag = true;
            for (var i = 0; i < this.SubtestRangeArray.length; i++) {
              if (this.SubtestRangeArray[i].sub_test_id_data == subData.sub_test_id_data) {
                subData.range_value = "Normal";
                subData.inputBgColor = '#ffffff';
              }
            }
          } else if (parseInt(inputTestVal) < parseInt(subData.min_nor_value)) {
            this.inputBgColor = '#fff4b6';
            // this.saveFlag = true;
            for (var i = 0; i < this.SubtestRangeArray.length; i++) {
              if (this.SubtestRangeArray[i].sub_test_id_data == subData.sub_test_id_data) {
                subData.range_value = "Min_Abnor";
                subData.inputBgColor = '#fff4b6';
              }
            }
            // alert("min-abnor")

          } else if (parseInt(inputTestVal) > parseInt(subData.max_nor_value)) {
            this.inputBgColor = '#fff4b6';
            // this.saveFlag = true;
            for (var i = 0; i < this.SubtestRangeArray.length; i++) {
              if (this.SubtestRangeArray[i].sub_test_id_data == subData.sub_test_id_data) {
                subData.range_value = "Max-Abnor";
                subData.inputBgColor = '#fff4b6';
              }
            }
          }

        } else if (((subData.min_nor_value != undefined || subData.min_nor_value != "") && (subData.max_nor_value != undefined || subData.max_nor_value != "")) && ((subData.min_abnor_value != undefined || subData.min_abnor_value != "") && (subData.max_abnor_value != undefined || subData.max_abnor_value != "")) && ((subData.min_crit_value == undefined || subData.min_crit_value == "") && (subData.max_crit_value == undefined || subData.max_crit_value == ""))) {

          console.log('Second condition', subData)
          if (parseInt(inputTestVal) >= parseInt(subData.min_nor_value) && inputTestVal <= parseInt(subData.max_nor_value)) {
            this.inputBgColor = '#ffffff';
            // this.saveFlag = true;
            for (var i = 0; i < this.SubtestRangeArray.length; i++) {
              if (this.SubtestRangeArray[i].sub_test_id_data == subData.sub_test_id_data) {
                subData.range_value = "Normal";
                subData.inputBgColor = '#ffffff';
              }
            }
          } else if (parseInt(inputTestVal) < parseInt(subData.min_nor_value) && parseInt(inputTestVal) >= parseInt(subData.min_abnor_value)) {
            this.inputBgColor = '#fff4b6';
            // this.saveFlag = true;
            for (var i = 0; i < this.SubtestRangeArray.length; i++) {
              if (this.SubtestRangeArray[i].sub_test_id_data == subData.sub_test_id_data) {
                subData.range_value = "Min_Abnor";
                subData.inputBgColor = '#fff4b6';
              }
            }

          } else if (parseInt(inputTestVal) > parseInt(subData.max_nor_value) && parseInt(inputTestVal) <= parseInt(subData.max_abnor_value)) {
            this.inputBgColor = '#fff4b6';
            // this.saveFlag = true;
            for (var i = 0; i < this.SubtestRangeArray.length; i++) {
              if (this.SubtestRangeArray[i].sub_test_id_data == subData.sub_test_id_data) {
                subData.range_value = "Max-Abnor";
                subData.inputBgColor = '#fff4b6';
              }
            }

          } else if (parseInt(inputTestVal) < parseInt(subData.min_abnor_value)) {
            // this.saveFlag = true;
            this.inputBgColor = '#feb6b9 ';
            for (var i = 0; i < this.SubtestRangeArray.length; i++) {
              if (this.SubtestRangeArray[i].sub_test_id_data == subData.sub_test_id_data) {
                subData.range_value = "Min-crit";
                subData.inputBgColor = '#feb6b9';
              }
            }

          } else if (parseInt(inputTestVal) > parseInt(subData.max_abnor_value)) {
            // this.saveFlag = true;
            this.inputBgColor = '#feb6b9 ';
            for (var i = 0; i < this.SubtestRangeArray.length; i++) {
              if (this.SubtestRangeArray[i].sub_test_id_data == subData.sub_test_id_data) {
                subData.range_value = "Max-crit";
                subData.inputBgColor = '#feb6b9';
              }
            }
          }
        } else if (((subData.min_nor_value != undefined || subData.min_nor_value != "") && (subData.max_nor_value != undefined || subData.max_nor_value != "")) && ((subData.min_abnor_value != undefined || subData.min_abnor_value != "") && (subData.max_abnor_value != undefined || subData.max_abnor_value != "")) && ((subData.min_crit_value != undefined || subData.min_crit_value != "") && (subData.max_crit_value != undefined || subData.max_crit_value != ""))) {
          //check only normal and abnormal on else show alert for improbable value
          console.log('third condition', subData)

          if (this.improbable_array.length != 0) {
            for (var m = 0; m < this.improbable_array.length; m++) {
              if (this.improbable_array[m].sub_test_id != undefined && subData.sub_test_id != undefined) {
                if (this.improbable_array[m].sub_test_id == subData.sub_test_id) {
                  if (parseInt(inputTestVal) > parseInt(subData.min_crit_value) && parseInt(inputTestVal) < parseInt(subData.max_crit_value)) {
                    this.saveFlag = true;
                    this.improbable_array.splice(m, 1);
                  }
                }
              } else if (this.improbable_array[m].sub_test_id_data != undefined && subData.sub_test_id_data != undefined) {
                if (this.improbable_array[m].sub_test_id_data == subData.sub_test_id_data) {
                  if (parseInt(inputTestVal) > parseInt(subData.min_crit_value) && parseInt(inputTestVal) < parseInt(subData.max_crit_value)) {
                    this.saveFlag = true;
                    this.improbable_array.splice(m, 1);
                  }
                }
              }
            }
          }

          if (parseInt(inputTestVal) >= parseInt(subData.min_nor_value) && inputTestVal <= parseInt(subData.max_nor_value)) {
            this.inputBgColor = '#ffffff';

            if (this.improbable_array.length == 0) {
              this.saveFlag = true;
            }

            for (var i = 0; i < this.SubtestRangeArray.length; i++) {
              if (this.SubtestRangeArray[i].sub_test_id_data == subData.sub_test_id_data) {
                subData.range_value = "Normal";
                subData.inputBgColor = '#ffffff';
              }
            }
          } else if (parseInt(inputTestVal) < parseInt(subData.min_nor_value) && parseInt(inputTestVal) >= parseInt(subData.min_abnor_value)) {
            this.inputBgColor = '#fff4b6';

            if (this.improbable_array.length == 0) {
              this.saveFlag = true;
            }

            for (var i = 0; i < this.SubtestRangeArray.length; i++) {
              if (this.SubtestRangeArray[i].sub_test_id_data == subData.sub_test_id_data) {
                subData.range_value = "Min_Abnor";
                subData.inputBgColor = '#fff4b6';
              }
            }
            // alert("min-abnor")

          } else if (parseInt(inputTestVal) > parseInt(subData.max_nor_value) && parseInt(inputTestVal) <= parseInt(subData.max_abnor_value)) {
            this.inputBgColor = '#fff4b6';

            if (this.improbable_array.length == 0) {
              this.saveFlag = true;
            }

            for (var i = 0; i < this.SubtestRangeArray.length; i++) {
              if (this.SubtestRangeArray[i].sub_test_id_data == subData.sub_test_id_data) {
                subData.range_value = "Max-Abnor";
                subData.inputBgColor = '#fff4b6';
              }
            }

          } else if (parseInt(inputTestVal) < parseInt(subData.min_abnor_value) && parseInt(inputTestVal) >= parseInt(subData.min_crit_value)) {

            if (this.improbable_array.length == 0) {
              this.saveFlag = true;
            }

            this.inputBgColor = '#feb6b9 ';
            for (var i = 0; i < this.SubtestRangeArray.length; i++) {
              if (this.SubtestRangeArray[i].sub_test_id_data == subData.sub_test_id_data) {
                subData.range_value = "Min-crit";
                subData.inputBgColor = '#feb6b9';
              }
            }

          } else if (parseInt(inputTestVal) > parseInt(subData.max_abnor_value) && parseInt(inputTestVal) <= parseInt(subData.max_crit_value)) {

            if (this.improbable_array.length == 0) {
              this.saveFlag = true;
            }

            this.inputBgColor = '#feb6b9 ';
            for (var i = 0; i < this.SubtestRangeArray.length; i++) {
              if (this.SubtestRangeArray[i].sub_test_id_data == subData.sub_test_id_data) {
                subData.range_value = "Max-crit";
                subData.inputBgColor = '#feb6b9';
              }
            }

          } else if (parseInt(inputTestVal) < parseInt(subData.min_crit_value)) {
            this.saveFlag = false;
            for (var i = 0; i < this.SubtestRangeArray.length; i++) {
              if (this.SubtestRangeArray[i].sub_test_id_data == subData.sub_test_id_data) {
                subData.range_value = "Min-impro";
                if (inputTestVal.length >= subData.min_crit_value.length) {
                  this.toastr.error("You've entered an improbable value", null, { timeOut: 3000 })
                }
              }
            }
            this.improbable_array.push(subData)

          } else if (parseInt(inputTestVal) > parseInt(subData.max_crit_value)) {
            this.saveFlag = false;
            for (var i = 0; i < this.SubtestRangeArray.length; i++) {
              if (this.SubtestRangeArray[i].sub_test_id_data == subData.sub_test_id_data) {
                subData.range_value = "Max-impro";
                if (inputTestVal.length >= subData.max_crit_value.length) {
                  this.toastr.error("You've entered an improbable value", null, { timeOut: 3000 })
                }
              }
            }
            this.improbable_array.push(subData)
          }
        }
      }
    }

    console.log(this.improbable_array)
  }


  onDragStart(event: DragEvent, row: any, parent: any) {
    event.dataTransfer.setData('text/plain', row.id.toString() + '/' + parent.s_no.toString());
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent, index: number) {
    const draggedRowId = +event.dataTransfer.getData('text/plain').split('/')[0];
    const draggedParentRowId = +event.dataTransfer.getData('text/plain').split('/')[1] - 1;
    const draggedRowIndex = this.pendingLists[draggedParentRowId].tests.findIndex(row => row.id === draggedRowId);

    if (draggedRowIndex > -1) {
      const [draggedRow] = this.pendingLists[draggedParentRowId].tests.splice(draggedRowIndex, 1);
      this.pendingLists[draggedParentRowId].tests.splice(index, 0, draggedRow);
    }
  }

  onDragStart1(event: DragEvent, row: any) {
    event.dataTransfer.setData('text/plain', row.id.toString());
  }

  onDragOver1(event: DragEvent) {
    event.preventDefault();
  }

  onDrop1(event: DragEvent, index: number) {
    const draggedRowId = +event.dataTransfer.getData('text/plain');
    const draggedRowIndex = this.SubtestRangeArray.findIndex(row => row.id === draggedRowId);

    if (draggedRowIndex > -1) {
      const [draggedRow] = this.SubtestRangeArray.splice(draggedRowIndex, 1);
      this.SubtestRangeArray.splice(index, 0, draggedRow);
    }
  }

  getReportDetails(diagId, testName, testId, data, test_data) {//Get report details
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'record/diagreportview/',
      {
        flag: "diagnosis",
        country: ipaddress.country_code,
        diag_app_id: diagId,
        type: data.department,
        test_type: test_data.test_type,
        pres_test_id: testId,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj != null) {
            if (obj.main_tests != null && obj.main_tests.length != 0) {
              if (obj.main_tests[0].image != null) {
                this.reportDetails = ipaddress.Ip_with_img_address + obj.main_tests[0].image;
                this.reportImges = ipaddress.Ip_with_img_address + obj.main_tests[0].image;
                this.dicomDoctor = false;

              } else {
                this.dicomDoctor = true;
              }

              for (var i = 0; i < obj.main_tests.length; i++) {
                if (obj.main_tests[i].test_name == this.testName) {
                  this.presTestID = obj.main_tests[i].pres_test_id;
                  this.sequence = obj.main_tests[i].sequences;
                  this.impression = obj.main_tests[i].impression;
                  this.getretrivelArray = [];

                  if (obj.main_tests[i].test_method != undefined)
                    this.testMethod = obj.main_tests[i].test_method;

                  if (obj.main_tests[i].created_by != undefined)
                    this.createdBy = obj.main_tests[i].created_by;

                  this.testsName = obj.main_tests[i].test_name;

                  if (obj.main_tests[i].subtests != null) {
                    for (var j = 0; j < obj.main_tests[i].subtests.length; j++) {
                      var temp_value = "";
                      var test_method = "";

                      this.subTestNameFlag = obj.main_tests[i].subtests[j].sub_test_name == this.testsName ? false : true;

                      if (obj.main_tests[i].subtests[j].range != undefined)
                        temp_value = obj.main_tests[i].subtests[j].range;

                      if (obj.main_tests[i].subtests[j].test_method != undefined) {
                        test_method = obj.main_tests[i].subtests[j].test_method;
                        this.subTestFlag = true;
                      } else {
                        this.subTestFlag = false;
                      }

                      this.getretrivelArray.push({
                        sub_test_name: obj.main_tests[i].subtests[j].sub_test_name,
                        reading: obj.main_tests[i].subtests[j].reading,
                        unit: obj.main_tests[i].subtests[j].sub_uom,
                        test_range: temp_value,
                        sub_test_list_id: obj.main_tests[i].subtests[j].sub_test_list_id,
                      });
                    }
                  }

                  this.diagTestReadingArray.push({
                    test_name: obj.main_tests[i].test_name,
                    sub_test_list: this.getretrivelArray
                  });
                }

                if (obj.main_tests[i].image != undefined) {
                  this.dicomDoctor = false;
                  this.reportDetails = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;
                  this.reportImges = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image;

                } else {
                  this.dicomDoctor = true;
                }

                if (obj.main_tests[i].sequences != undefined)
                  this.sequence = obj.main_tests[i].sequences;

                if (obj.main_tests[i].impression != undefined)
                  this.impression = obj.main_tests[i].impression;

                if (obj.main_tests[i].culture != undefined)
                  this.culture = obj.main_tests[i].culture;
              }
            }
          }
        }, error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changeFilter(type) {
    this.searchInput = '';
    if (type == 'date') {
      this.date_Flag = true;
      this.mr_no_nameFlag = false;
    } else if (type == 'mr_no') {
      this.mr_no_nameFlag = true;
      this.date_Flag = false;
      this.filter_placeholder = "Search by MR.No"
      this.changeLocation("");
    } else {
      this.mr_no_nameFlag = true;
      this.date_Flag = false;
      this.changeLocation("");
      this.filter_placeholder = "Search by Name"
    }
  }

  filterPendingListArray(searchInput) {
    this.filteredPendingList = this.pendingLists.filter(item =>
      item.dmrno.toLowerCase().includes(searchInput.toLowerCase()) || item.patient_name.toLowerCase().includes(searchInput.toLowerCase())
    );
  }

  sampleBarcodeReprint(test_data, data, isGrouping) {
    console.log("sampleBarcodeReprint=---- test_data> " + JSON.stringify(test_data))
    console.log("sampleBarcodeReprint=----> " + JSON.stringify(data))
    this.sample_id = [];

    this.mrval = data.dmrno;
    this.patient_name = data.patient_first_name;

    if (test_data.length > 1) {
      for (var i = 0; i < test_data.length; i++) {
        for (var j = 0; j < this.sampletype_data.length; j++) {
          if (test_data[i].specimen_type == this.sampletype_data[j].sample_type_id) {
            this.sample_id.push({
              sample_type: this.sampletype_data[j].sample_desc,
              sample_id: test_data[i].sample_id
            })
          }
        }
      }
    } else {
      if (isGrouping == true) {
        for (var j = 0; j < this.sampletype_data.length; j++) {
          if (test_data[0].specimen_type == this.sampletype_data[j].sample_type_id) {
            this.sample_id.push({
              sample_type: this.sampletype_data[j].sample_desc,
              sample_id: test_data[0].sample_id
            })
          }
        }
      } else {
        for (var j = 0; j < this.sampletype_data.length; j++) {
          if (test_data.specimen_type == this.sampletype_data[j].sample_type_id) {
            this.sample_id.push({
              sample_type: this.sampletype_data[j].sample_desc,
              sample_id: test_data.sample_id
            })
          }
        }
      }
    }

    if (this.sample_id.length != 0) {
      this.sample_print_array = this.removeDuplicates(this.sample_id);

      console.log("sample_print_array", this.sample_print_array)
      setTimeout(() => {
        if (this.sample_print_array.length != 0) {
          this.printIdCard();
        }
      }, 1000);
    }
  }

  printIdCard() {
    let printContents, popupWin;
    printContents = document.getElementById('idprint').innerHTML;

    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
          <head>
            <title>Bill</title>
            <link rel="stylesheet" media="screen" href="">
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
            <style>
            @page { size: auto;  margin: 0mm; }
            .table_class {
              background-color: rgb(108, 180, 248);
            }

            *{
              font-family: monospace;
            }
            
            </style>
          </head>
              <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
            </html>`
    );
    popupWin.document.close();
  }

  removeDuplicates(sample_id) {
    let newArray = [];
    let uniqueObject = {};

    for (let i in sample_id) {
      var objTitle = sample_id[i]['sample_id'];
      uniqueObject[objTitle] = sample_id[i];
    }

    for (let i in uniqueObject) {
      newArray.push(uniqueObject[i]);
    }

    return newArray;
  }
}

