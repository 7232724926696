import { Component, OnInit, ElementRef, ViewChild, } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { customjs } from '../../../assets/js/custom';
import { Observable, Observer, Subscription } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../../common-module/change-password/change-password.component';
import { ipaddress } from '../../ipaddress'; import { Message_data } from '../../../assets/js/Message_data';
import { Diet_Helper } from '../../../app/Diet_module/Diet_Helper';
import { MenuViewService } from './menu-view.service';
import { MasterHelperService } from '../../../app/Doctor_module/MasterHelper.service';
import { MasterCSHelperService } from '../../../app/Doctor_module/casesheet/masterCSData.service';
import { Doc_Helper } from '../../../app/Doctor_module/Doc_Helper';
import { Master_Helper } from '../../../app/Doctor_module/Master_Helper';
import { TermsConditionComponent } from '../../../app/Doctor_module/terms-condition/terms-condition.component';
import { FrontDesk_Helper } from '../../../app/FrontDesk_module/FrontDesk_Helper';
import { Admin_Helper } from '../../../app/Admin_module/Admin_Helper';
import { Diagnosis_Helper } from '../../../app/Diagnosis_module/Diagnosis_Helper';
import { Physio_Helper } from '../../../app/Physio_module/Physio_Helper';
import { VoicerecognitionService } from '../../../app/voicerecognition.service';
import { pharmacy_helper } from '../../pharma/Pharmcy_Helper';
declare var $: any;
declare var webkitSpeechRecognition: any

@Component({
  selector: 'app-menu-view',
  templateUrl: './menu-view.component.html',
  styleUrls: ['./menu-view.component.scss']
})
export class MenuViewComponent implements OnInit {
  recognition = new webkitSpeechRecognition()
  @ViewChild('asied_section_width', { static: false }) asied_width: ElementRef;
  @ViewChild('right_section_width', { static: false }) right_width: ElementRef;
  @ViewChild('logo', { static: false }) logo: ElementRef;
  @ViewChild('scrol_traget') public myScrollContainer: ElementRef;
  subscription: Subscription;
  public open_flag: boolean = true;
  public hospitalLogo;
  public hospitalName;
  public userInfo;
  public profileImage;
  public firstName: string;
  public lastName: string;
  public messages;
  public msgflag: boolean = false;
  public notifications;
  public notifyflag: boolean = false;
  public settings: boolean = false;
  //Doctor menu
  public docInpatSubmenuFlag: boolean = false;
  public minimized_asied: boolean = false;
  public selectedMenu;
  public docAppointmentListFlag: boolean = true;
  public docBillingFlag: boolean = true;
  public docSecondopinionFlag: boolean = true;
  public docNursePermssionFlag: boolean = true;
  public docDashboardFlag: boolean = true;
  public dashboardBasicFlag: boolean = true;
  public docHomecareFlag: boolean = true;
  public docCaseSheetFlag: boolean = true;
  public docCaseSummaryFlag: boolean = true;
  public docWalkinFlag: boolean = true;
  public docInpatientFlag: boolean = true;
  public docMisReport: boolean = true;
  public docReferenceVideoFlag: boolean = true;
  public docReportsFlag: boolean = false;
  public consolidatedFlag: boolean = true;
  public notificationFlag: boolean = false;
  public displayPage;
  public notificationCount;
  public docScheduleSubmenuFlag: boolean = false;
  Patientsflag = true;
  //front-desk
  public permissionSubmenuFlag: boolean = false;
  public reportsSubmenuFlag: boolean = false;
  public minimizedAsied: boolean = false;
  public inpatientFlag: boolean = false;
  public manageAppointmentFlag: boolean = true;
  public viewPrescritionFlag: boolean = true;
  public billingChargesFlag: boolean = true;
  public billCreateFlag: boolean = true;
  public billEditFlag: boolean = true;
  public advanceFlag: boolean = true;
  public patientListFlag: boolean = true;
  public wardMasterFlag: boolean = true;
  public refund: boolean = true;
  public consolidatedBillingFlag: boolean = true
  public inpatientMenuFlag: boolean = true;
  public consultantFlag: boolean = true;
  public billingFlag: boolean = false;
  public permissionsFlag: boolean = true;
  public reportsFlag: boolean = false;
  public hospBillsReportFlag: boolean = true;
  public diagBillsReportFlag: boolean = true;
  public inpatReportFlag: boolean = true;
  public reference_video: boolean = true;
  public WardTransferFlag: boolean = true;
  //Admin
  public salesReportFlag: boolean = true;
  public stockReportFlag: boolean = true;
  public stockEditFlag: boolean = true;
  public docBillEditFlag: boolean = true;
  public diagBillEditFlag: boolean = true;
  public salesReportGstFlag: boolean = true;
  public stockReceivableFlag: boolean = true;
  public schdForDrugInspFlag: boolean = true;
  public inwardReportFlag: boolean = true;
  public adminInpatReportFlag: boolean = true;
  public salesForDrugInspFlag: boolean = true;
  public adminHospBillsReportFlag: boolean = true;
  public adminDiagBillsReportFlag: boolean = true;
  public adminConsolidatedFlag: boolean = true;
  //diagnosis
  public pathSubMenuFlag: boolean = false;
  public radioSubMenuFlag: boolean = false;
  public microSubMenuFlag: boolean = false;
  public testSubMenuFlag: boolean = false;
  public outsubFlag: boolean = false;
  public dashSubMenuFlag: boolean = false;
  public minimizedAsiedFlag: boolean = false;
  public salesreport: boolean = true;
  public diagBillFlag: boolean;
  public reportFlag: boolean;
  public sourceFlag: boolean;
  public repAppFlag: boolean;
  public dashFlag: boolean;
  public menuAppFlag: boolean;
  public diagMenuReportsFlag: boolean;
  public user: boolean;
  public test: boolean;
  //public package: boolean;
  public nepAppFlag: boolean;
  public diabFlag: boolean;
  public userHidden: boolean;
  public DrugsNameFlag: boolean = false;
  //nurse
  public appointmentData: boolean = true;
  //physio
  public eventIconFlag: boolean;
  public homecareFlag: boolean;
  public isAdmin: boolean;
  public walkinFlag: boolean;
  public currentDate;
  public loginType;
  public sendType;
  public packageCreateFlag: boolean = false;
  public doctor_referral: boolean = true;
  public leadMgmtFlag: boolean = true;
  public partialPaymentFlag: boolean = true;
  public insuranceBillFlag: boolean = true;
  public salesreportdoctorReportFlag: boolean = true;
  public Bill_refundflag: boolean = true;
  public billDueFlag: boolean = true;
  public bedOccupancyFlag: boolean = true;
  public abcAnalysisFlag: boolean = true;
  public purchaseOrderFlag: boolean = true;
  public productFlag: boolean = true;
  public productReceivablesFlag: boolean = true;
  public labtestFlag: boolean = true;
  public consolidatedreportflag: boolean = true;
  public consolidatedreportflagdoc: boolean = true;
  public notvistedpatreportflag: boolean = true;
  public tatsubmenuFlag1: boolean = false;
  public hospitaltatflag: boolean = true;
  public diagtatflag = false;
  public pharmatatflag: boolean = true;
  public pathreportFlag: boolean;
  public radioreportFlag: boolean;
  public microreportFlag: boolean;
  public Docsubmenu_flag: boolean = false;
  public homeDocsubmenu_flag: boolean = false;
  public SubSchedule: boolean = false;
  public Submisreports: boolean = true;
  fullMenu: boolean = false;
  subfrontbilling: boolean = false;
  frontinpatientFlag: boolean = false;
  adminpermissionsFlag: boolean = true;
  permission: boolean = true;
  inpatientMenuFlagfalse: boolean = true;
  ADMINviewPrescritionFlag: boolean = true;
  public physiosubFlagsub: boolean = false;
  public physiosubFlag: boolean = false;
  public daig_mainMenu: boolean = false;
  nurseMainMenusub: boolean = true;
  dietiMainMenusub: boolean = true;
  PharmacysubmenuFlag = true;
  PharmacysubmenuFlag1 = true;
  PharmacyreportSubmenuFlag = true;
  manageOrders: boolean = true;
  // public pathreportFlag: boolean;
  // public Docsubmenu_flag: boolean = false;
  public currentorder: boolean = true;
  public pastorder: boolean = true
  public interdepart: boolean = true;
  public bill_est: boolean = true;
  public inventoryReceivables: boolean = true;
  public rackandbinflag: boolean = true;
  public suppayment: boolean = true;
  public suppaymentret: boolean = true;
  public pharmareports: boolean = true;
  public stockstatusflag: boolean = true;
  public salesreportflag: boolean = true;
  public receivalblesreportflag: boolean = true;
  public returnsreportflag: boolean = true;
  public pharmatat: boolean = true;
  public referalflag: boolean = true;
  public masterflag: boolean = true;
  public durgtypeflag: boolean = true;
  public drugnameflag: boolean = true;
  public addroleflag: boolean = true;
  public culturepathology: boolean = true;
  public specimenttype: boolean = true;
  public diagnosticmastflag: boolean = true;
  public diagnosticflag: boolean = true;
  public diagnosticreatflag: boolean = true;
  public genericnameflag: boolean = true;
  public schedulenameflag: boolean = true;
  public productformflag: boolean = true;
  public assetsmasflag: boolean = true;
  public maintainanceflag: boolean = true;
  public disposalflag: boolean = true;
  public help: boolean = true;
  public ABC_analysis: boolean = true;
  public Bedocc_ratio: boolean = true;
  public stockadjustflag: boolean = true;
  public pharmaassets: boolean = true;
  public billingnor: boolean = true;
  public patregFlag: boolean = true;
  public Admin_MastersFlag: boolean = false;
  public Pharmacy_MastersFlag: boolean = false;
  public Lab_MastersFlag: boolean = false;
  public patienregFlag: boolean = true;
  public UserRoleFlag: boolean = true;
  public MainPatients: boolean = true;
  public Otbookingfra: boolean = true;
  public CultureSensitivityDrugNameFlag: boolean = true;
  public visitpurposeFlag: boolean = true;
  public RatecardFlag: boolean = true;
  public gramstainsflag: boolean = true;
  public addtemplateflag: boolean = true;
  public ProductFormflag: boolean = true;
  public ScheduleNameflag: boolean = true;
  public GenericNameflag: boolean = true;
  public StoreNameflag: boolean = true;
  public Stockexchangeflag: boolean = true;
  public DiagnosticReagentflag: boolean = true;
  public DiagnosticTube: boolean = true;
  public DiagnosticDepartmentflag: boolean = true;
  public Sampletypeflag: boolean = true;
  public CulturePathologyflag: boolean = true;
  public DrugsNameTypeFlag: boolean = true;
  public pharmasentdrug: boolean = true;
  public pharmasubdept: boolean = true;
  public specimenaccessionerFlag: boolean = true;
  public ConductionDoctorFlag: boolean = true;
  public Surgery_equipmentsFlag: boolean = true;
  public patientflag: boolean = true;
  public submenuFlagb: boolean = false;
  testdiag: boolean;
  inwardFlag: boolean;
  Pharmacyreference_videoFlag: boolean;
  PharmacystockEdit: boolean;
  PharmacypurchaseOrder: boolean;
  PharmacyinventoryReceivables: boolean;
  PharmacypurchaseRequest: boolean;
  Pharmacydiscount: boolean;
  PharmacyproductMaster: boolean;
  PharmacysuppliersProduct: boolean;
  Pharmacysuppliers: boolean;
  Pharmacymanufacturerflag: boolean;
  repAppSubFlag: boolean;
  specimen_coll_flag: boolean = true;
  diag_testAndPack_flag: boolean = true;
  pathology_report_flag: boolean = true;
  path_rept_pending_flag: boolean = true;
  path_rept_rejected_flag: boolean = true;
  path_rept_completed_flag: boolean = true;
  path_rept_approved_flag: boolean = true;
  radiology_report_flag: boolean = true;
  radio_rept_pending_flag: boolean = true;
  radio_rept_rejected_flag: boolean = true;
  radio_rept_completed_flag: boolean = true;
  radio_rept_approved_flag: boolean = true;
  microbiology_report_flag: boolean = true;
  // microbmicrgy_report_flag: boolean = true;
  micro_rept_pending_flag: boolean = true;
  micro_rept_rejected_flag: boolean = true;
  micro_rept_completed_flag: boolean = true;
  micro_rept_approved_flag: boolean = true;
  outsource_centre_flag: boolean = true;
  outsource_payment_flag: boolean = true;
  diab_reading_flag: boolean = true;
  nephro_reading_flag: boolean = true;
  diag_users_flag: boolean = true;
  diag_pres_flag: boolean = true;
  wardDetailsFlag: boolean;
  diag_billing_flag: boolean = true;
  OutsourceFlag: boolean = true;
  public diagAppointmentFlag: boolean = true;
  diag_masters_flag: boolean = true;
  cult_sense_drugType_flag: boolean = true;
  cult_path_flag: boolean = true;
  specimen_type_flag: boolean = true;
  diag_tube_type_flag: boolean = true;
  diag_reagent_flag: boolean = true;
  cult_sense_drugName_flag: boolean = true;
  public BilllistFlag: boolean = true;
  public FraMasters: boolean = true;
  public fraMicrobiologydruggroup: boolean = true;
  public SensitiveDrug: boolean = true;
  public fraAddRole: boolean = true;
  public fraculturepathology: boolean = true;
  public fraspecimentype: boolean = true;
  public FraTestTubeType: boolean = true;
  public fraTestReagents: boolean = true;
  public fraStockAdjustment: boolean = true;
  public fraStoreName: boolean = true;
  public fraGenericName: boolean = true;
  public fraScheduleName: boolean = true;
  public fraProductForm: boolean = true;
  public fraSubDeparment: boolean = true;
  public fraAddtemplate: boolean = true;
  public fraGramstains: boolean = true;
  public fraratecard: boolean = true;
  public fraVisitpurpose: boolean = true;
  public OtProcedurefra: boolean = true;
  public Otmasterfra: boolean = true;
  public wardMasterviewFlag: boolean = true;
  public OtProcedureflag: boolean = true;
  schedulesviewschedules: boolean = true;
  schedulescalendar: boolean = true;
  schedulesavailability: boolean = true;
  docDashboard: boolean = false;
  //Billing
  public advancesFlag: boolean = true;
  public refundFlag: boolean = true;
  public billListFlag: boolean = true;
  public chargeFlag: boolean = true;
  public consolidatedBillFlag: boolean = true;
  public insuranceBillingFlag: boolean = true;
  public partialPaymentBillFlag: boolean = true;
  public diagnosticDFlag: boolean = true;
  public stockarFlag: boolean = true;
  public StoreNameFlag: boolean = true;
  public SMFlag: boolean = true;
  public subDepartmentFlag: boolean = true;
  public addTemplateFlag: boolean = true;
  public gramStainFlag: boolean = true;
  public rateCardFlag: boolean = true;
  public visitPurposeFlag: boolean = true;
  public dischargeListfra: boolean = true;
  public MasterFlag = true;
  public nursedocappointment: boolean = true;
  public nursedoclist: boolean = true;
  public nurseward: boolean = true;
  public nurseinpat: boolean = true;
  public nursebooking: boolean = true;
  public nurseprocedure: boolean = true;
  public nurseMedicine: boolean = true;
  public nurseAssignments: boolean = true;
  public mainBilllistFlag: boolean = true;
  public pharmacyID;
  public submenuFlag: boolean = false;
  public reportSubmenuFlag: boolean = false;
  public manufacturer: boolean = true;
  public suppliers: boolean = true;
  public suppliersProduct: boolean = true;
  public discount: boolean = true;
  public productMaster: boolean = true;
  public purchaseRequest: boolean = true;
  public purchaseOrder: boolean = true;
  public stockEdit: boolean = true;
  public stockorlevel: boolean = true;
  public pharmasetting: boolean = true;
  public purchaseRequestlist: boolean = true;
  public addmasterflag: boolean = true;
  pharmasalesGst: boolean = true;
  pharamasales_report: boolean = true;
  pharmastockReceivable: boolean = true;
  docbilling: boolean = true;
  Receivablereports: boolean = true;
  Stockreports: boolean = true;
  docDoctorcalender: boolean = true;
  bill_listf = false;
  hidefilter = false;
  public pastinwardsflag:boolean;
  public shortexpiryflag:boolean;
  public central_store;
  public req_central_store;
  public user_department;
  public user_department_id;
  public user_store_id;
  public user_store;
  public weightageFlag:boolean=true;
  public menu_bar_keys = {
    "in patient": 'patient',
    "home": "home", "patients": 'patientsinnermenu'
    , "dashboard": 'dashboardinnermenu',
    "consolidatedbills": 'consolidatedbillsinnermenu', "appointments": 'appointment_list', "walkin": 'walkin', "permissions": 'permission',
    "walk in": 'walkin', "wapking": "walkin",
    "secondopinion": 'secondopinion', "billings": 'billing', "building": "billing", "buildings": "billing", "2nd opinion": 'secondopinion', "second opinion": 'secondopinion'
    , "homecare": 'homecare', "schedule": 'schedule', "mycalender": 'mycalenderinnermenu', "home care": 'homecare', "permission": 'permissionsmenu'
    , "billing": 'billing',
    "doctor": 'doctorinnermenu', "viewschedule": 'viewscheduleinnermenu', "notification": 'notification', "notifications": 'notification',
    "help": 'help', "referencevideo": 'reference_video', "misreports": 'reportsMenu', "reference video": 'reference_video',
    "salesreports": 'salesreports', "stockreports": 'stockreportsinnermenu', "mis report": "reportsMenu", "mis reports": "reportsMenu",
    "receivablereports": 'receivablereportsinnermenu'
  }
  public client_side_bar = {
    "appointment": "appointment_menu_bar_client", "doctor": "doctorinnermenu",
    "diagnosis": "diagnosisinnermenu", "physio": "physioinnermenu", "dietician": "dieticianinnermenu",
    "second opinion": "sec_opi_smenu", "vitals measurments": "Vitals_mes_menu"
    , "lab reports": "labrepmenu", "know your health": "yourhealthmenu"
    , "activities": "activity_inner_mennu", "activity": "activity_inner_mennu", "diet": "diet_inner_mennu",
    "mindfulness": "mind_inner_mennu", "ehr": "ehrmenu", "health data": "health_inner_mennu", "post covid": "post_inner_mennu", "documents": "documents_inner_mennu",
    "pharmacy": "Pharma_menu_client", "current": "current_inner_menu", "completed": "completed_inner_menu", "medicine": "medicinemenu", "relation": "relationmenu", "bill list": "bill_menu_client"
    , "reading": "reading_menu", "readings": "reading_menu", "settings": "settings_menu", "features": "feature_innermenu",
    "health packages": "healtpackages_menu", "health package": "healtpackages_menu",
    "notifications": "Notification_menu", "notification": "Notification_menu"
  }
  usertype: any;
  public mainreport: boolean = true;
  Usertype: any;
  demanageappointment: boolean;
  deappointment: boolean;
  doctorappointments: boolean;
  defront: boolean;
  debillestimate: any;
  dediet_dashboard: boolean;
  deotbooking: boolean;
  demanageappointments: boolean;
  inpatFlag: boolean = true;
  public diag_manufacturer:boolean=true;
  public diag_stockorlevel:boolean=true;
  public diag_suppliers:boolean=true;
  public diag_suppliersProduct:boolean=true;
  public diag_productMaster:boolean=true;
  public diag_purchaseRequest:boolean=true;
  public diag_purchaseOrder:boolean=true;
  public diag_inventoryReceivables:boolean=true;
  public diag_stockEdit:boolean=true;
  public diag_stockreturnsflag:boolean=true;
  public diag_suppaymentret:boolean=true;
  public diag_stockadjustflag:boolean=true;
  public diag_rackandbinflag:boolean=true;
  public diag_stockstatusflag:boolean=true;
  public diag_salesreportflag:boolean=true;
  public diag_receivalblesreportflag:boolean=true;
  public diag_returnsreportflag:boolean=true;
  public diag_schdForDrugInspFlag:boolean=true;
  public diag_salesForDrugInspFlag:boolean=true;
  public diag_Bedocc_ratio:boolean=true;
  public diag_ABC_analysis:boolean=true;
  public diag_shortexpiryflag:boolean=true;
  public diag_pastinwardsflag:boolean=true;
  public dia_greportsflag:boolean=true;
  public nurs_manufacturer:boolean=true;
  public nurs_stockorlevel:boolean=true;
  public nurs_suppliers:boolean=true;
  public nurs_suppliersProduct:boolean=true;
  public nurs_productMaster:boolean=true;
  public nurs_purchaseRequest:boolean=true;
  public nurs_purchaseOrder:boolean=true;
  public nurs_inventoryReceivables:boolean=true;
  public nurs_stockEdit:boolean=true;
  public nurs_stockreturnsflag:boolean=true;
  public nurs_suppaymentret:boolean=true;
  public nurs_stockadjustflag:boolean=true;
  public nurs_rackandbinflag:boolean=true;
  public nurs_stockstatusflag:boolean=true;
  public nurs_salesreportflag:boolean=true;
  public nurs_receivalblesreportflag:boolean=true;
  public nurs_returnsreportflag:boolean=true;
  public nurs_schdForDrugInspFlag:boolean=true;
  public nurs_salesForDrugInspFlag:boolean=true;
  public nurs_Bedocc_ratio:boolean=true;
  public nurs_ABC_analysis:boolean=true;
  public nurs_shortexpiryflag:boolean=true;
  public nurs_pastinwardsflag:boolean=true;
  public nurs_greportsflag:boolean=true;
  public front_manufacturer:boolean=true;
  public front_stockorlevel:boolean=true;
  public front_suppliers:boolean=true;
  public front_suppliersProduct:boolean=true;
  public front_productMaster:boolean=true;
  public front_purchaseRequest:boolean=true;
  public front_purchaseOrder:boolean=true;
  public front_inventoryReceivables:boolean=true;
  public front_stockEdit:boolean=true;
  public front_stockreturnsflag:boolean=true;
  public front_suppaymentret:boolean=true;
  public front_stockadjustflag:boolean=true;
  public front_rackandbinflag:boolean=true;
  public front_stockstatusflag:boolean=true;
  public front_salesreportflag:boolean=true;
  public front_receivalblesreportflag:boolean=true;
  public front_returnsreportflag:boolean=true;
  public front_schdForDrugInspFlag:boolean=true;
  public front_salesForDrugInspFlag:boolean=true;
  public front_Bedocc_ratio:boolean=true;
  public front_ABC_analysis:boolean=true;
  public front_shortexpiryflag:boolean=true;
  public front_pastinwardsflag:boolean=true;
  public diet_manufacturer:boolean=true;
  public diet_stockorlevel:boolean=true;
  public diet_suppliers:boolean=true;
  public diet_suppliersProduct:boolean=true;
  public diet_productMaster:boolean=true;
  public diet_purchaseRequest:boolean=true;
  public diet_purchaseOrder:boolean=true;
  public diet_inventoryReceivables:boolean=true;
  public diet_stockEdit:boolean=true;
  public diet_stockreturnsflag:boolean=true;
  public diet_suppaymentret:boolean=true;
  public diet_stockadjustflag:boolean=true;
  public diet_rackandbinflag:boolean=true;
  public diet_stockstatusflag:boolean=true;
  public diet_salesreportflag:boolean=true;
  public diet_receivalblesreportflag:boolean=true;
  public diet_returnsreportflag:boolean=true;
  public diet_schdForDrugInspFlag:boolean=true;
  public diet_salesForDrugInspFlag:boolean=true;
  public diet_Bedocc_ratio:boolean=true;
  public diet_ABC_analysis:boolean=true;
  public diet_shortexpiryflag:boolean=true;
  public diet_pastinwardsflag:boolean=true;
  public diet_greportsflag:boolean=true;
  public phy_manufacturer:boolean=true;
  public phy_stockorlevel:boolean=true;
  public phy_suppliers:boolean=true;
  public phy_suppliersProduct:boolean=true;
  public phy_productMaster:boolean=true;
  public phy_purchaseRequest:boolean=true;
  public phy_purchaseOrder:boolean=true;
  public phy_inventoryReceivables:boolean=true;
  public phy_stockEdit:boolean=true;
  public phy_stockreturnsflag:boolean=true;
  public phy_suppaymentret:boolean=true;
  public phy_stockadjustflag:boolean=true;
  public phy_rackandbinflag:boolean=true;
  public phy_stockstatusflag:boolean=true;
  public phy_salesreportflag:boolean=true;
  public phy_receivalblesreportflag:boolean=true;
  public phy_returnsreportflag:boolean=true;
  public phy_schdForDrugInspFlag:boolean=true;
  public phy_salesForDrugInspFlag:boolean=true;
  public phy_Bedocc_ratio:boolean=true;
  public phy_ABC_analysis:boolean=true;
  public phy_shortexpiryflag:boolean=true;
  public phy_pastinwardsflag:boolean=true;
  public phy_greportsflag:boolean=true;
  public dayBookReportFlag:boolean=true;
  public otherExpenseFlag:boolean=true;
  public logReportFlag:boolean=true;
  public purchaseOrder_acc:boolean = true;
  public suppaymentret_acc:boolean = true;
  public stockstatusflag_acc:boolean= true;
  public receivalblesreportflag_acc:boolean= true;
  public salesreportflag_acc:boolean= true;
  public inpatReportFlag_acc:boolean= true;
  public salesrepgst_acc:boolean= true;
  public diagBillsReportFlag_acc:boolean= true;
  public hospBillsReportFlag_acc:boolean= true;
  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService,
    public messageservice: MenuViewService, public dialog: MatDialog, public masterData: MasterHelperService, public masterCSdata: MasterCSHelperService
    , public speechRecognition: VoicerecognitionService) {
    this.masterData.callMasterData();
    this.masterCSdata.clearValue();
    FrontDesk_Helper.setBillFlag(undefined);
    Admin_Helper.setDiagBill(undefined);
    Diet_Helper.setDietFlow(undefined);
    this.appointments("app");
    // this.menuNav("appointment");
  }

  check_scroll() {
    this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
  }

  speechRecognitionvalue() {
    this.subscription = this.speechRecognition.getMessage().subscribe(message => {
      if (message) {
        console.log("menu" + message)
        if (message in this.menu_bar_keys) {
          $("#" + this.menu_bar_keys[message]).click();
        }
      }
    })
  }

  ngOnInit() {
    this.loginType = Helper_Class.getLoginType();
    this.speechRecognitionvalue()
    console.log("loginType" + this.loginType);
    this.getCurrentDate();
    this.subscription = this.messageservice.getMessage().subscribe(message => {
      if (message) {
        if (this.loginType == "Admin") {
          this.menuNavAdmin(message, "");
        } else {
          this.menuNav(message);
        }
      }
    });
    this.messageservice.listen().subscribe((m: any) => {
      if (m == "detail_view") {
        //this.check_scroll();
      }
    });
    this.userInfo = Helper_Class.getInfo();
    this.firstName = this.userInfo.first_name;
    this.lastName = this.userInfo.last_name;
    this.docInpatSubmenuFlag = false;
    this.minimized_asied = false;
    this.pastinwardsflag = false;
    this.shortexpiryflag = false;
    this.central_store=this.userInfo.hospitals[0].is_central_store;
    this.req_central_store=this.userInfo.hospitals[0].req_central_store;
    this.user_department=this.userInfo.hospitals[0].dept_desc
    this.user_department_id=this.userInfo.hospitals[0].department_id
    this.user_store=this.userInfo.hospitals[0].store_desc	
    this.user_store_id=this.userInfo.hospitals[0].store_id
    console.log("USER INFO DETAILS " + JSON.stringify(this.userInfo))
    // this.hospitalLogo = Doc_Helper.getHospital_logo();
    if (this.userInfo.hptl_logo != undefined) {
      this.hospitalLogo = ipaddress.Ip_with_img_address + this.userInfo.hptl_logo;
    } else {
      //this.userInfo.hospitals[0].hptl_clinic_id
      if (this.userInfo.provider == "pharmacy") {
        this.hospitalLogo = ipaddress.Ip_with_img_address + this.userInfo.hospitals[0].logo;
      }
    }
    this.hospitalName = this.userInfo.hospitals[0].pat_ident_prefix;

    // dynamic menu display
    if (this.loginType == "doctor" || this.loginType == "consultant") {
      localStorage.setItem("hospital_name", this.hospitalName);
      this.sendType = "doctor";
      this.Usertype = "Doctor";
      var Moduleidlist;
      if (Doc_Helper.getModuleList() != null) {
        Moduleidlist = Doc_Helper.getModuleList();
        this.dashboardBasicFlag = false;
        this.docCaseSummaryFlag = false;
        this.docReferenceVideoFlag = false;
        Doc_Helper.setCasesheet_flag("0");
        Doc_Helper.setCasesumm_flag("0");
        console.log("module id ((())) " + JSON.stringify(Moduleidlist))
        for (var i = 0; i < Moduleidlist.length; i++) {
          if (Moduleidlist[i].module_id == "66"
            && (Moduleidlist[i].create == "1" || Moduleidlist[i].view == "1"
              || Moduleidlist[i].edit == "1")) {
            this.docHomecareFlag = false;
          } else if (Moduleidlist[i].module_id == "12"
            && (Moduleidlist[i].create == "1" || Moduleidlist[i].view == "1"
              || Moduleidlist[i].edit == "1")) {
            this.dashboardBasicFlag = false;
          } else if (Moduleidlist[i].module_id == "13"
            && (Moduleidlist[i].create == "1" || Moduleidlist[i].view == "1"
              || Moduleidlist[i].edit == "1")) {
            this.docDashboardFlag = false;
            this.demanageappointment = false
          } else if (Moduleidlist[i].module_id == "4") {
            this.docAppointmentListFlag = false;
            if (Moduleidlist[i].create == "1") {
              Helper_Class.setAppCreate("1");
            } else {
              Helper_Class.setAppCreate("0");
            }
            if (Moduleidlist[i].delete == "1") {
              Helper_Class.setBillDelete("1");
            } else {
              Helper_Class.setBillDelete("0");
            }
          } else if (Moduleidlist[i].module_id == "7") {
            this.docBillingFlag = false;
            if (Moduleidlist[i].create == "1") {
              Helper_Class.setBillCreate("1");
            } else {
              Helper_Class.setBillCreate("0");
            }
            if (Moduleidlist[i].edit == "1") {
              Helper_Class.setBillEdit("1");
            } else {
              Helper_Class.setBillEdit("0");
            }
            if (Moduleidlist[i].delete == "1") {
              Helper_Class.setBillDelete("1");
            }
            else {
              Helper_Class.setBillDelete("0");
            }
          }
          else if (Moduleidlist[i].module_id == "132") {
            // patient List
            this.patientListFlag = false;
            this.inpatientMenuFlag = false;
          } 
          else if (Moduleidlist[i].module_id == "120") {
            this.docNursePermssionFlag = false;

          }
          else if (Moduleidlist[i].module_id == "20"
          ) {

          } else if (Moduleidlist[i].module_id == "85"
          ) {
            this.docCaseSheetFlag = false;
            Doc_Helper.setCasesheet_flag("1");
          } else if (Moduleidlist[i].module_id == "30"
          ) {
            this.docCaseSummaryFlag = false;
            Doc_Helper.setCasesumm_flag("1");
          } else if (Moduleidlist[i].module_id == "5"
          ) {
            this.docWalkinFlag = false;
          } else if (Moduleidlist[i].module_id == "1") {


            this.Patientsflag = false;
          }
          else if (Moduleidlist[i].module_id == "2") {
            this.docDashboard = false;
            this.docInpatientFlag = false;
          } else if (Moduleidlist[i].module_id == "6") {
            this.viewPrescritionFlag = false;
          }
          else if (Moduleidlist[i].module_id == "7") {
            this.docbilling = false;
          } else if (Moduleidlist[i].module_id == "8"
          ) {
            this.docSecondopinionFlag = false;


          } else if (Moduleidlist[i].module_id == "9"
          ) {
            this.schedulescalendar = false;
            this.docDoctorcalender = false

          } else if (Moduleidlist[i].module_id == "45"
          ) {
            this.docMisReport = false;

          } else if (Moduleidlist[i].module_id == "46"
          ) {
            this.docReferenceVideoFlag = false;

          }
          else if (Moduleidlist[i].module_id == "71"
          ) {
            // Upload reports
          }
          else if (Moduleidlist[i].module_id == "77"
          ) {
            this.consolidatedFlag = false;
          }
          else if (Moduleidlist[i].module_id == "199") {
            // this.viewPrescritionFlag = false;
          }
          else if (Moduleidlist[i].module_id == "11") {
            this.schedulesviewschedules = false;
            this.docDoctorcalender = false;
          }
          else if (Moduleidlist[i].module_id == "10") {
            this.schedulesavailability = false;
            this.docDoctorcalender = false;
          }
          // master
          else if (Moduleidlist[i].module_id == "26") {
            // CultureSensitivityDrugGroup
            this.DrugsNameTypeFlag = false;
            this.FraMasters = false;

          } else if (Moduleidlist[i].module_id == "123") {
            // ADmaster
            this.salesreport = false;
            this.mainreport = false;

          } else if (Moduleidlist[i].module_id == "124") {
            // ADmaster
            this.Receivablereports = false;
            this.mainreport = false;

          } else if (Moduleidlist[i].module_id == "133") {
            // ADmaster
            this.addmasterflag = false
            this.FraMasters = false;

          } else if (Moduleidlist[i].module_id == "28") {
            this.Sampletypeflag = false;
            // culturepathology
            this.FraMasters = false;
            this.cult_path_flag = false;
          }
          else if (Moduleidlist[i].module_id == "83" || Moduleidlist[i].module_id == "154") {
            // medicine tracker or diet tracker
            this.nurseMedicine = false

          }
          else if (Moduleidlist[i].module_id == "29") {
            // sampletype
            this.DiagnosticDepartmentflag = false;
            this.FraMasters = false;
          }
          else if (Moduleidlist[i].module_id == "30") {
            // Diagnostict
            this.DiagnosticTube = false;
            this.FraMasters = false;
          }
          else if (Moduleidlist[i].module_id == "31") {
            // DiagnosticR
            this.DiagnosticReagentflag = false;
            this.FraMasters = false;
          }
          else if (Moduleidlist[i].module_id == "32") {
            // stockar
            this.Stockexchangeflag = false;
            this.FraMasters = false;
          }
          else if (Moduleidlist[i].module_id == "33") {
            // StoreName
            this.StoreNameflag = false;
            this.FraMasters = false;
          }
          else if (Moduleidlist[i].module_id == "34") {
            // GM
            this.GenericNameflag = false;
            this.FraMasters = false;
          }
          else if (Moduleidlist[i].module_id == "35") {
            // SM
            this.ScheduleNameflag = false;
            this.FraMasters = false;
          }
          else if (Moduleidlist[i].module_id == "36") {
            // ifm
            this.ProductFormflag = false
            this.FraMasters = false;
          }
          else if (Moduleidlist[i].module_id == "140") {
            // ADD TEMPLATE
            this.addtemplateflag = false;
            this.FraMasters = false;
          }
          else if (Moduleidlist[i].module_id == "141") {
            // GRAMSTAIN
            this.gramstainsflag = false;
            this.FraMasters = false;
          }
          else if (Moduleidlist[i].module_id == "142") {
            // RateCard
            this.RatecardFlag = false
            this.FraMasters = false;
          }
          else if (Moduleidlist[i].module_id == "146") {
            // Visitpurpose
            this.visitpurposeFlag = false;
            this.FraMasters = false;
          }
          else if (Moduleidlist[i].module_id == "134") {
            this.CultureSensitivityDrugNameFlag = false
            this.FraMasters = false;
          }
          else if (Moduleidlist[i].module_id == "147") {
            // ConductionDoctorFlag
            this.ConductionDoctorFlag = false
            this.FraMasters = false;
          }
          else if (Moduleidlist[i].module_id == "148") {
            // specimenaccessionerFlag
            this.specimenaccessionerFlag = false;
            this.FraMasters = false;


          }
          else if (Moduleidlist[i].module_id == "149") {
            this.Surgery_equipmentsFlag = false
            this.FraMasters = false;

          }
          else if (Moduleidlist[i].module_id == "62") {
            this.Stockreports = false;
            this.mainreport = false;

          }
        }
      }
      this.getNotificationList();
    }
    //front-desk modules//
    else if (this.loginType == "front-desk") {
      this.usertype = this.userInfo.user_type
      this.Usertype = "Front office";
      var Moduleidlist = Helper_Class.getmodulelist();
      this.sendType = "front-desk";
      if (Helper_Class.getmodulelist() != null) {

        Moduleidlist = Helper_Class.getmodulelist();
        // this.billingChargesFlag   = true;
        this.billCreateFlag = true;
        this.billEditFlag = true;
        // this.inpatReportFlag = true;
        this.appointmentData = false;
        this.pathreportFlag = false;
        this.testdiag = false;
        this.test = false;
        this.radioreportFlag = false;
        this.microreportFlag = false;
        this.sourceFlag = false;
        this.MainPatients = false;
        this.diabFlag = false;
        this.userHidden = false;

        this.fraAddRole = false;
        this.fraSubDeparment = false;
        this.fraAddtemplate = false;
        this.fraGramstains = false;
        this.DrugsNameFlag = false;
        if(this.central_store =="1"){
          this.front_suppliers=false;
        }else{
          if(this.req_central_store == "1"){
            this.front_suppliers=true;
          }else{
            this.front_suppliers=false;
          }
          
        }

        if(this.central_store =="1"){
          this.front_suppliersProduct=false;
        }else{
          if(this.req_central_store == "1"){
            this.front_suppliersProduct=true;
          }else{
            this.front_suppliersProduct=false;
          }
        }

        if(this.central_store =="1"){
          this.front_productMaster=false;
        }else{
          if(this.req_central_store == "1"){
            this.front_productMaster=true;
          }else{
            this.front_productMaster=false;
          }
        }

        //purchase request
        if(this.central_store =="1"){
          if(this.req_central_store == "1"){
            this.front_purchaseRequest=false;
          }else{
            this.front_purchaseRequest=true;
          }
        }else if(this.central_store == "0"){
          if(this.req_central_store == "1"){
            this.front_purchaseRequest=false;
          }else{
            this.front_purchaseRequest=true;
          }
          
        }
        else{
          this.front_purchaseRequest=true;
        }

        if(this.central_store =="1" ){
          this.front_purchaseOrder=false;
        }else{
          if(this.req_central_store == "1"){
            this.front_purchaseOrder=true;
          }else{
            this.nurs_purchaseOrder=false;
          }
          
        } 

        if(this.central_store =="1"  ){
          this.front_inventoryReceivables=false;
        }else{
          if(this.req_central_store == "1"){
            this.front_inventoryReceivables=true;
          }else{
            this.front_inventoryReceivables=false;
          }
          
        }
      //  reports
        if(this.central_store =="1" ){
          this.front_stockstatusflag=false;
        }else{
          if(this.req_central_store == "1"){
            this.front_stockstatusflag=false;
          }else{
            this.front_stockstatusflag=false;
          }
          
        }

        // if(this.central_store =="1" ){
        //   this.nurs_greportsflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.nurs_greportsflag=false;
        //   }else{
        //     this.nurs_greportsflag=false;
        //   }
        // }
        // this.fraVisitpurpose = false;
        var bill: any = {};
        for (var i = 0; i < Moduleidlist.length; i++) {
          if (Moduleidlist[i].module_id == "1") {
            this.inpatientMenuFlag = false;
            this.inpatientFlag = false;//in-patients
          } else if (Moduleidlist[i].module_id == "151") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.WardTransferFlag = false;//ip-ward
            }
          } else if (Moduleidlist[i].module_id == "3") {
            this.dischargeListfra = false;//ip-discharge
          }
          else if (Moduleidlist[i].module_id == "135") {
            this.inpatientMenuFlag = false;
          }
          else if (Moduleidlist[i].module_id == "146") {
            this.fraVisitpurpose = false;
          }
          else if (Moduleidlist[i].module_id == "15") {
            this.manageAppointmentFlag = false;
            this.demanageappointment = false;
            this.demanageappointments = false
            // Doctor appointments
          } else if (Moduleidlist[i].module_id == "132") {
            // patient List
            this.patientListFlag = false;
            // this.inpatientMenuFlag = false;
          } else if (Moduleidlist[i].module_id == "2") {
            // Inpatient List

          } else if (Moduleidlist[i].module_id == "7") {
            // bill
            this.billingnor = false;
            this.BilllistFlag = false;
            this.mainBilllistFlag = false;

          } else if (Moduleidlist[i].module_id == "6") {
            // Medical prescription
            this.viewPrescritionFlag = false;

          } else if (Moduleidlist[i].module_id == "13") {
            // Medical prescription
            this.reference_video = false;

          } else if (Moduleidlist[i].module_id == "16") {
            // Diagnosis appointment
            this.manageAppointmentFlag = false;
          }

          else if (Moduleidlist[i].module_id == "17") {
            // Dietician appointment

          }
          else if (Moduleidlist[i].module_id == "18") {
            // Patient registration
            this.patienregFlag = false;
          }
          else if (Moduleidlist[i].module_id == "19") {
            // Advance
            this.advanceFlag = false;
            this.mainBilllistFlag = false;
          }
          else if (Moduleidlist[i].module_id == "20") {
            // Charges
            this.billingChargesFlag = false;
            this.mainBilllistFlag = false;

          }
          else if (Moduleidlist[i].module_id == "21") {
            // consolidated
            this.consolidatedBillingFlag = false;
            this.billingChargesFlag = false;
          }
          else if (Moduleidlist[i].module_id == "22") {
            // partial pay
            this.partialPaymentFlag = false;
            this.billingChargesFlag = false;
          }
          else if (Moduleidlist[i].module_id == "23") {
            // partial pay
            this.insuranceBillFlag = false;
            this.billingChargesFlag = false;
          }
          else if (Moduleidlist[i].module_id == "24") {
            // OT booking

            this.Otbookingfra = false;
          }
          else if (Moduleidlist[i].module_id == "25") {
            // OT booking
            this.OtProcedurefra = false;


          }
          else if (Moduleidlist[i].module_id == "26") {
            this.fraMicrobiologydruggroup = false;
            // fraMicrobiologydruggroup
          }
          else if (Moduleidlist[i].module_id == "27") {
            // Lead management
            this.leadMgmtFlag = false 

          }
          else if (Moduleidlist[i].module_id == "28") {
            // fraculture pathology
            this.fraculturepathology = false;
            this.FraMasters = false;

          }
          else if (Moduleidlist[i].module_id == "29") {
            // fraculture pathology
            this.fraspecimentype = false;
            this.FraMasters = false;
          }

          else if (Moduleidlist[i].module_id == "30") {
            // Diagnosis tube types
            this.FraMasters = false;
            this.FraTestTubeType = false;
          }
          else if (Moduleidlist[i].module_id == "31") {
            this.FraMasters = false;
            this.fraTestReagents = false;
          }
          else if (Moduleidlist[i].module_id == "32") {
            this.FraMasters = false;
            this.fraStockAdjustment = false;
          }
          else if (Moduleidlist[i].module_id == "33") {
            this.FraMasters = false;
            this.fraStoreName = false;
          }
          else if (Moduleidlist[i].module_id == "34") {
            this.FraMasters = false;
            this.fraGenericName = false;
          }
          else if (Moduleidlist[i].module_id == "35") {
            this.FraMasters = false;
            this.fraScheduleName = false;
          }
          else if (Moduleidlist[i].module_id == "36") {
            this.FraMasters = false;
            this.fraProductForm = false;
          }
          else if (Moduleidlist[i].module_id == "37") {
            this.wardMasterFlag = false;
            this.FraMasters = false;

          }
          else if (Moduleidlist[i].module_id == "38") {
            this.Otmasterfra = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "134") {
            this.SensitiveDrug = false;
            this.FraMasters = false;
          }
          else if (Moduleidlist[i].module_id == "142") { // Bill Print
            this.fraratecard = false;
            this.FraMasters = false;

          }
          else if (Moduleidlist[i].module_id == "147") {
            // ConductionDoctorFlag
            this.ConductionDoctorFlag = false
            this.FraMasters = false;
          }
          else if (Moduleidlist[i].module_id == "148") {
            // specimenaccessionerFlag
            this.specimenaccessionerFlag = false;
            this.FraMasters = false;
          }
          else if (Moduleidlist[i].module_id == "149") {
            this.Surgery_equipmentsFlag = false
            this.FraMasters = false;

          }

          else if (Moduleidlist[i].module_id == "39") {
            this.doctor_referral = false;
          }
          else if (Moduleidlist[i].module_id == "40") {
            this.hospBillsReportFlag = false;

          }
          else if (Moduleidlist[i].module_id == "41") {
            this.diagBillsReportFlag = false;
          }
          else if (Moduleidlist[i].module_id == "42") {
            this.notvistedpatreportflag = false;
          }
          else if (Moduleidlist[i].module_id == "43") {
            this.consolidatedreportflag = false;
          }
          else if (Moduleidlist[i].module_id == "44") {
            // Ward view  = false;
            this.wardMasterviewFlag = false;
          } else if (Moduleidlist[i].module_id == "52") {
            this.viewPrescritionFlag = false;
          } else if (Moduleidlist[i].module_id == "53") {

          } else if (Moduleidlist[i].module_id == "56") {
            this.advanceFlag = false;

          } else if (Moduleidlist[i].module_id == "57") {

          } else if (Moduleidlist[i].module_id == "58") {
            this.patientListFlag = false;

          } else if (Moduleidlist[i].module_id == "59") {

          } else if (Moduleidlist[i].module_id == "60") {

          } else if (Moduleidlist[i].module_id == "71") {
            // Upload reports

          }
          else if (Moduleidlist[i].module_id == "77") {


          } else if (Moduleidlist[i].module_id == "65") {
            this.hospBillsReportFlag = false;

          }
          else if (Moduleidlist[i].module_id == "67") {
            // Case summary home


          } else if (Moduleidlist[i].module_id == "68") {
            this.inpatReportFlag = false;

          } else if (Moduleidlist[i].module_id == "28") { // Bill View
            Helper_Class.setBillView(null);
            Helper_Class.setBillView("1");

          } else if (Moduleidlist[i].module_id == "54") { // Bill Create
            this.billCreateFlag = false;
            bill.create = Moduleidlist[i];
            FrontDesk_Helper.setBillFlag(bill);
            Helper_Class.setBillCreate(null);
            Helper_Class.setBillCreate("1");

          } else if (Moduleidlist[i].module_id == "55") { // Bill Edit
            this.billEditFlag = false;
            bill.edit = Moduleidlist[i];
            FrontDesk_Helper.setBillFlag(bill);
            Helper_Class.setBillEdit(null);
            Helper_Class.setBillEdit("1");

          } else if (Moduleidlist[i].module_id == "85") { // Bill Print
            // this.Casesheets = false

            Helper_Class.setBillPrint(null);
            Helper_Class.setBillPrint("1");

          } else if (Moduleidlist[i].module_id == "101") { // Bill Print
            Helper_Class.setBillDelete(null);
            Helper_Class.setBillDelete("1");


          }
          else if (Moduleidlist[i].module_id == "46") {
            this.reference_video = false;

          }
          else if (Moduleidlist[i].module_id == "89") {
            this.abcAnalysisFlag = false;

          }
          else if (Moduleidlist[i].module_id == "90") {

            this.bedOccupancyFlag = false;

          }
          else if (Moduleidlist[i].module_id == "91") {

            this.billDueFlag = false;

          }
          else if (Moduleidlist[i].module_id == "92") {
            this.refund = false;
            this.Bill_refundflag = false;
            this.mainBilllistFlag = false;
          }
          else if (Moduleidlist[i].module_id == "132") {
            // Patient list

          }
          else if (Moduleidlist[i].module_id == "133") {
            this.UserRoleFlag = false;
          }


          else if (Moduleidlist[i].module_id == "136") {
            // Hospital  TAT
          }
          else if (Moduleidlist[i].module_id == "109") {
            this.salesreportdoctorReportFlag = false;

          } else if (Moduleidlist[i].module_id == "110") {
            // this.Bill_refundflag  = false;

          } else if (Moduleidlist[i].module_id == "111") {
            // this.billDueFlag= false;

          } else if (Moduleidlist[i].module_id == "112") {
            this.bedOccupancyFlag = false;

          } else if (Moduleidlist[i].module_id == "113") {
            this.abcAnalysisFlag = false;

          } else if (Moduleidlist[i].module_id == "114") {
            this.labtestFlag = false;

          } else if (Moduleidlist[i].module_id == "115") {
            this.productReceivablesFlag = false;
          }
          else if (Moduleidlist[i].module_id == "138") {
            this.consultantFlag = false;
          }
          else if (Moduleidlist[i].module_id == "120") {
            // permissions
            this.permissionsFlag = false;
            if (Moduleidlist[i].module_id == "86" || Moduleidlist[i].module_id == "87" || Moduleidlist[i].module_id == "88") {
              this.permissionSubmenuFlag = false;
            }
          }
          else if (Moduleidlist[i].module_id == "86") {
            this.permissionSubmenuFlag = false;
          } else if (Moduleidlist[i].module_id == "87") {
            this.permissionSubmenuFlag = false;
          } else if (Moduleidlist[i].module_id == "88") {
            this.permissionSubmenuFlag = false;
          }
          else if (Moduleidlist[i].module_id == "143") {
            // permissions
            // Inpatient reports

          }

          else if (Moduleidlist[i].module_id == "148") {
            // sa
          }
          else if (Moduleidlist[i].module_id == "150") {
          }

        }

        if (this.hospBillsReportFlag == true && this.diagBillsReportFlag == true && this.inpatReportFlag == true && this.notvistedpatreportflag == true ) {
          this.reportsFlag = true;
          
        }
        this.getNotificationList();
      }
    }
    else if (this.loginType == "OT admin") {
      var Moduleidlist = Helper_Class.getmodulelist();
      this.sendType = "OT admin";
      this.Usertype = "OT admin";
      console.log("Moduleidlist === ", Moduleidlist)
      if (Helper_Class.getmodulelist() != null) {
        Moduleidlist = Helper_Class.getmodulelist();
        // this.billingChargesFlag = true;
        this.billCreateFlag = true;
        this.billEditFlag = true;
        // this.inpatReportFlag = true;
        this.appointmentData = false;
        this.pathreportFlag = false;
        this.testdiag = false;
        this.test = false;
        this.radioreportFlag = false;
        this.microreportFlag = false;
        this.sourceFlag = false;
        this.MainPatients = false;
        this.diabFlag = false;
        this.userHidden = false;
        this.BilllistFlag = false

        this.fraAddRole = false;
        this.fraSubDeparment = false;
        this.fraAddtemplate = false;
        this.fraGramstains = false;

        this.fraVisitpurpose = false;
        var bill: any = {};

        for (var i = 0; i < Moduleidlist.length; i++) {
          if (Moduleidlist[i].module_id == "15") {
            this.manageAppointmentFlag = false;
            // Doctor appointments
          } else if (Moduleidlist[i].module_id == "1") {
            this.inpatientMenuFlag = false;
            // patient List

          }
          else if (Moduleidlist[i].module_id == "2") {
            // Inpatient List
            // this.inpatientMenuFlag = false;

          } else if (Moduleidlist[i].module_id == "7") {
            // bill
            this.billingnor = false;

          } else if (Moduleidlist[i].module_id == "6") {
            // Medical prescription
            this.viewPrescritionFlag = false;

          } else if (Moduleidlist[i].module_id == "13") {
            // Medical prescription
            this.reference_video = false;

          } else if (Moduleidlist[i].module_id == "16") {
            // Diagnosis appointment
          } else if (Moduleidlist[i].module_id == "17") {
            // Dietician appointment

          } else if (Moduleidlist[i].module_id == "18") {
            // Patient registration
            this.patienregFlag = false;
          } else if (Moduleidlist[i].module_id == "19") {
            // Advance
            this.advanceFlag = false;
          } else if (Moduleidlist[i].module_id == "20") {
            // Charges
            this.billingChargesFlag = false;

          } else if (Moduleidlist[i].module_id == "21") {
            // consolidated
            this.consolidatedBillingFlag = false;
          } else if (Moduleidlist[i].module_id == "22") {
            // partial pay
            this.partialPaymentFlag = false;
          } else if (Moduleidlist[i].module_id == "23") {
            // partial pay
            this.insuranceBillFlag = false;
          } else if (Moduleidlist[i].module_id == "24") {
            // OT booking
            this.Otbookingfra = false;
            this.deotbooking = false;
          } else if (Moduleidlist[i].module_id == "25") {
            // OT booking
            this.OtProcedurefra = false;

          } else if (Moduleidlist[i].module_id == "26") {
            this.fraMicrobiologydruggroup = false;
            // fraMicrobiologydruggroup
          } else if (Moduleidlist[i].module_id == "27") {
            // Lead management
            this.leadMgmtFlag = false

          } else if (Moduleidlist[i].module_id == "28") {
            // fraculture pathology
            this.fraculturepathology = false;

          } else if (Moduleidlist[i].module_id == "29") {
            // fraculture pathology
            this.fraspecimentype = false;
          } else if (Moduleidlist[i].module_id == "30") {
            // Diagnosis tube types
            this.FraTestTubeType = false;
          } else if (Moduleidlist[i].module_id == "31") {
            this.fraTestReagents = false;

          } else if (Moduleidlist[i].module_id == "32") {
            this.fraStockAdjustment = false;

          } else if (Moduleidlist[i].module_id == "33") {
            this.fraStoreName = false;
          } else if (Moduleidlist[i].module_id == "34") {
            this.fraGenericName = false;
          } else if (Moduleidlist[i].module_id == "35") {
            this.fraScheduleName = false;
          } else if (Moduleidlist[i].module_id == "36") {
            this.fraProductForm = false;
          } else if (Moduleidlist[i].module_id == "37") {
            this.wardMasterFlag = false;
          } else if (Moduleidlist[i].module_id == "38") {
            this.Otmasterfra = false;
          } else if (Moduleidlist[i].module_id == "39") {
            this.doctor_referral = false;
          } else if (Moduleidlist[i].module_id == "40") {
            this.hospBillsReportFlag = false;

          } else if (Moduleidlist[i].module_id == "41") {
            this.diagBillsReportFlag = false;
          } else if (Moduleidlist[i].module_id == "42") {
            this.notvistedpatreportflag = false;
          } else if (Moduleidlist[i].module_id == "43") {
            this.consolidatedreportflag = false;
          } else if (Moduleidlist[i].module_id == "44") {
            // this.consultantFlag = false;
            this.wardMasterviewFlag = false;
          } else if (Moduleidlist[i].module_id == "52") {
            this.viewPrescritionFlag = false;
          } else if (Moduleidlist[i].module_id == "53") {

          } else if (Moduleidlist[i].module_id == "56") {
            this.advanceFlag = false;

          } else if (Moduleidlist[i].module_id == "57") {

          }
          // else if (Moduleidlist[i].module_id == "58") {
          //   this.patientListFlag = false;

          // } 
          else if (Moduleidlist[i].module_id == "59") {

          } else if (Moduleidlist[i].module_id == "60") {

          } else if (Moduleidlist[i].module_id == "77") {


          } else if (Moduleidlist[i].module_id == "65") {
            this.hospBillsReportFlag = false;

          } else if (Moduleidlist[i].module_id == "66") {
            this.diagBillsReportFlag = false;

          } else if (Moduleidlist[i].module_id == "68") {
            this.inpatReportFlag = false;

          } else if (Moduleidlist[i].module_id == "28") { // Bill View
            Helper_Class.setBillView(null);
            Helper_Class.setBillView("1");

          } else if (Moduleidlist[i].module_id == "54") { // Bill Create
            this.billCreateFlag = false;
            bill.create = Moduleidlist[i];
            FrontDesk_Helper.setBillFlag(bill);
            Helper_Class.setBillCreate(null);
            Helper_Class.setBillCreate("1");

          } else if (Moduleidlist[i].module_id == "55") { // Bill Edit
            this.billEditFlag = false;
            bill.edit = Moduleidlist[i];
            FrontDesk_Helper.setBillFlag(bill);
            Helper_Class.setBillEdit(null);
            Helper_Class.setBillEdit("1");

          } else if (Moduleidlist[i].module_id == "85") { // Bill Print
            Helper_Class.setBillPrint(null);
            Helper_Class.setBillPrint("1");

          } else if (Moduleidlist[i].module_id == "101") { // Bill Print
            Helper_Class.setBillDelete(null);
            Helper_Class.setBillDelete("1");


          }
          else if (Moduleidlist[i].module_id == "142") { // Bill Print
            this.fraratecard = false;


          } else if (Moduleidlist[i].module_id == "46") {
            this.reference_video = false;

          }
          else if (Moduleidlist[i].module_id == "89") {
            this.abcAnalysisFlag = false;

          }
          else if (Moduleidlist[i].module_id == "90") {

            this.bedOccupancyFlag = false;

          }
          else if (Moduleidlist[i].module_id == "91") {

            this.billDueFlag = false;

          }
          else if (Moduleidlist[i].module_id == "92") {
            this.refund = false;
            this.Bill_refundflag = false;

          }
          else if (Moduleidlist[i].module_id == "133") {
            this.UserRoleFlag = false;

          }
          else if (Moduleidlist[i].module_id == "134") {
            this.SensitiveDrug = false;

          }
          else if (Moduleidlist[i].module_id == "109") {
            this.salesreportdoctorReportFlag = false;

          } else if (Moduleidlist[i].module_id == "110") {
            // this.Bill_refundflag= false;

          } else if (Moduleidlist[i].module_id == "111") {
            // this.billDueFlag = false;

          } else if (Moduleidlist[i].module_id == "112") {
            this.bedOccupancyFlag = false;

          } else if (Moduleidlist[i].module_id == "113") {
            this.abcAnalysisFlag = false;

          } else if (Moduleidlist[i].module_id == "114") {
            this.labtestFlag = false;

          } else if (Moduleidlist[i].module_id == "115") {
            this.productReceivablesFlag = false;
          }
          else if (Moduleidlist[i].module_id == "132") {
            this.patientListFlag = false;
          }
          else if (Moduleidlist[i].module_id == "138") {
            this.consultantFlag = false;
          }
          else if (Moduleidlist[i].module_id == "120") {
            // permissions
            this.permissionsFlag = false;
            this.permissionSubmenuFlag = false;
          }
          else if (Moduleidlist[i].module_id == "147") {
            // ConductionDoctorFlag
            this.ConductionDoctorFlag = false
            this.FraMasters = false;

          }
          else if (Moduleidlist[i].module_id == "148") {
            // specimenaccessionerFlag
            this.specimenaccessionerFlag = false;
            this.FraMasters = false;
          }
          else if (Moduleidlist[i].module_id == "149") {
            this.Surgery_equipmentsFlag = false
            this.FraMasters = false;

          }
          else if (Moduleidlist[i].module_id == "26") {
            this.productformflag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "134") {
            this.cult_sense_drugName_flag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "133") {
            this.fraAddRole = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "28") {
            this.cult_path_flag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "29") {
            this.specimen_type_flag = false;
            this.FraMasters = false;
          }
          //  else if (Moduleidlist[i].module_id == "") {
          //   this.diagnosticDFlag = false;
          // }
          else if (Moduleidlist[i].module_id == "30") {
            this.diag_tube_type_flag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "31") {
            this.diag_reagent_flag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "32") {
            this.stockarFlag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "33") {
            this.StoreNameFlag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "34") {
            this.genericnameflag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "35") {
            this.SMFlag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "") {
            this.subDepartmentFlag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "140") {
            this.addTemplateFlag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "141") {
            this.gramStainFlag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "142") {
            this.rateCardFlag = false; this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "146") {
            this.visitPurposeFlag = false; this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "134") {
            this.fraculturepathology = false; this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "133") {
            this.UserRoleFlag = false; this.FraMasters = false;
          }
        }

        if (this.hospBillsReportFlag == true && this.diagBillsReportFlag == true && this.inpatReportFlag == true) {
          this.reportsFlag = true;
        }
      }
    } else if (this.loginType == "Accounts") {
      var Moduleidlist = Helper_Class.getmodulelist();
      this.sendType = "Accounts";
      this.Usertype = "Accounts";
      console.log("Moduleidlist === ", Moduleidlist);
      this.inventoryReceivables = true;
      this.suppaymentret = true;
      this.salesreportflag = true;
      this.receivalblesreportflag = true;
      this.inpatReportFlag = true;
      this.dayBookReportFlag = true;
      this.otherExpenseFlag = true;
      this.logReportFlag = true;
      this.purchaseOrder_acc = false;
      this.suppaymentret_acc = false;
      this.stockstatusflag_acc= false;
      this.receivalblesreportflag_acc= false;
      this.salesreportflag_acc= false;
      this.inpatReportFlag_acc= false;
      this.receivalblesreportflag_acc= false;
      this.salesrepgst_acc= false;
      this.diagBillsReportFlag_acc= false;
      this.hospBillsReportFlag_acc= false;

      if (Helper_Class.getmodulelist() != null) {
        Moduleidlist = Helper_Class.getmodulelist();
        for (var i = 0; i < Moduleidlist.length; i++) {
          if (Moduleidlist[i].module_id == "57") {
            if (Moduleidlist[i].edit == '1' || Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' || 
                Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1') {
              this.inventoryReceivables = false;
            } 
          } else if (Moduleidlist[i].module_id == "60") {
            if (Moduleidlist[i].edit == '1' || Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' || 
                Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1') {
              this.suppaymentret = false;
            } 
          } else if (Moduleidlist[i].module_id == "123") {
            if (Moduleidlist[i].edit == '1' || Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' || 
                Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1') {
              this.salesreportflag = false;
            } 
          } else if (Moduleidlist[i].module_id == "124") {
            if (Moduleidlist[i].edit == '1' || Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' || 
                Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1') {
              this.receivalblesreportflag = false;
            } 
          } else if (Moduleidlist[i].module_id == "172") {
            if (Moduleidlist[i].edit == '1' || Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' || 
                Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1') {
              this.inpatReportFlag = false;
            } 
          } else if (Moduleidlist[i].module_id == "173") {
            if (Moduleidlist[i].edit == '1' || Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' || 
                Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1') {
              this.dayBookReportFlag = false;
            } 
          } else if (Moduleidlist[i].module_id == "174") {
            if (Moduleidlist[i].edit == '1' || Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' || 
                Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1') {
              this.otherExpenseFlag = false;
            } 
          } else if (Moduleidlist[i].module_id == "176") {
            if (Moduleidlist[i].edit == '1' || Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' || 
                Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1') {
              this.logReportFlag = false;
            } 
          } 
        }
      }
    }
    else if (this.loginType == "Admin") {
      this.sendType = "admin";
      this.Usertype = "Admin";
      this.salesReportFlag = true;
      this.stockEditFlag = true;
      this.stockReportFlag = true;
      this.docBillEditFlag = true;
      this.diagBillEditFlag = true;
      this.salesReportGstFlag = true;
      this.stockReceivableFlag = true;
      this.schdForDrugInspFlag = true;
      this.inwardReportFlag = true;
      this.inpatReportFlag = true;
      this.salesForDrugInspFlag = true;
      this.hospBillsReportFlag = true;
      this.diagBillsReportFlag = true;
      this.notvistedpatreportflag = false;
      this.hospitaltatflag = false;
      this.diagtatflag = false;
      this.pharmatatflag = false;
      // doctor
      this.docAppointmentListFlag = false;
      this.docBillingFlag = false;
      this.docSecondopinionFlag = false;
      this.docNursePermssionFlag = false;
      this.docDashboardFlag = false;
      this.dashboardBasicFlag = false;
      this.docHomecareFlag = false;
      this.docCaseSheetFlag = false;
      this.docCaseSummaryFlag = false;
      this.docWalkinFlag = false;
      this.docInpatientFlag = false;
      this.docMisReport = false;
      this.docReferenceVideoFlag = false;
      this.viewPrescritionFlag = false;
      // frant-desk
      this.manageAppointmentFlag = false;
      this.ADMINviewPrescritionFlag = false;
      this.billingChargesFlag = true;
      this.billCreateFlag = true;
      this.billEditFlag = true;
      this.advanceFlag = true;
      this.patientListFlag = false;
      this.wardMasterFlag = false;
      this.refund = false;
      this.consultantFlag = true;
      this.inpatientMenuFlag = false;
      this.hospBillsReportFlag = true;
      this.diagBillsReportFlag = true;
      this.inpatReportFlag = true;
      this.notvistedpatreportflag = false;
      this.consolidatedreportflag = false;
      this.consolidatedBillingFlag = false;
      this.partialPaymentFlag = false;
      this.insuranceBillFlag = false;
      this.billingChargesFlag = false;
      this.advanceFlag = false;
      this.subfrontbilling = false;
      this.adminpermissionsFlag = true;
      this.inpatientMenuFlag = false;
      this.permissionsFlag = false;
      this.consultantFlag = true;
      this.leadMgmtFlag = false;
      this.doctor_referral = false;
      this.reference_video = false;
      this.reportsFlag = false;
      this.hospBillsReportFlag = false;
      this.inpatReportFlag = false;
      this.diagBillsReportFlag = false;
      this.salesreportdoctorReportFlag = false;
      this.Bill_refundflag = false;
      this.billDueFlag = false;
      this.bedOccupancyFlag = false;
      this.abcAnalysisFlag = false;
      this.purchaseOrderFlag = false;
      this.productFlag = false;
      this.productReceivablesFlag = false;
      this.labtestFlag = false;
      this.notvistedpatreportflag = false;
      this.consolidatedreportflag = true;
      //PHYSIO balnce fetched in physio module
      this.physiosubFlag = false;
      //nurse
      this.nurseMainMenusub = false;
      this.dietiMainMenusub = false;
      //phamacy
      this.PharmacysubmenuFlag = false;
      this.PharmacysubmenuFlag1 = false;
      this.PharmacyreportSubmenuFlag = false;
      this.manageOrders = false;
      this.Pharmacymanufacturerflag = false;
      this.Pharmacysuppliers = false;
      this.PharmacysuppliersProduct = false;
      this.Pharmacydiscount = false;
      this.PharmacyproductMaster = false;
      this.PharmacypurchaseRequest = false;
      this.PharmacypurchaseOrder = false;
      this.PharmacyinventoryReceivables = false;
      this.PharmacystockEdit = false;
      this.inwardFlag = false;
      this.Pharmacyreference_videoFlag = true;
      this.pastinwardsflag=false
      this.shortexpiryflag = false;

      var Moduleidlist;
      if (Helper_Class.getmodulelist() != null) {
        Moduleidlist = Helper_Class.getmodulelist();
        this.stockEditFlag = true;
        this.stockReportFlag = true;
        this.docBillEditFlag = true;
        this.diagBillEditFlag = true;
        this.salesReportGstFlag = true;
        this.stockReceivableFlag = true;
        this.schdForDrugInspFlag = true;
        this.inwardReportFlag = true;
        this.inpatReportFlag = true;
        this.salesForDrugInspFlag = true;
        this.hospBillsReportFlag = true;
        this.diagBillsReportFlag = true;
        this.notvistedpatreportflag = false;

        this.hospitaltatflag = false;
        this.diagtatflag = false;
        this.pharmatatflag = false;

        // doctor
        this.docAppointmentListFlag = false;
        this.docBillingFlag = false;
        this.docSecondopinionFlag = false;
        this.docNursePermssionFlag = true;
        this.docDashboardFlag = false;
        this.dashboardBasicFlag = true;
        this.docHomecareFlag = true;
        this.docCaseSheetFlag = true;
        this.docCaseSummaryFlag = true;
        this.docWalkinFlag = false;
        this.docInpatientFlag = false;
        this.docMisReport = true;
        this.docReferenceVideoFlag = false;
        this.viewPrescritionFlag = false;
        // frant-desk
        this.manageAppointmentFlag = false;
        this.ADMINviewPrescritionFlag = false;
        this.billingChargesFlag = true;
        this.billCreateFlag = true;
        this.billEditFlag = true;
        this.advanceFlag = true;
        this.patientListFlag = false;
        this.wardMasterFlag = false;
        this.refund = true;
        this.consultantFlag = true;
        this.inpatientMenuFlag = false;
        this.hospBillsReportFlag = true;
        this.diagBillsReportFlag = true;
        this.inpatReportFlag = true;
        this.notvistedpatreportflag = false;
        this.consolidatedreportflag = false;
        this.consolidatedBillingFlag = false;
        this.partialPaymentFlag = false;
        this.insuranceBillFlag = false;
        this.billingChargesFlag = false;
        this.advanceFlag = false;
        this.subfrontbilling = false;
        this.adminpermissionsFlag = true;
        this.inpatientMenuFlag = false;
        this.consultantFlag = true;
        this.leadMgmtFlag = false;
        this.doctor_referral = false;
        this.reference_video = false;
        this.reportsFlag = false;
        this.hospBillsReportFlag = false;
        this.inpatReportFlag = false;
        this.diagBillsReportFlag = false;
        this.salesreportdoctorReportFlag = false;
        this.Bill_refundflag = false;
        this.billDueFlag = false;
        this.bedOccupancyFlag = false;
        this.abcAnalysisFlag = false;
        this.purchaseOrderFlag = false;
        this.productFlag = false;
        this.productReceivablesFlag = false;
        this.labtestFlag = false;
        this.notvistedpatreportflag = false;
        this.consolidatedreportflag = true;
        //PHYSIO balnce fetched in physio module
        this.physiosubFlag = false;
        //nurse
        this.nurseMainMenusub = false;
        this.dietiMainMenusub = false;
        //phamacy

        this.PharmacysubmenuFlag = false;
        this.PharmacysubmenuFlag1 = false;
        this.PharmacyreportSubmenuFlag = false;
        this.manageOrders = false;
        this.Pharmacymanufacturerflag = false;
        this.Pharmacysuppliers = false;
        this.PharmacysuppliersProduct = false;
        this.Pharmacydiscount = false;
        this.PharmacyproductMaster = false;
        this.PharmacypurchaseRequest = false;
        this.PharmacypurchaseOrder = false;
        this.PharmacyinventoryReceivables = false;
        this.PharmacystockEdit = false;
        this.inwardFlag = false;
        this.Pharmacyreference_videoFlag = true;
        for (var i = 0; i < Moduleidlist.length; i++) {
          if (Moduleidlist[i] == "47") {
            this.salesReportFlag = false;
          } else if (Moduleidlist[i] == "49") {
            this.stockEditFlag = false;
          } else if (Moduleidlist[i] == "48") {
            this.stockReportFlag = false;
          } else if (Moduleidlist[i] == "50") {
            this.docBillEditFlag = false;
          } else if (Moduleidlist[i] == "51") {
            this.diagBillEditFlag = false;
            Admin_Helper.setDiagBill(false);
          } else if (Moduleidlist[i] == "61") {
            this.salesReportGstFlag = false;
          } else if (Moduleidlist[i] == "62") {
            this.stockReceivableFlag = false;
          } else if (Moduleidlist[i] == "62") {
            this.stockReceivableFlag = false;
          } else if (Moduleidlist[i] == "63") {
            this.schdForDrugInspFlag = false;
          } else if (Moduleidlist[i] == "169") {
            this.salesForDrugInspFlag = false;
          } else if (Moduleidlist[i] == "65") {
            this.hospBillsReportFlag = false;
          } else if (Moduleidlist[i] == "66") {
            this.diagBillsReportFlag = false;
          } else if (Moduleidlist[i] == "67") {
            this.inwardReportFlag = false;
          } else if (Moduleidlist[i] == "68") {
            this.inpatReportFlag = false;
          } else if (Moduleidlist[i] == "77") {
            this.adminConsolidatedFlag = false;
          } else if (Moduleidlist[i] == "28") { // Bill View
            Helper_Class.setBillView(null);
            Helper_Class.setBillView("1");
          } else if (Moduleidlist[i] == "54") { // Bill Create
            Helper_Class.setBillCreate(null);
            Helper_Class.setBillCreate("1");
          } else if (Moduleidlist[i] == "55") { // Bill Edit
            Helper_Class.setBillEdit(null);
            Helper_Class.setBillEdit("1");
          } else if (Moduleidlist[i] == "85") { // Bill Print
            Helper_Class.setBillPrint(null);
            Helper_Class.setBillPrint("1");
          } else if (Moduleidlist[i] == "100") { // package create
            this.packageCreateFlag = true
          }
        }
      }

    }
    else if (this.loginType == "diagnosis") {
      this.Usertype = "Diagnosis";
      this.sendType = "diagnosis";
      if (Diagnosis_Helper.getmodulelistIds() != null) {
        // if(this.central_store =="1"){
        //   this.diag_manufacturer=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_manufacturer=true;
        //   }else{
        //     this.diag_manufacturer=false;
        //   }
          
        // }

        // if(this.central_store =="1"){
        //   this.diag_stockorlevel=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_stockorlevel=true;
        //   }else{
        //     this.diag_stockorlevel=false;
        //   }
         
        // }

        if(this.central_store =="1"){
          this.diag_suppliers=false;
        }else{
          if(this.req_central_store == "1"){
            this.diag_suppliers=true;
          }else{
            this.diag_suppliers=false;
          }
          
        }

        if(this.central_store =="1"){
          this.diag_suppliersProduct=false;
        }else{
          if(this.req_central_store == "1"){
            this.diag_suppliersProduct=true;
          }else{
            this.diag_suppliersProduct=false;
          }
        }

        if(this.central_store =="1"){
          this.diag_productMaster=false;
        }else{
          if(this.req_central_store == "1"){
            this.diag_productMaster=true;
          }else{
            this.diag_productMaster=false;
          }
        }

        //purchase request
        if(this.central_store =="1"){
          if(this.req_central_store == "1"){
            this.diag_purchaseRequest=false;
          }else{
            this.diag_purchaseRequest=true;
          }
        }else if(this.central_store == "0"){
          if(this.req_central_store == "1"){
            this.diag_purchaseRequest=false;
          }else{
            this.diag_purchaseRequest=true;
          }
          
        }
        else{
          this.diag_purchaseRequest=true;
        }

        if(this.central_store =="1" ){
          this.diag_purchaseOrder=false;
        }else{
          if(this.req_central_store == "1"){
            this.diag_purchaseOrder=true;
          }else{
            this.diag_purchaseOrder=false;
          }
          
        } 

        if(this.central_store =="1"  ){
          this.diag_inventoryReceivables=false;
        }else{
          if(this.req_central_store == "1"){
            this.diag_inventoryReceivables=true;
          }else{
            this.diag_inventoryReceivables=false;
          }
          
        }

        // if(this.central_store =="1" ){
        //   this.diag_stockEdit=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_stockEdit=true;
        //   }else{
        //     this.diag_stockEdit=false;
        //   }
          
        // }

        // if(this.central_store =="1"  ){
        //   this.diag_stockreturnsflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_stockreturnsflag=true;
        //   }else{
        //     this.diag_stockreturnsflag=false
        //   }
        // }

        // if(this.central_store =="1"  ){
        //   this.diag_suppaymentret=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_suppaymentret=true;
        //   }else{
        //     this.diag_suppaymentret=false;
        //   }
        // }

        // if(this.central_store =="1"){
        //   this.diag_stockadjustflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_stockadjustflag=true;
        //   }else{
        //     this.diag_stockadjustflag=false;
        //   }
        // }

        // if(this.central_store =="1" ){
        //   this.diag_rackandbinflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_rackandbinflag=true;
        //   }else{
        //     this.diag_rackandbinflag=false;
        //   }
        // }
      //  reports
        if(this.central_store =="1" ){
          this.diag_stockstatusflag=false;
        }else{
          if(this.req_central_store == "1"){
            this.diag_stockstatusflag=false;
          }else{
            this.diag_stockstatusflag=false;
          }
          
        }
        // if(this.central_store =="1" ){
        //   this.diag_salesreportflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_salesreportflag=true;
        //   }else{
        //     this.diag_salesreportflag=false;
        //   }
          
        // }        
        // if(this.central_store =="1" ){
        //   this.diag_receivalblesreportflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_receivalblesreportflag=true;
        //   }else{
        //     this.diag_receivalblesreportflag=false;
        //   }
          
        // }      
        //   if(this.central_store =="1" ){
        //   this.diag_returnsreportflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_returnsreportflag=true;
        //   }else{
        //     this.diag_returnsreportflag=false;
        //   }
          
        // }      
        //   if(this.central_store =="1" ){
        //   this.diag_schdForDrugInspFlag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_schdForDrugInspFlag=true;
        //   }else{
        //     this.diag_schdForDrugInspFlag=false;
        //   }
          
        // }       
        //  if(this.central_store =="1" ){
        //   this.diag_salesForDrugInspFlag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_salesForDrugInspFlag=true;
        //   }else{
        //     this.diag_salesForDrugInspFlag=false;
        //   }
        // }
      //  if(this.central_store =="1" ){
      //   this.diag_Bedocc_ratio=false;
      //    }else{
      //     if(this.req_central_store == "1"){
      //       this.diag_Bedocc_ratio=true;
      //     }else{
      //       this.diag_Bedocc_ratio=false;
      //     }
      //    }        
        // if(this.central_store =="1" ){
        // this.diag_ABC_analysis=false;
        //  }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_ABC_analysis=true;
        //   }else{
        //     this.diag_ABC_analysis=false;
        //   }
        //  }       
        // if(this.central_store =="1" ){
        // this.diag_shortexpiryflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_shortexpiryflag=true;
        //   }else{
        //     this.diag_shortexpiryflag=false;
        //   }
        // }       
      //   if(this.central_store =="1" ){
      //   this.diag_pastinwardsflag=false;
      //  }else{
      //   if(this.req_central_store == "1"){
      //     this.diag_pastinwardsflag=true;
      //   }else{
      //     this.diag_pastinwardsflag=false;
      //   }
      //   }
        if(this.central_store =="1" ){
          this.dia_greportsflag=false;
        }else{
          if(this.req_central_store == "1"){
            this.dia_greportsflag=false;
          }else{
            this.dia_greportsflag=false;
          }
        }
        Moduleidlist = Diagnosis_Helper.getmodulelistIds();
        console.log("JSON.stringify(Moduleidlist)"+JSON.stringify(Moduleidlist))
        for (var i = 0; i < Moduleidlist.length; i++) {
          if (Moduleidlist[i] == "1000") { // Bill View
            this.diagBillFlag = false;
            Helper_Class.setBillView(null);
            Helper_Class.setBillView("1");
          } else if (Moduleidlist[i] == "541") { // Bill Create
            Helper_Class.setBillCreate(null);
            Helper_Class.setBillCreate("1");
          } else if (Moduleidlist[i] == "551") { // Bill Edit
            Helper_Class.setBillEdit(null);
            Helper_Class.setBillEdit("1");
          } else if (Moduleidlist[i] == "851") { // Bill Print
            Helper_Class.setBillPrint(null);
            Helper_Class.setBillPrint("1");
          } else if (Moduleidlist[i] == "46") {
            this.reference_video = false;
          } else if (Moduleidlist[i] == "16") {
            if (Moduleidlist[i].create == "1")
              this.diagAppointmentFlag = false;
            this.deappointment = false;
          } else if (Moduleidlist[i] == "101") {
            this.specimen_coll_flag = false;
          } else if (Moduleidlist[i] == "148") {
            this.specimenaccessionerFlag = false;
          } else if (Moduleidlist[i] == "102") {
            this.diag_testAndPack_flag = false;
            this.diag_masters_flag = false;

          } else if (Moduleidlist[i] == "103" || Moduleidlist[i] == "104" || Moduleidlist[i] == "105" || Moduleidlist[i] == "106") {
            this.pathology_report_flag = false;
            if (Moduleidlist[i] == "103") {
              this.path_rept_pending_flag = false;
            } else if (Moduleidlist[i] == "104") {
              this.path_rept_rejected_flag = false;
            } else if (Moduleidlist[i] == "105") {
              this.path_rept_completed_flag = false;
            } else if (Moduleidlist[i] == "106") {
              this.path_rept_approved_flag = false;
            }
          } else if (Moduleidlist[i] == "107" || Moduleidlist[i] == "108" || Moduleidlist[i] == "109" || Moduleidlist[i] == "110") {
            this.radiology_report_flag = false;
            if (Moduleidlist[i] == "107") {
              this.radio_rept_pending_flag = false;
            } else if (Moduleidlist[i] == "108") {
              this.radio_rept_rejected_flag = false;
            } else if (Moduleidlist[i] == "109") {
              this.radio_rept_completed_flag = false;
            } else if (Moduleidlist[i] == "110") {
              this.radio_rept_approved_flag = false;
            }
          } else if (Moduleidlist[i] == "111" || Moduleidlist[i] == "112" || Moduleidlist[i] == "113" || Moduleidlist[i] == "114") {
            this.microbiology_report_flag = false;
            if (Moduleidlist[i] == "111") {
              this.micro_rept_pending_flag = false;
            } else if (Moduleidlist[i] == "112") {
              this.micro_rept_rejected_flag = false;
            } else if (Moduleidlist[i] == "113") {
              this.micro_rept_completed_flag = false;
            } else if (Moduleidlist[i] == "114") {
              this.micro_rept_approved_flag = false;
            }
          } else if (Moduleidlist[i] == "115" || Moduleidlist[i] == "116") {
            this.OutsourceFlag = false;
            if (Moduleidlist[i] == "115") {
              this.outsource_centre_flag = false;
            } else if (Moduleidlist[i] == "116") {
              this.outsource_payment_flag = false;
            }
          } else if (Moduleidlist[i] == "117") {
            this.diab_reading_flag = false;
          } else if (Moduleidlist[i] == "118") {
            this.diag_users_flag = false;
          } else if (Moduleidlist[i] == "119") {
            this.diag_pres_flag = false;
          } else if (Moduleidlist[i] == "7") {
            this.diag_billing_flag = false;
          } else if (Moduleidlist[i] == "26" || Moduleidlist[i] == "28" || Moduleidlist[i] == "29" || Moduleidlist[i] == "30" 
            || Moduleidlist[i] == "31" || Moduleidlist[i] == "134") {
            this.diag_masters_flag = false;
            if (Moduleidlist[i] == "26") {
              this.cult_sense_drugType_flag = false;
            } else if (Moduleidlist[i] == "28") {
              this.cult_path_flag = false;
            } else if (Moduleidlist[i] == "29") {
              this.specimen_type_flag = false;
            } else if (Moduleidlist[i] == "30") {
              this.diag_tube_type_flag = false;
            } else if (Moduleidlist[i] == "31") {
              this.diag_reagent_flag = false;
            } else if (Moduleidlist[i] == "134") {
              this.cult_sense_drugName_flag = false;
            } else if(Moduleidlist[i] == "168") {
              this.nephro_reading_flag= false;
            }
          }
          else if (Moduleidlist[i] == "132") {
            // patient List
            this.patientListFlag = false;
            this.inpatientMenuFlag = false;
          } 
        }
      }

    } else if (this.loginType == "nurse") {
      this.sendType = "nurse";
      this.Usertype = "Nurse";
      if (Helper_Class.getmodulelist() != null) {
        if(this.central_store =="1"){
          this.nurs_suppliers=false;
        }else{
          if(this.req_central_store == "1"){
            this.nurs_suppliers=true;
          }else{
            this.nurs_suppliers=false;
          }
          
        }

        if(this.central_store =="1"){
          this.nurs_suppliersProduct=false;
        }else{
          if(this.req_central_store == "1"){
            this.nurs_suppliersProduct=true;
          }else{
            this.nurs_suppliersProduct=false;
          }
        }

        if(this.central_store =="1"){
          this.nurs_productMaster=false;
        }else{
          if(this.req_central_store == "1"){
            this.nurs_productMaster=true;
          }else{
            this.nurs_productMaster=false;
          }
        }

        //purchase request
        if(this.central_store =="1"){
          if(this.req_central_store == "1"){
            this.nurs_purchaseRequest=false;
          }else{
            this.nurs_purchaseRequest=true;
          }
        }else if(this.central_store == "0"){
          if(this.req_central_store == "1"){
            this.nurs_purchaseRequest=false;
          }else{
            this.nurs_purchaseRequest=true;
          }
          
        }
        else{
          this.nurs_purchaseRequest=true;
        }

        if(this.central_store =="1" ){
          this.nurs_purchaseOrder=false;
        }else{
          if(this.req_central_store == "1"){
            this.nurs_purchaseOrder=true;
          }else{
            this.nurs_purchaseOrder=false;
          }
          
        } 

        if(this.central_store =="1"  ){
          this.nurs_inventoryReceivables=false;
        }else{
          if(this.req_central_store == "1"){
            this.nurs_inventoryReceivables=true;
          }else{
            this.nurs_inventoryReceivables=false;
          }
          
        }

        // if(this.central_store =="1" ){
        //   this.nurs_stockEdit=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.nurs_stockEdit=true;
        //   }else{
        //     this.nurs_stockEdit=false;
        //   }
          
        // }

        // if(this.central_store =="1"  ){
        //   this.nurs_stockreturnsflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.nurs_stockreturnsflag=true;
        //   }else{
        //     this.nurs_stockreturnsflag=false
        //   }
        // }

        // if(this.central_store =="1"  ){
        //   this.nurs_suppaymentret=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.nurs_suppaymentret=true;
        //   }else{
        //     this.nurs_suppaymentret=false;
        //   }
        // }

        // if(this.central_store =="1"){
        //   this.nurs_stockadjustflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.nurs_stockadjustflag=true;
        //   }else{
        //     this.nurs_stockadjustflag=false;
        //   }
        // }

        // if(this.central_store =="1" ){
        //   this.nurs_rackandbinflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.nurs_rackandbinflag=true;
        //   }else{
        //     this.nurs_rackandbinflag=false;
        //   }
        // }
      //  reports
        if(this.central_store =="1" ){
          this.nurs_stockstatusflag=false;
        }else{
          if(this.req_central_store == "1"){
            this.nurs_stockstatusflag=false;
          }else{
            this.nurs_stockstatusflag=false;
          }
          
        }
        // if(this.central_store =="1" ){
        //   this.diag_salesreportflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_salesreportflag=true;
        //   }else{
        //     this.diag_salesreportflag=false;
        //   }
          
        // }        
        // if(this.central_store =="1" ){
        //   this.diag_receivalblesreportflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_receivalblesreportflag=true;
        //   }else{
        //     this.diag_receivalblesreportflag=false;
        //   }
          
        // }      
        //   if(this.central_store =="1" ){
        //   this.diag_returnsreportflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_returnsreportflag=true;
        //   }else{
        //     this.diag_returnsreportflag=false;
        //   }
          
        // }      
        //   if(this.central_store =="1" ){
        //   this.diag_schdForDrugInspFlag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_schdForDrugInspFlag=true;
        //   }else{
        //     this.diag_schdForDrugInspFlag=false;
        //   }
          
        // }       
        //  if(this.central_store =="1" ){
        //   this.diag_salesForDrugInspFlag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_salesForDrugInspFlag=true;
        //   }else{
        //     this.diag_salesForDrugInspFlag=false;
        //   }
        // }
      //  if(this.central_store =="1" ){
      //   this.diag_Bedocc_ratio=false;
      //    }else{
      //     if(this.req_central_store == "1"){
      //       this.diag_Bedocc_ratio=true;
      //     }else{
      //       this.diag_Bedocc_ratio=false;
      //     }
      //    }        
        // if(this.central_store =="1" ){
        // this.diag_ABC_analysis=false;
        //  }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_ABC_analysis=true;
        //   }else{
        //     this.diag_ABC_analysis=false;
        //   }
        //  }       
        // if(this.central_store =="1" ){
        // this.diag_shortexpiryflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_shortexpiryflag=true;
        //   }else{
        //     this.diag_shortexpiryflag=false;
        //   }
        // }       
      //   if(this.central_store =="1" ){
      //   this.diag_pastinwardsflag=false;
      //  }else{
      //   if(this.req_central_store == "1"){
      //     this.diag_pastinwardsflag=true;
      //   }else{
      //     this.diag_pastinwardsflag=false;
      //   }
      //   }
        if(this.central_store =="1" ){
          this.nurs_greportsflag=false;
        }else{
          if(this.req_central_store == "1"){
            this.nurs_greportsflag=false;
          }else{
            this.nurs_greportsflag=false;
          }
        }
        Moduleidlist = Helper_Class.getmodulelist();
        console.log("ModuleList" + JSON.stringify(Moduleidlist));
        for (var i = 0; i < Moduleidlist.length; i++) {
          if (Moduleidlist[i].module_id == "1") {
            // inpatient
            this.patientflag = false;
            this.nurseinpat = false;
          
          } else if (Moduleidlist[i].module_id == "92") {
            // appointments
            this.appointmentData = false;
            this.doctorappointments = false;
          }
          
          else if (Moduleidlist[i].module_id == "4") {
            // appointments
            this.nursedocappointment = false;
          } else if (Moduleidlist[i].module_id == "15") {
            // Doctor appointments


          } else if (Moduleidlist[i].module_id == "24") {
            // OT booking
            this.nurseinpat = false;
            this.nursebooking = false;
          
          } else if (Moduleidlist[i].module_id == "25") {
            // OT procedures
            this.nurseinpat = false;
            this.nurseprocedure = false;
           

          } else if (Moduleidlist[i].module_id == "32") {
            // Stock adjustment reasons

          }
          else if (Moduleidlist[i].module_id == "44") {
            // Ward view
           
            this.nurseward = false
           
          }
          else if (Moduleidlist[i].module_id == "83" || Moduleidlist[i].module_id == "154") {
            // medicine tracker or diet tracker
            this.nurseinpat = false;
            this.nurseMedicine = false
           

          }
          else if (Moduleidlist[i].module_id == "129") {
            // Doctor list
            this.nursedoclist = false;

          }
          else if (Moduleidlist[i].module_id == "130") {
            // Doctor list
            this.nurseAssignments = false;

          }
          else if (Moduleidlist[i].module_id == "22") {
            this.partialPaymentFlag = false;
          } else if (Moduleidlist[i].module_id == "26") {
            this.productformflag = false;
          } else if (Moduleidlist[i].module_id == "134") {
            this.cult_sense_drugName_flag = false;
          } else if (Moduleidlist[i].module_id == "133") {
            this.fraAddRole = false;
          } else if (Moduleidlist[i].module_id == "28") {
            this.cult_path_flag = false;
          } else if (Moduleidlist[i].module_id == "29") {
            this.specimen_type_flag = false;
          }
          else if (Moduleidlist[i].module_id == "30") {
            this.diag_tube_type_flag = false;
          } else if (Moduleidlist[i].module_id == "31") {
            this.diag_reagent_flag = false;
          } else if (Moduleidlist[i].module_id == "32") {
            this.stockarFlag = false;
          } else if (Moduleidlist[i].module_id == "33") {
            this.StoreNameFlag = false;
          } else if (Moduleidlist[i].module_id == "34") {
            this.genericnameflag = false;
          } else if (Moduleidlist[i].module_id == "35") {
            this.SMFlag = false;
          } else if (Moduleidlist[i].module_id == "") {
            this.subDepartmentFlag = false;
          } else if (Moduleidlist[i].module_id == "140") {
            this.addTemplateFlag = false;
          } else if (Moduleidlist[i].module_id == "141") {
            this.gramStainFlag = false;
          } else if (Moduleidlist[i].module_id == "142") {
            this.rateCardFlag = false;
          } else if (Moduleidlist[i].module_id == "146") {
            this.visitPurposeFlag = false;
          } else if (Moduleidlist[i].module_id == "134") {
            this.fraculturepathology = false;
          } else if (Moduleidlist[i].module_id == "133") {
            this.UserRoleFlag = false;
          }
          else if (Moduleidlist[i].module_id == "147") {
            // ConductionDoctorFlag
            this.ConductionDoctorFlag = false
          }
          else if (Moduleidlist[i].module_id == "148") {
            // specimenaccessionerFlag
            this.specimenaccessionerFlag = false;
          }
          else if (Moduleidlist[i].module_id == "149") {
            this.Surgery_equipmentsFlag = false
          }
          else if (Moduleidlist[i].module_id == "151") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.WardTransferFlag = false;
            }
            this.nurseinpat=false;
          }
          else if (Moduleidlist[i].module_id == "132") {
            // patient List
            this.patientListFlag = false;
            this.inpatientMenuFlag = false;
          } 
          // nurseAssignments
        }
      
      }
    }
    else if (this.loginType == "Billing") {
      this.sendType = "Billing";
      this.Usertype = "Billing";
      if (Helper_Class.getmodulelist() != null) {
        Moduleidlist = Helper_Class.getmodulelist();
        console.log("ModuleList ==== " + JSON.stringify(Moduleidlist));
        for (var i = 0; i < Moduleidlist.length; i++) {
          if (Moduleidlist[i].module_id == "1") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.inpatFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "7") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.billListFlag = false;
              this.defront = false;
            }
          } else if (Moduleidlist[i].module_id == "13") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.reference_video = false;
            }
          } else if (Moduleidlist[i].module_id == "19") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.advancesFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "20") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.chargeFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "21") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.consolidatedBillFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "22") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.partialPaymentFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "23") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.insuranceBillingFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "26") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.productformflag = false;
            }
          } else if (Moduleidlist[i].module_id == "28") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.cult_path_flag = false;
            }
          } else if (Moduleidlist[i].module_id == "29") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.specimen_type_flag = false;
            }
          } else if (Moduleidlist[i].module_id == "30") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.diag_tube_type_flag = false;
            }
          } else if (Moduleidlist[i].module_id == "31") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.diag_reagent_flag = false;
            }
          } else if (Moduleidlist[i].module_id == "32") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.stockarFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "33") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.StoreNameFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "34") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.genericnameflag = false;
            }
          } else if (Moduleidlist[i].module_id == "35") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.productformflag = false;
            }
          } else if (Moduleidlist[i].module_id == "40") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.hospBillsReportFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "41") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.diagBillsReportFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "42") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.notvistedpatreportflag = false;
            }
          } else if (Moduleidlist[i].module_id == "43") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.consolidatedreportflag = false;
            }
          } else if (Moduleidlist[i].module_id == "48") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.stockReportFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "63") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.schdForDrugInspFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "169") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.salesForDrugInspFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "67") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.inwardReportFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "68") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.inpatReportFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "92") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.refundFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "123") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.pharmasalesGst = false;
              this.pharamasales_report = false;
            }
          } else if (Moduleidlist[i].module_id == "124") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.pharmastockReceivable = false;
            }
          } else if (Moduleidlist[i].module_id == "133") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.fraAddRole = false;
            }
          } else if (Moduleidlist[i].module_id == "134") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.fraculturepathology = false;
            }
          } else if (Moduleidlist[i].module_id == "140") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.fraAddtemplate = false;
            }
          } else if (Moduleidlist[i].module_id == "141") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.fraGramstains = false;
            }
          } else if (Moduleidlist[i].module_id == "142") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.rateCardFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "146") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.visitPurposeFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "147") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.ConductionDoctorFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "148") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.specimenaccessionerFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "149") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.Surgery_equipmentsFlag = false;
            }
          } else if (Moduleidlist[i].module_id == "") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.fraSubDeparment = false;
            }
          }
          else if (Moduleidlist[i].module_id == "132") {
            // patient List
            this.patientListFlag = false;
            this.inpatientMenuFlag = false;
          } 
        }
      }
    }
    else if (this.loginType == "physio") {
      this.Usertype = "Physio";
      var Moduleidlist;
      this.sendType = "physio";
      if (Physio_Helper.getModuleList() != null) {
        // if(this.central_store =="1"){
        //   this.diag_manufacturer=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_manufacturer=true;
        //   }else{
        //     this.diag_manufacturer=false;
        //   }
          
        // }

        // if(this.central_store =="1"){
        //   this.diag_stockorlevel=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_stockorlevel=true;
        //   }else{
        //     this.diag_stockorlevel=false;
        //   }
         
        // }

        if(this.central_store =="1"){
          this.phy_suppliers=false;
        }else{
          if(this.req_central_store == "1"){
            this.phy_suppliers=true;
          }else{
            this.phy_suppliers=false;
          }
          
        }

        if(this.central_store =="1"){
          this.phy_suppliersProduct=false;
        }else{
          if(this.req_central_store == "1"){
            this.phy_suppliersProduct=true;
          }else{
            this.phy_suppliersProduct=false;
          }
        }

        if(this.central_store =="1"){
          this.phy_productMaster=false;
        }else{
          if(this.req_central_store == "1"){
            this.phy_productMaster=true;
          }else{
            this.phy_productMaster=false;
          }
        }

        //purchase request
        if(this.central_store =="1"){
          if(this.req_central_store == "1"){
            this.phy_purchaseRequest=false;
          }else{
            this.phy_purchaseRequest=true;
          }
        }else if(this.central_store == "0"){
          if(this.req_central_store == "1"){
            this.phy_purchaseRequest=false;
          }else{
            this.phy_purchaseRequest=true;
          }
          
        }
        else{
          this.phy_purchaseRequest=true;
        }

        if(this.central_store =="1" ){
          this.phy_purchaseOrder=false;
        }else{
          if(this.req_central_store == "1"){
            this.phy_purchaseOrder=true;
          }else{
            this.phy_purchaseOrder=false;
          }
          
        } 

        if(this.central_store =="1"  ){
          this.phy_inventoryReceivables=false;
        }else{
          if(this.req_central_store == "1"){
            this.phy_inventoryReceivables=true;
          }else{
            this.phy_inventoryReceivables=false;
          }
          
        }

        // if(this.central_store =="1" ){
        //   this.diag_stockEdit=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_stockEdit=true;
        //   }else{
        //     this.diag_stockEdit=false;
        //   }
          
        // }

        // if(this.central_store =="1"  ){
        //   this.diag_stockreturnsflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_stockreturnsflag=true;
        //   }else{
        //     this.diag_stockreturnsflag=false
        //   }
        // }

        // if(this.central_store =="1"  ){
        //   this.diag_suppaymentret=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_suppaymentret=true;
        //   }else{
        //     this.diag_suppaymentret=false;
        //   }
        // }

        // if(this.central_store =="1"){
        //   this.diag_stockadjustflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_stockadjustflag=true;
        //   }else{
        //     this.diag_stockadjustflag=false;
        //   }
        // }

        // if(this.central_store =="1" ){
        //   this.diag_rackandbinflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_rackandbinflag=true;
        //   }else{
        //     this.diag_rackandbinflag=false;
        //   }
        // }
      //  reports
        if(this.central_store =="1" ){
          this.phy_stockstatusflag=false;
        }else{
          if(this.req_central_store == "1"){
            this.phy_stockstatusflag=false;
          }else{
            this.phy_stockstatusflag=false;
          }
          
        }
        // if(this.central_store =="1" ){
        //   this.diag_salesreportflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_salesreportflag=true;
        //   }else{
        //     this.diag_salesreportflag=false;
        //   }
          
        // }        
        // if(this.central_store =="1" ){
        //   this.diag_receivalblesreportflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_receivalblesreportflag=true;
        //   }else{
        //     this.diag_receivalblesreportflag=false;
        //   }
          
        // }      
        //   if(this.central_store =="1" ){
        //   this.diag_returnsreportflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_returnsreportflag=true;
        //   }else{
        //     this.diag_returnsreportflag=false;
        //   }
          
        // }      
        //   if(this.central_store =="1" ){
        //   this.diag_schdForDrugInspFlag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_schdForDrugInspFlag=true;
        //   }else{
        //     this.diag_schdForDrugInspFlag=false;
        //   }
          
        // }       
        //  if(this.central_store =="1" ){
        //   this.diag_salesForDrugInspFlag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_salesForDrugInspFlag=true;
        //   }else{
        //     this.diag_salesForDrugInspFlag=false;
        //   }
        // }
      //  if(this.central_store =="1" ){
      //   this.diag_Bedocc_ratio=false;
      //    }else{
      //     if(this.req_central_store == "1"){
      //       this.diag_Bedocc_ratio=true;
      //     }else{
      //       this.diag_Bedocc_ratio=false;
      //     }
      //    }        
        // if(this.central_store =="1" ){
        // this.diag_ABC_analysis=false;
        //  }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_ABC_analysis=true;
        //   }else{
        //     this.diag_ABC_analysis=false;
        //   }
        //  }       
        // if(this.central_store =="1" ){
        // this.diag_shortexpiryflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_shortexpiryflag=true;
        //   }else{
        //     this.diag_shortexpiryflag=false;
        //   }
        // }       
      //   if(this.central_store =="1" ){
      //   this.diag_pastinwardsflag=false;
      //  }else{
      //   if(this.req_central_store == "1"){
      //     this.diag_pastinwardsflag=true;
      //   }else{
      //     this.diag_pastinwardsflag=false;
      //   }
      //   }
        if(this.central_store =="1" ){
          this.phy_greportsflag=false;
        }else{
          if(this.req_central_store == "1"){
            this.phy_greportsflag=false;
          }else{
            this.phy_greportsflag=false;
          }
        }

        Moduleidlist = Physio_Helper.getModuleList();
        this.homecareFlag = true;
        this.eventIconFlag = true;
        this.isAdmin = true;
        this.walkinFlag = false;
        if (this.userInfo.admin == "1") {
          this.isAdmin = false;
        }
        for (var i = 0; i < Moduleidlist.length; i++) {
          if (Moduleidlist[i] == "35") {
            this.homecareFlag = false;
          } else if (Moduleidlist[i] == "21") {
            this.eventIconFlag = false;
          } else if (Moduleidlist[i] == "46") {
            this.reference_video = false;
          }
          else if (Moduleidlist[i].module_id == "147") {
            // ConductionDoctorFlag
            this.ConductionDoctorFlag = false

          }
          else if (Moduleidlist[i].module_id == "148") {
            // specimenaccessionerFlag
            this.specimenaccessionerFlag = false;



          }
          else if (Moduleidlist[i].module_id == "149") {
            this.Surgery_equipmentsFlag = false

          }
        }
      }
    }
    else if (this.loginType == "pharmacy") {
      this.Usertype = "Pharmacy";
      this.subscription = this.messageservice.getMessage().subscribe(message => {

        if (message) {
          this.menuNav(message);
        }
      });
      this.userInfo = Helper_Class.getInfo();
      this.firstName = this.userInfo.first_name;
      this.lastName = this.userInfo.last_name;
      this.profileImage = ipaddress.Ip_with_img_address + this.userInfo.profile_image;
      this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
      this.submenuFlag = false;
      this.submenuFlag1 = false;
      this.reportSubmenuFlag = false;
      this.minimizedAsied = false;
      this.RatecardFlag = false;
      this.pastinwardsflag=false
      this.shortexpiryflag = false;
      var module_list;
      if (pharmacy_helper.getModuleList() != null) {
        module_list = pharmacy_helper.getModuleList();
        this.pharmareports = true;
        this.masterflag = false;
        this.pharmatat = false;
        this.inwardFlag = true;
        this.reference_video = true;
        this.help = true;
        this.weightageFlag=false


        for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
          //new
          if (Helper_Class.getmodulelist()[i].module_id == '13') {
            //Reference video
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.reference_video = true;
            } else {
              this.reference_video = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "18") {
            // Patient registration
            this.patienregFlag = false;

          } else if (Helper_Class.getmodulelist()[i].module_id == '14') {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.help = true;
            } else {
              this.help = false;
            }
          } else if (Helper_Class.getmodulelist()[i].module_id == "26") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.DrugsNameTypeFlag = true;
            } else {
              this.DrugsNameTypeFlag = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "28") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.CulturePathologyflag = true;
            } else {
              this.CulturePathologyflag = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "29") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.DiagnosticDepartmentflag = true;
            } else {
              this.DiagnosticDepartmentflag = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "30") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.DiagnosticTube = true;
            } else {
              this.DiagnosticTube = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "31") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.DiagnosticReagentflag = true;
            } else {
              this.DiagnosticReagentflag = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "32") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.Stockexchangeflag = true;
            } else {
              this.Stockexchangeflag = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "33") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.StoreNameflag = true;
            } else {
              this.StoreNameflag = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "34") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.GenericNameflag = true;
            } else {
              this.GenericNameflag = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "35") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.ScheduleNameflag = true;
            } else {
              this.ScheduleNameflag = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "36") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.ProductFormflag = true;
            } else {
              this.ProductFormflag = false
            }

          } else if
            (Helper_Class.getmodulelist()[i].module_id == "45") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.manageOrders = true;
              this.currentorder = true;
            } else {
              this.manageOrders = false;
              this.currentorder = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "46") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.manageOrders = true;
              this.pastorder = true;
            } else {
              this.manageOrders = false;
              this.pastorder = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "47") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.manageOrders = true;
              this.interdepart = true;
            } else {
              this.manageOrders = false;
              this.interdepart = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "48") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.manageOrders = true;
              this.bill_est = true;
              this.debillestimate = true;
            } else {
              this.manageOrders = false;
              this.bill_est = false;
              this.debillestimate = false;
            }
          } else if (Helper_Class.getmodulelist()[i].module_id == "49") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.manufacturer = true;
            } else {
              this.manufacturer = false;
            }
          } else if (Helper_Class.getmodulelist()[i].module_id == "50") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.stockorlevel = true;
            } else {
              this.stockorlevel = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "51") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.suppliers = true;
            } else {
              this.suppliers = false;
            }
          
          } else if (Helper_Class.getmodulelist()[i].module_id == "52") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.discount = true;
            } else {
              this.discount = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "53") {
            //product master
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.productMaster = true;
            } else {
              this.productMaster = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "54") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.suppliersProduct = true;
            } else {
              this.suppliersProduct = false;
            }
            
          } else if (Helper_Class.getmodulelist()[i].module_id == "55") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.purchaseRequest = true;
            } else {
              this.purchaseRequest = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "56") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.purchaseOrder = true;
            } else {
              this.purchaseOrder = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "57") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.inventoryReceivables = true;
            } else {
              this.inventoryReceivables = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "58") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.stockEdit = true;
            } else {
              this.stockEdit = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "59") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.pharmareports = true;
              this.returnsreportflag = true;
            } else {
              this.pharmareports = false;
              this.returnsreportflag = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "60") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.suppaymentret = true;
            } else {
              this.suppaymentret = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "61") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.rackandbinflag = true;
            } else {
              this.rackandbinflag = false;
            }
          } else if (Helper_Class.getmodulelist()[i].module_id == '89') {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.pharmareports = true;
              this.ABC_analysis = true;
            } else {
              this.pharmareports = false;
              this.ABC_analysis = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == '90') {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.pharmareports = true;
              this.Bedocc_ratio = true;
            } else {
              this.pharmareports = false;
              this.Bedocc_ratio = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == '122') {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.pharmareports = true;
              this.stockstatusflag = true;
            } else {
              this.pharmareports = false;
              this.stockstatusflag = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == '123') {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.pharmareports = true;
              this.salesreportflag = true;
            } else {
              this.pharmareports = false;
              this.salesreportflag = false;
            }

          }  else if (Helper_Class.getmodulelist()[i].module_id == "124") {
            // receivable report
            this.receivalblesreportflag = false;
            this.mainreport = false;

          } else if (Helper_Class.getmodulelist()[i].module_id == '126') {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.stockadjustflag = true;
            } else {
              this.stockadjustflag = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == '127') {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.inwardFlag = true
            } else {
              this.inwardFlag = false
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "133") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.addmasterflag = true;
            } else {
              this.addmasterflag = false
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "134") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.CultureSensitivityDrugNameFlag = true;

            } else if (Moduleidlist[i].module_id == "147") {
              this.ConductionDoctorFlag = false
            } else if (Moduleidlist[i].module_id == "148") {
              this.specimenaccessionerFlag = false;
            } else if (Moduleidlist[i].module_id == "149") {
              this.Surgery_equipmentsFlag = false

            }  else {
              this.CultureSensitivityDrugNameFlag = false
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "132") {
            this.patientListFlag = false

          } else if (Helper_Class.getmodulelist()[i].module_id == "140") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.addtemplateflag = true;
            } else {
              this.addtemplateflag = false;
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "141") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.gramstainsflag = true;
            } else {
              this.gramstainsflag = false;
            }
          } else if (Helper_Class.getmodulelist()[i].module_id == "142") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.RatecardFlag = true;
            } else {
              this.RatecardFlag = false
            }

          } else if (Helper_Class.getmodulelist()[i].module_id == "146") {
            if (Helper_Class.getmodulelist()[i].edit == "0" && Helper_Class.getmodulelist()[i].create == "0" &&
              Helper_Class.getmodulelist()[i].delete == "0" && Helper_Class.getmodulelist()[i].print == "0" &&
              Helper_Class.getmodulelist()[i].view == "0"
            ) {
              this.visitpurposeFlag = true;
            } else {
              this.visitpurposeFlag = false;
            }
          } else if (Helper_Class.getmodulelist()[i].module_id == "124") {
            if (Moduleidlist[i].create == '1' || Moduleidlist[i].delete == '1' ||
              Moduleidlist[i].print == '1' || Moduleidlist[i].view == '1' || Moduleidlist[i].edit == '1') {
              this.pharmastockReceivable = false;
            }
          } else if (Helper_Class.getmodulelist()[i].module_id == "63") {
            this.salesForDrugInspFlag = false;
           
          } else if (Helper_Class.getmodulelist()[i].module_id == "64") {
            this.schdForDrugInspFlag = false;
          }
        }
      }
    }
    else if (this.loginType == "dietician") {
      this.Usertype = "Dietician";
      var Moduleidlist = Helper_Class.getmodulelist();
      this.sendType = "OT admin";
      console.log("Moduleidlist === ", Moduleidlist)
      if (Helper_Class.getmodulelist() != null) {
        Moduleidlist = Helper_Class.getmodulelist();
        if(this.central_store =="1"){
          this.diet_suppliers=false;
        }else{
          if(this.req_central_store == "1"){
            this.diet_suppliers=true;
          }else{
            this.diet_suppliers=false;
          }
          
        }

        if(this.central_store =="1"){
          this.diet_suppliersProduct=false;
        }else{
          if(this.req_central_store == "1"){
            this.diet_suppliersProduct=true;
          }else{
            this.diet_suppliersProduct=false;
          }
        }

        if(this.central_store =="1"){
          this.diet_productMaster=false;
        }else{
          if(this.req_central_store == "1"){
            this.diet_productMaster=true;
          }else{
            this.diet_productMaster=false;
          }
        }

        //purchase request
        if(this.central_store =="1"){
          if(this.req_central_store == "1"){
            this.diet_purchaseRequest=false;
          }else{
            this.diet_purchaseRequest=true;
          }
        }else if(this.central_store == "0"){
          if(this.req_central_store == "1"){
            this.diet_purchaseRequest=false;
          }else{
            this.diet_purchaseRequest=true;
          }
          
        }
        else{
          this.diet_purchaseRequest=true;
        }

        if(this.central_store =="1" ){
          this.diet_purchaseOrder=false;
        }else{
          if(this.req_central_store == "1"){
            this.diet_purchaseOrder=true;
          }else{
            this.diet_purchaseOrder=false;
          }
          
        } 

        if(this.central_store =="1"  ){
          this.diet_inventoryReceivables=false;
        }else{
          if(this.req_central_store == "1"){
            this.diet_inventoryReceivables=true;
          }else{
            this.diet_inventoryReceivables=false;
          }
          
        }

        // if(this.central_store =="1" ){
        //   this.nurs_stockEdit=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.nurs_stockEdit=true;
        //   }else{
        //     this.nurs_stockEdit=false;
        //   }
          
        // }

        // if(this.central_store =="1"  ){
        //   this.nurs_stockreturnsflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.nurs_stockreturnsflag=true;
        //   }else{
        //     this.nurs_stockreturnsflag=false
        //   }
        // }

        // if(this.central_store =="1"  ){
        //   this.nurs_suppaymentret=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.nurs_suppaymentret=true;
        //   }else{
        //     this.nurs_suppaymentret=false;
        //   }
        // }

        // if(this.central_store =="1"){
        //   this.nurs_stockadjustflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.nurs_stockadjustflag=true;
        //   }else{
        //     this.nurs_stockadjustflag=false;
        //   }
        // }

        // if(this.central_store =="1" ){
        //   this.nurs_rackandbinflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.nurs_rackandbinflag=true;
        //   }else{
        //     this.nurs_rackandbinflag=false;
        //   }
        // }
      //  reports
        if(this.central_store =="1" ){
          this.diet_stockstatusflag=false;
        }else{
          if(this.req_central_store == "1"){
            this.diet_stockstatusflag=false;
          }else{
            this.diet_stockstatusflag=false;
          }
          
        }
        // if(this.central_store =="1" ){
        //   this.diag_salesreportflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_salesreportflag=true;
        //   }else{
        //     this.diag_salesreportflag=false;
        //   }
          
        // }        
        // if(this.central_store =="1" ){
        //   this.diag_receivalblesreportflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_receivalblesreportflag=true;
        //   }else{
        //     this.diag_receivalblesreportflag=false;
        //   }
          
        // }      
        //   if(this.central_store =="1" ){
        //   this.diag_returnsreportflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_returnsreportflag=true;
        //   }else{
        //     this.diag_returnsreportflag=false;
        //   }
          
        // }      
        //   if(this.central_store =="1" ){
        //   this.diag_schdForDrugInspFlag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_schdForDrugInspFlag=true;
        //   }else{
        //     this.diag_schdForDrugInspFlag=false;
        //   }
          
        // }       
        //  if(this.central_store =="1" ){
        //   this.diag_salesForDrugInspFlag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_salesForDrugInspFlag=true;
        //   }else{
        //     this.diag_salesForDrugInspFlag=false;
        //   }
        // }
      //  if(this.central_store =="1" ){
      //   this.diag_Bedocc_ratio=false;
      //    }else{
      //     if(this.req_central_store == "1"){
      //       this.diag_Bedocc_ratio=true;
      //     }else{
      //       this.diag_Bedocc_ratio=false;
      //     }
      //    }        
        // if(this.central_store =="1" ){
        // this.diag_ABC_analysis=false;
        //  }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_ABC_analysis=true;
        //   }else{
        //     this.diag_ABC_analysis=false;
        //   }
        //  }       
        // if(this.central_store =="1" ){
        // this.diag_shortexpiryflag=false;
        // }else{
        //   if(this.req_central_store == "1"){
        //     this.diag_shortexpiryflag=true;
        //   }else{
        //     this.diag_shortexpiryflag=false;
        //   }
        // }       
      //   if(this.central_store =="1" ){
      //   this.diag_pastinwardsflag=false;
      //  }else{
      //   if(this.req_central_store == "1"){
      //     this.diag_pastinwardsflag=true;
      //   }else{
      //     this.diag_pastinwardsflag=false;
      //   }
      //   }
        if(this.central_store =="1" ){
          this.diet_greportsflag=false;
        }else{
          if(this.req_central_store == "1"){
            this.diet_greportsflag=false;
          }else{
            this.diet_greportsflag=false;
          }
        }
        // this.billingChargesFlag = true;
        this.billCreateFlag = true;
        this.billEditFlag = true;
        // this.inpatReportFlag = true;
        this.appointmentData = false;
        this.pathreportFlag = false;
        this.testdiag = false;
        this.test = false;
        this.radioreportFlag = false;
        this.microreportFlag = false;
        this.sourceFlag = false;
        this.MainPatients = false;
        this.diabFlag = false;
        this.userHidden = false;
        this.BilllistFlag = false

        this.fraAddRole = false;
        this.fraSubDeparment = false;
        this.fraAddtemplate = false;
        this.fraGramstains = false;

        this.fraVisitpurpose = false;
        var bill: any = {};

        for (var i = 0; i < Moduleidlist.length; i++) {
          if (Moduleidlist[i].module_id == "15") {
            this.manageAppointmentFlag = false;
            // Doctor appointments
          } else if (Moduleidlist[i].module_id == "1") {
            this.inpatientMenuFlag = false;
            // patient List

          }
          else if (Moduleidlist[i].module_id == "2") {
            // Inpatient List
            // this.inpatientMenuFlag = false;
            this.dediet_dashboard = false;

          } else if (Moduleidlist[i].module_id == "7") {
            // bill
            this.billingnor = false;

          } else if (Moduleidlist[i].module_id == "6") {
            // Medical prescription
            this.viewPrescritionFlag = false;

          } else if (Moduleidlist[i].module_id == "13") {
            // Medical prescription
            this.reference_video = false;

          } else if (Moduleidlist[i].module_id == "16") {
            // Diagnosis appointment
          } else if (Moduleidlist[i].module_id == "17") {
            // Dietician appointment

          } else if (Moduleidlist[i].module_id == "18") {
            // Patient registration
            this.patienregFlag = false;
          } else if (Moduleidlist[i].module_id == "19") {
            // Advance
            this.advanceFlag = false;
          } else if (Moduleidlist[i].module_id == "20") {
            // Charges
            this.billingChargesFlag = false;

          } else if (Moduleidlist[i].module_id == "21") {
            // consolidated
            this.consolidatedBillingFlag = false;
          } else if (Moduleidlist[i].module_id == "22") {
            // partial pay
            this.partialPaymentFlag = false;
          } else if (Moduleidlist[i].module_id == "23") {
            // partial pay
            this.insuranceBillFlag = false;
          } else if (Moduleidlist[i].module_id == "24") {
            // OT booking

            this.Otbookingfra = false;
          } else if (Moduleidlist[i].module_id == "25") {
            // OT booking
            this.OtProcedurefra = false;

          } else if (Moduleidlist[i].module_id == "26") {
            this.fraMicrobiologydruggroup = false;
            // fraMicrobiologydruggroup
          } else if (Moduleidlist[i].module_id == "27") {
            // Lead management
            this.leadMgmtFlag = false

          } else if (Moduleidlist[i].module_id == "28") {
            // fraculture pathology
            this.fraculturepathology = false;

          } else if (Moduleidlist[i].module_id == "29") {
            // fraculture pathology
            this.fraspecimentype = false;
          } else if (Moduleidlist[i].module_id == "30") {
            // Diagnosis tube types
            this.FraTestTubeType = false;
          } else if (Moduleidlist[i].module_id == "31") {
            this.fraTestReagents = false;

          } else if (Moduleidlist[i].module_id == "32") {
            this.fraStockAdjustment = false;

          } else if (Moduleidlist[i].module_id == "33") {
            this.fraStoreName = false;
          } else if (Moduleidlist[i].module_id == "34") {
            this.fraGenericName = false;
          } else if (Moduleidlist[i].module_id == "35") {
            this.fraScheduleName = false;
          } else if (Moduleidlist[i].module_id == "36") {
            this.fraProductForm = false;
          } else if (Moduleidlist[i].module_id == "37") {
            this.wardMasterFlag = false;
          } else if (Moduleidlist[i].module_id == "38") {
            this.Otmasterfra = false;
          } else if (Moduleidlist[i].module_id == "39") {
            this.doctor_referral = false;
          } else if (Moduleidlist[i].module_id == "40") {
            this.hospBillsReportFlag = false;

          } else if (Moduleidlist[i].module_id == "41") {
            this.diagBillsReportFlag = false;
          } else if (Moduleidlist[i].module_id == "42") {
            this.notvistedpatreportflag = false;
          } else if (Moduleidlist[i].module_id == "43") {
            this.consolidatedreportflag = false;
          } else if (Moduleidlist[i].module_id == "44") {
            // this.consultantFlag = false;
            this.wardMasterviewFlag = false;
          } else if (Moduleidlist[i].module_id == "52") {
            this.viewPrescritionFlag = false;
          } else if (Moduleidlist[i].module_id == "53") {

          } else if (Moduleidlist[i].module_id == "56") {
            this.advanceFlag = false;

          } else if (Moduleidlist[i].module_id == "57") {

          }
          // else if (Moduleidlist[i].module_id == "58") {
          //   this.patientListFlag = false;

          // } 
          else if (Moduleidlist[i].module_id == "59") {

          } else if (Moduleidlist[i].module_id == "60") {

          } else if (Moduleidlist[i].module_id == "77") {


          } else if (Moduleidlist[i].module_id == "65") {
            this.hospBillsReportFlag = false;

          } else if (Moduleidlist[i].module_id == "66") {
            this.diagBillsReportFlag = false;

          } else if (Moduleidlist[i].module_id == "68") {
            this.inpatReportFlag = false;

          } else if (Moduleidlist[i].module_id == "28") { // Bill View
            Helper_Class.setBillView(null);
            Helper_Class.setBillView("1");

          } else if (Moduleidlist[i].module_id == "54") { // Bill Create
            this.billCreateFlag = false;
            bill.create = Moduleidlist[i];
            FrontDesk_Helper.setBillFlag(bill);
            Helper_Class.setBillCreate(null);
            Helper_Class.setBillCreate("1");

          } else if (Moduleidlist[i].module_id == "55") { // Bill Edit
            this.billEditFlag = false;
            bill.edit = Moduleidlist[i];
            FrontDesk_Helper.setBillFlag(bill);
            Helper_Class.setBillEdit(null);
            Helper_Class.setBillEdit("1");

          } else if (Moduleidlist[i].module_id == "85") { // Bill Print
            Helper_Class.setBillPrint(null);
            Helper_Class.setBillPrint("1");

          } else if (Moduleidlist[i].module_id == "101") { // Bill Print
            Helper_Class.setBillDelete(null);
            Helper_Class.setBillDelete("1");


          }
          else if (Moduleidlist[i].module_id == "142") { // Bill Print
            this.fraratecard = false;


          } else if (Moduleidlist[i].module_id == "46") {
            this.reference_video = false;

          }
          else if (Moduleidlist[i].module_id == "89") {
            this.abcAnalysisFlag = false;

          }
          else if (Moduleidlist[i].module_id == "90") {

            this.bedOccupancyFlag = false;

          }
          else if (Moduleidlist[i].module_id == "91") {

            this.billDueFlag = false;

          }
          else if (Moduleidlist[i].module_id == "92") {
            this.refund = false;
            this.Bill_refundflag = false;

          }
          else if (Moduleidlist[i].module_id == "133") {
            this.UserRoleFlag = false;

          }
          else if (Moduleidlist[i].module_id == "134") {
            this.SensitiveDrug = false;

          }
          else if (Moduleidlist[i].module_id == "109") {
            this.salesreportdoctorReportFlag = false;

          } else if (Moduleidlist[i].module_id == "110") {
            // this.Bill_refundflag= false;

          } else if (Moduleidlist[i].module_id == "111") {
            // this.billDueFlag = false;

          } else if (Moduleidlist[i].module_id == "112") {
            this.bedOccupancyFlag = false;

          } else if (Moduleidlist[i].module_id == "113") {
            this.abcAnalysisFlag = false;

          } else if (Moduleidlist[i].module_id == "114") {
            this.labtestFlag = false;

          } else if (Moduleidlist[i].module_id == "115") {
            this.productReceivablesFlag = false;
          }
          else if (Moduleidlist[i].module_id == "132") {
            this.patientListFlag = false;
          }
          else if (Moduleidlist[i].module_id == "138") {
            this.consultantFlag = false;
          }
          else if (Moduleidlist[i].module_id == "120") {
            // permissions
            this.permissionsFlag = false;
            this.permissionSubmenuFlag = false;
          }
          else if (Moduleidlist[i].module_id == "147") {
            // ConductionDoctorFlag
            this.ConductionDoctorFlag = false
            this.FraMasters = false;

          }
          else if (Moduleidlist[i].module_id == "148") {
            // specimenaccessionerFlag
            this.specimenaccessionerFlag = false;
            this.FraMasters = false;



          }
          else if (Moduleidlist[i].module_id == "149") {
            this.Surgery_equipmentsFlag = false
            this.FraMasters = false;

          }
          else if (Moduleidlist[i].module_id == "26") {
            this.productformflag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "134") {
            this.cult_sense_drugName_flag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "133") {
            this.fraAddRole = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "28") {
            this.cult_path_flag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "29") {
            this.specimen_type_flag = false;
            this.FraMasters = false;
          } 
          //  else if (Moduleidlist[i].module_id                                                          == "") {
          //   this.diagnosticDFlag                                                                       = false;
          // }
          else if (Moduleidlist[i].module_id == "30") {
            this.diag_tube_type_flag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "31") {
            this.diag_reagent_flag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "32") {
            this.stockarFlag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "33") {
            this.StoreNameFlag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "34") {
            this.genericnameflag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "35") {
            this.SMFlag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "") {
            this.subDepartmentFlag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "140") {
            this.addTemplateFlag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "141") {
            this.gramStainFlag = false;
            this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "142") {
            this.rateCardFlag = false; this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "146") {
            this.visitPurposeFlag = false; this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "134") {
            this.fraculturepathology = false; this.FraMasters = false;
          } else if (Moduleidlist[i].module_id == "133") {
            this.UserRoleFlag = false; this.FraMasters = false;
          }
        }

        if (this.hospBillsReportFlag == true && this.diagBillsReportFlag == true && this.inpatReportFlag == true) {
          this.reportsFlag = true;
        }
      }

    }
  }

  appointments(data) {
    if (data == "home")
      Physio_Helper.setHomecare("1");
    else
      Physio_Helper.setHomecare("0");
  }

  setAppoinmentHelper(e) {
    if (e == 'appinment') {
      Doc_Helper.setAppDate(this.currentDate);
    } else if (e == "billing" || e == "ipbilling") {
      Doc_Helper.setBillFromDate(this.currentDate);
      Doc_Helper.setBillToDate(this.currentDate);
      if (e == "billing") {
        Doc_Helper.setIpCreateBill("opbil");
      } else {
        Doc_Helper.setIpCreateBill("ipbill");
      }
    } else if (e == "homecare") {
      Doc_Helper.setHomecareDate(this.currentDate);
    } else if (e == "doctor_availability") {
      Doc_Helper.setDocAvailabitiyDate(this.currentDate);
    }
  }

  getCurrentDate() {
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj.current_date != null) {
      this.currentDate = obj.current_date;
    }
  }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  getNotificationList() {
    console.log("check no list" + this.sendType + this.userInfo.user_id)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/nc',
      {
        type: this.sendType,
        user_id: this.userInfo.user_id,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json()

          console.log("check obj list" + JSON.stringify(obj))
          if (obj.notifications != null) {
            if (obj.notifications.length != 0) {
              this.notificationCount = obj.notifications;
              this.notificationFlag = true;
            }
            else {
              this.notificationCount = "0";
            }
          }
        },
        error => { });
  }

  open_setting() {
    this.settings = !this.settings;
  }

  logout(name) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
      JSON.stringify({
        type: this.sendType,
        user: this.userInfo.user_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.toastr.success(Message_data.logOut + name)
          localStorage.clear();
          this.router.navigateByUrl('/loginpage');
        },
        error => { });
  }

  menuNav(page) {
  
    if (this.loginType == "doctor" || this.loginType == "consultant") {
      this.sendType = "doctor";
      let page_nmae: string = page;
      this.selectedMenu = page;
      this.docInpatSubmenuFlag = false;
      this.DrugsNameFlag = false;
      this.docScheduleSubmenuFlag = false;
      this.docReportsFlag = false;
      Doc_Helper.setImageFlag(false);
      switch (page_nmae) {
        case 'home':
          this.displayPage = "dashboard";
          break;
        case 'patient':
          this.docInpatSubmenuFlag = !this.docInpatSubmenuFlag;
          if (this.minimized_asied == true) {
            this.minimizing();
          }
          break;
        case 'in_patient': this.docInpatSubmenuFlag = false; this.displayPage = page; break;
        case 'patient_bill': this.docInpatSubmenuFlag = false; break;
        case 'patient_dashboard': this.docInpatSubmenuFlag = false; this.displayPage = page; break;
        case 'appointment_list': this.displayPage = page; break;
        case 'walkin': this.displayPage = page; break;
        case 'permission': this.displayPage = page; break;
        case 'billing': Doc_Helper.setIpCreateBill("opbill"); this.displayPage = page; break;
        case 'secondopinion': this.displayPage = page; break;
        case 'homecare': this.displayPage = page; break;
        case 'in_patient': this.displayPage = page; break;
        case 'dashboars': this.displayPage = page; break;
        case 'docassessment': this.displayPage = page; break;
        case 'schedule':
          this.docScheduleSubmenuFlag = !this.docScheduleSubmenuFlag;
          if (this.minimized_asied == true) {
            this.minimizing();
          }
          break;

        case 'calender': this.displayPage = page; break;
        case 'doctor_availability': this.displayPage = page; break;
        case 'manage_schedule': this.displayPage = page; break;
        case 'notification': this.displayPage = page; break;
        case 'inPatientViewInfo': this.displayPage = page; break;
        case 'cashSheetPersonal': this.displayPage = page; break;
        case 'billcreate': this.displayPage = page; break;
        case 'billdetail': this.displayPage = page; break;
        case 'billedit': this.displayPage = page; break;
        case 'inPatientDS': this.displayPage = page; break;
        case 'inpatient_Discharge_view': this.displayPage = page; break;
        case 'inpatient_guno_Discharge_view': this.displayPage = page; break;
        case 'billlist': this.displayPage = page; break;
        case 'help': this.displayPage = page; break;
        case 'casesheet': this.displayPage = page; break;
        case 'secondopinion_casesheet': this.displayPage = page; break;
        case 'profile_update': this.displayPage = page; break;
        case 'medprescription': this.displayPage = page; break;
        case 'ModalPage': this.displayPage = page; break;
        case 'casesheet_list': this.displayPage = page; break;
        case 'cardio_casesheet_view': this.displayPage = page; break;
        case 'appointment_view': this.displayPage = page; break;
        case 'reference_videodoc': this.displayPage = page; break;
        case 'viewIpatient': this.displayPage = page; break;
        case 'reportsMenu':
          this.docReportsFlag = !this.docReportsFlag;
          if (this.minimized_asied == true) {
            this.minimizing();
          }
          break;
        case 'salseReport': this.displayPage = page; break;
        case 'receivablesReport': this.displayPage = page; break;
        case 'stockReport': this.displayPage = page; break;
        case 'inpatientbilling': this.displayPage = page; break;
        case 'ipbilling':
          Doc_Helper.setIpCreateBill("ipbill");
          this.displayPage = page; break;
        case 'ipbillcreate': this.displayPage = page; break;
        case 'ipcard': this.displayPage = page; break;
        case 'consolidatedBills': this.displayPage = page; break;
        case 'inpat_reports': this.displayPage = page; break;
        case 'inPat_report': this.displayPage = page; break;
        case 'Day_book': this.displayPage = page; break;
        case 'docappcreate': this.displayPage = page; break;
        case 'Sales_report_pharma': this.displayPage = page; break;
        case 'diag_bill_reports': this.displayPage = page; break;
        case 'hosp_bill_reports': this.displayPage = page; break;
        case 'docpresnocase': this.displayPage = page; break;
        case 'prescriptionlist': this.displayPage = page; break;
        case 'receptviewprescription': this.displayPage = page; break;
        case 'editpres': this.displayPage = page; break;
        case 'viewpres': this.displayPage = page; break;
        // master
        case 'DrugsName': this.DrugsNameFlag = !this.DrugsNameFlag;
          if (this.minimized_asied == true) {
            this.minimizing();
          }
          break;
        case 'DrugsNameType': this.displayPage = page; break;
        case 'CultureSensitivityDrug': this.displayPage = page; break;
        case 'addmaster': this.displayPage = page; break;
        case 'CulturePathology': this.displayPage = page; break;
        case 'Sampletype': this.displayPage = page; break;
        case 'DiagnosticDepartment': this.displayPage = page; break;
        case 'DiagnosticTube': this.displayPage = page; break;
        case 'DiagnosticReagent': this.displayPage = page; break;
        case 'Accounthead': this.displayPage = page; break;
        case 'AccountSubhead': this.displayPage = page; break;
        case 'Stockexchange': this.displayPage = page; break;
        case 'StoreName': this.displayPage = page; break;
        case 'GenericName': this.displayPage = page; break;
        case 'ScheduleName': this.displayPage = page; break;
        case 'ProductForm': this.displayPage = page; break;
        case 'Sub-Deparmentc': this.displayPage = page; break;
        case 'addtemplate': this.displayPage = page; break;
        case 'rateCard': this.displayPage = page; break;
        case 'visitpurpose': this.displayPage = page; break;
        case 'UserRole': this.displayPage = page; break;
        case 'CultureSensitivityDrugName': this.displayPage = page; break;
        case 'reference_video': this.displayPage = page; break;
        case 'specimenaccessioner': this.displayPage = page; break;
        case 'ConductionDoctor': this.displayPage = page; break;
        case 'Surgery_equipments': this.displayPage = page; break;
        case 'medicineTracker': this.displayPage = page; break;
        case 'patient_list': this.displayPage = page; break;
        case 'manageclient': this.displayPage = page; break;
        case 'discharge_timeline': this.displayPage = page; break;
        case 'concernform': this.displayPage = page; break;
        case 'other_expenses': this.displayPage = page; break;
        //medicineTracker
      }
      customjs.SelectMenuFun(this.selectedMenu, "doctor");

    }
    else if (this.loginType == "OT admin") {
      var page
      let page_nmae: string = page;
      this.selectedMenu = page;
      this.reportsSubmenuFlag = false;
      this.billingFlag = false;
      this.inpatientFlag = false;
      this.permissionSubmenuFlag = false;
      this.DrugsNameFlag = false;
      switch (page_nmae) {
        case 'home':
          this.displayPage = "home";
          break;
        case 'OTmaster': this.displayPage = page; break;
        case 'OTBooking': this.displayPage = page; break;
        case 'OTProcedure': this.displayPage = page; break;
        case 'other_expenses': this.displayPage = page; break;
        case 'consingmentlist': this.displayPage = page; break;
      }
      customjs.SelectMenuFun(this.selectedMenu, "front");
    }
    else if (this.loginType == "Accounts") {
      var page
      let page_nmae: string = page;
      this.selectedMenu = page;
      this.reportsSubmenuFlag = false;
      this.billingFlag = false;
      this.inpatientFlag = false;
      this.permissionSubmenuFlag = false;
      this.DrugsNameFlag = false;
      switch (page_nmae) {
        case 'other_expenses': this.displayPage = page; break;
        case 'Day_book': this.displayPage = page; break;
        case 'Day_book': this.displayPage = page; break;
        case 'stockmanagment': this.submenuFlag1 = !this.submenuFlag1;
        if(this.minimizedAsied == true){
          this.minimizing();
        } 
        break;
        case 'inventory_receivables': this.displayPage = page; break;
        case 'supppay': this.displayPage = page; break;
       case 'pharmacyresponse': this.displayPage = page; break;
       case 'orderview': this.displayPage = page; break;
        case 'sales_report': this.displayPage = page; break;
        case 'receivables_report': this.displayPage = page; break;
        case 'reports': this.submenuFlagb = !this.submenuFlagb;
        if(this.minimizedAsied == true){
          this.minimizing();
        } 
        break;
        case 'inPat_report': this.displayPage = page; break;
       case 'sales_report': this.displayPage = page; break;
       case 'receivables_report': this.displayPage = page; break;
        case 'inPat_report': this.displayPage = page; break;
        case 'receivables_report': this.displayPage = page; break;
       case 'stock_status': this.displayPage = page; break;
       case 'salesGst': this.displayPage = page; break;

      }
      customjs.SelectMenuFun(this.selectedMenu, "Accounts");
    }
    else if (this.loginType == "front-desk") {
      let page_nmae: string = page;
      this.selectedMenu = page;
      this.reportsSubmenuFlag = false;
      this.billingFlag = false;
      this.inpatientFlag = false;
      this.permissionSubmenuFlag = false;
      this.DrugsNameFlag = false;
      switch (page_nmae) {
        case 'home':
          this.displayPage = "home";
          break;

        // notification
        case 'manageappointments': this.displayPage = page; break;
        case 'notification': this.displayPage = page; break;
        case 'appcreate': this.displayPage = page; break;
        case 'receptionistdoctordetailview': this.displayPage = page; break;
        case 'receptviewprescription': this.displayPage = page; break;
        case 'prescriptionlist': this.displayPage = page; break;
        case 'frontdeskbilling':
          var send = {
            flow: "front-desk",
            id: this.userInfo.user_id
          }
          Helper_Class.setadminBillingFlow(send);
          this.displayPage = page; break;
        case 'receptbillcreate': this.displayPage = page; break;
        case 'notification': this.displayPage = page; break;
        case 'receptbilledit': this.displayPage = page; break;
        case 'receptbilldetail': this.displayPage = page; break;
        case 'registration': this.displayPage = page; break;
        case 'patient_list': this.displayPage = page; break;
        case 'nurse_ward': this.permissionSubmenuFlag = false; this.displayPage = page; break;
        case 'nurse_patient': this.permissionSubmenuFlag = false; this.displayPage = page; break;
        case 'nurse_doctor': this.permissionSubmenuFlag = false; this.displayPage = page; break;
        case 'permission':
          this.permissionSubmenuFlag = !this.permissionSubmenuFlag;
          if (this.minimizedAsied == true) {
            this.minimizing();
          }
          break;
        case 'billing':
          this.billingFlag = !this.billingFlag; if (this.minimizedAsied == true) {
            this.minimizing();
          };
          break;
        case 'advances': this.displayPage = page; break;
        case 'bills': this.displayPage = page; break;
        case 'billingDiagDetailsView': this.displayPage = page; break;
        case 'billingDetailsView': this.displayPage = page; break;
        case 'charges': this.displayPage = page; break;
        case 'refund': this.displayPage = page; break;
        case 'createRefund': this.displayPage = page; break;
        case 'inpatient':
          this.inpatientFlag = !this.inpatientFlag; if (this.minimizedAsied == true) {
            this.minimizing();
          };
          break;
        case 'dischargeList': this.displayPage = page; break;
        case 'Patients': this.displayPage = page; break;
        //Newly Added
        case 'wardTransfer': this.displayPage = page; break;
        case 'inPatientCreate': this.displayPage = page; break;
        case 'consultant': this.displayPage = page; break;
        case 'diaBillCreate': this.displayPage = page; break;
        case 'diaAppDetail': this.displayPage = page; break;
        case 'diaAppCreate': this.displayPage = page; break;
        case 'wardmaster': this.displayPage = page; break;
        case 'warddetails': this.displayPage = page; break;
        case 'OTmaster': this.displayPage = page; break;
        case 'OTBooking': this.displayPage = page; break;
        case 'OTProcedure': this.displayPage = page; break;
        case 'viewIpatient': this.displayPage = page; break;
        case 'diaBillingDetailsView': this.displayPage = page; break;
        case 'ipatientBillCreate': this.displayPage = page; break;
        case 'frontConsolidatedBills': this.displayPage = page; break;
        case 'dietappointments': Diet_Helper.setAppFlow(undefined); this.displayPage = page; break;
        case 'dietappointmentsdetailed': this.displayPage = page; break;
        case 'dietappointmentscreate': this.displayPage = page; break;
        case 'reports': this.reportsSubmenuFlag = !this.reportsSubmenuFlag;
          if (this.minimizedAsied == true) {
            this.minimizing();
          };
          break;
        case 'stock_status': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'hosp_bill_reports': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'diag_bill_reports': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'reference_video': this.displayPage = page; break;
        case 'listPackage': this.displayPage = page; break;
        case 'planpackage': this.displayPage = page; break;
        case 'createPackage': this.displayPage = page; break;
        case 'inpdischargeview': this.displayPage = page; break;
        case 'inPatientDS': this.displayPage = page; break;
        case 'docref': this.displayPage = page; break;
        case 'docrefcreate': this.displayPage = page; break;
        case 'medprescription': this.displayPage = page; break;
        case 'leadmgmt': this.displayPage = page; break;
        case 'partialPayments': this.displayPage = page; break;
        case 'insuranceBill': this.displayPage = page; break;
        case 'manageclient': this.displayPage = page; break;
        case 'sales_report_doctor': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'bill_refund': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'bill_due': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'bed_occupancy': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'abc_analysis': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'purchase_order': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'product': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'product_receivables': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'lab_test_wise': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'notvisited_report': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'inpat_reports': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'inPat_report': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'Day_book': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'consolidat_report': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'appedit': this.displayPage = page; break;
        case 'concent_form': this.displayPage = page; break;
        // master
        case 'DrugsName': this.DrugsNameFlag = !this.DrugsNameFlag;
          if (this.minimized_asied == true) {
            this.minimizing();
          }
          break;
        case 'DrugsNameType': this.displayPage = page; break;
        case 'CultureSensitivityDrug': this.displayPage = page; break;
        case 'addmaster': this.displayPage = page; break;
        case 'CulturePathology': this.displayPage = page; break;
        case 'Sampletype': this.displayPage = page; break;
        case 'DiagnosticDepartment': this.displayPage = page; break;
        case 'DiagnosticTube': this.displayPage = page; break;
        case 'DiagnosticReagent': this.displayPage = page; break;
        case 'Accounthead': this.displayPage = page; break;
        case 'AccountSubhead': this.displayPage = page; break;
        case 'Stockexchange': this.displayPage = page; break;
        case 'StoreName': this.displayPage = page; break;
        case 'GenericName': this.displayPage = page; break;
        case 'ScheduleName': this.displayPage = page; break;
        case 'ProductForm': this.displayPage = page; break;
        case 'RateCard': this.displayPage = page; break;
        case 'patreg': this.displayPage = page; break;
        case 'addtemplate': this.displayPage = page; break;
        case 'gramstains': this.displayPage = page; break;
        case 'visitpurpose': this.displayPage = page; break;
        case 'UserRole': this.displayPage = page; break;
        case 'specimenaccessioner': this.displayPage = page; break;
        case 'ConductionDoctor': this.displayPage = page; break;
        case 'Surgery_equipments': this.displayPage = page; break;
        case 'medicineTracker': this.displayPage = page; break;
        case 'dietTracker': this.displayPage = page; break;
        case 'medicineTracker': this.displayPage = page; break;
        case 'discharge_timeline': this.displayPage = page; break;
        case 'other_expenses': this.displayPage = page; break;
        // stock management
        case'stockmanagment': this.submenuFlag1 = !this.submenuFlag1;
        if(this.minimizedAsied == true){
          this.minimizing();
        } 
        break;
        case 'diagmanufacturer': this.displayPage = page; break;
        case'srl':this.displayPage = page; break;
        case'pharmacysupplier':this.displayPage = page; break;
        case'supplierProduct':this.displayPage = page; break;
        case'frontproduct':this.displayPage = page; break;
        case'purchasereqlist':this.displayPage = page; break;
        case'pharmacyresponse':this.displayPage = page; break;
        case'inventory_receivables':this.displayPage = page; break;
        case'stockStatusAdmin':this.displayPage = page; break;
        case'productReturns':this.displayPage = page; break;
        case'supppay':this.displayPage = page; break;
        case'stockadjustment':this.displayPage = page; break;
        case'rackbin':this.displayPage = page; break;
        case'pharmacyrequest':this.displayPage = page; break;
        case 'orderview':this.displayPage = page; break;
        case'inventory_upload':this.displayPage = page; break;
      
      }
      customjs.SelectMenuFun(this.selectedMenu, "front");
      Helper_Class.setdisplaypagename(page_nmae)

    } else if (this.loginType == "diagnosis") {
      let page_nmae: string = page;
      this.selectedMenu = page;
      this.testSubMenuFlag = false;
      this.pathSubMenuFlag = false;
      this.radioSubMenuFlag = false;
      this.microSubMenuFlag = false;
      this.outsubFlag = false;
      this.DrugsNameFlag = false;
      this.dashSubMenuFlag = false;
      this.sendType = "diagnosis";
      this.billingFlag = false;
      switch (page_nmae) {
        case 'diagnosis_home': this.displayPage = page; break;
        case 'appointment': this.displayPage = page; break;
        case 'tests': this.displayPage = page; break;
        case 'path_report_upload':
          this.pathSubMenuFlag = !this.pathSubMenuFlag;
          if (this.minimizedAsiedFlag == true) {
            this.minimizing();
          }
          break;
        case 'path_samplecollectADV': this.pathSubMenuFlag = false; this.displayPage = page; break;
        case 'pathpending': this.pathSubMenuFlag = false; this.displayPage = page; break;
        case 'pathrejected': this.pathSubMenuFlag = false; this.displayPage = page; break;
        case 'pathcompleted': this.pathSubMenuFlag = false; this.displayPage = page; break;

        case 'radio_report_upload':
          this.radioSubMenuFlag = !this.radioSubMenuFlag;
          if (this.minimizedAsiedFlag == true) {
            this.minimizing();
          }
          break;
        case 'radio_samplecollectADV': this.pathSubMenuFlag = false; this.displayPage = page; break;
        case 'radiopending': this.radioSubMenuFlag = false; this.displayPage = page; break;
        case 'radiorejected': this.radioSubMenuFlag = false; this.displayPage = page; break;
        case 'radiocompleted': this.radioSubMenuFlag = false; this.displayPage = page; break;

        case 'micro_report_upload':
          this.microSubMenuFlag = !this.microSubMenuFlag;
          if (this.minimizedAsiedFlag == true) {
            this.minimizing();
          }
          break;
        case 'micro_samplecollectADV': this.pathSubMenuFlag = false; this.displayPage = page; break;
        case 'micropending': this.microSubMenuFlag = false; this.displayPage = page; break;
        case 'microrejected': this.microSubMenuFlag = false; this.displayPage = page; break;
        case 'microcompleted': this.microSubMenuFlag = false; this.displayPage = page; break;

        case 'report_approval':
          this.repAppSubFlag = !this.repAppSubFlag;
          if (this.minimizedAsiedFlag == true) {
            this.minimizing();
          }
          break;

        case 'report_approval_pathology': this.repAppSubFlag = false; this.displayPage = page; break;
        case 'report_approval_radiology': this.repAppSubFlag = false; this.displayPage = page; break;
        case 'report_approval_microbiology': this.repAppSubFlag = false; this.displayPage = page; break;

        case 'report_upload_edit': this.displayPage = page; break;
        case 'report_upload_view': this.displayPage = page; break;
        case 'report_approval': this.displayPage = page; break;
        case 'nephrology_readings': this.displayPage = page; break;
        case 'diabetics_readings': this.displayPage = page; break;
        case 'billing': this.displayPage = page; break;
        case 'billingextender':
          this.billingFlag = !this.billingFlag; if (this.minimizedAsied == true) {
            this.minimizing();
          };
          break;

        case 'billinglist': this.displayPage = page; break;
        case 'users': this.displayPage = page; break;
        case 'help': this.displayPage = page; break;
        case 'diagnosisview': this.displayPage = page; break;
        case 'nephrologyreadingviewpage': this.displayPage = page; break;
        case 'users_view': this.displayPage = page; break;
        case 'report_approval_detailed': this.displayPage = page; break;
        case 'diagtestadd': this.displayPage = page; break;
        case 'diagtestaddadv': this.displayPage = page; break;
        case 'diagbilldetailview': this.displayPage = page; break;
        case 'diagbillcreate': this.displayPage = page; break;
        case 'diag_app_create': this.displayPage = page; break;
         // stock management
         case'stockmanagment': this.submenuFlag1 = !this.submenuFlag1;
         if(this.minimizedAsied == true){
           this.minimizing();
         } 
         break;
         case 'diagmanufacturer': this.displayPage = page; break;
         case'srl':this.displayPage = page; break;
         case'pharmacysupplier':this.displayPage = page; break;
         case'supplierProduct':this.displayPage = page; break;
         case'product':this.displayPage = page; break;
         case'purchasereqlist':this.displayPage = page; break;
         case'pharmacyresponse':this.displayPage = page; break;
         case'inventory_receivables':this.displayPage = page; break;
         case'stockStatusAdmin':this.displayPage = page; break;
         case'productReturns':this.displayPage = page; break;
         case'supppay':this.displayPage = page; break;
         case'stockadjustment':this.displayPage = page; break;
         case'rackbin':this.displayPage = page; break;
         case'pharmacyrequest':this.displayPage = page; break;
         case 'orderview':this.displayPage = page; break;
         case'inventory_upload':this.displayPage = page; break;
         //reports
         case'reports': this.reportSubmenuFlag = !this.reportSubmenuFlag;
         if(this.minimizedAsied == true){
           this.minimizing();
         } 
         break;
       case'stock_status':this.displayPage = page; break;
       case'sales_report':this.displayPage = page; break;
       case'receivables_report':this.displayPage = page; break;
       case'productReturnsReport':this.displayPage = page; break;
       case'gsd':this.displayPage = page; break;
       case'SDI':this.displayPage = page; break;
       case'bed_occupancypharm':this.displayPage = page; break;
       case'abc_analysispharm':this.displayPage = page; break;
       case'shrtexpiry':this.displayPage = page; break;
       case'pastinwards':this.displayPage = page; break;
        case 'dashboard': this.dashSubMenuFlag = !this.dashSubMenuFlag;
          if (this.minimizedAsiedFlag == true) {
            this.minimizing();
          }
          break;
        case 'patient_list': this.dashSubMenuFlag = false; this.displayPage = page; break;
        case 'reports': this.dashSubMenuFlag = false; this.displayPage = page; break;
        case 'billedit': this.displayPage = page; break;
        case 'diagPackageCreate': this.displayPage = page; break;
        case 'appedit': this.displayPage = page; break;
        case 'samplecollect': this.displayPage = page; break;
        case 'samplecollectADV': this.displayPage = page; break;
        case 'assertion': this.displayPage = page; break;
        case 'outsource':
          this.outsubFlag = !this.outsubFlag;
          if (this.minimizedAsiedFlag == true) {
            this.minimizing();
          }
          break;
        case 'out-centre-list': this.outsubFlag = false; this.displayPage = page; break;
        case 'out-centre': this.outsubFlag = false; this.displayPage = page; break;
        case 'out-reports': this.outsubFlag = false; this.displayPage = page; break;
        case 'out-payments': this.outsubFlag = false; this.displayPage = page; break;
        case 'out-payments-adv': this.outsubFlag = false; this.displayPage = page; break;
        case 'out-tests': this.outsubFlag = false; this.displayPage = page; break;
        case 'out-test-map': this.displayPage = page; break;
        case 'partialPayments': this.displayPage = page; break;
        case 'testsandpackadv': this, this.displayPage = page; break;
        case 'specimen-report-template': this.displayPage = page; break;

        case 'DrugsName': this.DrugsNameFlag = !this.DrugsNameFlag;
          if (this.minimizedAsiedFlag == true) {
            this.minimizing();
          }
          break;
        // case 'Adminhome': this.displayPage  = page; break;
        // case 'home': this.displayPage  = page; break;

        // case 'misreports': this.displayPage  = page; break;
        // master

        case 'DrugsNameType': this.displayPage = page; break;
        case 'CultureSensitivityDrug': this.displayPage = page; break;
        case 'addmaster': this.displayPage = page; break;
        case 'CulturePathology': this.displayPage = page; break;
        case 'Sampletype': this.displayPage = page; break;
        case 'DiagnosticDepartment': this.displayPage = page; break;
        case 'DiagnosticTube': this.displayPage = page; break;
        case 'DiagnosticReagent': this.displayPage = page; break;
        case 'Accounthead': this.displayPage = page; break;
        case 'AccountSubhead': this.displayPage = page; break;
        case 'Stockexchange': this.displayPage = page; break;
        case 'StoreName': this.displayPage = page; break;
        case 'GenericName': this.displayPage = page; break;
        case 'ScheduleName': this.displayPage = page; break;
        case 'ProductForm': this.displayPage = page; break;
        case 'gram_stains': this.displayPage = page; break;
        case 'visit_purpose': this.displayPage = page; break;
        case 'addtemplate': this.displayPage = page; break;
        case 'specimenaccessioner': this.displayPage = page; break;
        case 'ConductionDoctor': this.displayPage = page; break;
        case 'conductionDoc': this.displayPage = page; break; 
        case 'Surgery_equipments': this.displayPage = page; break;
        case 'patient_list': this.displayPage = page; break;
        case 'manageclient': this.displayPage = page; break;
        case 'other_expenses': this.displayPage = page; break;
      }
      customjs.SelectMenuFun(this.selectedMenu, "diag");

    } else if (this.loginType == "nurse") {
      let page_nmae: string = page;
      this.selectedMenu = page;
      this.inpatientFlag = false;
      switch (page_nmae) {
        case 'profile': this.displayPage = page; break;
        case 'appointment': this.displayPage = page; break;
        case 'appoinmentDetailView': this.displayPage = page; break;
        case 'assignList': this.displayPage = page; break;
        case 'assignDetailView': this.displayPage = page; break;
        case 'doctorAppList': this.displayPage = page; break;
        case 'doctorList': this.displayPage = page; break;
        // case 'inpatient': this.displayPage = page; break;
        case 'inPatientViewInfo': this.displayPage = page; break;
        case 'medicine_tracker': this.displayPage = page; break;
        case 'DoctorAppDetail': this.displayPage = page; break;
        case 'viewIpatient': this.displayPage = page; break;
        case 'casesheet': this.displayPage = page; break;
        case 'casesheet_list': this.displayPage = page; break;
        case 'cardio_casesheet_view': this.displayPage = page; break;
        case 'OTBooking': this.displayPage = page; break;
        case 'OTProcedure': this.displayPage = page; break;
        case 'Patients': this.displayPage = page; break;
        case 'warddetails': this.displayPage = page; break;
        case 'DIpatientTracking': this.displayPage = page; break;
        case 'dietTracker': this.displayPage = page; break;
        case 'medicineTracker': this.displayPage = page; break;
        case 'dietNewTracker': this.displayPage = page; break;
        case 'specimen-report-template': this.displayPage = page; break;
        case 'docpresnocase': this.displayPage = page; break;
        case 'DrugsName': this.DrugsNameFlag = !this.DrugsNameFlag;
          if (this.minimizedAsiedFlag == true) {
            this.minimizing();
          }
          break;
        // case 'Adminhome': this.displayPage  = page; break;
        // case 'home': this.displayPage  = page; break;

        // case 'misreports': this.displayPage  = page; break;
        // master

        case 'DrugsNameType': this.displayPage = page; break;
        case 'CultureSensitivityDrug': this.displayPage = page; break;
        case 'addmaster': this.displayPage = page; break;
        case 'CulturePathology': this.displayPage = page; break;
        case 'Sampletype': this.displayPage = page; break;
        case 'DiagnosticDepartment': this.displayPage = page; break;
        case 'DiagnosticTube': this.displayPage = page; break;
        case 'DiagnosticReagent': this.displayPage = page; break;
        case 'Accounthead': this.displayPage = page; break;
        case 'AccountSubhead': this.displayPage = page; break;
        case 'Stockexchange': this.displayPage = page; break;
        case 'StoreName': this.displayPage = page; break;
        case 'GenericName': this.displayPage = page; break;
        case 'ScheduleName': this.displayPage = page; break;
        case 'ProductForm': this.displayPage = page; break;
        case 'gram_stains': this.displayPage = page; break;
        case 'visit_purpose': this.displayPage = page; break;
        case 'addtemplate': this.displayPage = page; break;
        case 'specimenaccessioner': this.displayPage = page; break;
        case 'ConductionDoctor': this.displayPage = page; break;
        case 'Surgery_equipments': this.displayPage = page; break;
        case 'wardtall': this.displayPage = page; break;
        case 'wardTransfer': this.displayPage = page; break;
        case 'nursereports': this.displayPage = page; break;
        case 'patient_list': this.displayPage = page; break;
        case 'manageclient': this.displayPage = page; break;
        case 'discharge_timeline': this.displayPage = page; break;
        case 'dietaccess': this.displayPage = page; break;
         // stock management
         case'stockmanagment': this.submenuFlag1 = !this.submenuFlag1;
         if(this.minimizedAsied == true){
           this.minimizing();
         } 
         break;
         case 'diagmanufacturer': this.displayPage = page; break;
         case'srl':this.displayPage = page; break;
         case'pharmacysupplier':this.displayPage = page; break;
         case'supplierProduct':this.displayPage = page; break;
         case'product':this.displayPage = page; break;
         case'purchasereqlist':this.displayPage = page; break;
         case'pharmacyresponse':this.displayPage = page; break;
         case'inventory_receivables':this.displayPage = page; break;
         case'stockStatusAdmin':this.displayPage = page; break;
         case'productReturns':this.displayPage = page; break;
         case'supppay':this.displayPage = page; break;
         case'stockadjustment':this.displayPage = page; break;
         case'rackbin':this.displayPage = page; break;
         case'pharmacyrequest':this.displayPage = page; break;
         case 'orderview':this.displayPage = page; break;
         case'inventory_upload':this.displayPage = page; break;
         //reports
         case'reports': this.reportSubmenuFlag = !this.reportSubmenuFlag;
         if(this.minimizedAsied == true){
           this.minimizing();
         } 
         break;
       case'stock_status':this.displayPage = page; break;
       case'sales_report':this.displayPage = page; break;
       case'receivables_report':this.displayPage = page; break;
       case'productReturnsReport':this.displayPage = page; break;
       case'gsd':this.displayPage = page; break;
       case'SDI':this.displayPage = page; break;
       case'bed_occupancypharm':this.displayPage = page; break;
       case'abc_analysispharm':this.displayPage = page; break;
       case'shrtexpiry':this.displayPage = page; break;
       case'pastinwards':this.displayPage = page; break;
        case 'inpatient':
          this.inpatientFlag = !this.inpatientFlag; if (this.minimizedAsied == true) {
            this.minimizing();
          };
          break;
        case 'other_expenses': this.displayPage = page; break;
      }
      customjs.SelectMenuFun(this.selectedMenu, "nurse");

    } else if (this.loginType == "physio") {
      this.DrugsNameFlag = false;
      let page_nmae: string = page;
      this.selectedMenu = page;
      switch (page_nmae) {
        case 'appointment': this.displayPage = page; break;
        case 'casesheet': this.displayPage = page; break;
        case 'assignmentList': this.displayPage = page; break;
        case 'assignmentDetailPage': this.displayPage = page; break;
        case 'therapies': this.displayPage = page; break;
        case 'userList': this.displayPage = page; break;
        case 'userViewPage': this.displayPage = page; break;
        case 'Home': this.displayPage = page; break;
        case 'billing': this.displayPage = page; break;
        case 'walkin': this.displayPage = page; break;
        case 'billdetails': this.displayPage = page; break;
        case 'billCreate': this.displayPage = page; break;
        // master
        case 'DrugsName': this.DrugsNameFlag = !this.DrugsNameFlag;
          if (this.minimized_asied == true) {
            this.minimizing();
          }
          break;

        case 'permissionF': this.DrugsNameFlag = !this.DrugsNameFlag;
          if (this.minimized_asied == true) {
            this.minimizing();
          }
          break;
        case 'DrugsNameType': this.displayPage = page; break;
        case 'CultureSensitivityDrug': this.displayPage = page; break;
        case 'addmaster': this.displayPage = page; break;
        case 'CulturePathology': this.displayPage = page; break;
        case 'Sampletype': this.displayPage = page; break;
        case 'DiagnosticDepartment': this.displayPage = page; break;
        case 'DiagnosticTube': this.displayPage = page; break;
        case 'DiagnosticReagent': this.displayPage = page; break;
        case 'Accounthead': this.displayPage = page; break;
        case 'AccountSubhead': this.displayPage = page; break;
        case 'Stockexchange': this.displayPage = page; break;
        case 'StoreName': this.displayPage = page; break;
        case 'GenericName': this.displayPage = page; break;
        case 'ScheduleName': this.displayPage = page; break;
        case 'ProductForm': this.displayPage = page; break;
        case 'specimenaccessioner': this.displayPage = page; break;
        case 'ConductionDoctor': this.displayPage = page; break;
        case 'Surgery_equipments': this.displayPage = page; break;
        case 'patient_list': this.displayPage = page; break;
        case 'manageclient': this.displayPage = page; break;
        case 'discharge_timeline': this.displayPage = page; break;
        case 'other_expenses': this.displayPage = page; break;
         // stock management
         case'stockmanagment': this.submenuFlag1 = !this.submenuFlag1;
         if(this.minimizedAsied == true){
           this.minimizing();
         } 
         break;
         case 'diagmanufacturer': this.displayPage = page; break;
         case'srl':this.displayPage = page; break;
         case'pharmacysupplier':this.displayPage = page; break;
         case'supplierProduct':this.displayPage = page; break;
         case'product':this.displayPage = page; break;
         case'purchasereqlist':this.displayPage = page; break;
         case'pharmacyresponse':this.displayPage = page; break;
         case'inventory_receivables':this.displayPage = page; break;
         case'stockStatusAdmin':this.displayPage = page; break;
         case'productReturns':this.displayPage = page; break;
         case'supppay':this.displayPage = page; break;
         case'stockadjustment':this.displayPage = page; break;
         case'rackbin':this.displayPage = page; break;
         case'pharmacyrequest':this.displayPage = page; break;
         case 'orderview':this.displayPage = page; break;
         case'inventory_upload':this.displayPage = page; break;
         //reports
         case'reports': this.reportSubmenuFlag = !this.reportSubmenuFlag;
         if(this.minimizedAsied == true){
           this.minimizing();
         } 
         break;
       case'stock_status':this.displayPage = page; break;
       case'sales_report':this.displayPage = page; break;
       case'receivables_report':this.displayPage = page; break;
       case'productReturnsReport':this.displayPage = page; break;
       case'gsd':this.displayPage = page; break;
       case'SDI':this.displayPage = page; break;
       case'bed_occupancypharm':this.displayPage = page; break;
       case'abc_analysispharm':this.displayPage = page; break;
       case'shrtexpiry':this.displayPage = page; break;
       case'pastinwards':this.displayPage = page; break  
      }
      customjs.SelectMenuFun(this.selectedMenu, "diag");

    }
    else if (this.loginType == "dietician") {
      let page_nmae: string = page;
      this.selectedMenu = page;
      this.DrugsNameFlag = false;
      this.sendType = "dietician";
      switch (page_nmae) {
        case 'profile': this.displayPage = page; break;
        case 'manageappointments': this.displayPage = page; break;
        case 'dietPlan': this.displayPage = page; break;
        case 'dietPlancard': this.displayPage = page; break;
        case 'appointment': this.displayPage = page; break;
        case 'appt_create': this.displayPage = page; break;
        case 'casesheet': this.displayPage = page; break;
        case 'notification': this.displayPage = page; break;
        case 'diet_dashboard': this.displayPage = page; break;
        case 'patientList':
          Helper_Class.setDiagReadingList(null);
          Helper_Class.setDiagReadingList("dietList");
          this.displayPage = page; break;
        case 'patientTracking': this.displayPage = page; break;
        case 'billList':
          var setFlow = {
            flow: 'dietcian',
            user_id: this.userInfo.user_id
          }
          Helper_Class.setBillingFlow(null);
          Helper_Class.setBillingFlow(setFlow);
          this.displayPage = page;
          break;
        case 'billCreate': this.displayPage = page; break;
        case 'billdetail': this.displayPage = page; break;
        case 'readings':
          Helper_Class.setDiagReadingList(null);
          Helper_Class.setDiagReadingList("readings");
          this.displayPage = page;
          break;
        case 'diabReadings': this.displayPage = page; break;
        case 'reference_video': this.displayPage = page; break;
        // master
        case 'DrugsName': this.DrugsNameFlag = !this.DrugsNameFlag;
          if (this.minimized_asied == true) {
            this.minimizing();
          }
          break;

        case 'DrugsNameType': this.displayPage = page; break;
        case 'CultureSensitivityDrug': this.displayPage = page; break;
        case 'addmaster': this.displayPage = page; break;
        case 'CulturePathology': this.displayPage = page; break;
        case 'Sampletype': this.displayPage = page; break;
        case 'DiagnosticDepartment': this.displayPage = page; break;
        case 'DiagnosticTube': this.displayPage = page; break;
        case 'DiagnosticReagent': this.displayPage = page; break;
        case 'Accounthead': this.displayPage = page; break;
        case 'AccountSubhead': this.displayPage = page; break;
        case 'Stockexchange': this.displayPage = page; break;
        case 'StoreName': this.displayPage = page; break;
        case 'GenericName': this.displayPage = page; break;
        case 'ScheduleName': this.displayPage = page; break;
        case 'ProductForm': this.displayPage = page; break;
        case 'specimenaccessioner': this.displayPage = page; break;
        case 'ConductionDoctor': this.displayPage = page; break;
        case 'Surgery_equipments': this.displayPage = page; break;
        case 'patient_list': this.displayPage = page; break;
        case 'manageclient': this.displayPage = page; break;
        case 'other_expenses': this.displayPage = page; break;
         // stock management
         case'stockmanagment': this.submenuFlag1 = !this.submenuFlag1;
         if(this.minimizedAsied == true){
           this.minimizing();
         } 
         break;
         case 'diagmanufacturer': this.displayPage = page; break;
         case'srl':this.displayPage = page; break;
         case'pharmacysupplier':this.displayPage = page; break;
         case'supplierProduct':this.displayPage = page; break;
         case'product':this.displayPage = page; break;
         case'purchasereqlist':this.displayPage = page; break;
         case'pharmacyresponse':this.displayPage = page; break;
         case'inventory_receivables':this.displayPage = page; break;
         case'stockStatusAdmin':this.displayPage = page; break;
         case'productReturns':this.displayPage = page; break;
         case'supppay':this.displayPage = page; break;
         case'stockadjustment':this.displayPage = page; break;
         case'rackbin':this.displayPage = page; break;
         case'pharmacyrequest':this.displayPage = page; break;
         case'orderview':this.displayPage = page; break;
         case'inventory_upload':this.displayPage = page; break;
         //reports
         case'reports': this.reportSubmenuFlag = !this.reportSubmenuFlag;
         if(this.minimizedAsied == true){
           this.minimizing();
         } 
         break;
       case'stock_status':this.displayPage = page; break;
       case'sales_report':this.displayPage = page; break;
       case'receivables_report':this.displayPage = page; break;
       case'productReturnsReport':this.displayPage = page; break;
       case'gsd':this.displayPage = page; break;
       case'SDI':this.displayPage = page; break;
       case'bed_occupancypharm':this.displayPage = page; break;
       case'abc_analysispharm':this.displayPage = page; break;
       case'shrtexpiry':this.displayPage = page; break;
       case'pastinwards':this.displayPage = page; break;  
      }
      customjs.SelectMenuFun(this.selectedMenu, "diag");
    }
    else if (this.loginType == "Billing") {
      let page_nmae: string = page;
      this.selectedMenu = page;
      this.submenuFlagb = false;
      Helper_Class.setReportType(null);
      var sendData = {
        flow: "phrama",
        report_type: page,
        pharmacyID: this.pharmacyID,
      }
      Helper_Class.setReportType(sendData);
      switch (page_nmae) {
        // case 'frontdeskbilling': this.displayPage  = page; break;
        case 'appointment': this.displayPage = page; break;
        case 'frontAdvances': this.displayPage = page; break;
        case 'chargesTYPE': this.displayPage = page; break;
        case 'Frontrefund': this.displayPage = page; break;
        case 'frontAdminConsolidatedBills': this.displayPage = page; break;
        case 'frontpartialPayments': this.displayPage = page; break;
        case 'FrontAdmininsuranceBill': this.displayPage = page; break;
        case 'billingDetailsView': this.displayPage = page; break;
        case 'billingDiagDetailsView': this.displayPage = page; break;
        case 'ipatientBillCreate': this.displayPage = page; break;
        case 'adminReceptbillcreate': this.displayPage = page; break;
        case 'dietappointments': Diet_Helper.setAppFlow(undefined); this.displayPage = page; break;
        case 'dietappointmentsdetailed': this.displayPage = page; break;
        case 'dietappointmentscreate': this.displayPage = page; break;
        case 'frontdeskbilling':
          var send = {
            flow: "Billing",
            id: this.userInfo.user_id
          }
          Helper_Class.setadminBillingFlow(send);
          this.displayPage = page; break;
        // case 'frontdeskbilling':
        //   var send= {
        //     flow: "Billing",
        //     id: this.userInfo.user_id
        //   }
        //   Helper_Class.setadminBillingFlow(send);
        //   this.displayPage = page; break;

        case 'manageappointments': this.displayPage = page; break;
        case 'appcreate': this.displayPage = page; break;
        case 'receptionistdoctordetailview': this.displayPage = page; break;
        case 'receptviewprescription': this.displayPage = page; break;
        case 'prescriptionlist': this.displayPage = page; break;
        case 'frontdeskbilling':
          var send = {
            flow: "front-desk",
            id: this.userInfo.user_id
          }
          Helper_Class.setadminBillingFlow(send);
          this.displayPage = page; break;
        case 'receptbillcreate': this.displayPage = page; break;
        case 'receptbilledit': this.displayPage = page; break;
        case 'receptbilldetail': this.displayPage = page; break;
        case 'registration': this.displayPage = page; break;
        case 'patient_list': this.displayPage = page; break;
        case 'nurse_ward': this.permissionSubmenuFlag = false; this.displayPage = page; break;
        case 'nurse_patient': this.permissionSubmenuFlag = false; this.displayPage = page; break;
        case 'nurse_doctor': this.permissionSubmenuFlag = false; this.displayPage = page; break;
        case 'permission':
          this.permissionSubmenuFlag = !this.permissionSubmenuFlag;
          if (this.minimizedAsied == true) {
            this.minimizing();
          }
          break;
        case 'billing':
          this.billingFlag = !this.billingFlag; if (this.minimizedAsied == true) {
            this.minimizing();
          };
          break;
        case 'advances': this.displayPage = page; break;
        case 'bills': this.displayPage = page; break;
       
        case 'billingDetailsView': this.displayPage = page; break;
        case 'charges': this.displayPage = page; break;
        case 'refund': this.displayPage = page; break;
        case 'createRefund': this.displayPage = page; break;
        case 'inpatient':
          this.inpatientFlag = !this.inpatientFlag; if (this.minimizedAsied == true) {
            this.minimizing();
          };
          break;
        case 'dischargeList': this.displayPage = page; break;
        case 'Patients': this.displayPage = page;Helper_Class.setReportType("Patients");break;
        case 'inPatientCreate': this.displayPage = page; break;
        case 'consultant': this.displayPage = page; break;
        case 'diaBillCreate': this.displayPage = page; break;
        case 'diaAppDetail': this.displayPage = page; break;
        case 'diaAppCreate': this.displayPage = page; break;
        case 'wardmaster': this.displayPage = page; break;
        case 'OTmaster': this.displayPage = page; break;
        case 'OTBooking': this.displayPage = page; break;
        case 'OTProcedure': this.displayPage = page; break;
        case 'viewIpatient': this.displayPage = page; break;
        case 'diaBillingDetailsView': this.displayPage = page; break;
        
        // case 'ipatientBillCreate': this.displayPage = page; break;
        case 'frontConsolidatedBills': this.displayPage = page; break;
        case 'dietappointments': Diet_Helper.setAppFlow(undefined); this.displayPage = page; break;
        case 'dietappointmentsdetailed': this.displayPage = page; break;
        case 'dietappointmentscreate': this.displayPage = page; break;
        case 'reports': this.reportsSubmenuFlag = !this.reportsSubmenuFlag;
          if (this.minimizedAsied == true) {
            this.minimizing();
          };
          break;
        case 'hosp_bill_reports': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'diag_bill_reports': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'reference_video': this.displayPage = page; break;
        case 'listPackage': this.displayPage = page; break;
        case 'createPackage': this.displayPage = page; break;
        case 'inpdischargeview': this.displayPage = page; break;
        case 'discharge_timeline': this.displayPage = page; break;
        case 'inPatientDS': this.displayPage = page; break;
        case 'docref': this.displayPage = page; break;
        case 'docrefcreate': this.displayPage = page; break;
        case 'medprescription': this.displayPage = page; break;
        case 'leadmgmt': this.displayPage = page; break;
        case 'partialPayments': this.displayPage = page; break;
        case 'insuranceBill': this.displayPage = page; break;
        case 'manageclient': this.displayPage = page; break;
        case 'sales_report_doctor': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'bill_refund': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'bill_due': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'bed_occupancy': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'abc_analysis': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'purchase_order': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'product': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'product_receivables': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'lab_test_wise': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'notvisited_report': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'consolidat_report': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'inpat_reports': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'inPat_report': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'Day_book': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        case 'appedit': this.displayPage = page; break;
        case 'concent_form': this.displayPage = page; break;
        // master
        case 'DrugsName': this.DrugsNameFlag = !this.DrugsNameFlag;
          if (this.minimized_asied == true) {
            this.minimizing();
          }
          break;
        case 'DrugsNameType': this.displayPage = page; break;
        case 'CultureSensitivityDrug': this.displayPage = page; break;
        case 'addmaster': this.displayPage = page; break;
        case 'CulturePathology': this.displayPage = page; break;
        case 'Sampletype': this.displayPage = page; break;
        case 'DiagnosticDepartment': this.displayPage = page; break;
        case 'DiagnosticTube': this.displayPage = page; break;
        case 'DiagnosticReagent': this.displayPage = page; break;
        case 'Accounthead': this.displayPage = page; break;
        case 'AccountSubhead': this.displayPage = page; break;
        case 'Stockexchange': this.displayPage = page; break;
        case 'StoreName': this.displayPage = page; break;
        case 'GenericName': this.displayPage = page; break;
        case 'ScheduleName': this.displayPage = page; break;
        case 'ProductForm': this.displayPage = page; break;
        case 'RateCard': this.displayPage = page; break;
        case 'specimenaccessioner': this.displayPage = page; break;
        case 'ConductionDoctor': this.displayPage = page; break;
        case 'Surgery_equipments': this.displayPage = page; break;
        case 'chargetype': this.displayPage = page; break;
        case 'consolidat_reportadmin': this.displayPage = page; break;
        case 'notvisited_reportadmin': this.displayPage = page; break;
        case 'stock_report': this.displayPage = page; break;
        case 'IPR': this.displayPage = page; break;
        case 'IR': this.displayPage = page; break;
        
        case 'HBR':
          Helper_Class.setReportType(null);
          var sendData = {
            flow: "phrama",
            report_type: page,
            pharmacyID: this.pharmacyID,
          }
          Helper_Class.setReportType(sendData);
          this.displayPage = page; break;
        case 'DBR': this.displayPage = page; break;
        case 'SDI': this.displayPage = page; break;
        case 'gsd': this.displayPage = page; break;
        case 'stockReceivable': this.displayPage = page; break;
        case 'salesGst': this.displayPage = page; break;
        case 'sales_report': this.displayPage = page; break;
        case 'patient_list': this.displayPage = page; break;
        case 'manageclient': this.displayPage = page; break;
        case 'reporter': this.submenuFlagb = !this.submenuFlagb;
          if (this.minimized_asied == true) {
            this.minimizing();
          }
          break;
        case 'other_expenses': this.displayPage = page; break;
      }
      customjs.SelectMenuFun(this.selectedMenu, "Billing");
    }
    // pharmacy
    else if (this.loginType == "pharmacy") {
      document.getElementById("pharma_overlay").style.overflow = "auto";
      // pharmacy_helper.setpoid("");
      // pharmacy_helper.setfromreqlist("frompurchasereq");
      // this.messageservice.sendMessage("pharmacyrequest");
      let page_nmae: string = page;
      this.selectedMenu = page;
      this.submenuFlag = false;
      this.submenuFlag1 = false;
      this.reportSubmenuFlag = false;
      this.DrugsNameFlag = false;

      switch (page_nmae) {
        case 'pharmacyhome':
          this.displayPage = "pharmacyhome";
          break;
        case 'patient':
          this.submenuFlag = !this.submenuFlag;
          if (this.minimizedAsied == true) {
            this.minimizing();
          }
          break;
        case 'stockmanagment': this.submenuFlag1 = !this.submenuFlag1;
          if (this.minimizedAsied == true) {
            this.minimizing();
          }
          break;
        case 'pharmacysupplier': this.submenuFlag1 = false; this.displayPage = page; break;
        case 'discounts': this.submenuFlag1 = false; this.displayPage = page; break;
        case 'product': this.submenuFlag1 = false; this.displayPage = page; break;
        case 'pharmacyrequest': this.submenuFlag1 = false; this.displayPage = page; break;
        case 'pharmacyresponse': this.submenuFlag1 = false; this.displayPage = page; break;
        case 'inventory_upload': this.submenuFlag1 = false; this.displayPage = page; break;
        case 'inventory_receivables': this.submenuFlag1 = false; this.displayPage = page; break;
        case 'stockStatusAdmin': this.submenuFlag1 = false; this.displayPage = page; break;
        case 'reports': this.reportSubmenuFlag = !this.reportSubmenuFlag;
          if (this.minimizedAsied == true) {
            this.minimizing();
          }
          break;
        case 'stock_status': this.reportSubmenuFlag = false; this.displayPage = page; break;
        case 'sales_report': this.reportSubmenuFlag = false; this.displayPage = page; break;
        case 'receivables_report': this.reportSubmenuFlag = false; this.displayPage = page; break;
        case 'pharmaracksbins': this.reportSubmenuFlag = false; this.displayPage = page; break;
        case 'productReturnsReport': this.reportSubmenuFlag = false; this.displayPage = page; break;
        case 'weightflag': this.reportsSubmenuFlag = false; this.displayPage = page; break;
        Helper_Class.setReportType(null);

          sendData = {
            flow: "phrama",
            report_type: "returnsReport",
            pharmacyID: this.pharmacyID,
          }
          Helper_Class.setReportType(sendData);
          this.displayPage = page; break;
        case 'ordercreate': this.displayPage = page; break;
        case 'referral': this.displayPage = page; break;
        case 'filterpage': this.displayPage = page; break;
        case 'help': this.displayPage = page; break;
        case 'pharmacycurentinformation': this.submenuFlag = false; this.displayPage = page; break;
        case 'pharmacypastinformation': this.submenuFlag = false; this.displayPage = page; break;
        case 'pharmacymoduleorderdetailview': this.submenuFlag = false; this.displayPage = page; break;
        case 'orderview': this.submenuFlag = false; this.displayPage = page; break;
        case 'inward': this.displayPage = page; break;
        case 'pastinwards' : this.displayPage = page; break;
        case 'shrtexpiry' : this.displayPage = page; break;
        case 'phamratimeline': this.displayPage = page; break;
        case 'ipWriteRx': this.displayPage = page; break;
        case 'manufacturer': this.displayPage = page; break;
        case 'srl': this.displayPage = page; break;
        case 'supplierProduct': this.displayPage = page; break;
        case 'productReturns': this.displayPage = page; break;
        case 'interdept': this.displayPage = page; break;
        case 'interdeptlist': this.displayPage = page; break;
        case 'supppay': this.displayPage = page; break;
        case 'rackbin': this.displayPage = page; break;
        case 'pharmasettings': this.displayPage = page; break;
        case 'purchasereqlist': this.displayPage = page; break;
        case 'billestimate': this.displayPage = page; break;
        //this.displayPage = page; break;
        case 'reference_video': this.displayPage = page; break;
        // master
        case 'DrugsName': this.DrugsNameFlag = !this.DrugsNameFlag;
          if (this.minimized_asied == true) {
            this.minimizing();
          }
          break;
        // case 'DrugsNameType': this.displayPage = page; break;
        // case 'CultureSensitivityDrug': this.displayPage = page; break;
        // case 'addmaster': this.displayPage  = page; break;
        // case 'CulturePathology': this.displayPage = page; break;
        // case 'Sampletype': this.displayPage = page; break;
        // case 'DiagnosticDepartment': this.displayPage = page; break;
        // case 'DiagnosticTube': this.displayPage  = page; break;
        // case 'DiagnosticReagent': this.displayPage = page; break;
        // // case 'Accounthead': this.displayPage = page; break;
        // // case 'AccountSubhead': this.displayPage = page; break;
        // case 'Stockexchange': this.displayPage= page; break;
        // case 'StoreName': this.displayPage  = page; break;
        // case 'GenericName': this.displayPage = page; break;
        // case 'ScheduleName': this.displayPage = page; break;
        // case 'ProductForm': this.displayPage  = page; break;
        // case 'stockadjustment': this.displayPage  = page; break;
        case 'bed_occupancypharm': this.displayPage = page; break;
        case 'abc_analysispharm': this.displayPage = page; break;
        case 'DrugsNameType': this.displayPage = page; break;
        case 'CultureSensitivityDrug': this.displayPage = page; break;
        case 'addmaster': this.displayPage = page; break;
        case 'CulturePathology': this.displayPage = page; break;
        case 'Sampletype': this.displayPage = page; break;
        case 'DiagnosticDepartment': this.displayPage = page; break;
        case 'DiagnosticTube': this.displayPage = page; break;
        case 'DiagnosticReagent': this.displayPage = page; break;
        case 'Accounthead': this.displayPage = page; break;
        case 'AccountSubhead': this.displayPage = page; break;
        case 'Stockexchange': this.displayPage = page; break;
        case 'StoreName': this.displayPage = page; break;
        case 'GenericName': this.displayPage = page; break;
        
        case 'pharmaratecard': this.displayPage = page; break;
        case 'ScheduleName': this.displayPage = page; break;
        case 'ProductForm': this.displayPage = page; break;
        case 'Sub-Deparmentc': this.displayPage = page; break;
        case 'addtemplate': this.displayPage = page; break;
        case 'rateCard': this.displayPage = page; break;
        case 'visitpurpose': this.displayPage = page; break;
        case 'UserRole': this.displayPage = page; break;
        case 'CultureSensitivityDrugName': this.displayPage = page; break;
        case 'reference_video': this.displayPage = page; break;
        case 'specimenaccessioner': this.displayPage = page; break;
        case 'ConductionDoctor': this.displayPage = page; break;
        
        case 'Surgery_equipments': this.displayPage = page; break;
        case 'stockadjustment': this.displayPage = page; break;
        case 'patient_list': this.displayPage = page; break;
        case 'manageclient': this.displayPage = page; break;
        case 'patreg': this.displayPage = page; break;
        case 'gsd': this.displayPage = page; break;
        case 'SDI': this.displayPage = page; break;
        case 'other_expenses': this.displayPage = page; break;
        case 'consing': this.displayPage = page; break;
      }
      customjs.SelectMenuFun(this.selectedMenu, "pharmacy");
    }
  }


  public submenuFlag1: boolean = false;
  public submenu_flag: boolean = false;
  menuNavAdmin(page, type) {
    this.Docsubmenu_flag = false;
    this.fullMenu = false;
    this.daig_mainMenu = false;
    this.physiosubFlagsub = false;
    this.nurseMainMenusub = false;
    this.dietiMainMenusub = false;
    this.manageOrders = false;
    this.tatsubmenuFlag1 = false;
    this.DrugsNameFlag = false;
    this.submenuFlag1 = false;
    this.submenu_flag = false;
    this.Admin_MastersFlag = false;
    this.Pharmacy_MastersFlag = false;
    this.Lab_MastersFlag = false;
    this.docInpatientFlag = false;
    this.purchaseRequest = false;
    this.ABC_analysis = false;
    this.Bedocc_ratio = false;
    this.path_rept_pending_flag = false

    let page_name: string = page;
    this.selectedMenu = page;
    Helper_Class.setReportType(type);
    switch (page_name) {
      case 'home':
        this.displayPage = "home";
        break;

      case 'sales_report':
        this.displayPage = "sales_report";
        break;
      case 'micro_samplecollectADV': this.displayPage = page; break;
      case 'radio_samplecollectADV': this.displayPage = page; break;
      case 'path_samplecollectADV': this.displayPage = page; break;
      case 'calender': this.displayPage = page; break;
      case 'stock_report': this.displayPage = page; break;
      case 'stock_edit': this.displayPage = page; break;
      case 'Dochome': this.displayPage = page; break;
      case 'billing_home': this.displayPage = page; break;
      case 'bill_list': this.displayPage = page; break;
      case 'bill_edit': this.displayPage = page; break;
      case 'salesGst': this.displayPage = page; break;
      case 'stockReceivable': this.displayPage = page; break;
      case 'gsd': this.displayPage = page; break;
      case 'recreceivableReport': this.displayPage = page; break;
      case 'SDI': this.displayPage = page; break;

      case 'HBR': this.displayPage = page; break;
      case 'DBR': this.displayPage = page; break;
      case 'IR': this.displayPage = page; break;
      case 'IPR': this.displayPage = page; break;
      // from doctor
      case 'salesReport': this.displayPage = page; break;
      case 'stockReport': this.displayPage = page; break;
      case 'receivablesReport': this.displayPage = page; break;

      case 'help': this.displayPage = page; break;
      case 'Reference_video': this.displayPage = page; break;
      case 'misreports': this.displayPage = page; break;

      case 'notification': this.displayPage = page; break;
      case 'report_upload_view': this.displayPage = page; break;
      case 'secondopinion': this.displayPage = page; break;
      case 'doctor_availability': this.displayPage = page; break;
      case 'manage_schedule': this.displayPage = page; break;
      // from front deskbillingFlag
      // case'manageappointmentsMain': this.displayPage  = page; break;
      case 'manageappointments': this.displayPage = page; break;
      case 'frontprescriptionlist': this.displayPage = page; break;
      // case'frontbilling': this.displayPage  = page; break;
      case 'frontdeskadminbilling': this.displayPage = page; break;
      case 'Frontrefund': this.displayPage = page; break;
      case 'frontAdvances': this.displayPage = page; break;
      case 'chargesTYPE': this.displayPage = page; break;
      case 'frontAdminConsolidatedBills': this.displayPage = page; break;
      case 'frontpartialPayments': this.displayPage = page; break;
      case 'FrontAdmininsuranceBill': this.displayPage = page; break;
      case 'Frontpatient_list': this.displayPage = page; break;
      case 'permission': this.displayPage = page; break;
      case 'nurse_ward': this.displayPage = page; break;
      case 'nurse_patient': this.displayPage = page; break;
      case 'nurse_doctor': this.displayPage = page; break;
      // case'admininpatient':this.displayPage = page; break;
      case 'adminPatients': this.displayPage = page; break;
      case 'dischargeList': this.displayPage = page; break;
      // case 'menuNavAdmin': this.displayPage = page; break;
      case 'consultant': this.displayPage = page; break;
      case 'wardmaster': this.displayPage = page; break;
      case 'leadmgmt': this.displayPage = page; break;
      case 'docref': this.displayPage = page; break;
      case 'reference_video': this.displayPage = page; break;
      case 'hosp_bill_reports': this.displayPage = page; break;
      case 'diag_bill_reports': this.displayPage = page; break;
      case 'sales_report_doctor': this.displayPage = page; break;
      case 'inpat_reports': this.displayPage = page; break;
      case 'inPat_report': this.displayPage = page; break;
      case 'Day_book': this.displayPage = page; break;
      case 'bill_refund': this.displayPage = page; break;
      case 'createRefund': this.displayPage = page; break;
      case 'bill_due': this.displayPage = page; break;
      case 'bed_occupancy': this.displayPage = page; break;
      case 'abc_analysis': this.displayPage = page; break;
      case 'purchase_order': this.displayPage = page; break;
      case 'product': this.displayPage = page; break;
      case 'product_receivables': this.displayPage = page; break;
      case 'lab_test_wise': this.displayPage = page; break;
      case 'notvisited_report': this.displayPage = page; break;
      case 'consolidat_report': this.displayPage = page; break;
      // case'schedule':this.displayPage  = page; break;
      case 'diagnosis_home': this.displayPage = page; break;
      case 'admin-home': this.displayPage = page; break;
      case 'homecare': this.displayPage = page; break;

      case 'billing': this.displayPage = page; break;
      case 'dashboard': this.displayPage = page; break;
      // case'patient':this.displayPage  = page; break;
      case 'in_patient': this.displayPage = page; break;
      case 'ipbilling':
        Doc_Helper.setIpCreateBill("ipbill");
        this.displayPage = page; break;

      case 'prescriptionlist': this.displayPage = page; break;
      case 'walkin': this.displayPage = page; break;
      case 'appointment_list': this.displayPage = page; break;
      case 'consolidatedBills': this.displayPage = page; break;
      case 'patient_dashboard': this.displayPage = page; break;
      case 'consolidatedBills': this.displayPage = page; break;
      case 'billingList':

        this.submenu_flag = !this.submenu_flag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'Adminhome':
        this.Docsubmenu_flag = !this.Docsubmenu_flag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'reports':
        this.submenuFlag1 = !this.submenuFlag1;
        if (this.minimized_asied == true) {
          this.minimizing();

        }
        break;

      case 'reportsfrmadmin':
        this.reportsSubmenuFlag = !this.reportsSubmenuFlag;
        if (this.minimized_asied == true) {
          this.minimizing();

        }
        break;
      case 'patient':
        this.homeDocsubmenu_flag = !this.homeDocsubmenu_flag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'schedule':
        this.SubSchedule = !this.SubSchedule;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'misreports':
        this.Submisreports = !this.Submisreports;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;

      case 'manageappointmentsMain':
        this.fullMenu = !this.fullMenu;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;

      case 'frontbilling':
        this.subfrontbilling = !this.subfrontbilling;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'admininpatient':
        this.frontinpatientFlag = !this.frontinpatientFlag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'billingEdit': this.displayPage = page; break;
      case 'billingCreate': this.displayPage = page; break;
      case 'inPatientCreate': this.displayPage = page; break;
      case 'ipatientBillCreate': this.displayPage = page; break;
      case 'receptbilldetail': this.displayPage = page; break;
      case 'billingDetailsView': this.displayPage = page; break;
      case 'billingDiagDetailsView': this.displayPage = page; break;
      case 'diagBillingList': this.displayPage = page; break;
      case 'diagBillingView': this.displayPage = page; break;
      case 'diagBillingEdit': this.displayPage = page; break;
      case 'pharmaBillingList': this.displayPage = page; break;
      case 'pharmaBillingView': this.displayPage = page; break;
      case 'createPackage': this.displayPage = page; break;
      case 'reports':
        this.submenuFlag1 = !this.submenuFlag1;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'clientPackageList': this.displayPage = page; break;
      case 'listPackage': this.displayPage = page; break;
      case 'createUserPackage': this.displayPage = page; break;
      case 'adminReceptbillcreate': this.displayPage = page; break;
      case 'diagbillcreate': this.displayPage = page; break;
      case 'leadmgmt': this.displayPage = page; break;
      case 'notvisited_reportadmin': this.displayPage = page; break;
      case 'consolidat_reportadmin': this.displayPage = page; break;
      case 'authorize_list': this.displayPage = page; break;
      case 'insurer_list': this.displayPage = page; break;
      case 'insurerlist': this.displayPage = page; break;
      case 'insurer_edit': this.displayPage = page; break;
      case 'insurer_detail': this.displayPage = page; break;
      //insurerlist//insurere_edit//insurer_detail
      case 'tatreports':
        this.tatsubmenuFlag1 = !this.tatsubmenuFlag1;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'hospitaltat': this.displayPage = page; break;
      case 'diagnosistat': this.displayPage = page; break;
      case 'pharmasistat': this.displayPage = page; break;

      // Diagnosis
      case 'diagappointment':
        this.daig_mainMenu = !this.daig_mainMenu;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;

      case 'path_report_upload':
        this.pathSubMenuFlag = !this.pathSubMenuFlag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'radio_report_upload':
        this.radioSubMenuFlag = !this.radioSubMenuFlag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'micro_report_upload':
        this.microSubMenuFlag = !this.microSubMenuFlag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;

      case 'outsource':
        this.outsubFlag = !this.outsubFlag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'diagbilling':
        this.billingFlag = !this.billingFlag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'diagdashboard':
        this.dashSubMenuFlag = !this.dashSubMenuFlag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'diagusers': this.displayPage = page; break;



      // case 'diagappointment': this.displayPage = page; break;
      case 'appointment': this.displayPage = page; break;
      case 'samplecollectADV': this.displayPage = page; break;
      case 'testsandpackadv': this.displayPage = page; break;
      // case '': this.displayPage  = page; break;
      case 'pathrejected': this.displayPage = page; break;
      case 'pathcompleted': this.displayPage = page; break;
      case 'report_approval_pathology': this.displayPage = page; break;
      case 'pathpending': this.displayPage = page; break;
      case 'radio_report_upload': this.displayPage = page; break;
      case 'radiopending': this.displayPage = page; break;
      case 'radiorejected': this.displayPage = page; break;
      case 'radiocompleted': this.displayPage = page; break;
      case 'report_approval_radiology': this.displayPage = page; break;
      case 'micro_report_upload': this.displayPage = page; break;
      case 'micropending': this.displayPage = page; break;
      case 'microrejected': this.displayPage = page; break;
      case 'microcompleted': this.displayPage = page; break;
      case 'report_approval_microbiology': this.displayPage = page; break;
      case 'out-centre-list': this.displayPage = page; break;
      case 'out-payments-adv': this.displayPage = page; break;
      case 'diabetics_readings': this.displayPage = page; break;
      // case '': this.displayPage  = page; break;
      case 'billinglist': this.displayPage = page; break;
      case 'partialPayments': this.displayPage = page; break;
      case 'diagusers': this.displayPage = page; break;
      // case 'diagdashboard': this.displayPage = page; break;
      case 'diagpatient_list': this.displayPage = page; break;
      case 'diagreports': this.displayPage = page; break;
      case 'diagreference_video': this.displayPage = page; break;
      case 'diaghelp': this.displayPage = page; break;
      // physio

      case 'physioappointment':
        this.physiosubFlagsub = !this.physiosubFlagsub;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'physioHome': this.displayPage = page; break;
      case 'physiocasesheet': this.displayPage = page; break;
      case 'physioassignmentList': this.displayPage = page; break;
      case 'physioassignmentDetailPage': this.displayPage = page; break;
      case 'physiotherapies': this.displayPage = page; break;
      case 'physiouserList': this.displayPage = page; break;
      case 'physiouserViewPage': this.displayPage = page; break;
      case 'diagusers': this.displayPage = page; break;
      case 'physiobilling': this.displayPage = page; break;
      case 'Physiowalkin': this.displayPage = page; break;
      case 'physiobilldetails': this.displayPage = page; break;
      case 'physio-appointment': this.displayPage = page; break;
      // nurse

      case 'nurseMainMenu':
        this.nurseMainMenusub = !this.nurseMainMenusub;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'nurseappointment': this.displayPage = page; break;
      case 'nurseassignList': this.displayPage = page; break;
      case 'nursedoctorAppList': this.displayPage = page; break;
      case 'nursedoctorList': this.displayPage = page; break;
      case 'nurseinpatient': this.displayPage = page; break;
      // dietiMainMenu
      case 'dietiMainMenu':
        this.dietiMainMenusub = !this.dietiMainMenusub;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'DIappointment': this.displayPage = page; break;
      case 'DImanageappointments': this.displayPage = page; break;
      case 'DIpatientList': this.displayPage = page; break;
      case 'DIreadings': this.displayPage = page; break;
      case 'DIbillList': this.displayPage = page; break;
      case 'DInotification': this.displayPage = page; break;
      case 'DIreference_video': this.displayPage = page; break;
      //  pharmcy
      case 'Pharmacyorder':
        this.PharmacysubmenuFlag = !this.PharmacysubmenuFlag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'pharmaMainMenu':
        this.manageOrders = !this.manageOrders;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      // case 'pharmaMainMenu':this.displayPage = page; break;

      // case 'DIappointment':this.displayPage   = page; break;
      case 'pharmacycurentinformation': this.displayPage = page; break;
      case 'pharmacypastinformation': this.displayPage = page; break;
      case 'Pharmacybillestimate': this.displayPage = page; break;
      case 'Pharmacyinterdeptlist': this.displayPage = page; break;
      // case 'Pharmacystockmanagment':this.displayPage = page; break; 
      case 'Pharmacystockmanagment':
        this.PharmacysubmenuFlag1 = !this.PharmacysubmenuFlag1;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'Pharmacymanufacturer': this.displayPage = page; break;
      case 'pharmacysupplier': this.displayPage = page; break;
      case 'PharmacysupplierProduct': this.displayPage = page; break;
      case 'Pharmacydiscounts': this.displayPage = page; break;
      case 'Pharmacyproduct': this.displayPage = page; break;
      case 'pharmacyrequest': this.displayPage = page; break;
      case 'pharmacyresponse': this.displayPage = page; break;
      case 'Pharmacyinventory_receivables': this.displayPage = page; break;
      case 'PharmacystockStatusAdmin': this.displayPage = page; break;
      case 'PharmacyproductReturns': this.displayPage = page; break;
      case 'Pharmacysupppay': this.displayPage = page; break;
      case 'Pharmacyrackbin': this.displayPage = page; break;
      case 'Pharmacysrl': this.displayPage = page; break;
      // case 'Pharmacyreports':this.displayPage = page; break;
      case 'Pharmacyreports':
        this.PharmacyreportSubmenuFlag = !this.PharmacyreportSubmenuFlag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'Pharmacystock_status': this.displayPage = page; break;
      case 'Pharmacysales_report': this.displayPage = page; break;
      case 'Pharmacyreceivables_report': this.displayPage = page; break;
      case 'PharmacyproductReturnsReport': this.displayPage = page; break;
      case 'Pharmacyreferral': this.displayPage = page; break;
      case 'Pharmacyinward': this.displayPage = page; break;
      case 'inward': this.displayPage = page; break;
      case 'Pharmacyreference_video': this.displayPage = page; break;
      case 'Pharmacyhelp': this.displayPage = page; break;
      // case 'Pharmacyreferral':this.displayPage  = page; break;
      // master
      case 'DrugsName': this.DrugsNameFlag = !this.DrugsNameFlag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'Admin_Masters': this.Admin_MastersFlag = !this.Admin_MastersFlag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'Pharmacy_Masters': this.Pharmacy_MastersFlag = !this.Pharmacy_MastersFlag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'Lab_Masters': this.Lab_MastersFlag = !this.Lab_MastersFlag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;

      case 'DrugsNameType': this.displayPage = page; break;
      case 'CultureSensitivityDrug': this.displayPage = page; break;
      case 'addmaster': this.displayPage = page; break;
      case 'CulturePathology': this.displayPage = page; break;
      case 'Sampletype': this.displayPage = page; break;
      case 'DiagnosticDepartment': this.displayPage = page; break;
      case 'DiagnosticTube': this.displayPage = page; break;
      case 'DiagnosticReagent': this.displayPage = page; break;
      // case 'Accounthead': this.displayPage  = page; break;
      // case 'AccountSubhead': this.displayPage = page; break;
      case 'Stockexchange': this.displayPage = page; break;
      case 'StoreName': this.displayPage = page; break;
      case 'GenericName': this.displayPage = page; break;
      case 'ScheduleName': this.displayPage = page; break;
      case 'ProductForm': this.displayPage = page; break;
      case 'Sub-Deparmentc': this.displayPage = page; break;
      case 'addtemplate': this.displayPage = page; break;
      case 'inPatientViewInfo': this.displayPage = page; break;
      case 'billdetail': this.displayPage = page; break;
      case 'inpatient_Discharge_view': this.displayPage = page; break;
      case 'viewpres': this.displayPage = page; break;
      case 'docappcreate': this.displayPage = page; break;
      case 'docpresnocase': this.displayPage = page; break;
      case 'receptviewprescription': this.displayPage = page; break;
      case 'editpres': this.displayPage = page; break;
      case 'CultureSensitivityDrugName': this.displayPage = page; break;
      case 'UserRole': this.displayPage = page; break;
      case 'Surgery-Equipments': this.displayPage = page; break;
      case 'visitpurpose': this.displayPage = page; break;
      case 'diag_app_create': this.displayPage = page; break;
      case 'appedit': this.displayPage = page; break;
      case 'diagnosisview': this.displayPage = page; break;
      case 'specimen-report-template': this.displayPage = page; break;
      case 'report_upload_edit': this.displayPage = page; break;
      // case 'report_approval_detailed': this.displayPage = page; break;
      case 'manufacturer': this.displayPage = page; break;
      case 'purchasereqlist': this.displayPage = page; break;
      case 'billedit': this.displayPage = page; break;
      case 'ipbillcreate': this.displayPage = page; break;
      case 'receptionistdoctordetailview': this.displayPage = page; break;
      case 'interdept': this.displayPage = page; break;
      case 'diagPackageCreate': this.displayPage = page; break;
      case 'out-centre': this.outsubFlag = false; this.displayPage = page; break;
      case 'out-test-map': this.displayPage = page; break;
      case 'BillCreditTypes': this.displayPage = page; break;
      case 'Surgery_equipments': this.displayPage = page; break;
      case 'purchasereqlist': this.displayPage = page; break;
      case 'bed_occupancypharm': this.displayPage = page; break;
      case 'abc_analysispharm': this.displayPage = page; break;
      case 'wardtall': this.displayPage = page; break;
      case 'patreg': this.displayPage = page; break;
      case 'warddetails': this.displayPage = page; break;
      case 'OTmaster': this.displayPage = page; break;
      case 'wardmaster': this.displayPage = page; break;
      case 'manageappointmentst': this.displayPage = page; break;
      case 'diagtestaddadv': this.displayPage = page; break;
      case 'diagtestadd': this.displayPage = page; break;
      case 'RateCard': this.displayPage = page; break;
      case 'gramstains': this.displayPage = page; break;
      case 'ordercreate': this.displayPage = page; break;
      case 'billestimate': this.displayPage = page; break;
      case 'report_approval_detailed': this.displayPage = page; break;
      case 'assertion': this.displayPage = page; break;
      case 'adminpermission': this.permissionSubmenuFlag = !this.permissionSubmenuFlag;
        if (this.minimized_asied == true) {
          this.minimizing();
        }
        break;
      case 'specimenaccessioner': this.displayPage = page; break;
      case 'out-tests': this.outsubFlag = false; this.displayPage = page; break;
      case 'DoctorAppDetail': this.displayPage = page; break;
      case 'pharmacymoduleorderdetailview': this.displayPage = page; break;
      case 'ConductionDoctor': this.displayPage = page; break;
      case 'ConductionDoctorSurgery' : this.displayPage = page; break;
      case 'Sales_report_pharma': this.displayPage = page; break;
      case 'diaAppCreate': this.displayPage = page; break;
      case 'appcreate': this.displayPage = page; break;
      case 'dietappointmentscreate': this.displayPage = page; break;
      case 'manageclient': this.displayPage = page; break;
      case 'viewIpatient': this.displayPage = page; break;
      case 'Patients': this.displayPage = page; break;
      case 'medicineTracker': this.displayPage = page; break;    
      case 'inpdischargeview': this.displayPage = page; break;
      case 'casesheet': this.displayPage = page; break;
      case 'casesheet_list': this.displayPage = page; break;
      case 'other_expenses': this.displayPage = page; break;
      case 'log_rep': this.displayPage = page; break;

    }
    customjs.SelectMenuFun(this.selectedMenu, "Admin");

  }

  minimizing() {
    this.minimized_asied = !this.minimized_asied;
    if (this.minimized_asied == true) {
      this.asied_width.nativeElement.setAttribute('style', 'width:3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style', 'width:97%;margin-left: 3%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style', 'padding: 12px 0 0 3px;}');
      this.docInpatSubmenuFlag = false;
      this.Docsubmenu_flag = false;
      this.fullMenu = false;
      this.daig_mainMenu = false;
      this.physiosubFlagsub = false;
      this.nurseMainMenusub = false;
      this.dietiMainMenusub = false;
      this.manageOrders = false;
      this.tatsubmenuFlag1 = false;
      this.DrugsNameFlag = false;
      this.submenuFlag1 = false;
      this.submenu_flag = false;
      this.billingFlag = false;
      this.submenuFlagb = false;
      this.reportsSubmenuFlag = false;
    } else {
      this.asied_width.nativeElement.setAttribute('style', 'width:17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.right_width.nativeElement.setAttribute('style', 'width:83%;margin-left: 17%; transition: all 0.3s ease-in-out;animation-delay: 15s;');
      this.logo.nativeElement.setAttribute('style', 'padding: 17px 0 0 14px;}');
    }
  }

  onResize(event) {
    this.asied_width.nativeElement.removeAttribute('style');
    this.right_width.nativeElement.removeAttribute('style');
    this.logo.nativeElement.removeAttribute('style');
    this.minimized_asied = false;
  }

  openMenumodel() {
    this.minimized_asied = false;
    customjs.openMobileMenuFun();
  }

  menuModelAction(action) {
    if (action == 'profile_update' || action == 'diagnosis_home' || action == 'profile') {
      if (this.loginType == "Admin") {
        this.menuNavAdmin(action, "");
      } else if (Helper_Class.getInfo().user_type == 'Chief pathologist' || Helper_Class.getInfo().user_type == 'Pathologist'
        || Helper_Class.getInfo().user_type == 'Chief lab technician' || Helper_Class.getInfo().user_type == 'Lab technician') {
        this.menuNav('users_view');

      } else if (this.loginType == "front-desk") {
        //do nothing
      } else {
        this.menuNav(action);
      }

    } else if (action == 'changePassword') {
      const dialogRef = this.dialog.open(ChangePasswordComponent, {
        width: '33%',
        height: '275px',
        data: { type: this.sendType }
      });

    } else if (action == 'terms_conditions') {
      const dialogRef = this.dialog.open(TermsConditionComponent, {
        width: '50%',
        height: '500px',
        data: {}
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result["key"] == 1) {
        } else {
          this.toastr.error(Message_data.defaultErr);
        }
      });

    } else {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
        JSON.stringify({
          type: this.sendType,
          user: this.userInfo.user_id
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.toastr.success(Message_data.logOut);
            localStorage.clear();
            this.router.navigateByUrl('/loginpage');
          },
          error => { });
    }
    $('#exampleModal').modal('hide');
  }

  setDoctor(e) {
    FrontDesk_Helper.setDate(undefined);
    if (e == "prescriptionlist") {
      FrontDesk_Helper.setDocPrescription(undefined);
      FrontDesk_Helper.setDate(this.currentDate);
    } else if (e == "manageappointments") {
      FrontDesk_Helper.setdoctor(undefined);
      FrontDesk_Helper.setDietician(undefined);
      FrontDesk_Helper.setDate(this.currentDate);
    }
  }

  setFrontDeskAppoinmentHelper(e) {
    if (e == "billing") {
      FrontDesk_Helper.setBillFromDate(this.currentDate);
      FrontDesk_Helper.setBillToDate(this.currentDate);
    }
  }
  setValue() {
    pharmacy_helper.setManufacturer(false);
    pharmacy_helper.setSupplier(false);
  }

  setAppFlow(data) {
    Helper_Class.setPartialUser(data);
  }

  setReport(reports) {
    Helper_Class.setReportType(undefined);
    Helper_Class.setReportType(reports);
  }

  setReportType(data) {
    Diagnosis_Helper.setReportType(data);
  }

  //admin
  setAdminAppoinmentHelper(e) {
    if (e == "billing") {
      FrontDesk_Helper.setBillFromDate(this.currentDate);
      FrontDesk_Helper.setBillToDate(this.currentDate);
      var bill = {
        edit: "55"
      }
      FrontDesk_Helper.setBillFlag(bill);
    }
  }
  isStoppedSpeechRecog = false;
  public text = '';
  tempWords: any;
  Name: string;
  // text:string;
  arr = []

  init() {
    this.recognition.interimResults = true;
    this.recognition.lang = 'en-US';

    this.recognition.addEventListener('result', (e: { results: Iterable<unknown> | ArrayLike<unknown>; }) => {
      const transcript = Array.from(e.results)
        .map((result: any) => result[0])
        .map((result) => result.transcript)
        .join('');
      this.tempWords = transcript.toLowerCase().trim();
      console.log("transcript" + transcript);
    });
  }

  start() {
    const constraints = { audio: true }; // Constraints object for getUserMedia()
    this.isStoppedSpeechRecog = false;

    // Request access to user's microphone
    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        // Enumerate available devices
        navigator.mediaDevices.enumerateDevices()
          .then((devices) => {
            const audioDevices = devices.filter((device) => device.kind === 'audioinput');
            if (audioDevices.length > 0) {
              const selectedDeviceId = audioDevices[0].deviceId; // Select the first available microphone
              this.startRecognition(selectedDeviceId); // Start recognition with the selected microphone
            } else {
              console.error('No audio input devices found.');
            }
          })
          .catch((error) => {
            console.error('Error enumerating devices:', error);
          });
      })
      .catch((error) => {
        console.error('Error accessing microphone:', error);
      });
  }

  startRecognition(deviceId: string) {
    const recognition = new webkitSpeechRecognition();
    recognition.interimResults = true;
    recognition.lang = 'en-US';
    recognition.start();
    console.log("Speech recognition started")

    recognition.addEventListener('end', (condition: any) => {
      if (this.isStoppedSpeechRecog) {
        recognition.stop();
        console.log("End speech recognition");
      } else {
        this.wordConcat();
        console.log("wordConcat");
        this.startRecognition(deviceId); // Restart recognition with the selected microphone
      }
    });

    recognition.addEventListener('result', (e: { results: Iterable<unknown> | ArrayLike<unknown>; }) => {
      const transcript = Array.from(e.results)
        .map((result: any) => result[0])
        .map((result) => result.transcript)
        .join('');
      this.tempWords = transcript.toLowerCase().trim();
      console.log("transcript" + transcript);
    });
  }

  wordConcat() {
    if (this.tempWords != undefined && this.tempWords != "") {
      this.text = this.tempWords
      this.speechRecognition.sendMessage(this.tempWords)

      if (this.text === "login") {
        this.router.navigateByUrl("loginpage")
      }
      if (this.text === "privacy policy") {
        this.router.navigateByUrl("privacy_policy")
      }
      if (this.text === "registration") {
        this.router.navigateByUrl("registration")
      }
    }
    this.tempWords = '';
  }

  stopregpp() {
    this.isStoppedSpeechRecog = true;
    this.speechRecognition.stopMessages()
    console.log("Speech recognition stoped")
  }

  MenuModelAction(action) {
    if (action == 'pharmacyhome') {
      this.menuNav(action);
    } else if (action == 'changePassword') {
      const dialogRef = this.dialog.open(ChangePasswordComponent, {
        width: '33%',
        height: '275px',
        data: {}
      });
    }
    else {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/logout/',
        JSON.stringify({
          type: "doctor",
          user: this.userInfo.user_id
        }),
        { headers: headers })
        .subscribe(
          response => {
            this.toastr.success("Logout successfully" + name)
            localStorage.clear();
            this.router.navigateByUrl('/loginpage');
          },
          error => { });
    }
    $('#exampleModal').modal('hide');
  }

}
