import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Time_Formate_Session, Day_Return, Session_Return } from '../../../assets/js/common';
import { ToastrService } from 'ngx-toastr';
import { Message_data } from '../../../assets/js/Message_data';
import { Helper_Class } from '../../helper_class';
import { Date_Formate } from '../../../assets/js/common.js';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { ipaddress } from '../../ipaddress' ;import { ClientCancelAppointmentComponent } from 'src/app/client/client-cancel-appointment/client-cancel-appointment.component';
import { MatDialog } from '@angular/material/dialog';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-receptionistdoctordetailview',
  templateUrl: './receptionistdoctordetailview.component.html',
  styleUrls: ['./receptionistdoctordetailview.component.css']
})
export class ReceptionistdoctordetailviewComponent implements OnInit {
  @ViewChild('recognitionResult') recognitionResult: ElementRef;
  @ViewChild('printlogowithname') printlogowithname: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  isReadonly() { return true; }
  public cuur_mor_sess;
  public cuur_afetr_sess;
  public cuur_eve_sess;
  public gender_print;
  public patientid;
  public currentDate;
  public genderflag:boolean = true;
  public ageflag:boolean = true;
  public ageAndGender;
  public refflag:boolean=false;
  public reassignBtnFlag: boolean;
  public reassignFlag: boolean;
  public sendDoctorName;
  public tokenvalue;
  public doctorList = [];
  public templist = [];
  public sessionTempList = [];
  public tokenOrTime;
  public timeSplit;
  public assgDoctorConsList = [];
  public nonAvailData;
  public visitingSessionData = [];
  public typeOrTime;
  public unavailableData;
  public doctorConsultSessionList = [];
  public appTypeByTokenFlag;
  public appTypeByTimeFlag
  public bookNowFlag;
  public session;
  public unavailableFlag: boolean;
  public appointmentTime;
  public tempTime;
  public doctor;
  public doctorId;
  public sendRescheduleData;
  public cancelFlag;
  public currentTime;
  public videoFlag;
  public clientRegId: string;
  public patientId: string;
  public clntName: string;
  public doctorName: string;
  public appDate: string;
  public appType: string;
  public appSession: string;
  public hospName: string;
  public hospAddress: string;
  public hospLocation: string;
  public hospCity: string;
  public hospState: string;
  public printTemplate: string;
  public hptlPresLogo: string;
  public hospLogo: string;
  public patientAddress: string;
  public referredDoctor: string="";
  public fromDaySplit;
  public toDaySplit;
  public appointmentDate;
  public tempCurrentDate;
  public tempCurrGetFTime;
  public timeList = [];
  public availableFlag;
  public hospitalName;
  public availableSessionFlag: boolean;
  public doctorAvailableFlag: boolean;
  public doctorNonAvailableFlag: boolean;
  public getPatientDetails;
  public userInfo;
  public currentMinDate;
  public firstName;
  public middleName;
  public lastName;
  public clientId;
  public relationId;
  public subRelId;
  public relationType;
  public age;
  public gender;
  public bloodGroup;
  public fatherOrGuardian;
  public income;
  public occupation;
  public contactNo;
  public symptom;
  public treatmentType;
  public specialization;
  public hospInfo;
  public appInfo;
  public splId;
  public sendDataForDoctdetail;
  public dayOfWeek;
  public rescheduleFlag:boolean;
  public normalRescheduleFlag:boolean =  false;
  public reschdApptDate;
  public sendDataForDocAvail;
  public reschedAppType;
  public appTypeTokenFlag;
  public timeorTokenFlag;
  public visitHomeSession;
  public visitSession;
  public normalDoctorList = [];
  public doctorAvailListData = [];
  public reschdVisitingSessionData = [];
  public homeSessionData = [];
  public reschdAppointmentTime;
  public rescheduleReason: string;
  public salutation:string;

  constructor(public dialog:MatDialog,public datepipe: DatePipe, public gservice: CommonDataService, public http: Http, public toastr: ToastrService, public messageService: MenuViewService) {
    this.reassignBtnFlag = true;
    this.bookNowFlag = true;
    this.cancelFlag = true;
    this.videoFlag = true;
    this.reassignFlag = true;
    this.unavailableFlag = true;
    this.appTypeByTokenFlag = true;
    this.appTypeByTimeFlag = true;
    this.rescheduleFlag = false
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.printTemplate = Helper_Class.getInfo().pres_print_template;  
   
    this.getPatientDetails = FrontDesk_Helper.getreceptionistcreatepage();
    this.getCurrentDate();
    this.getAppointmentDetails();
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
            this.currentTime = obj.current_time;
            this.currentMinDate = obj.current_date;
            this.reschdApptDate = this.currentDate;
            var get_date = obj.current_date.split('-');
            this.appointmentDate = get_date[2] + "-" + get_date[1] + "-" + get_date[0]
          }
        },error => {});
  }

  getAppointmentDetails() {
    function StringBuilder(this: any, value) {
      this.strings = new Array("");
      this.append(value);
    }
    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }
    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }
    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/viewapp/',
      JSON.stringify({
        doc_app_id: this.getPatientDetails.doc_app_id,
        type: "doctor",
        home_care: "0",
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("doctor ---"+JSON.stringify(obj))
          if (obj != null && obj.specialization_id != null) {
            this.splId = obj.specialization_id;
          }
          if (obj.first_name != null) {
            if(obj.salutation != undefined){
              this.salutation=obj.salutation;
            } else {
              this.salutation="";
            }
            this.patientId = obj.patient_id;
            this.patientid= obj.patient_id;
            this.doctorId = obj.doc_id;
            this.clientRegId = obj.client_reg_id;
            this.firstName = encrypt_decript.Decript(obj.first_name);
            if (obj.middle_name != null && obj.middle_name != undefined) {
              this.middleName = encrypt_decript.Decript(obj.middle_name);
            }
            this.lastName = encrypt_decript.Decript(obj.last_name);
            if (obj.middle_name != null && obj.middle_name != undefined) {
              localStorage.setItem("client_name", obj.first_name + " " + obj.middle_name + " " + obj.last_name);
              this.clntName =this.salutation+" "+encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
            } else {
              localStorage.setItem("client_name", obj.first_name + " " + obj.last_name);
              this.clntName =this.salutation+" "+encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
            }
            this.clientId = obj.client_reg_id;
            this.relationId = obj.relation_id;
            if (obj.sub_rel_id != null) {
              this.subRelId = obj.sub_rel_id;
            }
            if (obj.dob != null) {
              localStorage.setItem("Case_Clnt_dob", obj.dob);
            }
           
            this.relationType = obj.relation;
            if(obj.age != undefined){
              this.ageflag= true
              this.age = obj.age;
            }
            var gendervalue=encrypt_decript.Decript(obj.gender)
            if(gendervalue != undefined){
              this.genderflag= true
              this.gender = gendervalue;
              if(this.gender == "Male"){
                this.gender_print = "M"
              }else if(this.gender == "Female"){
                this.gender_print = "F"
              }else{
                this.gender_print = "T"
              }
            }   
           
            if (this.ageflag && this.genderflag) {
              this.ageAndGender = this.age + "/" + this.gender;
            } else if (this.ageflag) {
              this.ageAndGender = this.age;
            } else if (this.genderflag) {
              this.ageAndGender = this.gender;
            } else {
              this.ageAndGender = "";
            }                    
            localStorage.setItem("Age_data", this.age);
            if (obj.blood_desc != null) {
              this.bloodGroup = obj.blood_desc;
            }
            if (obj.guardian != null) {
              this.fatherOrGuardian = encrypt_decript.Decript(obj.guardian);
            }
            if (obj.income != null) {
              this.income = encrypt_decript.Decript(obj.income);
            }
            if (obj.occupation != null) {
              this.occupation = encrypt_decript.Decript(obj.occupation);
            }
            this.contactNo = encrypt_decript.Decript(obj.mobile);
            if (obj.symptoms != null) {
              this.symptom = obj.symptoms;
            }
            this.treatmentType = obj.medicare_name;
            if (obj.specialization != null) {
              this.specialization = obj.specialization;
            }
            // console.log("ref yjghiuhg"+JSON.stringify(obj.ref_by))
            if (obj.ref_by && obj.ref_by !== "null") {
              this.refflag=true
              this.referredDoctor = obj.ref_by;
            }else{
              this.refflag=false
            }
            this.doctorName ="Dr."+obj.dr_middle_name != undefined && obj.dr_middle_name != null && obj.dr_middle_name != '' ? obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name : obj.dr_first_name + " " + obj.dr_last_name;
            if (obj.dr_middle_name != null && obj.dr_middle_name != undefined) {
              localStorage.setItem("doctor_name", obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name);
            } else {
              localStorage.setItem("doctor_name", obj.dr_first_name + " " + obj.dr_last_name);
            }
            if (obj.hptl_id != null) {
              localStorage.setItem("hptl_clinic_id", obj.hptl_id);
            }
            localStorage.setItem("hospital_name", obj.hptl_name);
            this.hospName = obj.hptl_name;
            this.hospLogo = ipaddress.Ip_with_img_address + obj.hptl_logo;
            if (obj.hptl_pres_logo != undefined) {
              this.hptlPresLogo = ipaddress.Ip_with_img_address + obj.hptl_pres_logo;
              localStorage.setItem("hptl_pres_logo", this.hptlPresLogo);
            }
            if (obj.bg_image != undefined) {
              localStorage.removeItem("bg_image");
              localStorage.setItem("bg_image", ipaddress.Ip_with_img_address + obj.bg_image);
            }
            if (obj.address2 != null && obj.address2 != "") {
              localStorage.setItem("address_data", obj.address1 + " " + obj.address2);
              this.hospAddress = obj.address1 + " " + obj.address2;
            } else {
              localStorage.setItem("address_data", obj.address1);
              this.hospAddress = obj.address1;
            }
            localStorage.setItem("Locatiion", obj.location);
            this.hospLocation = obj.location;
            localStorage.setItem("city_zip", obj.city + " - " + obj.zipcode);
            this.hospCity = obj.city + " - " + obj.zipcode;
            localStorage.setItem("state_country", obj.state + " , " + obj.country);
            this.hospState = obj.state + " , " + obj.country;
            if (obj.middle_name != null && obj.middle_name != undefined) {
              localStorage.setItem("client_name", encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name));
            } else {
              localStorage.setItem("client_name", encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name));
            }
            if (obj.hcm_country_id != null) {
              localStorage.setItem("clt_country", obj.hcm_country_id)
            }
            if (obj.hcm_state_id != null) {
              localStorage.setItem("clt_state", obj.hcm_state_id)
            }
            if (obj.hcm_city_id != null) {
              localStorage.setItem("clt_city", obj.hcm_city_id)
            }
            if (obj.hcm_location_id != null) {
              localStorage.setItem("clt_location", obj.hcm_location_id)
            }
            if (obj.location != null) {
              localStorage.setItem("clt_location_desc", obj.location)
            }
            var sb = new StringBuilder("")
            if (obj.hptl_name != null) {
              sb.append(obj.hptl_name);
              sb.append("\n");
              sb.append(obj.hosp_location);
              sb.append("\n");
              sb.append(obj.hosp_city);
              sb.append("\n");
              this.hospInfo = sb.toString();
            }
            var clntsb = new StringBuilder("")
            var address;
            if (obj.rel_address1 != undefined && obj.rel_address2 != undefined) {
              if(obj.rel_address2 &&  obj.rel_address2 !== "null"){
                address = encrypt_decript.Decript(obj.rel_address1) + "," + encrypt_decript.Decript(obj.rel_address2);
              }else{
                address = encrypt_decript.Decript(obj.rel_address1)
              }
            } else if (obj.rel_address1 != undefined) {
              address = encrypt_decript.Decript(obj.rel_address1)
            }
            if (obj.rel_address1 != null) {
              this.patientAddress= address+","+obj.location_desc+","+obj.city_desc+","+obj.state_desc;
              // clntsb.append(address);
              // clntsb.append("\n");
              // clntsb.append(obj.location_desc);
              // clntsb.append("\n");
              // clntsb.append(obj.city_desc);
              // clntsb.append("\n");
              // clntsb.append(obj.state_desc);
              // clntsb.append("\n");
              // this.patientAddress = clntsb.toString();
            }
            var Appoint_data = new StringBuilder("");
            if (obj.app_type == "1") {
              Appoint_data.append("Appointment Type : New");
              Appoint_data.append("\n");
              this.appType = "New";
            } else {
              Appoint_data.append("Appointment Type : Follow-ups");
              Appoint_data.append("\n");
              this.appType = "Follow-up";
            }
            var date_txt = new Date(obj.date);
            this.dayOfWeek = date_txt.getDay() + 1;
            var get_date = obj.date.split('-');
            this.appDate = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
            Appoint_data.append("Date : " + get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
            Appoint_data.append("\n");
            Appoint_data.append("Session : " + encrypt_decript.Decript(obj.session).toString());
            Appoint_data.append("\n");
            this.appSession = encrypt_decript.Decript(obj.session).toString();      

            if( obj.token_count == "false"){
              if (obj.token != undefined) {
                Appoint_data.append("Token no : " + obj.token);
                Appoint_data.append("\n");
              } else {        
                // Appoint_data.append("Token no : " + " ");
                // Appoint_data.append("\n");
              }
            }else{          
              if (obj.token_count == "true") {
                if (obj.token != undefined) {
                  Appoint_data.append("Token no : " + obj.token);
                  Appoint_data.append("\n");
                  this.tokenvalue ="Token"+" "+obj.token;
                }
              } else {
                var Temptimesplit = obj.f_time;
                var offTime = Temptimesplit.split(':');
                offTime[0] = offTime[0] % 12;
                var output = offTime.join(':');
                var get_time = output.split(":");
                if (get_time[0] == "0") {
                  output = "12" + ":" + get_time[1];
                }
                Appoint_data.append("Time : " +
                  Time_Formate_Session(output, encrypt_decript.Decript(obj.session)));
                Appoint_data.append("\n");
                this.tokenvalue ="At"+" "+output;
              }
             
              }
              if(this.tokenvalue != undefined) {
                this.appInfo = this.appType+" "+"Appointment on"+" "+this.appDate+" "+encrypt_decript.Decript(obj.session)+" "+this.tokenvalue;
              } else{
                this.appInfo = this.appType+" "+"Appointment on"+" "+this.appDate+" "+encrypt_decript.Decript(obj.session);
              }      
          //  this.appInfo = Appoint_data.toString();
            if (obj.status == "0") {
              this.toastr.error(Message_data.completedApptCantedit);
            } else {
              this.reassignBtnFlag = false;
              this.cancelFlag = false;
              this.videoFlag = false;
              this.rescheduleFlag =  true;
            }
          } else {
            this.toastr.error(Message_data.getNetworkMessage());
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  reassign() {
    this.reassignFlag = false;
    this.getCurrentDate();
    this.getDoctors();
    this.reassignBtnFlag = true;
    this.rescheduleFlag = false;
  }

  back() {
    this.messageService.sendMessage("manageappointments");
  }

  getDoctors() {
    var ipaddress1 = Helper_Class.getIPAddress();
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/docbyrep',
      JSON.stringify({
        country: ipaddress.country_code,
        spl_id: this.splId,
        reg_id: this.userInfo.user_id,
        imei: ipaddress1,
        type: "front-desk",
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          for (var i = 0; i < obj.doctors.length; i++) {
            this.sendDoctorName = "";
            if (obj.doctors[i].middle_name != "" && obj.doctors[i].middle_name != undefined) {
              this.sendDoctorName = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
            } else {
              this.sendDoctorName = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
            }
            this.nonAvailData = [];
            if (obj.doctors[i].non_avail != null) {
              this.nonAvailData = obj.doctors[i].non_avail;
            }
            this.doctorList.push({
              Doc_Name: this.sendDoctorName,
              Doc_ID: obj.doctors[i].prov_id
            });
            this.templist = [];
            this.sessionTempList = [];
            this.tokenOrTime = "";
            if (obj.doctors[i].consultation != undefined) {
              for (var j = 0; j < obj.doctors[i].consultation.length; j++) {
                this.tokenOrTime = obj.doctors[i].consultation[j].token_count == "0" ? "time" : "token";
                this.timeSplit = new Array();
                this.timeSplit = obj.doctors[i].consultation[j].available_from.split(':');
                this.timeSplit = new Array();
                this.timeSplit = obj.doctors[i].consultation[j].available_to.split(':');
                this.templist.push({
                  Workday: Day_Return(obj.doctors[i].consultation[j].work_days),
                  Session: Session_Return(obj.doctors[i].consultation[j].day_session),
                  Avail_from: this.gservice.get_Timeformate(obj.doctors[i].consultation[j].available_from),
                  Avail_to: this.gservice.get_Timeformate(obj.doctors[i].consultation[j].available_to),
                  Time_Duration: obj.doctors[i].consultation[j].time_duration
                });
                this.sessionTempList.push(Session_Return(obj.doctors[i].consultation[j].day_session));
              }
            }
            this.assgDoctorConsList.push({
              Doc_ID: obj.doctors[i].prov_id,
              Cosultation: this.templist,
              Tokentime: this.tokenOrTime,
              Sessionlist: this.sessionTempList,
              NonAvail: this.nonAvailData,
            });
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changeDoctorForReassign(doctor) {
    this.doctorConsultSessionList = [];
    this.visitingSessionData = [];
    this.unavailableData = [];
    this.appTypeByTokenFlag = true;
    this.appTypeByTimeFlag = true;
    this.bookNowFlag = false;
    if (doctor != null) {
      for (var i = 0; i < this.doctorList.length; i++) {
        if (this.doctorList[i].Doc_Name == this.doctor) {
          this.doctorId = this.doctorList[i].Doc_ID;
        }
      }
      for (var i = 0; i < this.assgDoctorConsList.length; i++) {
        if (this.doctorId == this.assgDoctorConsList[i].Doc_ID) {
          this.doctorConsultSessionList = this.assgDoctorConsList[i].Cosultation;
          this.typeOrTime = this.assgDoctorConsList[i].Tokentime;
          this.unavailableData = this.assgDoctorConsList[i].NonAvail;
          if (this.assgDoctorConsList[i].Sessionlist.indexOf("Morning") != -1) 
            this.visitingSessionData.push({ description: "Morning", id: "1" });
          if (this.assgDoctorConsList[i].Sessionlist.indexOf("Afternoon") != -1)
            this.visitingSessionData.push({ description: "Afternoon", id: "2" });
          if (this.assgDoctorConsList[i].Sessionlist.indexOf("Evening") != -1) 
            this.visitingSessionData.push({ description: "Evening", id: "3" });
          break;
        }
      }
    }
  }

  reschedule() {
    this.rescheduleFlag = false;
    this.sendDataForDoctdetail = {
        day: this.dayOfWeek,
        hptl_id: this.userInfo.hptl_clinic_id,
        doc_id: this.doctorId
    };
    this.getDoctorScheduleList();
    this.normalRescheduleFlag = true;
  }

  getDoctorScheduleList() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "appointment/getweekschedule/", JSON.stringify(this.sendDataForDoctdetail), { headers: headers }).subscribe(
      data => {
        console.log(">>>>>>>>>>>>>>>>>>>>>>>"+JSON.stringify(data))
        console.log(">>>>>>>>>>>>>>>>>>>>>>>"+JSON.stringify(data.json()))
        if (data.json().weekschedule != null && data.json().weekschedule.length != 0) {
          for (var k = 0; k < data.json().weekschedule.length; k++) {
          this.homeSessionData.push(this.gservice.get_Session(data.json().weekschedule[k].day_session));
            this.normalDoctorList.push({
              hospital: this.hospName,
            workdays: this.gservice.get_Day(data.json().weekschedule[k].work_days),
            available_from: this.gservice.get_Timeformate(data.json().weekschedule[k].available_from),
            available_to: this.gservice.get_Timeformate(data.json().weekschedule[k].available_to),
            day_session: this.gservice.get_Session(data.json().weekschedule[k].day_session),
              token_count: data.json().weekschedule[k].token_count,
            });
          }
          this.getDoctorAvailability(this.reschdApptDate);
        }
      },
      error => {});
  }

  getDoctorAvailability(current_date) {
    this.unavailableFlag = false;
    var today = new Date(current_date);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.sendDataForDocAvail = {
      day: today.getDay() + 1,
      hptl_id: this.userInfo.hptl_clinic_id,
      doc_id: this.doctorId,
      country: ipaddress.country_code
    }
    this.http.post(ipaddress.getIp + "appointment/getdoctime/", JSON.stringify(this.sendDataForDocAvail), { headers: headers }).subscribe(
      data => {
        if (data.json().non_available != null) {
          this.unavailableData = data.json().non_available;
        }
        if (data.json().available != null) {
          if (data.json().available != null && data.json().available[0] != null) {
            this.doctorAvailListData = data.json().available;
            this.homeSessionData = [];
            for (var i = 0; i < data.json().available.length; i++) {
              this.homeSessionData.push(this.gservice.get_Session(data.json().available[i].day_session));
            }
            if (this.doctorAvailListData[0].token_count != "0") {
              this.reschedAppType = "type";
              this.appTypeTokenFlag = true;
            } else {
              this.reschedAppType = "time";
              this.appTypeTokenFlag = false;
            }
           
            this.addSessionForReschd();
            this.checkDocAvailForCurrDateReschd(current_date, this.currentTime);
          } else {
            this.doctorAvailableFlag = false;
            this.doctorNonAvailableFlag = true;
          }
        }
      },
      error => {});
  }
  
  checkDocAvailForCurrDateReschd(curtdate, curttime) {
    this.availableFlag = false;
    for (var i = 0; i < this.doctorAvailListData.length; i++) {
      var Cur_timesplit = curttime.split(':');
      var Current_time = new Date();
      Current_time.setHours(Cur_timesplit[0]);
      Current_time.setMinutes(Cur_timesplit[1]);
      var Avtotimesplit = this.doctorAvailListData[i].available_to.split(':');
      var Retrieved_time = new Date();
      Retrieved_time.setHours(Avtotimesplit[0]);
      Retrieved_time.setMinutes(Avtotimesplit[1]);
      if (curtdate == this.currentDate) {
        if (Current_time < Retrieved_time) {
          this.availableFlag = true;
        }
      } else {
        this.availableFlag = true;
      }
    }
    if (this.availableFlag == false) {
      this.doctorAvailableFlag = false;
      this.doctorNonAvailableFlag = true;
    } else {
      this.doctorAvailableFlag = true;
      this.doctorNonAvailableFlag = false;
      this.changeVisitSessionForReschd(this.visitSession, curtdate);
    }
  }

  changeVisitSessionForReschd(session, appdate) {
    this.unavailableFlag = false;
    if (session != null) {
      this.timeorTokenFlag = true;
      if (this.reschedAppType == "time") {
        for (var i = 0; i < this.doctorAvailListData.length; i++) {
          if (session == this.gservice.get_Session(this.doctorAvailListData[i].day_session)) {
            var From_Split = this.doctorAvailListData[i].available_from.split(':');
            var To_Split = this.doctorAvailListData[i].available_to.split(':');
            var fromday = new Date();
            var parts = appdate.split('-');
            var curr_datetime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.currentTime);
            var curr_get_ftime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.doctorAvailListData[i].available_from);
            fromday.setHours(From_Split[0]);
            fromday.setMinutes(From_Split[1]);
            var today = new Date();
            today.setHours(To_Split[0]);
            today.setMinutes(To_Split[1]);
            this.timeList = [];
            var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
            var chk_time = this.datepipe.transform(fromday, 'hh:mm');
            var end_time = this.datepipe.transform(today, 'hh:mm');
            this.availableFlag = this.checkDocNotAvailableForReschd(appdate, session.description, chk_time, end_time);
            if (this.availableFlag == true && this.currentDate == this.datepipe.transform(appdate, 'dd-MM-yyyy') && curr_get_ftime > curr_datetime) {
              this.timeList.push(New_time1);
            } else {
              if (this.availableFlag == true && this.currentDate != this.datepipe.transform(appdate, 'dd-MM-yyyy')) {
                this.timeList.push(New_time1);
              }
            }
            while (fromday < today) {
              var Day_Now = new Date();
              if (this.currentDate == this.datepipe.transform(appdate, 'dd-MM-yyyy')) {
                var Now_Split = this.currentTime.split(':');
                Day_Now.setHours(Now_Split[0]);
                Day_Now.setMinutes(Now_Split[1]);
              } else {
                Day_Now.setHours(From_Split[0]);
                Day_Now.setMinutes(From_Split[1]);
              }
              fromday.setMinutes(fromday.getMinutes() + parseInt(this.doctorAvailListData[i].time_duration));
              var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
              var Avail_flag = this.checkDocNotAvailableForReschd(appdate, session.description, chk_time1, end_time);
              if (Avail_flag == true && Day_Now <= fromday && fromday < today) {
                var New_time = this.datepipe.transform(fromday, 'hh:mm a');
                if (New_time != this.gservice.get_Timeformate(this.doctorAvailListData[i].available_to)) {
                  this.timeList.push(New_time);
                }
              }
            }
            break;
          }
        }
      }
    } else {
      this.timeorTokenFlag = false;
    }
  }

  checkDocNotAvailableForReschd(get_sel_date, sess, f_time, t_time) {
    var Available_flag = true;
    var get_date = get_sel_date.split('-');
    var check_date = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
    if (this.unavailableData.length != 0) {
      for (var i = 0; i < this.unavailableData.length; i++) {
        if (this.unavailableData[i].full_day != null) {
          var Start_Date = new Date(this.unavailableData[i].from_date);
          var End_Date = new Date(this.unavailableData[i].to_date);
          if (check_date >= Start_Date && check_date <= End_Date) {
            Available_flag = false;
            break;
          }
        } else {
          if (sess != null) {
            var From_Split = this.unavailableData[i].from_time.split(':');
            var To_Split = this.unavailableData[i].to_time.split(':');
            var t1 = new Date(check_date);
            t1.setHours(From_Split[0]);
            t1.setMinutes(From_Split[1]);
            var t2 = new Date(check_date); 
            t2.setHours(To_Split[0]);
            t2.setMinutes(To_Split[1]);
            var get_f_time = this.gservice.get_time_form_session(f_time, this.gservice.get_seesion_ampm(sess));
            var get_t_time = this.gservice.get_time_form_session(t_time, this.gservice.get_seesion_ampm(sess))
            var get_time1 = this.convertTimeformat("24", get_f_time);
            var get_time2 = this.convertTimeformat("24", get_t_time);
            var From_Split1 = get_time1.split(':');
            var To_Split1 = get_time2.split(':');
            var get_f_time1:any = get_time1.split(':');
            var get_f_time2:any = get_time2.split(':');
            var f_time1 = new Date(check_date);
            f_time1.setHours(get_f_time1[0]);
            f_time1.setMinutes(get_f_time1[1]);
            var get_chk_date = this.datepipe.transform(check_date, 'dd-MM-yyyy');
            var get_f_date = this.datepipe.transform(this.unavailableData[i].from_date, 'dd-MM-yyyy');
            var get_t_date = this.datepipe.transform(this.unavailableData[i].from_date, 'dd-MM-yyyy');
            if (get_chk_date.toString() == get_f_date.toString() && sess == this.unavailableData[i].day_session || get_chk_date.toString() == get_t_date.toString() && sess == this.unavailableData[i].day_session) {
              if (f_time1 > t1 && f_time1 < t2) {
                Available_flag = false;
              }
              if (f_time1 == t1 && f_time1 <= t2) {
                Available_flag = false;
              }
              if (f_time1 >= t1 && f_time1 == t2) {
                Available_flag = false;
              }
              if (f_time1 == t1 && f_time1 == t2) {
                Available_flag = false;
              }
            }
            if (get_chk_date.toString() == get_f_date.toString() && get_chk_date.toString() == get_t_date.toString() && sess == this.unavailableData[i].day_session) {
              if (f_time1 > t1 && f_time1 < t2) {
                Available_flag = false;
              }
              if (f_time1 == t1 && f_time1 < t2) {
                Available_flag = false;
              }
              if (f_time1 > t1 && f_time1 == t2) {
                Available_flag = false;
              }
              if (f_time1 == t1 && f_time1 == t2) {
                Available_flag = false;
              }
            }
          }
        }
      }
    }
    return Available_flag;
  }

  bookForReschedule() {
    if (this.rescheduleReason != undefined && this.rescheduleReason.length != 0) {
      var gettime = this.currentTime.split(':');
      var appDate = (this.reschdApptDate);
      var appdatenew = this.reschdApptDate;
      var mrg_flag = "", aft_flag = "", eve_flag = "";
      if (gettime[0] < 12) {
        mrg_flag = "Morning";
        aft_flag = "Afternoon";
        eve_flag = "Evening";
      } else if (gettime[0] >= 12 && gettime[0] <= 16) {
        mrg_flag = "";
        aft_flag = "Afternoon";
        eve_flag = "Evening";
      } else if (gettime[0] >= 16 && gettime[0] <= 24) {
        mrg_flag = "";
        aft_flag = "";
        eve_flag = "Evening";
      }
      if (this.currentDate == appdatenew) {
        if (this.appSession == "Morning" && this.visitSession == mrg_flag) {
          this.toastr.error(Message_data.sltValidSession);
        } else if (this.appSession == "Afternoon" && this.visitSession == aft_flag) {
          this.toastr.error(Message_data.sltValidSession);
        } else if (this.appSession == "Evening" && this.visitSession == eve_flag) {
          this.toastr.error(Message_data.sltValidSession);
        } else {
          this.book();
        }
      } else {
        this.book();
      }
    } else {
      this.toastr.error(Message_data.reasonForReschedule);
    }
  }

  book() {
    var valid_flag = false;
    var Date_splitter = (this.reschdApptDate).split('-');
    var send_data;
    var url;
    if (this.reschedAppType == "type") {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      url = "appointment/doctoken/";
      send_data = {
        hospital_id: this.userInfo.hptl_clinic_id,
        doc_reg_id: this.doctorId,
        date: this.reschdApptDate,
        session: encrypt_decript.Encript(this.visitSession).toString()
      }
    } else {
      url = "appointment/getappexist/";
      if (this.reschdAppointmentTime == undefined) {
        this.toastr.error(Message_data.sltTime);
        valid_flag = true;
      } else {
        var Temptime = this.reschdAppointmentTime.split(' ');
        send_data = {
          type: "doctor",
          doc_reg_id:  this.doctorId,
          appointment_date: this.gservice.get_Date_Formate((this.reschdApptDate)),
          session: encrypt_decript.Encript(this.visitSession).toString(),
          from_time: Temptime[0] + ":00"
        }
      }
    }
    if (valid_flag == false) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + url,
        JSON.stringify(
          send_data
        ), { headers: headers }).subscribe(
          data => {
            if (data.json().result != null) {
              if (this.reschedAppType == "type") {
                if (data.json().result != "true") {
                  this.toastr.error(Message_data.sryApptClosedForSession);
                  valid_flag = false;
                }
                else {
                  this.finalRescheduleBooking();
                }
              }
              else 
              {
                if (data.json().result != true) {
                  this.finalRescheduleBooking();
                }
                else {
                  this.toastr.error(Message_data.sryAlreadyBooked);
                  valid_flag = false;
                }
              }
            }
          },
          error => {});
    }
  }

  finalRescheduleBooking() {
  //  alert(this.visitSession)
    var Date_splitter = (this.reschdApptDate).split('-');
    if (this.reschedAppType == "type") {
      var Res_Send_Data = JSON.stringify({
        hospital_id: this.userInfo.hptl_clinic_id,
        doc_reg_id: this.doctorId,
        date: this.reschdApptDate,
        session: encrypt_decript.Encript(this.visitSession).toString(),
        doc_app_id: this.getPatientDetails.doc_app_id,
        token: true,
        reschedule_reason: this.rescheduleReason,
        token_on_arrival: this.userInfo.token_on_arrival
      })
    } else {
      var Temptime = this.reschdAppointmentTime.split(' ');
      var Res_Send_Data = JSON.stringify({
        hospital_id: this.userInfo.hptl_clinic_id,
        doc_reg_id: this.doctorId,
        date: Date_Formate(Date_splitter[2] + "-" + Date_splitter[1] + "-" + Date_splitter[0]),
        session: encrypt_decript.Encript(this.visitSession).toString(),
        doc_app_id: this.getPatientDetails.doc_app_id,
        token: false,
        f_time: Temptime[0] + ":00",
        reschedule_reason: this.rescheduleReason, token_on_arrival: this.userInfo.token_on_arrival
      })
    }
    console.log("send data resc+"+JSON.stringify(Res_Send_Data))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "appointment/reschedule/", Res_Send_Data
      , { headers: headers }).subscribe(
        data => {
          if (data.json().result != "Can't reschedule more then three times") {
            this.toastr.success(data.json().result);
            this.messageService.sendMessage("manageappointments");
          } else {
            this.toastr.error(data.json().result);
            this.messageService.sendMessage("manageappointments");
          }
        },
        error => {
        }
      )
  }

  addSessionForReschd() {
    this.reschdVisitingSessionData = [];
    var Current_Time_Split = this.currentTime.split(':');
    var appDate = (this.reschdApptDate).split("-");
    var appdatenew = appDate[2] + "-" + appDate[1] + "-" + appDate[0];
   var get_date = Date_Formate(appdatenew);
  // alert("appdatenew"+this.currentDate+appdatenew)
    if (this.currentDate == get_date) {
     // alert("0kk===="+this.currentDate+appdatenew)
      var curr_datetime = new Date(Date_Formate(appdatenew) + " " + this.currentTime);
      var cuur_mor_sess = "", cuur_afetr_sess = "", cuur_eve_sess = "";

      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        cuur_mor_sess = "Morning";
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        cuur_eve_sess = "Evening";
      }
    
      if (Current_Time_Split[0] < 12) {
        if (this.gservice.get_Array_Contains_string(this.homeSessionData, "Morning") == true &&  this.cuur_mor_sess == "Morning") {
          this.reschdVisitingSessionData.push({
            description: "Morning",
            id: "1"
          });
        }
        if (this.gservice.get_Array_Contains_string(this.homeSessionData, "Afternoon") == true) {
          this.reschdVisitingSessionData.push({
            description: "Afternoon",
            id: "2"
          });
        }
        if (this.gservice.get_Array_Contains_string(this.homeSessionData, "Evening") == true) {
          this.reschdVisitingSessionData.push({
            description: "Evening",
            id: "3"
          });
        }
      } else if (Current_Time_Split[0] >= 12 && Current_Time_Split[0] < 16) {
        if (this.gservice.get_Array_Contains_string(this.homeSessionData, "Afternoon") == true) {
          this.reschdVisitingSessionData.push({
            description: "Afternoon",
            id: "2"
          });
        }
        if (this.gservice.get_Array_Contains_string(this.homeSessionData, "Evening") == true) {
          this.reschdVisitingSessionData.push({
            description: "Evening",
            id: "3"
          });
        }
      } else {
        if (this.gservice.get_Array_Contains_string(this.homeSessionData, "Evening") == true) {
          this.reschdVisitingSessionData.push({
            description: "Evening",
            id: "3"
          });
        }
      }
    } else {
      if (this.gservice.get_Array_Contains_string(this.homeSessionData, "Morning") == true) {
        this.reschdVisitingSessionData.push({
          description: "Morning",
          id: "1"
        });
      }
      if (this.gservice.get_Array_Contains_string(this.homeSessionData, "Afternoon") == true) {
        this.reschdVisitingSessionData.push({
          description: "Afternoon",
          id: "2"
        });
      }
      if (this.gservice.get_Array_Contains_string(this.homeSessionData, "Evening") == true) {
        this.reschdVisitingSessionData.push({
          description: "Evening",
          id: "3"
        });
      }
    }
    if (this.reschdVisitingSessionData.length != 0) {
      this.visitSession = this.reschdVisitingSessionData[0].description;
      this.visitHomeSession = this.reschdVisitingSessionData[0].description;
    }
  }

  selectReschdApptDate(e) {
    this.reschdApptDate = e;
    var date_txt = new Date(this.reschdApptDate);
    this.dayOfWeek = date_txt.getDay() + 1;
    this.sendDataForDoctdetail = {
      day: this.dayOfWeek,
      hptl_id: this.userInfo.hptl_clinic_id,
      doc_id: this.doctorId,
     
    };
    this.getDoctorScheduleList();
  }

  changeSession() {
    if (this.typeOrTime == "time") {
      for (var i = 0; i < this.doctorConsultSessionList.length; i++) {
        if (this.session == this.doctorConsultSessionList[i].Session) {
          var From_Split = this.doctorConsultSessionList[i].Avail_from.split(':');
          var To_Split = this.doctorConsultSessionList[i].Avail_to.split(':');
          var fromday = new Date();
          fromday.setHours(this.fromDaySplit[0]);
          fromday.setMinutes(this.fromDaySplit[1]);
          var today = new Date();
          today.setHours(this.toDaySplit[0]);
          today.setMinutes(this.toDaySplit[1]);
          var parts = this.appointmentDate.split('-');
          this.tempCurrentDate = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.currentTime);
          this.tempCurrGetFTime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.doctorConsultSessionList[i].Avail_from);
          this.timeList = [];
          var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
          var chk_time = this.datepipe.transform(fromday, 'hh:mm');
          var end_time = this.datepipe.transform(today, 'hh:mm');
          this.availableFlag = this.checkDocAvailabilty(this.appointmentDate, this.session, chk_time, end_time);
          if (this.availableFlag == true && this.currentDate == this.appointmentDate && this.tempCurrGetFTime > this.tempCurrentDate) {
            this.timeList.push(New_time1);
          } else {
            if (this.availableFlag == true && this.currentDate != this.appointmentDate) {
              this.timeList.push(New_time1);
            }
          }
          while (fromday < today) {
            var Day_Now = new Date();
            if (this.currentDate == this.appointmentDate) {
              var Now_Split = this.currentTime.split(':');
              Day_Now.setHours(parseInt(Now_Split[0]));
              Day_Now.setMinutes(parseInt(Now_Split[1]));
            } else {
              Day_Now.setHours(this.fromDaySplit[0]);
              Day_Now.setMinutes(this.fromDaySplit[1]);
            }
            fromday.setMinutes(fromday.getMinutes() + parseInt(this.doctorConsultSessionList[i].Time_Duration));
            var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
            var Avail_flag = this.checkDocAvailabilty(this.appointmentDate, this.session, chk_time1, end_time);
            if (Avail_flag == true && Day_Now <= fromday && fromday < today) {
              var New_time = this.datepipe.transform(fromday, 'hh:mm a');
              if (New_time != this.gservice.get_Timeformate(this.doctorConsultSessionList[i].Avail_to)) {
                this.timeList.push(New_time);
              }
            }
          }
        }
      }
    }
  }

  convertTimeformat(format, str) {
    var time = str;
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return (sHours + ":" + sMinutes);
  }

  checkDocAvailabilty(get_sel_date, sess, f_time, t_time) {
    var Available_flag = true;
    var get_date = get_sel_date.split('-');
    var check_date = new Date(get_sel_date);
    if (this.unavailableData.length != 0) {
      for (var i = 0; i < this.unavailableData.length; i++) {
        if (this.unavailableData[i].full_day != null) {
          if (check_date >= new Date(this.unavailableData[i].from_date) && check_date <= new Date(this.unavailableData[i].to_date)) {
            Available_flag = false;
            break;
          }
        } else {
          if (sess != null) {
            var From_Split = this.unavailableData[i].from_time.split(':');
            var To_Split = this.unavailableData[i].to_time.split(':');
            var t1 = new Date(check_date);
            t1.setHours(From_Split[0]);
            t1.setMinutes(From_Split[1]);
            var t2 = new Date(check_date); 
            t2.setHours(To_Split[0]);
            t2.setMinutes(To_Split[1]);
            var get_f_time = this.gservice.get_time_form_session(f_time, this.gservice.get_seesion_ampm(sess));
            var get_t_time = this.gservice.get_time_form_session(t_time, this.gservice.get_seesion_ampm(sess));
            var get_time1 = this.convertTimeformat("24", get_f_time);
            var get_time2 = this.convertTimeformat("24", get_t_time);
            var From_Split1 = get_time1.split(':');
            var To_Split1 = get_time2.split(':');
            var get_f_time1 = get_time1.split(':');
            var get_f_time2 = get_time2.split(':');
            var f_time1 = new Date(check_date);
            f_time1.setHours(parseInt(get_f_time1[0]));
            f_time1.setMinutes(parseInt(get_f_time1[1]));
            var get_chk_date = this.gservice.get_Date_Formate(check_date);
            var get_f_date = this.gservice.get_Date_Formate(this.unavailableData[i].from_date);
            var get_t_date = this.gservice.get_Date_Formate(this.unavailableData[i].from_date);
            if (get_chk_date.toString() == get_f_date.toString() && sess == this.unavailableData[i].session || get_chk_date.toString() == get_t_date.toString() && sess == this.unavailableData[i].session) {
              if (f_time1 > t1 && f_time1 < t2) 
                Available_flag = false;
              if ((f_time1 == t1 && f_time1 <= t2)) 
                Available_flag = false;
              if ((f_time1 >= t1 && f_time1 == t2))
                Available_flag = false;
              if ((f_time1 == t1 && f_time1 == t2))
                Available_flag = false;
            }
            if (get_chk_date.toString() == get_f_date.toString() && get_chk_date.toString() == get_t_date.toString() && sess == this.unavailableData[i].session) {
              if (f_time1 > t1 && f_time1 < t2)
                Available_flag = false;
              if ((f_time1 == t1 && f_time1 < t2))
                Available_flag = false;
              if ((f_time1 > t1 && f_time1 == t2))
                Available_flag = false;
              if ((f_time1 == t1 && f_time1 == t2))
                Available_flag = false;
            }
          }
        }
      }
    }
    return Available_flag;
  }

  search() {
    if (this.session != null && this.session != undefined) {
      if (this.typeOrTime == "time") {
        this.appTypeByTokenFlag = true;
        this.appTypeByTimeFlag = false;
        this.changeSession();
        this.checkForDocAvailable(this.appointmentDate, this.currentTime);
      } else {
        this.appTypeByTokenFlag = false;
        this.appTypeByTimeFlag = true;
        this.checkForDocAvailable(this.appointmentDate, this.currentTime);
      }
    } else {
      this.toastr.error(Message_data.sltSession);
    }
  }

  checkForDocAvailable(curtdate, curttime) {
    var Available_flag = false;
    var gsDayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    var get_date = curtdate.split('-');
    var get_sel_date = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
    var dayName = gsDayNames[get_sel_date.getDay()];
    var get_sel_day_num = this.gservice.get_Day_num(dayName);
    for (var i = 0; i < this.doctorConsultSessionList.length; i++) {
      var get_day_num = this.gservice.get_Day_num(this.doctorConsultSessionList[i].day);
      if (this.doctorConsultSessionList[i].hospital == this.hospitalName) {
        var Cur_timesplit = [];
        if (curttime != undefined) {
          Cur_timesplit = curttime.split(':');
          var Curre_time = new Date(get_sel_date);
          Curre_time.setHours(Cur_timesplit[0]);
          Curre_time.setMinutes(Cur_timesplit[1]);
        }
        var Avtotimesplit = [];
        Avtotimesplit = this.doctorConsultSessionList[i].Avail_to.split(':');
        var Retrivel_time = new Date(get_sel_date);
        Retrivel_time.setHours(Avtotimesplit[0]);
        Retrivel_time.setMinutes(Avtotimesplit[1]);
        if (this.doctorConsultSessionList[i].day_num == "9" || (get_sel_day_num != 1 && get_day_num == 8)) {
          if (this.currentDate == this.gservice.get_Date_Formate(curtdate)) {
            if (Retrivel_time > Curre_time) {
              Available_flag = true;
            }
          } else {
            Available_flag = true;
          }
        } else {
          if ((new Date(curtdate)).getDay() + 1 == this.doctorConsultSessionList[i].day_num) {
            if (this.currentDate == this.gservice.get_Date_Formate(curtdate)) {
              if (Retrivel_time > Curre_time && this.currentDate == this.gservice.get_Date_Formate(curtdate)) {
                Available_flag = true;
              }
            } else {
              Available_flag = true;
            }
          }
        }
      }
    }
    Available_flag = this.checkDocAvailabilty(curtdate, null, null, null);
    if (this.availableFlag == false || this.availableSessionFlag == false) 
    {
      this.bookNowFlag = true;
      this.doctorAvailableFlag = true;
      this.doctorNonAvailableFlag = false;
    } else {
      this.bookNowFlag = false;
      this.doctorAvailableFlag = false;
      this.doctorNonAvailableFlag = true;
    }
  }

  cancel() {
    const dialogRef = this.dialog.open(ClientCancelAppointmentComponent, {
      width: '50%',
      height: '300px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if (result != undefined && result != undefined) {
          var sentdata = {
            reason: result,
            doc_app_id: this.getPatientDetails.doc_app_id,
          }
          var headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.post(ipaddress.getIp.toString() + 'appointment/cancelapp', JSON.stringify(sentdata), { headers: headers }).subscribe(
            data => {
              var obj = JSON.parse(data["_body"]);
              if (obj.key != null) {
                if (obj.key == "1") {
                  this.toastr.success(Message_data.appcancel);
                 this.messageService.sendMessage("manageappointments");
                }
              }else
                this.toastr.error(Message_data.apptNotCancelled);
            },error => {
              this.toastr.error(Message_data.getNetworkMessage());
            });
        }
      }
    });
  }
 

  bookNow() {
    var flag = true;
    if (this.session != null && this.session != undefined) {
      var get_date = Date_Formate(this.appointmentDate);
      var curr_datetime = new Date(Date_Formate(get_date) + " " + this.currentTime);
      var cuur_mor_sess = "", cuur_afetr_sess = "", cuur_eve_sess = "";
      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        cuur_mor_sess = "Morning";
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
        this.cuur_mor_sess = "Morning";
      }
      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }
      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        cuur_eve_sess = "Evening";
      }
      if (this.session == cuur_mor_sess || this.session == cuur_afetr_sess ||
        this.session == cuur_eve_sess) {
        if (this.getPatientDetails.Appointment_Date == this.appointmentDate && this.getPatientDetails.session == this.session) {
          this.toastr.error(Message_data.bookAnotherSession);
        } else {
          if (this.typeOrTime == "time") 
          {
            if (this.appointmentTime == undefined || this.appointmentTime == null) {
              this.toastr.error(Message_data.sltTime);
            } else {
              this.tempTime = this.appointmentTime.split(' ');
              for (var i = 0; i < this.doctorList.length; i++) {
                if (this.doctorList[i].Doc_Name == this.doctor) {
                  this.doctorId = this.doctorList[i].Doc_ID;
                }
              }
              var headers = new Headers();
              headers.append('Content-Type', 'application/json');
              this.http.post(ipaddress.getIp.toString() + 'appointment/getappexist',
                JSON.stringify({
                  doc_reg_id: this.doctorId,
                  appointment_date: Date_Formate(this.appointmentDate),
                  session: encrypt_decript.Encript(this.session).toString(),
                  from_time: this.tempTime[0] + ":00",
                  type: "doctor"
                }),
                { headers: headers })
                .subscribe(
                  response => {
                    var obj = JSON.parse(response["_body"]);
                    if (obj.result != false) {
                      this.toastr.error(Message_data.sryAlreadyBooked);
                    } else {
                      this.appBooking();
                    }
                  },
                  error => {
                    this.toastr.error(Message_data.getNetworkMessage());
                  });
            }
          } else {
            var headers = new Headers();
            headers.append('Content-Type', 'application/json');
            this.http.post(ipaddress.getIp.toString() + 'appointment/doctoken',
              JSON.stringify({
                hospital_id: this.userInfo.hptl_clinic_id,
                doc_reg_id: this.doctorId,
                date: Date_Formate(this.appointmentDate),
                session: encrypt_decript.Encript(this.session).toString(),
              }),
              { headers: headers })
              .subscribe(
                response => {
                  var obj = JSON.parse(response["_body"]);
                  if (obj.result != "true") {
                    flag = false;
                  } else {
                    this.appBooking();
                  }
                },
                error => {
                  this.toastr.error(Message_data.getNetworkMessage());
                });
          }
        }
      } else {
        this.toastr.error(Message_data.sltValidSession);
      }
    } else {
      this.toastr.error(Message_data.sltSession);
    }
  }

  appBooking() {
    if (this.typeOrTime != "time") {
      this.sendRescheduleData = JSON.stringify({
        hptl_id: this.userInfo.hptl_clinic_id,
        doc_reg_id: this.doctorId,
        appointment_date: this.appointmentDate,
        day_session: encrypt_decript.Encript(this.session).toString(),
        appointment_id: this.getPatientDetails.doc_app_id,
        token: true,
        appointment_time: "1"
      })
    } else {
      this.tempTime = this.appointmentTime.split(' ');
      this.sendRescheduleData = JSON.stringify({
        hptl_id: this.getPatientDetails.hptl_clinic_id,
        doc_reg_id: this.doctorId,
        appointment_date: this.appointmentDate,
        day_session: encrypt_decript.Encript(this.session).toString(),
        appointment_id: this.getPatientDetails.doc_app_id,
        token: false,
        appointment_time: this.tempTime[0] + ":00"
      })
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/asnapp', this.sendRescheduleData,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json()
          if (obj != "Can't reschedule more then three times") {
            this.toastr.success(obj.result);
          } else {
            this.toastr.error(obj.result);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  print() {
    let printContents, popupWin;
    if (this.printTemplate != undefined && this.printTemplate == "banner") {
      printContents = this.printbanner.nativeElement.innerHTML;
    } else if (this.printTemplate != undefined && this.printTemplate == "logowithname") {
      printContents = this.printlogowithname.nativeElement.innerHTML;
    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
    }
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
    <head>
      <title>Prescription</title>
      <link rel="stylesheet" media="screen" href="">
      <style>
      @page { size: auto;  margin: 0mm; }
      .table_class {
        background-color: rgb(108, 180, 248);
      }
      .casesheet_container {
        width: 211px;
        padding: 1px;
        background: #277196;
        display: inline-flex;
        position: relative;
        padding-left: 5px;
        top: 21px;
        color: #FFFFFF;
      }
      .border_style{
        border:solid 1px;
        border-color: #488aff;
      }
      .width_style{
        margin-top:4px;
        width:600px;
      }
      p{
        margin:0;
      }
      </style>
    </head>
    <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
  </html>`
    );
    popupWin.document.close();
  }
}
