<div class="row" style="margin:0 auto; width:100%">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Consingment list</h5>
                    </div>
                    <div class="headerButtons" >

                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>

               
                
                <!-- <div class="cover_div" style="margin-top: 0;">
                    <div class="header_lable">
                        Product details
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">HSN No
                                    <input type="text" class="ipcss " required [(ngModel)]="hsnNO" [disabled]="newFlag"
                                        maxlength="10" matInput
                                        onkeypress="return (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57)" />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">Product name

                                    <input type="text" class="ipcss " [(ngModel)]="productName"
                                        (keyup)="productNameFilter($event)" matInput [matAutocomplete]="auto10" />
                                    <mat-autocomplete #auto10="matAutocomplete">
                                        <mat-option (click)="selectItem(item)" *ngFor="let item of filteredList"
                                            value={{item}}> {{item}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-label>
                            </div>
                            
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">Batch no
                                    <input type="text" class="ipcss " [(ngModel)]="batch_no" matInput />
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">
                                    <div class="col-11">
                                        <mat-label class="matlabel">Supplier / distributor
                                            name<br>
                                            <mat-select class="ipcss " [(ngModel)]="supp_name">
                                                <mat-option *ngFor="let supplier of supplierList" (click)="supplierdatafunction(supplier)" value={{supplier.name}}>
                                                    {{supplier.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-label>
                                    </div>
                                    <div class="col-1" style="padding: 0;">
                                        <img class="manuf_add" style="width: 25px;position: relative;top: 24px;left: 0px;" src="../../../assets/img/captcha_refresh.png" (click)="cleararrayconsign('')" class="refresh_btn" alt="clear data">
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel" style="margin-top: 7px;">Avaliable quantity
                                            <input type="text" class="ipcss "  matInput [(ngModel)]="avail_qty"
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                        </mat-label>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1" style="width:10%">
                                <mat-label class="matlabel">MRP
                                    <input type="text" class="ipcss" [(ngModel)]="MRP" required matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1" style="width:10%">
                                <mat-label class="matlabel">Sale price
                                    <input type="text" class="ipcss" [(ngModel)]="s_price" required matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1" style="width:10%">
                                <mat-label class="matlabel">Cost price
                                    <input type="text" class="ipcss" [(ngModel)]="c_price" required matInput />
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1" style="width:10%">
                                <mat-label class="matlabel">CGST
                                    <input type="text" class="ipcss" [(ngModel)]="cgst" (keyup)='cgstcal(cgst)' required matInput  />
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1" style="width:10%">
                                <mat-label class="matlabel">SGST
                                    <input type="text" class="ipcss" [(ngModel)]="sgst" (keyup)='gstcal(sgst)' required matInput />
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1" style="width:10%">
                                <mat-label class="matlabel">GST
                                    <input type="text" class="ipcss" disable [(ngModel)]="gst" matInput />
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">Expiry Duration
                                    <input type="date" class="ipcss " [(ngModel)]="expirydura" matInput/>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" style="margin-bottom: auto;margin-top: auto;">
                                 
                                <div style="position: relative;top: 10px;">
                                    <input type="checkbox" [checked]="returnedaccepted" [(ngModel)]="returnedprod">  Returned
                                </div>

                            </div>

                        </div>
                    </div>
                </div> -->

                <!-- <div class="row">
                    <div class="col-12" style="display: flex;
                    flex-direction: row-reverse;
                    padding: 3px;">
                    <img src={{hide_show_btn}}  style="width:27px;margin-right: 5px;" (click)="toggleCover()"/>
                        </div>
                </div> -->

                <div class="row" style="margin-top: 10px;">

                 
                    
                    <div class="col-12">
                        <table style="font-size: 13px;" id="product_table" 
                        class="table table-hover table-dynamic">
                        <thead>
                            <tr>
                                <th>Sno</th>
                                <th>HSN no</th>
                                <th>Product name</th>
                                <th>Batch no</th>
                                <th>Supplier</th>
                                <th>Quantity</th>
                                <th>MRP</th>
                                <th>Sales price</th>
                                <th>Cost price</th>
                                <th>CGST</th>
                                <th>SGST</th>
                                <th>GST</th>
                                <th>GST amount</th>
                                <th>Expiry</th>
                                <th>Received by</th>
                                <th>Return</th>
                               
                            </tr>
                        </thead>
                        <tbody>


                            <ng-container *ngFor="let prod of consing_data;let i = index">
                                <tr (click)="toggleInputs(i)">
                                    <td>{{prod.sn}}</td>
                                    <td>{{prod.hsn_no}}</td>
                                    <td>{{prod.consignment_name}}</td>
                                    <td>{{prod.batch_no}}</td>
                                    <td>{{prod.supp_name}}</td>
                                    <td>{{prod.quantity}}</td>
                                    <td>{{prod.mrp}}</td>
                                    <td>{{prod.sale_price}}</td>
                                    <td>{{prod.cost_price}}</td>
                                    <td>{{prod.cgst}}</td>
                                    <td>{{prod.sgst}}</td>
                                    <td>{{prod.gst}}</td>
                                    <td>{{prod.gst_amount}}</td>
                                    <td>{{prod.expiry_date}}</td>
                                    <td>{{prod.received_by}}</td>
                                    <td>
                                     <input type="checkbox" [(ngModel)]="prod.is_prod_returned">
                                    </td>
                                  
                                 </tr>
                                 <tr *ngIf="currentOpenedIndex === i" [@slideToggle]>
                                    <td colspan="7">
                                        <div class="row maintance_sch">
                                            
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                                                <mat-label class="matlabel"> Name <br>
                                                  <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                                                    (keyup)="name.length >=3 && handlekeyUp($event)" maxlength="50" [(ngModel)]="name" matInput
                                                    [matAutocomplete]="autoordercrete"/>
                                                </mat-label>
                                                <mat-autocomplete #autoordercrete="matAutocomplete" [panelWidth]="'auto'">
                                    
                                                  <mat-option (click)="retrieveClient(patient)" *ngFor="let patient of patientArray"
                                                    value="{{patient.patient_name}}">
                                                    <div>{{patient.patient_name}} <span>(</span> {{patient.gender}} - {{patient.age}}<span>)</span> </div>
                                                  </mat-option>
                                                </mat-autocomplete>
                                              </div>
                                    
                                            
                                           
                                         
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 input">
                                                <mat-label class="matlabel" style="margin-top: 7px;">Used Qty<br>
                                                    <input type="text" class="ipcss "  maxlength="25" [(ngModel)]="this.usequantity" matInput required/>
                                                </mat-label>
                                            </div>

                                           
                                         
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1 input">
                                                <img style="position: relative;top: 20px;" src="../../../assets/ui_icons/buttons/Book_now.svg" 
                                               (click)="booknow(prod)"
                                                class="saveimgbtn_inpatinfo" >
    
                                            </div>
                                        </div>
                                      </td>    
                                </tr>
                                </ng-container>

                        </tbody>
                    </table> 
                    </div>
                    <div  class="col-12">

                    </div>
                </div>


            </mdb-card-body>
        </mdb-card>
    </div>
</div>

