<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
            <div class="headerTilte app_list_header">
                <h5 class="m-0" class="mainHeadingStyle">Discharge Timeline</h5>
            </div>
            <div  class="headerButtons">
              <a (click)="backClicked()">
                <img src="../../../assets/ui_icons/buttons/Back_button.svg" 
                  class="saveimgbtn_inpatinfo" style="cursor: pointer;" />
              </a> &nbsp;
              <a (click)="save()">
                <img src="../../../assets/ui_icons/buttons/save_button.svg"  
                  class="saveimgbtn_inpatinfo" style="cursor: pointer;" />
              </a>
            </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div_top">
          <div class="header_lable">
              Discharge Timeline
          </div>
          <div class="content_cover">
            <div class="col-12">
              <div class="cover_div"  style="margin-top: 5px !important;"> 
                <div class="header_lable">
                    Patient details
                </div>                      
                <div class="content_cover">             
                    <div class="row">
                      <div class="col-2" style="text-align: center;">
                        <img src="{{this.profileImg}}" onerror="this.src='../../../assets/img/default.jpg';"
                          class="profileimage">
                      </div>
                      <div class="col-10">
                        <div class="row">                                                       
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 82px; font-weight: 500 !important;">
                                    MR No</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    {{mrno}}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 82px; font-weight: 500 !important;">
                                    Patient name</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    {{patient_name}}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 82px; font-weight: 500 !important;">
                                    Age/Gender</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                      {{gender_age}}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 82px; font-weight: 500 !important;">
                                    Mobile</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    {{mobile_no}}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>      
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 82px; font-weight: 500 !important;">
                                    Doctor</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    {{doctor_name}}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div> 
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 82px; font-weight: 500 !important;">
                                    Speciality</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    {{speciality}}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>  
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 82px; font-weight: 500 !important;">
                                    DOA</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">   
                                    {{admitted_date}} {{admitted_time}}                 
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>                                                       
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 82px; font-weight: 500 !important;">
                                    Ward/Bed</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    {{ward_name}} <span *ngIf="bed_no != ''">/</span> {{bed_no}}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>                                                                                          
                          <div class="col-6">
                            <table style="width: 100%;" class="head-table">
                                <tbody>
                                  <tr class="head-tr">
                                      <td class="head-td" style="width: 82px; font-weight: 500 !important;">
                                        Address</td>
                                      <td class="head-td" style="width: 10px; font-weight: 500 !important;">:&nbsp;</td>
                                      <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">                                 
                                        {{address1}} {{address2}} {{location}} {{city}} {{zipcode}} {{state}} {{country}}                  
                                    </td>
                                  </tr>                 
                                </tbody>
                            </table>
                          </div>
                        </div>      
                      </div>
                    </div>               
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="cover_div" style="margin-top: 10px !important;"> 
                <div class="header_lable">
                    Patient Discharge Status
                </div>                      
                <div class="content_cover"> 
                  <div class="row">                                      
                    <fieldset>   
                      <div class="row">
                        <div class="col-12">
                          <div class="switch-field">
                            <input type="radio" id="initiate_discharge" (click)="change_type(1,'initiate_discharge')" [disabled]="true" />
                            <label for="initiate_discharge" [ngClass]="initiateDischargeFlag ? 'radio_active':'radio_inactive'">                                                          
                                <div class="col-12" style="border-top: 2px solid rgba(0, 0, 0, 0.2);margin-top: 0px;margin-left: 19px;width: 960%;">
                                </div>
                            </label>     
                            
                            <input type="radio" id="OT_discharge" (click)="change_type(2,'OT_discharge')" [disabled]="true" />
                            <label for="OT_discharge" [ngClass]="OTDischargeFlag ? 'radio_active':'radio_inactive'">   
                              <div class="col-12" style="border-top: 2px solid rgba(0, 0, 0, 0.2);margin-top: 0px;margin-left: 19px;width: 960%;">
                              </div>                                                     
                            </label>

                            <input type="radio" id="clinical_discharge" (click)="change_type(3,'clinical_discharge')" [disabled]="true" />
                            <label for="clinical_discharge" [ngClass]="clinicalDischargeFlag ? 'radio_active':'radio_inactive'">   
                                <div class="col-12" style="border-top: 2px solid rgba(0, 0, 0, 0.2);margin-top: 0px;margin-left: 19px;width: 960%;">
                                </div>                                                       
                            </label> 
                                                                              
                            <input type="radio" id="financial_discharge" (click)="change_type(4,'financial_discharge')" [disabled]="true" />
                            <label for="financial_discharge" [ngClass]="financialDischargeFlag ? 'radio_active':'radio_inactive'">  
                                <div class="col-12" style="border-top: 2px solid rgba(0, 0, 0, 0.2);margin-top: 0px;margin-left: 19px;width: 960%;">
                                </div>                                                         
                            </label>  
                                                                                  
                            <input type="radio" id="report_finalized" (click)="change_type(5,'report_finalized')" [disabled]="true" />
                            <label for="report_finalized" [ngClass]="reportFinalizedFlag ? 'radio_active':'radio_inactive'">    
                                <div class="col-12" style="border-top: 2px solid rgba(0, 0, 0, 0.2);margin-top: 0px;margin-left: 19px;width: 960%;">
                                </div>                                                       
                            </label> 

                            <input type="radio" id="physical_discharge" (click)="change_type(6,'physical_discharge')" [disabled]="true" />
                            <label for="physical_discharge" [ngClass]="physicalDischargeFlag ? 'radio_active':'radio_inactive'"> 
                              <div class="col-12" style="border-top: 2px solid rgba(0, 0, 0, 0.2);margin-top: 0px;margin-left: 19px;width: 960%;">
                              </div>                                                       
                            </label>                                     

                            <input type="radio" id="house_keeping_discharge" (click)="change_type(7,'house_keeping_discharge')" [disabled]="true" />
                            <label for="house_keeping_discharge" [ngClass]="HouseKeepingDischargeFlag ? 'radio_active':'radio_inactive'">                                                                               
                            </label>                                                                                                                                 
                          </div>
                          <div class="row">
                            <div class="col-12"> 
                              <div class="lableStyle visibility">Dummy Discharge</div>
                              <div class="lableStyle">Initiate Discharge</div>
                              <div class="lableStyle">OT Discharge</div>
                              <div class="lableStyle">Clinical Discharge</div>
                              <div class="lableStyle">Financial Discharge</div>
                              <div class="lableStyle">Report Finalized</div>   
                              <div class="lableStyle">Physical Discharge</div>                              
                              <div class="lableStyle">House Keeping Discharge</div>
                            </div>                           
                          </div>                                                            
                        </div>
                      </div>                                                          
                    </fieldset>                                     
                  </div>
                </div>
              </div>
            </div>                                                                        
            
            <div class="cover_div"  style="margin-top: 10px !important;"> 
              <div class="header_lable">
                  Initiate Discharge
              </div>                      
              <div class="content_cover">             
                <div class="row">                               
                  <div class="col-12">
                    <div class="row">                                                       
                      <div class="col-4">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 142px; font-weight: 500 !important;">
                                Initiate Discharge</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                <mat-checkbox color="primary" [(ngModel)]="initiateDischarge" ></mat-checkbox> 
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-4">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 182px; font-weight: 500 !important;">
                                  Expected Discharge Date</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">                                               
                                  <input class="ipcss widthbilllist" type="date" [(ngModel)]="expt_discharge_date" [min]="currentDateTime"/>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-4">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <td class="head-td" style="width: 160px; font-weight: 500 !important;">
                              Expected Discharge Time</td>
                            <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                            <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">                             
                              <div class="row">                         
                                <div class="col-3" style="padding-right:0;">
                                  <select type="date" class="ipcss inpat_width" style="width: auto !important;" [(ngModel)]="discharge_time1">
                                    <option *ngFor="let hrs of phy_dischage_hrs" value="{{hrs}}">{{hrs}}
                                    </option>
                                  </select>
                                </div>
                                <div class="col-1" style="padding:0;position: relative;top: 5px;left: 11px;"> : </div>
                                <div class="col-3" style="padding-left:0;">
                                  <select type="date" class="ipcss inpat_width" style="width: auto !important;" [(ngModel)]="discharge_time2">
                                    <option *ngFor="let min of phy_dischage_min" value="{{min}}">{{min}}
                                    </option>
                                  </select>
                                </div>
                                <div class="col-5">
                                  <select type="date" class="ipcss inpat_width" style="width: auto !important;" [(ngModel)]="discharge_time3">
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                  </select>
                                </div>                         
                              </div>
                            </td>
                          </tbody>
                        </table>                  
                      </div>
                      <div class="col-4">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 142px; font-weight: 500 !important;">
                                Discharging Doctor</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">                                             
                                <input matInput class="ipcss widthbilllist" type="text" [(ngModel)]="discharge_doctor_name" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>  
                      <div class="col-4">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 182px; font-weight: 500 !important;">
                                Initiate Discharge Remarks</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                <textarea class="ipcss inpat_width" style="height: 50px;" [(ngModel)]="initiate_discharge_remarks" required matInput></textarea>                                              
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>                      
                    </div>      
                  </div>
                </div>               
              </div>
            </div>   

            <div class="cover_div"  style="margin-top: 10px !important;"> 
              <div class="header_lable">
                  OT-Discharge
              </div>                      
              <div class="content_cover">             
                <div class="row">                               
                  <div class="col-12">
                    <div class="row">                                                       
                      <div class="col-6">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 142px; font-weight: 500 !important;">
                                OT-Booking </td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                <mat-checkbox color="primary" [(ngModel)]="ot_book" ></mat-checkbox> 
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-6">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 142px; font-weight: 500 !important;">
                                OT-Booking Cancelled </td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                <label class="switch">
                                  <input type="checkbox" [(ngModel)]="ot_book_cancelled">
                                  <span class="slider round"></span>
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>                                                                               
                    </div>      
                  </div>
                </div>               
              </div>
            </div>
            
            <div class="cover_div"  style="margin-top: 10px !important;"> 
              <div class="header_lable">
                  Clinical Discharge
              </div>                      
              <div class="content_cover">             
                <div class="row">                               
                  <div class="col-12">
                    <div class="row">                                                       
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 140px; font-weight: 500 !important;">
                                Clinical Reports Generated</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                <mat-checkbox color="primary" [(ngModel)]="clinical_reports" ></mat-checkbox> 
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>  
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 140px; font-weight: 500 !important;">
                                Discharge Summary Generated</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                <mat-checkbox color="primary" [(ngModel)]="discharge_summary" ></mat-checkbox> 
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div> 
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 120px; font-weight: 500 !important;">
                                Clinical Discharge</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                <mat-checkbox color="primary" [(ngModel)]="clinicalDischarge" ></mat-checkbox> 
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>      
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 120px; font-weight: 500 !important;">
                                Clinical Discharge Remarks</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                <textarea class="ipcss inpat_width" style="height: 50px;width: auto;" required matInput [(ngModel)]="clinical_discharge_remarks"></textarea>                                              
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>      
                  </div>
                </div>               
              </div>
            </div>

            <div class="cover_div"  style="margin-top: 10px !important;"> 
              <div class="header_lable">
                Pending Activities
              </div>                      
              <div class="content_cover">
                <div class="col-12">            
                  <div class="row">                                                             
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 select_bottom">
                      <table style="width: 100%;" class="head-table">
                          <tbody style="height: 40px !important;">
                          <tr class="head-tr">
                              <td class="head-td" style="width: 145px; font-weight: 500 !important;background-color: rgba(0, 0, 0, 0.2) !important;">
                                  <span style="color: red;">!</span> Hospital Activities</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;background-color: rgba(0, 0, 0, 0.2) !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif;text-align: right;font-size: 20px !important;font-weight: 500;background-color: rgba(0, 0, 0, 0.2) !important;padding-right: 5px !important;">                                                                         
                                {{hosp_bill_count}}
                              </td>
                          </tr>
                          </tbody>
                      </table>
                      
                      <a (click)="hospitalPathFunction('doctor')">
                        <p style="text-align: right;color: #66b9eb;cursor: pointer;" *ngIf="hosp_bill_count != '0'" >
                          View
                        </p>
                      </a>
                      <div *ngIf="billListFlag">
                        <div class="billlisttable" *ngIf="hospBillListFlag">
                          <table *ngIf="billList.length" class="table table-nowrap table-hover table-sm diagBillList">
                            <thead>
                              <tr>
                                <th class="delete">Bill no</th>
                                <th>Date</th>                                                                    
                                <th>Amount</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let bill of billList; let i = index">
                                <td style="font-size: 12px;text-align: center;">
                                  {{bill.bill_id}}
                                </td>
                                <td style="font-size: 12px;text-align: center;">
                                  {{bill.date}}
                                </td>
                                <td style="font-size: 12px;text-align: right;">
                                  {{bill.amount}}
                                </td>
                                <td style="font-size: 12px;text-align: left !important;"> 
                                  {{bill.status}}
                                </td>
                              </tr>
                            </tbody>
                          </table>               
                        </div>
                      </div>                                             
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 select_bottom">
                      <table style="width: 100%;" class="head-table">
                          <tbody style="height: 40px !important;">
                          <tr class="head-tr">
                              <td class="head-td" style="width: 145px; font-weight: 500 !important;background-color: rgba(0, 0, 0, 0.2) !important;">
                                  <span style="color: red;">!</span> Diagnosis Activities</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;background-color: rgba(0, 0, 0, 0.2) !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif;text-align: right;font-size: 20px !important;font-weight: 500;background-color: rgba(0, 0, 0, 0.2) !important;padding-right: 5px !important;">
                                {{diag_bill_count}}                                              
                              </td>
                          </tr>
                          </tbody>
                      </table>
                      <a (click)="diagnosisPathFunction('diagnosis')">
                        <p style="text-align: right;color: #66b9eb;cursor: pointer;" *ngIf="diag_bill_count != '0'">
                          View
                        </p>
                      </a>
                      <div *ngIf="billingListFlag">
                        <div class="billlisttable" *ngIf="diagBillListFlag">
                          <table *ngIf="billingList.length" class="table table-nowrap table-hover table-sm diagBillList" >
                            <thead>
                              <tr>
                                <th class="delete">Bill no</th>
                                <th>Date</th>                                                                    
                                <th>Amount</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let bill of billingList; let i = index">
                                <td style="font-size: 12px;text-align: center;">
                                  {{bill.bill_id}}
                                </td>
                                <td style="font-size: 12px;text-align: center;">
                                  {{bill.date}}
                                </td>
                                <td style="font-size: 12px;text-align: right;">
                                  {{bill.amount}}
                                </td>
                                <td style="font-size: 12px;text-align: left !important;"> 
                                  {{bill.status}}
                                </td>
                              </tr>
                            </tbody>
                          </table>               
                        </div>
                      </div>                                            
                    </div>                                                               
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 select_bottom">
                      <table style="width: 100%;" class="head-table">
                          <tbody style="height: 40px !important;">
                          <tr class="head-tr">
                              <td class="head-td" style="width: 145px; font-weight: 500 !important;background-color: rgba(0, 0, 0, 0.2) !important;">
                                  <span style="color: red;">!</span> Pharmacy Activities</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;background-color: rgba(0, 0, 0, 0.2) !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif;text-align: right;font-size: 20px !important;font-weight: 500;background-color: rgba(0, 0, 0, 0.2) !important;padding-right: 5px !important;">
                                {{pharma_bill_count}}                                                                                
                              </td>
                          </tr>
                          </tbody>
                      </table>
                      <a (click)="pharmaPathFunction('pharmacy')">
                        <p style="text-align: right;color: #66b9eb;cursor: pointer;" *ngIf="pharma_bill_count != '0'">
                          View
                        </p>
                      </a>
                      <div *ngIf="pharmaBillingListFlag">
                        <div class="billlisttable" *ngIf="pharmaBillListFlag">
                          <table *ngIf="pharmaBillList.length" class="table table-nowrap table-hover table-sm diagBillList">
                            <thead>
                              <tr>
                                <th class="delete">Bill no</th>
                                <th>Date</th>                                                                    
                                <th>Amount</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let bill of pharmaBillList; let i = index">
                                <td style="font-size: 12px;text-align: center;">
                                  {{bill.bill_id}}
                                </td>
                                <td style="font-size: 12px;text-align: center;">
                                  {{bill.date}}
                                </td>
                                <td style="font-size: 12px;text-align: right;">
                                  {{bill.amount}}
                                </td>
                                <td style="font-size: 12px;text-align: left !important;"> 
                                  {{bill.status}}
                                </td>
                              </tr>
                            </tbody>
                          </table>               
                        </div>
                      </div>  
                    </div>
                  </div>              
                </div>
              </div>
            </div>

            <div class="cover_div"  style="margin-top: 10px !important;"> 
              <div class="header_lable">
                  Physical Discharge
              </div>                      
              <div class="content_cover">             
                <div class="row">                               
                  <div class="col-12">
                    <div class="row">                                                       
                      <div class="col-4">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 142px; font-weight: 500 !important;">
                                Physical Discharge</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                <mat-checkbox color="primary" [(ngModel)]="physicalDischarge" [disabled]="physical_disable"></mat-checkbox> 
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-4">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 182px; font-weight: 500 !important;">
                                Physical Discharge Date</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">                   
                                <input class="ipcss widthbilllist" type="date" [(ngModel)]="phy_discharge_date" [min]="currentDateTime" [disabled]="physical_disable"/>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-4">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <td class="head-td" style="width: 160px; font-weight: 500 !important;">
                              Physical Discharge Time</td>
                            <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                            <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">                             
                              <div class="row">                         
                                <div class="col-3" style="padding-right:0;">
                                  <select type="date" class="ipcss inpat_width" style="width: auto !important;" [(ngModel)]="phy_discharge_time1" [disabled]="physical_disable">
                                    <option *ngFor="let hrs of phy_dischage_hrs" value="{{hrs}}">{{hrs}}
                                    </option>
                                  </select>
                                </div>
                                <div class="col-1" style="padding:0;position: relative;top: 5px;left: 11px;"> : </div>
                                <div class="col-3" style="padding-left:0;">
                                  <select type="date" class="ipcss inpat_width" style="width: auto !important;" [(ngModel)]="phy_discharge_time2" [disabled]="physical_disable">
                                    <option *ngFor="let min of phy_dischage_min" value="{{min}}">{{min}}
                                    </option>
                                  </select>
                                </div>
                                <div class="col-5">
                                  <select type="date" class="ipcss inpat_width" style="width: auto !important;" [(ngModel)]="phy_discharge_time3" [disabled]="physical_disable">
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                  </select>
                                </div>                         
                              </div>
                            </td>
                          </tbody>
                        </table>
                      </div>                                                                                 
                    </div>      
                  </div>
                </div>               
              </div>
            </div>

            <div class="cover_div"  style="margin-top: 10px !important;"> 
              <div class="header_lable">
                  House-Keeping
              </div>                      
              <div class="content_cover">             
                <div class="row">                               
                  <div class="col-12">
                    <div class="row">                                                       
                      <div class="col-6">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="width: 160px; font-weight: 500 !important;">
                              Room Verified & Cleaned</td>
                              <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                <mat-checkbox color="primary" [(ngModel)]="room_cleaned" [disabled]="physical_disable"></mat-checkbox> 
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>                                                                            
                    </div>      
                  </div>
                </div>               
              </div>
            </div>

          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
