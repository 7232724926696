<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Bill List</h5>
          </div>
          <div class="headerButtons">
            <div *ngIf="newbutton"><a *ngIf="billingType == 'patient' || billingType == 'dietician' || billingType == 'package' || billingType == 'All'" (click)="create()" ><img src="../../../assets/ui_icons/buttons/new_button.svg" class="saveimgbtn_inpatinfo"></a></div>
            <div *ngIf="newbutton"><a *ngIf="billingType == 'Diagnosis' || billingType == 'Billing'" (click)="digBillCreate()" ><img src="../../../assets/ui_icons/buttons/new_button.svg" class="saveimgbtn_inpatinfo"></a></div>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row"> 
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom">
            <mat-label class="matlabel">Billing Type<br>
              <select required class="ipcss" [(ngModel)]="billingType" required (change)="changeBillingType()"
                disableOptionCentering>
                <option value="All">All</option>
                <option value="patient">Doctor</option>
                <option [hidden]="diagnosisFlag" value="dietician">Dietician</option>
                <option [hidden]="diagnosisFlag" value="Diagnosis">Diagnosis</option>
                <option [hidden]="diagnosisFlag" value="package">Package</option>
              </select>
            </mat-label>
          </div>
        </div>
        <div class="row" *ngIf="billingType != 'dietician' && billingType != 'All' && billingType != 'package'">
          <div class="col-12" style="margin-bottom: 5px;margin-top: 5px;">
            <div class="row">
              <div class="col-12">
                <div class="switch-field" *ngIf="ynopt1 != undefined">
                  <input type="radio" id="radio-three" (click)="change_type(1,'yes')" />
                  <label for="radio-three" [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">
                    <div class="tab_cover">
                      <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Outpatient.svg" class="iocinStyle"
                          alt=""></div>
                      <div class="lableStyle">Out-patient</div>
                    </div>
                  </label>
                  <input type="radio" id="radio-four" (click)="change_type(1,'no')" />
                  <label for="radio-four" [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">
                    <div class="tab_cover">
                      <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Inpatient.svg" class="iocinStyle"
                          alt=""></div>
                      <div class="lableStyle">In-patient</div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="patientFlag">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
              <mat-label class="matlabel">Filter by<br>
                <select required class="ipcss" [(ngModel)]="filter" required (change)="changeFilter()"
                  disableOptionCentering>
                  <option value="Patient_ID">MR.No</option>
                  <option value="Location">Location</option>
                  <option value="Name">Name</option>
                  <option value="Date">Date</option>
                </select>
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
              <mat-label class="matlabel" [hidden]="toFlag">From<br>
                <input type="date" class="ipcss widthbilllist datecss" (change)="selectFromDate(fromDate)"
                  [hidden]="fromFlag" [(ngModel)]="fromDate" #matInput max="{{currentDate}}">
              </mat-label>
              <mat-label class="matlabel" [hidden]="cityFlag">City<br>
                <select required class="ipcss widthbilllist" [(ngModel)]="cityDesc" (change)="changeCity(cityDesc)"
                  disableOptionCentering>
                  <option *ngFor="let city of cityList" value={{city}}>{{city}}</option>
                </select>
              </mat-label>
              <mat-label class="matlabel" [hidden]="firstNameFlag">First name<br>
                <input #focusFName id="focusFName" matInput class="ipcss widthbilllist" type="text" [hidden]="firstNameFlag" required
                  [(ngModel)]="firstName" (blur)="fnameToUpper()" style="margin-bottom: 10px;" />
              </mat-label>
              <mat-label class="matlabel" [hidden]="uhidFlag">MR.No<br>
                <input #focusMRNo id="focusMRNo" matInput class="ipcss widthbilllist" type="text" [hidden]="uhidFlag" required
                  [(ngModel)]="patientId" (change)="getPatientListByMRNo(patientId)" />
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2" *ngIf="!locationFlag || !toFlag || !firstNameFlag ">
              <mat-label class="matlabel" [hidden]="locationFlag">Location<br>
                <mat-select required [hidden]="locationFlag" class="ipcss widthbilllist" [(ngModel)]="location"
                  disableOptionCentering>
                  <mat-option *ngFor="let location of locationList" value={{location}}>{{location}}</mat-option>
                </mat-select>
              </mat-label>
              <mat-label class="matlabel" [hidden]="toFlag">To<br>
                <input type="date" class="ipcss widthbilllist datecss" id="appt_date"
                  (change)="selectToDate(toDate)" [hidden]="toFlag" [(ngModel)]="toDate" max="{{currentDate}}"
                  matInput>
              </mat-label>
              <mat-label class="matlabel" [hidden]="firstNameFlag">Last name<br>
                <input matInput class="ipcss widthbilllist" type="text" [hidden]="firstNameFlag" required
                  [(ngModel)]="lastName" (blur)="lnameToUpper()" />
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-2 select_bottom">
              <mat-label class="matlabel">
                Filter by
              </mat-label>
              <select required class="ipcss" [(ngModel)]="appType" required
                disableOptionCentering style="height: 28px;">
                <option value="all">All</option>
                <option value="New">New</option>
                <option value="follow">Follow-up</option>
              </select>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 d-flex align-items-center">
              <mat-label class="matlabel">
                <a (click)="searchBill()"><img src="../../../assets/ui_icons/buttons/search_button.svg"
                    class="saveimgbtn_inpatinfo topvalue" /></a>
              </mat-label>
            </div>
          </div>
          <div class="row" style="margin-top: 15px;">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <p class="nodata" *ngIf="noBillsFlag">No bill(s) found</p>
              <table *ngIf="billList.length" mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm billlisttable">
                <thead>
                  <tr>
                    <th class="delete">Bill no</th>
                    <th>Date/Time</th>
                    <th>MR.No</th>
                    <th>Client name</th>  
                    <th *ngIf="billingType == 'All' || billingType == 'package'">IP/OP</th>  
                    <th>Age/Gender </th>   
                    <th>Doctor name</th>         
                    <th>Amount</th>
                    <th>Status</th>
                    <th *ngIf="billingType != 'package' && billingType != 'All' && (editbutton || deletebutton)" class="delete">Edit / Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let bill of billList; let i = index">
                    <td style="font-size: 12px;text-align: center;" (click)="viewBill(bill.bill_id,bill.doc_reg_id, bill.fin_year,bill)" >
                      {{bill.bill_id}}
                    </td>
                    <td style="font-size: 12px;text-align: center;"  (click)="viewBill(bill.bill_id,bill.doc_reg_id, bill.fin_year,bill)">
                      {{bill.date}}
                    </td>
                    <td style="font-size: 12px;text-align: center;"  (click)="viewBill(bill.bill_id,bill.doc_reg_id, bill.fin_year,bill)">
                      {{bill.mr_no}}
                    </td>
                    <td style="font-size: 12px;text-align: left !important;" (click)="viewBill(bill.bill_id,bill.doc_reg_id, bill.fin_year,bill)">
                      {{bill.salutation}}{{bill.client_name}}
                    </td>
                    <td *ngIf="billingType == 'All' || billingType == 'package'" style="font-size: 12px;text-align: center !important;" (click)="viewBill(bill.bill_id,bill.doc_reg_id, bill.fin_year,bill)">
                      {{bill.ip_op}}
                    </td>
                    <td style="font-size: 12px;text-align: center;"  (click)="viewBill(bill.bill_id,bill.doc_reg_id, bill.fin_year,bill)">
                      {{bill.age_gender}}
                    </td>
                    <td style="font-size: 12px;text-align: left;"  (click)="viewBill(bill.bill_id,bill.doc_reg_id, bill.fin_year,bill)">
                      {{bill.dr_name}}
                    </td>
                    <td style="font-size: 12px;text-align: right;" (click)="viewBill(bill.bill_id,bill.doc_reg_id, bill.fin_year,bill)">
                      {{bill.amount}}
                    </td>
                    <td style="font-size: 12px;text-align: left !important;" (click)="viewBill(bill.bill_id,bill.doc_reg_id, bill.fin_year,bill)"> 
                      {{bill.status}}
                    </td>
                    <td  style="font-size: 12px;text-align: center;" *ngIf="billingType != 'package' && billingType != 'All' && (editbutton || deletebutton)">
                      <img  *ngIf="bill.flag && editbutton" src="../../../assets/img/edit.png" class="billing_edit" style="z-index: 9;" alt="" (click)="editBill(bill.bill_id,bill.doc_reg_id,bill.doc_app_id,bill.fin_year)"> &nbsp;&nbsp;
                      <img  *ngIf="bill.flag && deletebutton" src="../../../assets/img/Delete.png" class="billing_edit" (click)="deleteBill(bill.bill_id, bill.fin_year)">
                    </td>
                  </tr>
                </tbody>
              </table>
              <table *ngIf="billingList.length" class="card_tbl table-dynamic" mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm billlisttable">
                <thead>
                  <tr>
                      <th>Bill no</th>
                      <th>Date/Time</th>
                      <th>MR.No</th>
                      <th>Client name</th>
                      <th>Age/Gender </th>   
                      <th>Doctor name</th> 
                      <th>Amount</th>
                      <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let bill of billingList" (click)="inViewBill(bill.bill_id, bill.bill_cat, bill.provider_id, bill.fin_year,bill)">
                      <td>{{bill.bill_id}}</td>
                      <td>{{bill.date}}</td>
                      <td>{{bill.mr_no}}</td>
                      <td class="align_left">{{bill.salutation}}{{bill.client_name}}</td>
                      <td>{{bill.age_gender}}</td>
                      <td class="align_left">{{bill.dr_name}}</td>
                      <td class="align_right">{{bill.amount}}</td>
                      <td class="align_left">{{bill.status}}</td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="!patientFlag">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom" [hidden]="doctorFlag">
              <mat-label class="matlabel">Doctor<br>
                <select [(ngModel)]="doctorId" class="ipcss">
                  <option>All</option>
                  <option *ngFor="let name of doctorList" value={{name.doctor_id}}>{{name.doctor_name}}</option>
                </select>
              </mat-label>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3">
              <mat-label class="matlabel">From date<br>
                <input type="date" class="ipcss widthbilllist datecss" required [(ngModel)]="fromDate"
                  max="{{currentDate}}" matInput>
              </mat-label>
            </div>
            <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3">
              <mat-label class="matlabel">To date<br>
                <input type="date" class="ipcss widthbilllist datecss" required [(ngModel)]="toDate"
                  max="{{currentDate}}" matInput>
              </mat-label>
            </div>
           
            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 d-flex align-items-center">
              <mat-label class="matlabel">
                <a (click)="searchBill()"><img src="../../../assets/ui_icons/buttons/search_button.svg"
                    class="saveimgbtn_inpatinfo topvalue" /></a>
              </mat-label>
            </div>
          </div>
          <div [hidden]="noBillsFlag" class="nodata">No bills found</div>
          <div [hidden]="billingList.length != 0" class="">
            <table *ngIf="billingList.length" class="card_tbl table-dynamic" mdbTable datatable [dtOptions]="dtOptions"
            class="table table-nowrap table-sm dataTable billlisttable">
              <thead>
                <tr>
                    <th>Bill no</th>
                    <th>Date/Time</th>
                    <th>MR.No</th>
                    <th>Client name</th>
                    <th>Age/Gender </th>   
                    <th>Doctor name</th>
                    <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let bill of billingList" (click)="inViewBill(bill.bill_id, bill.bill_cat, bill.provider_id, bill.fin_year,bill)">
                    <td>{{bill.bill_id}}</td>
                    <td>{{bill.date}}</td>
                    <td>{{bill.mr_no}}</td>
                    <td class="align_left">{{bill.client_name}}</td>
                    <td>{{bill.age_gender}}</td>
                    <td class="align_left">{{bill.dr_name}}</td>
                    <td class="align_right">{{bill.amount}}</td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>