<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white" style="border-bottom: 0 !important;" >
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Refund bill details</h5>
            </div>
            <div class="headerButtons">
              <a (click)="back()">
                <img src="../../../assets/ui_icons/buttons/Back_button.svg"
                  class="saveimgbtn_inpatinfo" />
              </a>&nbsp;
              <a *ngIf="printbutton" (click)="print()">
                <img src="../../../assets/img/printer.svg"
                  class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" />
              </a>
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body>
          <div *ngFor="let billprint of billingDetailList">         
            <div class="row">
              <div class="col-12">
                <div class="cover_div" style="font-size: 13px !important;">
                  <div class="header_lable">
                     Patient details
                  </div>
                  <div class="content_cover">                              
                    <div class="row">
                      <div class="col-2" style="text-align: center;">
                        <img src="{{billprint.profile_image}}" onerror="this.src='../../../assets/img/default.jpg';"
                          class="profileimage">
                      </div>
                      <div class="col-10">
                        <div class="row">                      
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                    MR No</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.client_hosp_id}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                    Patient name</td>
                                  <td class="head-td" style="width: 10px; font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.client_name}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                    Age/Gender</td>
                                  <td class="head-td" style="width: 10px; font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    {{billprint.age}} <span *ngIf="billprint.gender != '' && billprint.gender != undefined">/</span> {{billprint.gender}}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3" *ngIf="billprint.mobile != undefined && billprint.mobile != ''">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                    Mobile</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.mobile}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>      
                          <div class="col-3" *ngIf="billprint.dr_name != '' && billprint.dr_name != undefined">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                    Doctor</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.dr_name}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div> 
                          <div class="col-3" *ngIf="inpatientflag">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                    Inpatient Id</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.inpatientid}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3" *ngIf="!inpatientflag">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                    Visit Id</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.inpatientid}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3" *ngIf="inpatientflag">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                    DOA</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    {{billprint.admitteddate | date : "dd-MM-yyyy"}} {{billprint.admittedtime}}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>                   
                          <div class="col-3" *ngIf="billprint.wardname != undefined && billprint.wardname != '' && inpatientflag">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                    Ward/Bed</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.wardname}} <span *ngIf="billprint.bedno != undefined && billprint.bedno != ''">/</span> {{billprint.bedno}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>                      
                          <div class="col-3" *ngIf="billprint.created_date != undefined && billprint.created_date != ''">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                    Bill Date/Time</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{billprint.created_date}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3" *ngIf="billId != undefined && billId != ''">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                    Bill No</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    {{billId}} 
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>        
                          <div class="col-3" [hidden]="mainBalanceFlag">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                    Amount due(र)</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    {{billAmount}} 
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>   
                          <div class="col-3" *ngIf="credit_type != undefined">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                    Credit bill</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    {{credit_type}} 
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>                                 
                          <div class="col-6">
                            <table style="width: 100%;" class="head-table">
                                <tbody>
                                  <tr class="head-tr">
                                      <td class="head-td" style="width: 91px; font-weight: 500 !important;">
                                        Address</td>
                                      <td class="head-td" style="width: 10px; font-weight: 500 !important;">:&nbsp;</td>
                                      <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                        {{billprint.caddress1}} {{billprint.caddress2}} {{billprint.clocation}} {{ billprint.clientcity}} {{billprint.clestare}}
                                    </td>
                                  </tr>                 
                                </tbody>
                            </table>
                          </div>        
                        </div>                 
                      </div>                    
                    </div>
                  </div>
                </div>          
              </div>
            </div>
            <div class="row">
              <div class="col-12"   style="font-family: Arial, Helvetica, sans-serif;"> 
                <p  
                  class="tervys_heading" style="font-weight: 600;">Bill particulars</p> 
                  <div class="table-responsive dig_table_overflow" >
                    <table id="card_tbl"  class="table table-hover table-nowrap table-sm" > 
                      <thead class="thvalues">
                        <tr style="height: 25px;">
                          <th style="width: 5%;">Sno</th>
                          <th style="width: 10%;">Service</th>
                          <th style="width: 5%;" [hidden]="paytypreq">Payment type</th>
                          <th style="width: 3%;">Fee</th>
                          <th style="width: 3%;" [hidden]="discount_flag">Discount(%)</th>
                          <th style="width: 3%;" [hidden]="!bill_qty_flag || !inpatient_flag">Quantity</th>
                          <th style="width: 3%;" [hidden]="bill_qty_flag && discount_flag && !inpatient_flag">Amount</th>                              
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let person of billingPrintView">
                          <td class="billlistcenter"> {{person.sno}} </td>
                          <td class="billlistleft"> {{person.bill_type}} </td>
                          <td class="billlistleft" [hidden]="paytypreq"> {{person.pay_type}} </td>
                          <td class="billlistright" [hidden]="!bill_qty_flag"> {{person.amount_txt}} </td>
                          <td class="billlistright" [hidden]="bill_qty_flag"> {{person.amount_txt}} x {{person.quantity}}</td>
                          <td class="billlistright" [hidden]="discount_flag"> {{person.concession}} </td>
                          <td class="billlistright" [hidden]="!bill_qty_flag || !inpatient_flag"> {{person.quantity}} </td>
                          <td class="billlistright" [hidden]="multiply_flag"> {{person.multiply_value}} </td>                 
                          <td class="billlistright" [hidden]="discount_flag"> {{person.fee}} </td>                                          
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </div>           
            </div>
            <div [hidden]="treatmentPlanFlag">
              <p class="casesheet_para">Treatement plan</p>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" [hidden]="treatmentPlanFlag">
              <table mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable">
                <thead>
                  <tr>
                    <th>Plan</th>
                    <th class="tbl_txt_center">Estimate</th>
                    <th class="tbl_txt_center">Select</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let person of estimatePrintData let i = index">
                    <td class="billlistleft">{{person.treatment_desc}}</td>
                    <td class="billlistright">{{person.estimate}}</td>
                  </tr>
                </tbody>
              </table>
              <br><br><br>
            </div>
            <div class="row">
              <div class="col-4 col-sm-4 col-md-8 col-lg-8 col-xl-9">
                <div class="col-4 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <table  style="margin-top: 10px;font-family: Arial, Helvetica, sans-serif !important;"> 
                    <tr *ngFor="let pay of paymentsarray">
                      <td style="width: 150px !important;font-size: 12px;">{{pay.pay_desc}}</td>
                      <td style="width: 150px !important;font-size: 12px;text-align: right;">{{pay.amount}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="col-8 col-sm-8 col-md-4 col-lg-4 col-xl-3" style="margin-top: 10px;">
                <div class="row ">
                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="cgstFlag">
                    <mat-label class="matlabel ">CGST ({{billprint.cgst}}%) र </mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="cgstFlag">
                    <span class="billlistright">{{cgst}} </span>
                  </div>

                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="sgstFlag">
                    <mat-label class="matlabel ">SGST ({{billprint.cgst}}%) र </mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="sgstFlag">
                    <span class="billlistright">{{sgst}} </span>
                  </div>

                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="prevBalanceFlag">
                    <mat-label class="matlabel"> Previous balance र</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="prevBalanceFlag">
                    <span class="billlistright">{{prevBalance}}</span>
                  </div>

                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="totalBillAmount == billAmount">
                    <mat-label class="matlabel "> Total र</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="totalBillAmount == billAmount">
                    <span class="">{{totalBillAmount}} </span>
                  </div>

                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="discount_amount == 0">
                    <mat-label class="matlabel "> Discount <span [hidden]="!discount_flag">({{concession_txt}} %)</span> र</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="discount_amount == 0">
                    <span class="">{{discount_amount}} </span>
                  </div>

                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="totalFlag">
                    <mat-label class="matlabel "> Bill amount र</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="totalFlag">
                    <span class="">{{billAmount}} </span>
                  </div>

                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" [hidden]="billAdvanceFlag">
                    <mat-label class="matlabel">Advance र</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" [hidden]="billAdvanceFlag">
                    <span class="">{{advancevalue}} </span>
                  </div>

                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight" >
                    <mat-label class="matlabel">Paid र</mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright" >
                    <span class="">{{paid}} </span>
                  </div>

                  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-8 alignRight">
                    <mat-label class="matlabel">Balance र </mat-label>
                  </div>
                  <div class="col-4 col-sm-4 col-md-5 col-lg-6 col-xl-4 billlistright">
                    <span class="">{{billprint.balance}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div [hidden]="true" #printbanner id="printbanner">
            <table style="border:'0';width:100%">             
             <thead>
              <tr>
                <th style="width:100%;">                                 
                  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                    <div style="width: 100%; height: 205px !important;" *ngFor="let billprint of billingDetailList"> 
                      <div style="width: 100%;">
                        <img alt="image" src={{hospitalLogo}}  [style]="printlogostyle">
                      </div>                                                             
                      <table style="width: 100%;font-size: 12px;border: 1px solid black;margin-top: 15px;margin-bottom: 5px;border-radius: 10px;height: 95px;"> 
                        <tbody>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                            <td>: {{billprint.client_name}} </td>
                            <td style="padding-left: 5px;"><strong>Address</strong></td>
                            <td>: {{billprint.caddress1}} {{billprint.caddress2}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                            <td>: {{billprint.age}} / {{billprint.gender}}</td>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td><span class="address_visibility">:</span> {{billprint.clocation}} {{billprint.clientcity}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                            <td>: {{billprint.dr_name}}</td>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td><span class="address_visibility">:</span> {{billprint.clestare}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                            <td>: {{billId}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                            <td>:  {{billprint.created_date}}</td>
                          </tr>
                          <tr *ngIf="surgery != undefined && surgery != ''">
                            <td style="padding-left: 5px;"><strong>Surgery name</strong></td>                             
                            <td>: {{surgery}}</td>                                                   
                          </tr>                         
                          <tr *ngIf="credit_type != undefined && credit_type != ''">
                            <td style="padding-left: 5px;"><strong>Credit bill</strong></td>                             
                            <td>: {{credit_person}}</td>
                          </tr>                        
                          <tr>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td>
                            </td> 
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td *ngIf="barcode_flag">
                              <img [style]="barcode_style"
                                src={{barcode_url}} /></td> 
                          </tr>                         
                        </tbody>
                      </table>
                    </div>
                  </div>               
                </th>
              </tr>
            </thead>    
             <tbody>  
               <tr>  
                 <td width="100%">  
                  <p [style]="p_tag_style"><b>Bill/Receipt</b></p>
                  <div class="bill_border">
                    <div *ngFor="let billprint of billingDetailList">          
                      <div [hidden]="billingPrintViewFlag" style="margin-left: 20px;margin-right: 10px;">
                        <div class="row">
                          <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">                                   
                            <thead>
                              <tr>
                                <th style="width: 70px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                                <th style="width: 380px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                                <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="paytypreq"><b>Payment type</b></th>
                                <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th>
                                <th [hidden]="discount_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Discount(%)</th>
                                <th [hidden]="!bill_qty_flag || !inpatient_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Quantity</th>
                                <th [hidden]="bill_qty_flag && discount_flag && !inpatient_flag" style="width: 170px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let billprinttable of billingPrintView">
                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                                  data-th="sno">{{billprinttable.sno}}</td>
                                <td style="text-align: left;border: 1px solid black;border-collapse: collapse;"
                                  data-th="Type">{{billprinttable.bill_type}}</td>
                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                                  data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                                <td [style]="amount_td_style"
                                  data-th="Fee" [hidden]="!bill_qty_flag">{{billprinttable.amount_txt}}</td>
                                <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                  data-th="Fee" [hidden]="bill_qty_flag">{{billprinttable.amount_txt}} x {{billprinttable.quantity}}</td>
                                <td style="text-align: right;border: 1px solid black;border-collapse: collapse;"
                                  data-th="Type" [hidden]="discount_flag">{{billprinttable.concession}}</td>
                                <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                  data-th="Fee"[hidden]="!bill_qty_flag || !inpatient_flag"> {{billprinttable.quantity}} </td>
                                <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                  data-th="Fee" [hidden]="multiply_flag">{{billprinttable.multiply_value}}</td> 
                                <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                  data-th="Fee" [hidden]="discount_flag">{{billprinttable.fee}}</td>
                              </tr>
                            </tbody> 
                          </table>
                        </div>
                      </div>
                      <div style="width: 100%;">
                        <table  style="margin-bottom: -20px;">
                          <tr>
                            <td style="vertical-align: top; width: 26%;">
                              <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 15px;" *ngIf="paymentsarray !=0">
                                <tr *ngFor="let pay of paymentsarray">
                                  <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                  <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                                </tr>
                              </table>            
                            </td>                           
                            <td style="vertical-align: top;width: 31%;">
                              <table  style="float: right;margin-left: 110px;margin-right: 25px;" *ngIf="paymentsarray.length != 0">             
                                <tr [hidden]="cgstFlag">
                                  <th style="text-align: left;"><strong>Central GST</strong> % </th>
                                  <td style="text-align: right; width: 100px;" data-th="Central GST"
                                    class="fee_txt">
                                    {{cgst}}</td>
                                </tr>
                                <tr [hidden]="sgstFlag">
                                  <th style="text-align: left;"><strong>State GST</strong> % </th>
                                  <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                    {{sgst}}</td>
                                </tr>        
                                <tr [hidden]="prevBalanceFlag" > 
                                  <th style="text-align: left;">Previous balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                    class="fee_txt">{{prevBalance}}</td>
                                </tr>  
                                <tr [hidden]="totalBillAmount == billAmount">
                                  <th style="text-align: left;">Total (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                    {{totalBillAmount}}</td>
                                </tr>
                                <tr *ngIf="discount_amount != undefined && discount_amount != 0">
                                  <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession_txt}} %)</span> (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                    {{discount_amount}}</td>
                                </tr>
                                <tr [hidden]="billAmount == undefined || billAmount == 0">
                                  <th style="text-align: left;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                    class="fee_txt">
                                    {{billAmount}}</td>
                                </tr>
                                <tr [hidden]="billAdvanceFlag || advancevalue == 0">
                                  <th style="text-align: left;">Advance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{advancevalue}}</td>
                                </tr>
                                <tr [hidden]="paid == billAmount">
                                  <th style="text-align: left;">Paid (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{paid}}</td>
                                </tr>
                                <tr [hidden]="paid == billAmount || billprint.balance == 0">
                                  <th style="text-align: left;">Balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                    {{billprint.balance}}</td>
                                </tr> 
                              </table>
                              <table  style="float: right;margin-left: 120px;margin-right: 25px;" *ngIf="paymentsarray.length == 0">             
                                <tr [hidden]="cgstFlag">
                                  <th style="text-align: left;"><strong>Central GST</strong> % </th>
                                  <td style="text-align: right; width: 100px;" data-th="Central GST"
                                    class="fee_txt">
                                    {{cgst}}</td>
                                </tr>
                                <tr [hidden]="sgstFlag">
                                  <th style="text-align: left;"><strong>State GST</strong> % </th>
                                  <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                    {{sgst}}</td>
                                </tr>        
                                <tr [hidden]="prevBalanceFlag" > 
                                  <th style="text-align: left;">Previous balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                    class="fee_txt">{{prevBalance}}</td>
                                </tr>  
                                <tr [hidden]="totalBillAmount == billAmount">
                                  <th style="text-align: left;">Total (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                    {{totalBillAmount}}</td>
                                </tr>
                                <tr *ngIf="discount_amount != undefined && discount_amount != 0">
                                  <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession_txt}} %)</span> (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                    {{discount_amount}}</td>
                                </tr>
                                <tr [hidden]="billAmount == undefined || billAmount == 0">
                                  <th style="text-align: left;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                    class="fee_txt">
                                    {{billAmount}}</td>
                                </tr>
                                <tr [hidden]="billAdvanceFlag || advancevalue == 0">
                                  <th style="text-align: left;">Advance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{advancevalue}}</td>
                                </tr>
                                <tr [hidden]="paid == billAmount">
                                  <th style="text-align: left;">Paid (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{paid}}</td>
                                </tr>
                                <tr [hidden]="paid == billAmount || billprint.balance == 0">
                                  <th style="text-align: left;">Balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                    {{billprint.balance}}</td>
                                </tr> 
                              </table>
                            </td>                        
                          </tr>
                        </table>
                      </div>
                      <div  style="width: 100%;margin-left: 20px;margin-top: 15px;">
                        <div class="row">
                          <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;" *ngIf="numToWords != undefined && paid != 0">
                            <tr>
                              <td><strong>In Words:</strong>&nbsp;Refunded Rupees&nbsp;{{billprint.numToWords}}&nbsp;Only</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>                           
                 </td>
               </tr>
             </tbody>
             <tfoot style="margin-bottom: 10px;"> 
              <tr>  
                <td width="100%">  
                  <table style="width: 100%;">  
                    <tr>  
                      <td style="vertical-align: top;width:50%;">
                        <table style="float: left !important;margin-left: 15px;">
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                        </table> 
                      </td>  
                      <td style="vertical-align: top;width:50%;">
                        <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                          <tr>
                            <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                              <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                              <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                            </div>                       
                          </tr>
                        </table>                      
                      </td>
                    </tr>  
                  </table> 
                </td>
              </tr> 
             </tfoot>
            </table>
          </div>

          <div [hidden]="true" #printnoheader id="printnoheader">
            <table style="border:'0';width:100%">  
              <thead>
                <tr>
                  <th style="width:100%;">
                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                      <div [style]="nobanner_style" *ngFor="let billprint of billingDetailList">
                        <div style="height: 5px !important;"></div>
                        <table style="width: 100%;font-size: 12px;border: 1px solid black;border-radius: 10px;height: 85px;"> 
                          <tbody>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                              <td>: {{billprint.client_name}} </td>
                              <td style="padding-left: 5px;"><strong>Address</strong></td>
                              <td>: {{billprint.caddress1}} {{billprint.caddress2}}</td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                              <td>: {{billprint.age}} / {{billprint.gender}}</td>
                              <td style="padding-left: 5px;"><strong></strong></td>
                              <td><span class="address_visibility">:</span> {{billprint.clocation}} {{billprint.clientcity}}</td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                              <td>: {{billprint.dr_name}}</td>
                              <td style="padding-left: 5px;"><strong></strong></td>
                              <td><span class="address_visibility">:</span> {{billprint.clestare}}</td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                              <td>: {{billId}}</td>
                            </tr>
                            <tr>
                              <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                              <td>:  {{billprint.created_date}}</td>
                            </tr>
                            <tr *ngIf="surgery != undefined && surgery != ''">
                              <td style="padding-left: 5px;"><strong>Surgery name</strong></td>                             
                              <td>: {{surgery}}</td>                                                   
                            </tr>                         
                            <tr *ngIf="credit_type != undefined && credit_type != ''">
                              <td style="padding-left: 5px;"><strong>Credit bill</strong></td>                             
                              <td>: {{credit_person}}</td>
                            </tr>                        
                            <tr>
                              <td style="padding-left: 5px;"><strong></strong></td>
                              <td>
                              </td> 
                              <td style="padding-left: 5px;"><strong></strong></td>
                              <td *ngIf="barcode_flag">
                                <img [style]="barcode_style"
                                  src={{barcode_url}} /></td> 
                            </tr>                         
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody style="width: 100%;">  
                <tr>  
                 <td width="100%">  
                  <p style="text-align: center;font-size: 16px;margin: 0px;margin-bottom: 3px;margin-right: 10px;"><b>Bill/Receipt</b></p>
                  <div class="bill_border">
                    <div *ngFor="let billprint of billingDetailList">
                      <div [hidden]="billingPrintViewFlag" style="margin-left: 20px;margin-right: 10px;">
                        <div class="row">
                          <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                            <thead>
                              <tr>
                                <th style="width: 70px;border: 1px solid black;border-collapse: collapse;"><b>Sno</b></th>
                                <th style="width: 380px;border: 1px solid black;border-collapse: collapse;"><b>Service</b></th>
                                <th style="width: 150px;border: 1px solid black;border-collapse: collapse;" [hidden]="paytypreq"><b>Payment type</b></th>
                                <th style="width: 150px;border: 1px solid black;border-collapse: collapse;"><b>Fee</b></th>
                                <th [hidden]="discount_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Discount(%)</th>
                                <th [hidden]="!bill_qty_flag || !inpatient_flag" style="width: 150px;border: 1px solid black;border-collapse: collapse;">Quantity</th>
                                <th [hidden]="bill_qty_flag && discount_flag && !inpatient_flag" style="width: 170px;border: 1px solid black;border-collapse: collapse;"><b>Amount</b></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let billprinttable of billingPrintView">
                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                                  data-th="sno">{{billprinttable.sno}}</td>
                                <td style="text-align: left;border: 1px solid black;border-collapse: collapse;"
                                  data-th="Type">{{billprinttable.bill_type}}</td>
                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                                  data-th="Type" [hidden]="paytypreq">{{billprinttable.pay_type}}</td>
                                <td [style]="amount_td_style"
                                  data-th="Fee" [hidden]="!bill_qty_flag">{{billprinttable.amount_txt}}</td>
                                <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                  data-th="Fee" [hidden]="bill_qty_flag">{{billprinttable.amount_txt}} x {{billprinttable.quantity}}</td>
                                <td style="text-align: right;border: 1px solid black;border-collapse: collapse;"
                                  data-th="Type" [hidden]="discount_flag">{{billprinttable.concession}}</td>
                                <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                  data-th="Fee"[hidden]="!bill_qty_flag || !inpatient_flag"> {{billprinttable.quantity}} </td>
                                <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                  data-th="Fee" [hidden]="multiply_flag">{{billprinttable.multiply_value}}</td> 
                                <td style="text-align: right; border: 1px solid black;border-collapse: collapse;"
                                  data-th="Fee" [hidden]="discount_flag">{{billprinttable.fee}}</td>
                              </tr>
                            </tbody> 
                          </table>
                        </div>
                      </div>                  
                      <div style="width: 100%;">
                        <table style="margin-bottom: -20px;">
                          <tr>
                            <td style="vertical-align: top; width: 26%;">
                              <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 15px;">
                                <tr *ngFor="let pay of paymentsarray">
                                  <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                  <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                                </tr>
                              </table>              
                            </td>                           
                            <td style="vertical-align: top;width: 31%;">
                              <table  style="float: right;margin-left: 110px;margin-right: 25px;" *ngIf="paymentsarray.length != 0">             
                                <tr [hidden]="cgstFlag">
                                  <th style="text-align: left;"><strong>Central GST</strong> % </th>
                                  <td style="text-align: right; width: 100px;" data-th="Central GST"
                                    class="fee_txt">
                                    {{cgst}}</td>
                                </tr>
                                <tr [hidden]="sgstFlag">
                                  <th style="text-align: left;"><strong>State GST</strong> % </th>
                                  <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                    {{sgst}}</td>
                                </tr>        
                                <tr [hidden]="prevBalanceFlag" > 
                                  <th style="text-align: left;">Previous balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                    class="fee_txt">{{prevBalance}}</td>
                                </tr>  
                                <tr [hidden]="totalBillAmount == billAmount">
                                  <th style="text-align: left;">Total (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                    {{totalBillAmount}}</td>
                                </tr>
                                <tr *ngIf="discount_amount != undefined && discount_amount != 0">
                                  <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession_txt}} %)</span> (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                    {{discount_amount}}</td>
                                </tr>
                                <tr [hidden]="billAmount == undefined || billAmount == 0">
                                  <th style="text-align: left;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                    class="fee_txt">
                                    {{billAmount}}</td>
                                </tr>
                                <tr [hidden]="billAdvanceFlag || advancevalue == 0">
                                  <th style="text-align: left;">Advance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{advancevalue}}</td>
                                </tr>
                                <tr [hidden]="paid == billAmount">
                                  <th style="text-align: left;">Paid (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{paid}}</td>
                                </tr>
                                <tr [hidden]="paid == billAmount || billprint.balance == 0">
                                  <th style="text-align: left;">Balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                    {{billprint.balance}}</td>
                                </tr> 
                              </table>
                              <table  style="float: right;margin-left: 120px;margin-right: 25px;" *ngIf="paymentsarray.length == 0">             
                                <tr [hidden]="cgstFlag">
                                  <th style="text-align: left;"><strong>Central GST</strong> % </th>
                                  <td style="text-align: right; width: 100px;" data-th="Central GST"
                                    class="fee_txt">
                                    {{cgst}}</td>
                                </tr>
                                <tr [hidden]="sgstFlag">
                                  <th style="text-align: left;"><strong>State GST</strong> % </th>
                                  <td style="text-align: right;width: 100px;" data-th="State GST" class="fee_txt">
                                    {{sgst}}</td>
                                </tr>        
                                <tr [hidden]="prevBalanceFlag" > 
                                  <th style="text-align: left;">Previous balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                    class="fee_txt">{{prevBalance}}</td>
                                </tr>  
                                <tr [hidden]="totalBillAmount == billAmount">
                                  <th style="text-align: left;">Total (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                    {{totalBillAmount}}</td>
                                </tr>
                                <tr *ngIf="discount_amount != undefined && discount_amount != 0">
                                  <th style="text-align: left;">Discount <span [hidden]="!discount_flag">({{concession_txt}} %)</span> (र) </th>
                                  <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                    {{discount_amount}}</td>
                                </tr>
                                <tr [hidden]="billAmount == undefined || billAmount == 0">
                                  <th style="text-align: left;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                    class="fee_txt">
                                    {{billAmount}}</td>
                                </tr>
                                <tr [hidden]="billAdvanceFlag || advancevalue == 0">
                                  <th style="text-align: left;">Advance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{advancevalue}}</td>
                                </tr>
                                <tr [hidden]="paid == billAmount">
                                  <th style="text-align: left;">Paid (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                    class="fee_txt">
                                    {{paid}}</td>
                                </tr>
                                <tr [hidden]="paid == billAmount || billprint.balance == 0">
                                  <th style="text-align: left;">Balance (र)</th>
                                  <td style="text-align: right;width: 100px;" data-th="Balance" class="fee_txt">
                                    {{billprint.balance}}</td>
                                </tr> 
                              </table>
                            </td>                        
                          </tr>
                        </table>
                      </div>                                  
                      <div  style="width: 100%;margin-left: 20px;margin-top: 15px;">
                        <div class="row">
                          <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;" *ngIf="numToWords != undefined && paid != 0">
                            <tr>
                              <td><strong>In Words:</strong>&nbsp;Refunded Rupees&nbsp;{{billprint.numToWords}}&nbsp;Only</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>                           
                 </td>
                </tr>
              </tbody>
              <tfoot style="margin-bottom: 10px;"> 
                <tr>  
                  <td width="100%">  
                    <table style="width: 100%;">  
                      <tr>  
                        <td style="vertical-align: top;width:50%;">
                          <table style="float: left !important;margin-left: 15px;">
                            <tr>
                              <td>&nbsp;</td>
                            </tr>
                          </table> 
                        </td>  
                        <td style="vertical-align: top;width:50%;">
                          <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                            <tr>
                              <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                                <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                                <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                              </div>                       
                            </tr>
                          </table>                      
                        </td>
                      </tr>  
                    </table> 
                  </td>
                </tr> 
              </tfoot> 
            </table>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>