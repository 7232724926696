import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Time_Formate, convertNumberToWords } from 'src/assets/js/common';

@Component({
  selector: 'app-diagbilldetailview',
  templateUrl: './diagbilldetailview.component.html',
  styleUrls: ['./diagbilldetailview.component.css']
})
export class DiagbilldetailviewComponent implements OnInit {
  @ViewChild('noheader') noheader: ElementRef;
  @ViewChild('banner') banner: ElementRef;
  @ViewChild('logowithname') logowithname: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  public billID: string;
  dtOptions: DataTables.Settings = {};
  public header_footer_flag: boolean;
  public loader: boolean;
  public diagID;
  public billDetails;
  public printFlag: boolean;
  public centralGSTFlag;
  public stateGSTFlag;
  public centralGST;
  public stateGST;
  public billingDetails:any = [];
  public billPrintConcseFlag: boolean;
  public totalShow: boolean;
  public advanceFlag: boolean;
  public prevBlance;
  public prevBalFlag: boolean;
  public estimate;
  public treatmentID: boolean;
  public hospitalAddFlag: boolean;
  public address: string;
  public clientaddress1: string;
  public total: any;
  public totalAmount: string;
  public billPrintArray:any = [];
  public clientMidName: string;
  public hospitalLogo;
  public paid;
  public printtemplate: string;
  private finyear:string;
  public noaddress:boolean=false;
  public mobile;
  public print_style: string;
  public paymentsarray:any=[];
  public paytypreq:boolean=false;
  public main_balshow: boolean;
  public credit_type: string;
  public credit_person:string;
  public Moduleidlist:any = [];
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public ageval: any;
  public genderval: any;
  public numToWords: string;
  public bill_head: string;
  public totalBillAmount: any;
  public billingDetailedViewData: any;
  public billingPrintViewFlag: boolean;
  public print_size: any;
  public discount_amount: any;
  public concession: any;
  public  clientaddress2: string;
  public inpatientflag:boolean=false;
  public profile_image: string;
  public displayValue = true;
  public bill_qty_flag: boolean=false;
  public discount_flag:boolean = false;
  public logUserName;
  public barcode_flag:boolean = false;
  public barcode_url;
  public barcode_style: string;
  public nobanner_style;
  public inpatient_flag:boolean = false;
  public multiply_flag: boolean = true;
  public amount_td_style;
  public p_tag_style: any;
  lineColor = '#000000';
  elementType: 'svg';
  format:
    | ''
    | 'CODE128'
    | 'CODE128A'
    | 'CODE128B'
    | 'CODE128C'
    | 'EAN'
    | 'UPC'
    | 'EAN8'
    | 'EAN5'
    | 'EAN2'
    | 'CODE39'
    | 'ITF14'
    | 'MSI'
    | 'MSI10'
    | 'MSI11'
    | 'MSI1010'
    | 'MSI1110'
    | 'pharmacode'
    | 'codabar';
  width = 1.0;
  barcodeheight = 23;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 15;
  background = '#ffffff';
  flow:string;
 
  constructor(public toastr: ToastrService, public http: Http, public diagnosisservice: MenuViewService, public frontdeskservice: MenuViewService ,public adminservice:MenuViewService) {
    this.discount_flag = false;
  }
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2,] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };

    this.profile_image = "../../assets/img/default.jpg";
    
    this.Moduleidlist = Helper_Class.getmodulelist();
    if (this.Moduleidlist != undefined && this.Moduleidlist.length != 0) {
      for (var i = 0; i < this.Moduleidlist.length; i++) {
        if (this.Moduleidlist[i].module_id == "7") {
          if (this.Moduleidlist[i].edit == "1") {
            this.editbutton = true;
          }                    
          if (this.Moduleidlist[i].create == "1") {
            this.newbutton = true;
          }
          if (this.Moduleidlist[i].delete == "1") {
            this.deletebutton = true;
          }
          if (this.Moduleidlist[i].print == "1") {
            this.printbutton = true;
          }
          if (this.Moduleidlist[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    }

    if (Helper_Class.getInfo().hptl_name == 'SGH') {
      this.p_tag_style = "text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;margin-top: 10px;";
    } else {
      this.p_tag_style = "text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;";
    }
    
    this.discount_amount = 0;
    this.billDetails = Diagnosis_Helper.getbilldetailview();
    if(this.billDetails.bill_view_id != undefined) {
      this.billID = this.billDetails.bill_view_id;
      this.flow="diag_view"
    } else {
      this.billID = this.billDetails.pkg_bill_id;
      this.flow="package_view"
    }

    this.finyear = this.billDetails.fin_year;
    this.bill_head = Helper_Class.getInfo().bill_heading;
    this.print_size = Helper_Class.getInfo().bill_print_paper;
    
    if( Helper_Class.getLoginType() == "diagnosis" ){
      this.diagID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().hospitals[0].bill_print_logo;
      this.printtemplate = Helper_Class.getInfo().bill_print_template;
      this.printFlag = Helper_Class.getInfo().hospitals[0].bill_print == "1" ? true : false;
      this.print_style=Helper_Class.getInfo().hospitals[0].bill_print_logo_style;
      
    } else{
      this.diagID = Helper_Class.getInfo().diag_center_id;
      this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
      this.printtemplate = Helper_Class.getInfo().bill_print_template;
      this.print_style=Helper_Class.getInfo().bill_print_logo_style;
      this.printFlag = Helper_Class.getBillPrint() == "1" ? true : false;
    }
    
    this.hospitalAddFlag = true;
    this.billPrintConcseFlag = true;
    this.treatmentID = true;
    this.centralGSTFlag = true;
    this.stateGSTFlag = true;
    this.prevBalFlag = true;
    this.billingPrintViewFlag = true;
    if(Helper_Class.getInfo().user_type=="diagnosis"){
      if(Helper_Class.getInfo().hospitals[0].bill_pay_type_req =="1"){
        this.paytypreq=true;
      } else {
        this.paytypreq=false;
      }    
      
    } else {
      if(Helper_Class.getInfo().bill_pay_type_req =="1"){
        this.paytypreq=true;
      } else {
        this.paytypreq=false;
      }
    }

    if(Helper_Class.getInfo().bill_qty_flag == 0){
      this.bill_qty_flag = true;
    } else {
      this.bill_qty_flag = false;
    }

    if (Helper_Class.getInfo().first_name != undefined) {
      this.logUserName = Helper_Class.getInfo().first_name;
      if (Helper_Class.getInfo().last_name != undefined) {
        this.logUserName = Helper_Class.getInfo().first_name +" "+ Helper_Class.getInfo().last_name;
      }
    }

    this.getBillDetails();
  }

  getBillDetails() {
    this.billPrintArray = [];
    this.loader = false;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbill/',
      JSON.stringify({
        doc_reg_id: this.diagID,
        billing_id: this.billID,
        app_type: "diagnosis",
        fin_year:this.finyear,
        app_flow:this.flow
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("BillDetails",obj);
          if (obj.bills != null) {
            this.loader = true;
            var cgst;
            if (obj.cgst != null) {
              this.centralGSTFlag = false;
              this.stateGSTFlag = false;
            }

            if (obj.rate_card_desc == 'Service wise') {                  
              this.discount_flag = false;                 
            } else {
              this.discount_flag = true;
            }

            if (obj.inp_hosp_id != undefined && obj.inp_hosp_id != null && obj.inp_hosp_id != "") {
              this.inpatient_flag = true;
            } else {
              this.inpatient_flag = false;
            }

            if (this.bill_qty_flag == true && !this.discount_flag == false && this.inpatient_flag == false) {
              this.multiply_flag = true;
            } else if (this.bill_qty_flag == true && !this.discount_flag == true && this.inpatient_flag == false) {
              this.multiply_flag = true;
            } else if (this.bill_qty_flag == true && !this.discount_flag == false && this.inpatient_flag == true) {
              this.multiply_flag = false;
            } else if (this.bill_qty_flag == true && !this.discount_flag == true && this.inpatient_flag == true) {
              this.multiply_flag = true;
            } else if (this.bill_qty_flag == false && !this.discount_flag == false && this.inpatient_flag == false) {
              this.multiply_flag = false;
            } else if (this.bill_qty_flag == false && !this.discount_flag == true && this.inpatient_flag == false) {
              this.multiply_flag = true;
            } else if (this.bill_qty_flag == false && !this.discount_flag == false && this.inpatient_flag == true) {
              this.multiply_flag = false;
            } else if (this.bill_qty_flag == false && !this.discount_flag == true && this.inpatient_flag == true) {
              this.multiply_flag = true;
            }

            if (this.bill_qty_flag == true && this.discount_flag == true && this.inpatient_flag == false) {
              this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse;width: 300px";
            } else {
              this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse";
            }
            
            if (obj.bills != null && obj.bills.length != 0) {
              this.billingDetailedViewData = obj.bills;
              this.billingPrintViewFlag = this.billingDetailedViewData != null && this.billingDetailedViewData[0].test_id != null ?  false : true;
              var sno=0;
              for (var i = 0; i < obj.bills.length; i++) {   
                if (obj.bills[i].amount_txt == '') {
                  obj.bills[i].amount_txt = 0;
                }   
                var multiply_value;
                multiply_value = (parseFloat(obj.bills[i].amount_txt) * parseFloat(obj.bills[i].quantity));   
                
                if (obj.bills[i].concession == undefined) {
                  obj.bills[i].concession = 0;
                } 
                if (obj.bills[i].fee == undefined) {
                  obj.bills[i].fee = 0; 
                }

                sno +=1;
                this.billingDetails.push({
                  sno:sno,
                  test_name: obj.bills[i].test_name,
                  amount_txt: parseFloat(obj.bills[i].amount_txt),
                  fee: parseFloat(obj.bills[i].fee),
                  pay_type: obj.bills[i].pay_type,
                  concession: parseFloat(obj.bills[i].concession),
                  quantity: obj.bills[i].quantity,
                  multiply_value: parseFloat(multiply_value),
                  finalfee: parseFloat(obj.bills[i].fee)
                });
                this.concession = obj.bills[i].concession;
              }
            }
            
            if (obj.balance == undefined || obj.balance == "")
            this.main_balshow = true;

            if(obj.rate_card == undefined || obj.rate_card == null){
              this.discount_flag = true;
            }

            if(obj.profile_image != ""){
              this.profile_image = ipaddress.Ip_with_img_address + obj.profile_image;
            }

            if(obj.credit_type != undefined){
              this.credit_type = obj.credit_type;
              this.credit_person = obj.credit_person;
            } else {
              this.credit_type = '';
              this.credit_person = '';
            }

            this.billPrintConcseFlag = obj.bills != null && obj.bills[0].test_name != null ? false : true;
            this.totalShow = obj.bill_amount == undefined && obj.bill_amount == "" ? true : false;
            this.advanceFlag = obj.advance == undefined || obj.advance == "" || obj.advance == 0 ? true : false;
            
            this.estimate = obj.estimates;
            this.treatmentID = this.estimate != "" && this.estimate != undefined ? false : true;
            this.hospitalAddFlag = obj.diag_centre_name != null ? false : true;

            if (obj.created_date != undefined) {
              var get_date = obj.created_date.toString().split('-');
              var datetxt = get_date[2] + "-" + get_date[1] + "-" + get_date[0]+" "+Time_Formate(obj.created_time);
            }
            
            if (obj.first_name != undefined) {
              this.address = obj.address2 != null && obj.address2 != "" && obj.address2 != undefined ? obj.address1 + ", " + obj.address2 + "," : obj.address1 + ",";
              if(obj.caddress1 == "NA" ){
                this.noaddress= true;
              } else {
                this.noaddress=false;
              }

              if (obj.caddress2 != null && obj.caddress2 != undefined && obj.caddress1 != undefined) {
                this.clientaddress1 = encrypt_decript.Decript(obj.caddress1)+ ",";
                if(obj.caddress2 != " " && obj.caddress2 != "null" && obj.caddress2 != undefined) {
                  if (encrypt_decript.Decript(obj.caddress2) == "") {
                    this.clientaddress2 == "";
                  } else {
                    this.clientaddress2 = encrypt_decript.Decript(obj.caddress2)+",";
                  }
                }
              } else if (obj.caddress1 != undefined) {
                this.clientaddress1 = encrypt_decript.Decript(obj.caddress1)+",";
              } else if (obj.caddress2 != undefined) {
                this.clientaddress2 = encrypt_decript.Decript(obj.caddress2)+",";
              }      

              var clocation = '',czipcode = '',ccity = '',cstate = '',ccountry = '';
              if (obj.clocation != undefined) {
                clocation = obj.clocation+",";
              }
    
              if (obj.ccity != undefined) {
                ccity = obj.ccity+"-";
              }

              if(obj.czipcode != undefined) {
                czipcode = encrypt_decript.Decript(obj.czipcode)+",";
              }
    
              if (obj.cstate != undefined) {
                cstate = obj.cstate+"-";
              }   
              
              if(obj.ccountry != undefined) {
                ccountry = obj.ccountry+".";
              }

              var tot_amt1: any = 0;
              if (obj.bills != null && obj.bills.length != 0) {
                for (var i = 0; i < obj.bills.length; i++) {
                  if (obj.bills[i].amount_txt == undefined || obj.bills[i].amount_txt == '') {
                    obj.bills[i].amount_txt = 0;
                  }
                  if (obj.bills[i].concession == undefined || obj.bills[i].concession == '') {
                    obj.bills[i].concession = 0;
                  } 
                  if (obj.bills[i].fee == undefined || obj.bills[i].fee == '') {
                    obj.bills[i].fee = 0; 
                  }
                  if (obj.bills[i].amount_txt != null && obj.bills[i].amount_txt != '') {
                    tot_amt1 = (parseFloat(tot_amt1) + (parseFloat(obj.bills[i].amount_txt) * parseFloat(obj.bills[i].quantity)));
                    this.discount_amount = (parseFloat(this.discount_amount) + (parseFloat (obj.bills[i].amount_txt) * parseFloat(obj.bills[i].quantity)) * (parseFloat(obj.bills[i].concession)/ 100));
                  }
                }

                if (this.discount_amount != '') {          
                  var floatValue = this.discount_amount;
                  var floatString = floatValue.toString();
                  var parts = floatString.split('.');
                  var digitsAfterDecimal = parts[1] ? parts[1] : 0;
                  if (digitsAfterDecimal[0] > 5) {
                    this.discount_amount = Math.round(this.discount_amount);
                    this.discount_amount = parseInt(this.discount_amount); 
                  } else {
                    this.discount_amount = parseInt(this.discount_amount);
                  }                       
                }
              }
              this.totalBillAmount = Math.round(tot_amt1);
              console.log("totalBillAmount",this.totalBillAmount);
              var tot_amt: any = 0;
              if (obj.bills != null && obj.bills.length != 0) {
                for (var i = 0; i < obj.bills.length; i++) {
                  if (obj.bills[i].fee != null && obj.bills[i].fee != '') {
                    tot_amt = (parseFloat(tot_amt) + parseFloat(obj.bills[i].fee));
                  }
                }
              }
              if (obj.estimates != null) {
                if (obj.estimates.length != 0) {
                  for (var i = 0; i < obj.estimates.length; i++) {
                    if (obj.estimates[i].estimate != null) {
                      tot_amt = (parseFloat(tot_amt) + parseFloat(obj.estimates[i].estimate));
                    }
                  }
                }
              }
              this.total = tot_amt;
              this.totalAmount = tot_amt;
              if (obj.prev_balance != null && obj.prev_balance != 0) {
                this.prevBalFlag = false;
                this.prevBlance = obj.prev_balance;
                this.total = (parseFloat(tot_amt) + parseFloat(this.prevBlance));
                this.totalBillAmount = (parseFloat(tot_amt1) + parseFloat(this.prevBlance));
              } else {
                this.prevBalFlag = true;
              }
              
              if (obj.cgst != null) {
                this.centralGST = Math.round(parseFloat(this.total) * (parseFloat(obj.cgst) / 100));
                this.stateGST = Math.round(parseFloat(this.total) * (parseFloat(obj.sgst) / 100));
                this.total = parseFloat(tot_amt) + parseFloat(this.centralGST) + parseFloat(this.stateGST);
                this.total = Math.round(parseFloat(this.total));
                this.totalBillAmount = parseFloat(tot_amt1) + parseFloat(this.centralGST) + parseFloat(this.stateGST);
                this.totalBillAmount = parseFloat(this.totalBillAmount);
              }

              if (obj.bill_amount != undefined && obj.bill_amount != "") {
                if (parseInt(this.concession) != 100) {
                  this.total = encrypt_decript.Decript(obj.bill_amount);
                  if (this.total != '' && this.total != 0) {          
                    var floatValue = this.total;
                    var floatString = floatValue.toString();
                    var parts = floatString.split('.');
                    var digitsAfterDecimal = parts[1] ? parts[1] : 0;
                    if (digitsAfterDecimal[0] > 5) {
                      this.total = Math.round(this.total);
                      this.total = parseInt(this.total); 
                    } else {
                      this.total = parseInt(this.total);
                    }                       
                  }
                }               
              }   

              this.paid = obj.paid_amount != undefined ? parseFloat(obj.paid_amount) : 0;
              if (this.paid != '' && this.paid != 0) {          
                var floatValue = this.paid;
                var floatString = floatValue.toString();
                var parts = floatString.split('.');
                var digitsAfterDecimal = parts[1] ? parts[1] : 0;
                if (digitsAfterDecimal[0] > 5) {
                  this.paid = Math.round(this.paid);
                  this.paid = parseInt(this.paid); 
                } else {
                  this.paid = parseInt(this.paid);
                }                       
              }

              var balance;
              if (obj.paid_amount != 0 && obj.advance == 0){
                balance = (parseFloat(this.total)- parseFloat(this.paid));
              } else {
                balance = parseFloat(obj.balance);
              }

              if (obj.middle_name != null && obj.middle_name != "") {
                this.clientMidName = encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.middle_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
              } else {
                this.clientMidName = encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
              }
              
              var cname;
              if(obj.salutation != undefined) {
                cname = obj.salutation+"."+this.clientMidName;

              } else {
                cname = this.clientMidName;
              }

              var inpatientid,bed_no,admission_time,ward_name,admission_date;
              if(obj.inp_hosp_id != undefined) {
                inpatientid = obj.inp_hosp_id;
                this.inpatientflag=true;

              } else {
                this.inpatientflag=false;
                if(obj.appointment_id != undefined){
                  inpatientid = obj.appointment_id;
                }               
              }
              if(obj.bed_no != undefined) {
                bed_no = obj.bed_no;

              } 
              if(obj.ward_name != undefined) {
                ward_name = obj.ward_name;

              } 
              if(obj.admission_date != undefined) {
                admission_date = obj.admission_date;

              } 
              if(obj.admission_time != undefined) {
                admission_time = Time_Formate(obj.admission_time);
              } 

              if(obj.mobile != undefined){
                this.mobile = encrypt_decript.Decript(obj.mobile);
              }

              if(obj.age != undefined){
                this.ageval = obj.age;
              } else {
                this.ageval="";
              }
              
              if(obj.gender != undefined){
                this.genderval = obj.gender;  
              } else {
                this.genderval="";
              } 
           
              var genshort;         
              if (
                this.genderval != null &&
                this.genderval != undefined
              ) {
                  this.genderval = encrypt_decript.Decript(this.genderval);
                  genshort = this.genderval[0];
                if (this.genderval != undefined && this.genderval[0] == 'T') {
                  genshort = 'Tgen';
                }
              }  
                
              var docName;
              if (obj.dr_middle_name != undefined && obj.dr_middle_name != null) {
                docName = "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
              } else if (obj.dr_first_name != undefined && obj.dr_last_name != undefined) {
                docName = "Dr." + obj.dr_first_name + " " + obj.dr_last_name;
              } 
           
              if (obj.payments != undefined) {
                this.paymentsarray = obj.payments;                           
              }

              if(parseFloat(this.paid) > this.total){              
                this.numToWords = convertNumberToWords(this.total,ipaddress.country_code);
              } else {
                this.numToWords = convertNumberToWords(this.paid,ipaddress.country_code);
              }     
              
              var advance; 
              if(obj.advance == 0){
                advance = 0;
              } else {
                advance = obj.advance;
              }

              if (obj.barcode != undefined && obj.barcode != null) {
                this.barcode_flag = true;
                this.barcode_url = ipaddress.Ip_with_img_address + obj.barcode;
              }

              if (this.credit_type == '') {
                this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -35px;";
                this.nobanner_style = "width: 100%; height: 95px;";
              } else {
                this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -45px;";
                this.nobanner_style = "width: 100%; height: 110px;";
              }

              this.billPrintArray.push({
                profile_image: this.profile_image,
                dr_name: docName,
                licence_code: " Reg. No: " + obj.licence_code,
                diag_centre_name: obj.diag_centre_name,
                address: this.address,
                location: obj.location + ", ",
                city: obj.city + " - " + obj.zipcode + ",",
                state: obj.state + " - " + obj.country + ",",
                telephone: obj.telephone,
                created_date: datetxt,
                appointment_id: obj.appointment_id,
                client_name: cname,
                advance: advance,
                balance: balance,
                caddress1: this.clientaddress1,
                caddress2: this.clientaddress2,
                clocation: clocation,
                clientcity: ccity + czipcode,
                clestare: cstate + ccountry,
                client_reg_id: obj.client_hosp_id,
                inpatientid: inpatientid,
                bedno: bed_no,
                wardname: ward_name,
                admitteddate: admission_date,
                admittedtime: admission_time,
                mobile: this.mobile,
                age: this.ageval,
                gender: genshort,
                numToWords: this.numToWords,
                cgst: parseInt(obj.cgst),
                sgst: parseInt(obj.sgst)
              });
            }
          }
        }, error => { });
  }

  printArea() {
    var margin_top;
    let printContents, popupWin;
    if (this.printtemplate != undefined && this.printtemplate == "banner") {
      printContents = this.printbanner.nativeElement.innerHTML; 
      margin_top = '0px';
    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
      margin_top = '122px';
    }
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>                        
          @page {
            size: auto;
            margin-top: ${margin_top};
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            overflow: visible;
          } 
          * {
              font-size: 12px !important;
              line-height: 1 !important;
              page-break-inside: always;
              overflow: visible;
            }                                     
          @media print {   
            body {
              height: auto; /* Allow body height to adjust dynamically */
            }          
            .page-break {
              page-break-before: always; /* or page-break-after: always; */
              page-break-inside: always;
            }
          }           
          .address_visibility {
            visibility: hidden;
          }        
          table.report-container {
            page-break-after: always;
          }
          thead.report-header {
            display: table-header-group;
          }
          tfoot.report-footer {
            display: table-footer-group;
          }
          .print:last-child {
            page-break-after: auto;
          }
          .alignRight {
            text-align: right;
          }
          .col-print-1 {width:8%;  float:left;}
          .col-print-2 {width:16%; float:left;}
          .col-print-3 {width:25%; float:left;}
          .col-print-4 {width:33%; float:left;}
          .col-print-5 {width:42%; float:left;}
          .col-print-6 {width:50%; float:left;}
          .col-print-7 {width:58%; float:left;}
          .col-print-8 {width:66%; float:left;}
          .col-print-9 {width:75%; float:left;}
          .col-print-10 {width:83%; float:left;}
          .col-print-11 {width:92%; float:left;}
          .col-print-12 {width:100%; float:left;}
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
    popupWin.document.close();
  }
  
  backClicked() {
    if(Helper_Class.getIsAdmin() != undefined){
        this.adminservice.sendMessage("diagBillingList");
    }else{
      if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk")
      this.frontdeskservice.sendMessage("manageappointments");
      else if (Diagnosis_Helper.getCreateDiagAppt().flow == "dignosis")
        this.diagnosisservice.sendMessage("appointment");
      else if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdeskbilling")
        this.frontdeskservice.sendMessage("frontdeskbilling");
      else
        this.diagnosisservice.sendMessage('billinglist');
    } 
  }

}
