<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Inpatient report</h5>
                    </div>
                    <div style="width: 145px; margin-left: 15px;">
                        <mat-label class="matlabel">Preferrence</mat-label>
                        <mat-select class="ipcss" multiple [(ngModel)]="selected_filter"
                            (selectionChange)="setTable(selected_filter);">
                            <mat-option value="mrNo">MR.No</mat-option>
                            <mat-option value="wardBed">Ward/Bed</mat-option>
                            <mat-option value="admDateTime">Admission date/time </mat-option>
                            <mat-option value="ageGender">Age/Gender</mat-option>
                            <mat-option value="mobileNo">Mobile no</mat-option>
                            <mat-option value="docName">Doctor name</mat-option>
                            <mat-option value="billAmt">Bill amount</mat-option>
                            <mat-option value="billstatus">Bill status</mat-option>                          
                        </mat-select>
                    </div>
                    <div class="headerButtons">
                        <mat-label class="matlabel"><br>
                            <img src="../../../assets/ui_icons/buttons/back_icon.svg" style="width:30px;margin-right: 5px;" width="85px" (click)="todisplaycoverdiv()"  *ngIf="backbutton">
                            <a style="margin:0 5px;" (click)="print_area()" *ngIf="printbutton" ><img src="../../../assets/img/printer.svg"
                                class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;"
                                 *ngIf="backbutton"/>
                            </a>
                        </mat-label>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body style="padding: 0 1rem;">


                <div *ngIf="forcoverdiv" class="cover_div" style="padding-bottom: 13px;">
                    <!--  -->
                     
                    <div class="header_lable">
                     Inpatient report
                    </div>
                    <div class="content_cover">
                      <div class="row">
                        
                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom">
                           <mat-label class="matlabel">Duration<br>
                             <select  class="ipcss" (change)="updateFromDate()" [(ngModel)]="selectedDuration">
                               <option value="select">Select</option>
                               <option value="0">Today</option>
                               <option value="7">1 week</option>
                               <option value="30">1 month</option>
                               <option value="91">3 months</option>
                               <option value="183">6 months</option>
                               <option value="365">1 year</option>
                             </select>
                           </mat-label>
                         </div>
         
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                          <mat-label class="matlabel" style="margin-top: 7px;">From
                            <input type="date" class="ipcss_date " max="{{currentDate}}" [(ngModel)]="fromDate" #matInput style="width: 140px;">
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                          <mat-label class="matlabel" style="margin-top: 7px;">To
                            <input type="date" class="ipcss_date " max="{{currentDate}}"  [(ngModel)]="toDate" #matInput style="width: 140px;">
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                          <mat-label class="matlabel"><br>
                            <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo" (click)="getInpatientReportData()"/>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                          <mat-label class="matlabel"><br>
                          
                          </mat-label>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                <p class="nodata" *ngIf="inpatient_list == undefined || inpatient_list.length == 0">No record(s) found</p>
                <div *ngIf="inpatient_list != undefined && inpatient_list.length != 0" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" style="text-align: left;margin-top: 23px !important;">
                    <p class="textformat"> Active Inpatients: <strong>{{count}}</strong></p>
                </div>
                <div *ngIf="inpatient_list != undefined && inpatient_list.length != 0" class="table-responsive cover_div" style="width: 100%; height: 65vh;">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div style="width: 110%; margin-left: -25px;">
                                    <mat-accordion displayMode="flat" multi="false" class="mat-table">
                                        <section matSort class="mat-elevation-z2 mat-header-row" style="background-color: #c1e6fb; position: sticky; top: 0px; z-index: 2;">
                                            <span class="mat-header-cell">S.No</span>
                                            <span *ngIf="columns.admDateTime"  class="mat-header-cell">Adm date/time</span>
                                            <span *ngIf="columns.mrNo"  class="mat-header-cell">MR.No</span>
                                            <span class="mat-header-cell">Patient name</span>
                                            <span *ngIf="columns.ageGender"  class="mat-header-cell">Age/Gender</span>
                                            <span class="mat-header-cell">Inpatient id</span>
                                            <span *ngIf="columns.wardBed"  class="mat-header-cell">Ward & bed</span>
                                            <span *ngIf="columns.mobileNo"  class="mat-header-cell">Mobile no</span>
                                            <span *ngIf="columns.docName"  class="mat-header-cell">Doctor name</span>
                                            <span *ngIf="columns.billAmt"  class="mat-header-cell">Bill amount</span>
                                            <span *ngIf="columns.billstatus" class="mat-header-cell">Bill status</span>
                                        </section>
                                        <mat-expansion-panel *ngFor="let user of inpatient_list">
                                            <mat-expansion-panel-header class="mat-row" (click)="getChargeDetails(user)">
                                                <span class="mat-cell">{{user.sno}}</span>
                                                <span *ngIf="columns.admDateTime"  class="mat-cell">{{user.admDatendTime}}</span>
                                                <span *ngIf="columns.mrNo"  class="mat-cell">{{user.mr_no}}</span>
                                                <span class="mat-cell">{{user.patient_name}}</span>
                                                <span *ngIf="columns.ageGender"  class="mat-cell">{{user.age}} / {{user.gender}}</span>
                                                <span class="mat-cell">{{user.inp_hosp_id}}</span>
                                                <span *ngIf="columns.wardBed"  class="mat-cell">{{user.ward_bed}}</span>
                                                <span *ngIf="columns.mobileNo"  class="mat-cell">{{user.mobile}}</span>
                                                <span *ngIf="columns.docName"  class="mat-cell">{{user.doctor_name}}</span>
                                                <span *ngIf="columns.billAmt"  class="mat-cell">{{user.bill_amount}}</span>
                                                <span *ngIf="columns.billstatus" class="mat-cell">{{user.status}}</span>
                                            </mat-expansion-panel-header>
                                            <div
                                                style="height: 50vh; overflow-y: scroll; width: 104%; margin-left: -25px;">
                                                <table style="font-size: 12px;" class="headerfixed">
                                                    <thead>
                                                        <tr>
                                                            <th>S.no</th>
                                                            <th>Charge</th>
                                                            <th>Charge base</th>
                                                            <th>Amount</th>
                                                            <th>Quantity</th>
                                                            <th>Total amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let sales of charges_list">
                                                            <td>{{sales.sno}}</td>
                                                            <td>{{sales.charge_desc}}</td>
                                                            <td>{{sales.charge_base}}</td>
                                                            <td>{{sales.amount}}</td>
                                                            <td>{{sales.quantity}}</td>
                                                            <td>{{sales.total_amount}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div [hidden]="true" #printbanner id="printbanner">
                    <div [hidden]="inpatient_list == undefined || inpatient_list.length == 0">
                      <table style="border:'0';width:100%">                 
                        <thead>
                          <tr>
                            <th style="width:100%;">                                 
                              <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                                <div style="width: 100%; height: 205px !important;"> 
                                  <div style="width: 100%;">
                                    <img alt="image" src={{hospital_logo}}  [style]="printlogostyle">
                                  </div>                                                             
                                </div>
                              </div>               
                            </th>
                          </tr>
                        </thead>                       
                        <tbody>  
                          <tr>  
                            <td width="100%">                               
                              <div class="bill_border">
                              <div *ngFor="let user of inpatient_list">                
                                <div *ngIf="inpatient_list != undefined && inpatient_list.length != 0" style="margin-left: 20px;margin-right: 10px;">                      
                                  <div class="row">
                                    <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">    
                                        <thead>
                                            <tr>
                                                <th style="border: 1px solid black;border-collapse: collapse;">S.No</th>
                                                <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.admDateTime">Adm date/time</th>
                                                <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.mrNo">MR.No</th>
                                                <th style="border: 1px solid black;border-collapse: collapse;">Patient name</th>
                                                <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.ageGender">Age/ Gender</th>
                                                <th style="border: 1px solid black;border-collapse: collapse;">Inpatient id</th>
                                                <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.wardBed">Ward & Bed</th>
                                                <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.mobileNo">Mobile no</th>
                                                <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.docName">Doctor name</th>
                                                <th style="border: 1px solid black;border-collapse: collapse;"*ngIf="columns.billAmt">Bill amount</th>
                                                <th *ngIf="columns.billstatus" style="border: 1px solid black;border-collapse: collapse;">Bill status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let user of inpatient_list">
                                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{user.sno}}</td>
                                                <td style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.admDateTime">{{user.admDatendTime}}</td>
                                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;" *ngIf="columns.mrNo">{{user.mr_no}}</td>
                                                <td style="border: 1px solid black;border-collapse: collapse;">{{user.patient_name}}</td>
                                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;" *ngIf="columns.ageGender">{{user.age}} / {{user.gender}}</td>
                                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{user.inp_hosp_id}}</td>
                                                <td style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.wardBed">{{user.ward_bed}}</td>
                                                <td style="border: 1px solid black;border-collapse: collapse;"*ngIf="columns.mobileNo">{{user.mobile}}</td>
                                                <td style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.docName">{{user.doctor_name}}</td>
                                                <td style="text-align: right;border: 1px solid black;border-collapse: collapse;" *ngIf="columns.billAmt">{{user.bill_amount}}</td>
                                                <td *ngIf="columns.billstatus" style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{user.status}}</td>
                                            </tr>
                                        </tbody>                                     
                                    </table>
                                  </div>
                                </div>                                                                                          
                              </div>              
                            </div>                
                            </td>
                          </tr>
                        </tbody>    
                      </table>
                    </div>
                </div> 
          
                <div [hidden]="true" #printnoheader id="printnoheader">
                    <div [hidden]="inpatient_list == undefined || inpatient_list.length == 0">
                        <table style="border:'0';width:100%">             
                            <thead>
                                <tr>
                                <th style="width:100%;">
                                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                                    <!-- <div style="width: 100%; height: 95px;">
                                        <div style="height: 5px !important;">                                   
                                        </div>               
                                    </div> -->
                                    </div>
                                </th>
                                </tr>
                            </thead>       
                            <tbody>  
                                <tr>  
                                <td width="100%">                             
                                <div class="bill_border">
                                    <div>   
                                    <div *ngIf="inpatient_list != undefined && inpatient_list.length != 0" style="margin-left: 20px;margin-right: 10px;">                      
                                        <div class="row">
                                        <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">    
                                            <thead>
                                                <tr>
                                                    <th style="border: 1px solid black;border-collapse: collapse;">S.No</th>
                                                    <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.admDateTime">Adm date/time</th>
                                                    <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.mrNo">MR.No</th>
                                                    <th style="border: 1px solid black;border-collapse: collapse;">Patient name</th>
                                                    <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.ageGender">Age/Gender</th>
                                                    <th style="border: 1px solid black;border-collapse: collapse;">Inpatient id</th>
                                                    <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.wardBed">Ward & Bed</th>
                                                    <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.mobileNo">Mobile no</th>
                                                    <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.docName">Doctor name</th>
                                                    <th style="border: 1px solid black;border-collapse: collapse;"*ngIf="columns.billAmt">Bill amount</th>
                                                    <th *ngIf="columns.billstatus" style="border: 1px solid black;border-collapse: collapse;">Bill status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let user of inpatient_list">
                                                    <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{user.sno}}</td>
                                                    <td style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.admDateTime">{{user.admDatendTime}}</td>
                                                    <td style="text-align: center;border: 1px solid black;border-collapse: collapse;" *ngIf="columns.mrNo">{{user.mr_no}}</td>
                                                    <td style="border: 1px solid black;border-collapse: collapse;">{{user.patient_name}}</td>
                                                    <td style="text-align: center;border: 1px solid black;border-collapse: collapse;" *ngIf="columns.ageGender">{{user.age}} / {{user.gender}}</td>
                                                    <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{user.inp_hosp_id}}</td>
                                                    <td style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.wardBed">{{user.ward_bed}}</td>
                                                    <td style="border: 1px solid black;border-collapse: collapse;"*ngIf="columns.mobileNo">{{user.mobile}}</td>
                                                    <td style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.docName">{{user.doctor_name}}</td>
                                                    <td style="text-align: right;border: 1px solid black;border-collapse: collapse;" *ngIf="columns.billAmt">{{user.bill_amount}}</td>
                                                    <td *ngIf="columns.billstatus" style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{user.status}}</td>
                                                </tr>
                                            </tbody>                                     
                                        </table>
                                        </div>
                                    </div>                                                                                
                                    </div>              
                                </div>                
                                </td>
                                </tr>
                            </tbody>                       
                        </table>
                    </div>        
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>