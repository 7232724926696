<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Manage appointments</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/cancel_button.svg" (click)="cancel()" width="85px"
              style="margin-right: 9px;" [hidden]="showcancel" />
            <img src="../../../assets/ui_icons/buttons/confirm_button.svg" (click)="appointmentConfirm()" width="85px"
              [hidden]="showcancel1" />
            <!-- <img class="imgbtn" *ngIf="newbutton || newbuttondia || newbuttondie" src="../../../assets/ui_icons/buttons/new_button.svg" (click)="create()" style="margin-left: 9px ;" /> -->
            <img class="imgbtn" *ngIf="realflag1" src="../../../assets/ui_icons/buttons/new_button.svg"
              (click)="create()" style="margin-left: 9px ;" />
            <img class="imgbtn" *ngIf="realflag2" src="../../../assets/ui_icons/buttons/new_button.svg"
              (click)="create()" style="margin-left: 9px ;" />
            <img class="imgbtn" *ngIf="realflag3" src="../../../assets/ui_icons/buttons/new_button.svg"
              (click)="create()" style="margin-left: 9px ;" />
              <img src="../../../assets/ui_icons/buttons/token_button.svg" (click)="regeneratetoken('next')" width="85px"
              [hidden]="refreshtoken" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12" style="margin-bottom: 15px;">
            <div class="row">
              <div class="col-12">
                <div class="switch-field" *ngIf="ynopt1 != undefined">
                  <input *ngIf="showDoctor == '1' && viewbutton" type="radio" id="radio-three"
                    (click)="changeType(1,'doctor')" />
                  <label *ngIf="showDoctor == '1' && viewbutton" for="radio-three"
                    [ngClass]="ynopt1 == 'doctor' ? 'radio_active':'radio_inactive'">
                    <div class="tab_cover">
                      <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Doctor.svg" class="iocinStyle"
                          alt=""> </div>
                      <div class="lableStyle">Doctor </div>
                    </div>
                  </label>
                  <input *ngIf="showDiag == '1' && viewbuttondia " type="radio" id="radio-four"
                    (click)="changeType(1,'diagnosis')" />
                  <label *ngIf="showDiag == '1' && viewbuttondia" for="radio-four"
                    [ngClass]="ynopt1 == 'diagnosis' ? 'radio_active':'radio_inactive'">
                    <div class="tab_cover">
                      <div class="tab_icon_cover"><img src="../../../assets/ui_icons/diagnosis.svg" class="iocinStyle"
                          alt=""> </div>
                      <div class="lableStyle">Diagnosis </div>
                    </div>
                  </label>
                  <input *ngIf="showDietician == '1' && viewbuttondie" type="radio" id="radio-five"
                    (click)="changeType(1,'dietician')" />
                  <label *ngIf="showDietician == '1' && viewbuttondie" for="radio-five"
                    [ngClass]="ynopt1 == 'dietician' ? 'radio_active':'radio_inactive'">
                    <div class="tab_cover">
                      <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Dietician_icon_svg.svg"
                          class="iocinStyle" alt=""> </div>
                      <div class="lableStyle">Dietician </div>
                    </div>
                  </label>
                  <input *ngIf="showphysio == '1'" type="radio" id="radio-six" (click)="changeType(1,'physio')" />
                  <label *ngIf="showphysio == '1'" for="radio-six"
                    [ngClass]="ynopt1 == 'physio' ? 'radio_active':'radio_inactive'">
                    <div class="tab_cover">
                      <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Dietician_icon_svg.svg"
                          class="iocinStyle" alt=""> </div>
                      <div class="lableStyle">Physio </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">     

          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" *ngIf="doctorFlag">
            <mat-label class="matlabel">Doctor<br>
              <input type="text" class="ipcss" maxlength="50" (keyup)="getDoctorListByName(docName)" maxlength="50"
                [(ngModel)]="docName" matInput [matAutocomplete]="auto1" />
              <mat-autocomplete #auto1="matAutocomplete" [panelWidth]="'auto'" >
                <mat-option *ngFor="let item of doctorList" (click)="selectReferredDoctor(item)"
                  value="{{item.doc_reg_id}}">
                  {{item.doc_name}}
                </mat-option>
              </mat-autocomplete>
            </mat-label>
          </div>
          
          <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 select_bottom">
            <mat-label class="matlabel">Date<br>
              <input type="date" class="ipcss_date" id="appt_date" (change)="selectAppointmentDate(appointmentDate)"
                [(ngModel)]="appointmentDate" #matInput style="width: 140px;">
            </mat-label>
           </div>
         </div>
        <div class="row" *ngIf="doctorFlag">
          <div class="col-12"> <p class="nodata" *ngIf="noapp">No Appointments(s) Found</p></div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin: 10px 0 0 0px;">           
            <table *ngIf="appointmentList.length != 0" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable billlisttable">
              <thead>
                <tr>
                  <th>Profile image</th>
                  <th>Patient id</th>
                  <th>Name</th>
                  <th>Token/Time</th>
                  <th>Contact no</th>
                  <th>Doctor name</th>
                  <th>Session</th>
                  <th>Package</th>
                  <th>Status </th>
                  <th style="width: 5%;">
                   
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let person of appointmentList; let i = index" (click)="viewAppointment(person)">
                  <td><img src="{{person.profile_image}}" onerror="this.src='../../../assets/img/default.jpg';"
                      class="img-fluid z-depth-1 rounded-circle mr-1" class="profileimage"></td>
                  <td style="font-size: 12px;">{{ person.patient_id }}</td>
                  <td style="font-size: 12px;text-align: center;">{{ person.patient_name }}</td>
                  <td style="font-size: 12px;">{{ person.token }}</td>
                  <td style="font-size: 12px;">{{ person.contact }}</td>
                  <td style="font-size: 12px;">{{ person.doctorname }}</td>
                  <td style="font-size: 12px;">{{ person.session }}</td>
                  <td style="font-size: 12px;">{{ person.package }}</td>
                  <td style="font-size: 12px;">
                    <div style="position: relative;top: 9px;">
                      <img *ngIf="person.status_txt == 'Completed'" width="30px" height="auto"
                        src="../../../assets/ui_icons/Completed_icon.svg" />
                      <img *ngIf="person.status_txt == 'Cancel'" width="30px" height="auto"
                        src="../../../assets/ui_icons/cancel_icon.svg" />
                      <img *ngIf="person.status_txt == 'Not visited'" width="30px" height="auto"
                        src="../../../assets/ui_icons/not_visited_icon.svg" />
                      <img *ngIf="person.status_txt == 'Confirmed'" width="30px" height="auto"
                        src="../../../assets/ui_icons/confirmed_icon.svg" />
                      <img *ngIf="person.status_txt == 'Open'" width="30px" height="32px"
                        src="../../../assets/ui_icons/opened_icon.svg" />
                      <p>{{person.status_txt}}</p>
                     </div>
                  <td style="font-size: 12px;width: 5%;">
                    <mdb-checkbox [default]="true" [(ngModel)]="person.isSelected" name="list_name"
                      value="{{person.id}}" (change)="isAllSelected(person)">
                    </mdb-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
            <br><br><br>
           </div>
         </div>
        <div *ngIf="!doctorFlag">
          <div class="row">
            <div class="col-12"> <p class="nodata" *ngIf="diagAppointmentList.length == 0">No Appointments(s) Found</p></div>
            <div class="col-12" style="margin: 10px 0 0 0px;" *ngIf="diagAppointmentList.length">
              <table *ngIf="diagAppointmentList.length" mdbTable datatable [dtOptions]="dtOptionscheck"
                class="table table-nowrap table-sm dataTable">
                <thead>
                  <tr>
                    <th>Profile image</th>
                    <th>Name</th>
                    <th>Doctor</th>
                    <th>Date & Time</th>
                    <th> Session </th>
                    <th> Status </th>
                    <th style="width: 5%;" *ngIf="editbuttondia"> &nbsp; </th>
                    <!-- <th style="width: 5%;">&nbsp;</th> -->
                    <!-- <th style="width: 5%;">
                      <mdb-checkbox [default]="true" [(ngModel)]="masterSelected" name="list_name" value="m1"
                        (change)="checkUncheckAll()"></mdb-checkbox>
                    </th>  -->
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let list of diagAppointmentList">
              <td style="text-align: center;" (click)='viewDiagAppointment(list.App_id,list.status)'>
                <img src="{{list.profile_image}}"
                onerror="this.src='../../../assets/img/default.jpg';"
                class="profileimage">
              </td>
              <td style="text-align: left;" (click)='viewDiagAppointment(list.App_id,list.status)'>{{
                list.Clnt_Name}}</td>
                <td style="text-align: left;" (click)='viewDiagAppointment(list.App_id,list.status)'>{{
                  list.docName}}</td>
              <td (click)='viewDiagAppointment(list.App_id,list.status)'> {{list.App_date}} {{list.Time}}</td>
              <td (click)='viewDiagAppointment(list.App_id,list.status)'>{{list.Session}}</td>
              <td style="font-size: 12px;" (click)='viewDiagAppointment(list.App_id,list.status)'>
                <div style="position: relative;top: 9px;">
                  <img *ngIf="list.status == 'Completed'" width="30px" height="auto"
                    src="../../../assets/ui_icons/Completed_icon.svg" />
                  <img *ngIf="list.status == 'Cancel'" width="30px" height="auto"
                    src="../../../assets/ui_icons/cancel_icon.svg" />
                  <img *ngIf="list.status == 'Sample collected'" width="30px" height="auto"
                    src="../../../assets/ui_icons/sample_collected.svg" />
                  <img *ngIf="list.status == 'Confirmed'" width="30px" height="auto"
                    src="../../../assets/ui_icons/confirmed_icon.svg" />
                  <img *ngIf="list.status == 'Open'" width="30px" height="32px"
                    src="../../../assets/ui_icons/opened_icon.svg" />
                  <img *ngIf="list.status == 'Closed'" width="30px" height="32px"
                    src="../../../assets/ui_icons/Closed_icon.svg" />
                  <img *ngIf="list.status == 'Report yet to be finalized'" width="30px" height="32px"
                    src="../../../assets/ui_icons/report_yet_to_be_finalized.svg" />
                  <img *ngIf="list.status == 'Time alloted'" width="30px" height="32px"
                    src="../../../assets/ui_icons/time_not_alloted.svg" />
                  <p>{{list.status}}</p>
                </div>
              </td>
              <td style="font-size: 12px;width: 5%;" *ngIf="editbuttondia">
                <img src="../../../assets/img/edit.png" class="app_edit" (click)="editAppointment(list.App_id,list.status)">
              </td>
            </tr>
          </tbody>
          </table>
        </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fads" id="staticBackdrop" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel"
  aria-hidden="true">
  <!-- style="position: fixed; bottom: 16%; right: 6%; margin: 0px; width: 33%;" -->
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="titleStyle">
        <h5 class="modal-title" id="staticBackdropLabel">Reason for cancellation</h5>
        <span class="closeicon" data-bs-dismiss="modal" aria-label="Close" style="float: right;"><img
            src="../../../assets/img/close_icon.png" alt="Card image cap" style="width:19px"> </span>
      </div>
      <div class="modal-body">
        <textarea class="ipcss noappt_width" style="height:108px !important;" [(ngModel)]="cancelResone"
          maxlength="500"></textarea>
      </div>
      <div class="modal-footer">
        <img src="../../../assets/ui_icons/diet_plan/send_button.svg" (click)="sendCancel()" alt="Card image cap"
          style="width:85px; height: 25px;">
      </div>
    </div>
  </div>
</div>

<!-- [hidden]="true" -->
<div [hidden]="true">
  <div #printtoken id="printtoken">

    <div >
      <div style="margin-top: 30px;margin-left:auto;margin-right:auto;border:1px solid black;width:70px;height:70px;border-radius:35px;display: flex;flex-direction: column;
    justify-content: center;
    text-align: center;">

        <p style="font-size:25px;margin: 0px;">{{tocknumber}}</p>


      </div>
      <div style="padding: 10px;">
      <table style="vertical-align: top;">
        <!-- <tr>
          <td>{{'MR No'}}</strong></td>
          <td>: {{mrNumber}}</td>
        </tr> -->
         <tr style="vertical-align: top;">
           <td>Name</td>
           <td>: {{tockpatient_name}}</td>
         </tr>
         <tr style="vertical-align: top;">
          <td>MR no</td>
          <td>: {{tockmrnumber}}</td>
        </tr>
        <tr style="vertical-align: top;">
          <td>Doc Name</td>
          <td>: {{tokendocname}}</td>
        </tr>
        <tr style="vertical-align: top;">
          <td>Specialization</td>
          <td>: {{tockspl}}</td>
        </tr>
        <tr style="vertical-align: top;">
          <td>Appointment date</td>
          <td>: {{appdate}}</td>
        </tr>
        
        
        
       </table>
      </div>
    </div>
    <div style="margin: 10px;" class="d-flex align-items-center justify-content-center">
      <ngx-barcode class="barcode" [bc-value]="tockmrnumber" [bc-display-value]="displayValue"
        [bc-line-color]="lineColor" [element-type]="elementType" [format]="format" [bc-width]="width"
        [bc-height]="barcodeheight" [bc-font-options]="fontOptions" [bc-font]="font" [bc-text-align]="textAlign"
        [bc-text-position]="textPosition" [bc-text-margin]="textMargin" [bc-font-size]="fontSize"
        [bc-background]="background" [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
        [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode>
    </div>
  </div>
  <div #reprinttoken id="reprinttoken" style="border:1px solid black;border-radius:5px;width:30%;margin-left:10px">

    <div>
      <div style="margin-top: 30px;margin-left:auto;margin-right:auto;border:1px solid black;width:70px;height:70px;border-radius:35px;display: flex;flex-direction: column;
    justify-content: center;
    text-align: center;">
        <p style="font-size:25px">{{ref_tokenno}}</p>


      </div>
      <table style="vertical-align: top;">
        <!-- <tr>
          <td>{{'MR No'}}</strong></td>
          <td>: {{mrNumber}}</td>
        </tr> -->
         <tr style="vertical-align: top;">
           <td>Name</td>
           <td>: {{tockpatient_name}}</td>
         </tr>
         <tr style="vertical-align: top;">
          <td>MR no</td>
          <td>: {{tockmrnumber}}</td>
        </tr>
        <tr style="vertical-align: top;">
          <td>Doc Name</td>
          <td>: {{tokendocname}}</td>
        </tr>
        <tr style="vertical-align: top;">
          <td>Specialization</td>
          <td>: {{tockspl}}</td>
        </tr>
        <tr style="vertical-align: top;">
          <td>Appointment date</td>
          <td>: {{appdate}}</td>
        </tr>
        
        
        
       </table>
      <!-- <p>Name: {{tockpatient_name}}</p>
      <p>MR no: {{tockmrnumber}}</p>
      <P>Doc Name: {{tokendocname}}</P>
      <p>Specialization: {{tockspl}}</p>
      <p>Appointment date: {{appdate}}</p> -->
    </div>
    <div style="margin: 10px;" class="d-flex align-items-center justify-content-center">
      <ngx-barcode class="barcode" [bc-value]="tockmrnumber" [bc-display-value]="displayValue"
        [bc-line-color]="lineColor" [element-type]="elementType" [format]="format" [bc-width]="width"
        [bc-height]="barcodeheight" [bc-font-options]="fontOptions" [bc-font]="font" [bc-text-align]="textAlign"
        [bc-text-position]="textPosition" [bc-text-margin]="textMargin" [bc-font-size]="fontSize"
        [bc-background]="background" [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
        [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode>
    </div>
  </div>
</div>
</mdb-card-body>
</mdb-card>
</div>
</div>