<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Appointment details</h5>
          </div>

          <div class="headerButtons">
            <a style="margin:0 5px;" (click)="back()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                class="backimgbtn_inpatinfo" /></a>
            <a *ngIf="!updateFlag"><img src="../../../assets/ui_icons/buttons/Book_now.svg" class="saveimgbtn_inpatinfo"
                (click)="book('booking')" [hidden]="bookFlag" /></a>          
          </div>
        </div>


      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="" style="margin-top: -10px;">
            <!-- <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5" style="margin:0px"
                [hidden]="hidePattype">
                <div class="row">
                  <div class="col-3">
                    <mat-label class="matlabel" style="font-weight: 600;">Client Type : </mat-label>
                  </div>
                  <div class="col-8">
                    <div class="switch-field">
                      <input type="radio" id="radio-three" (click)="changeType('client',1,'yes')" />
                      <label for="radio-three" [ngClass]="clientTypeFlag ? 'radio_active':'radio_inactive'">New</label>
                      <input type="radio" id="radio-four" (click)="changeType('client',1,'no')" />
                      <label for="radio-four"
                        [ngClass]="!clientTypeFlag ? 'radio_active':'radio_inactive'">Existing</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5" style="margin: 0px;">
                <div class="row">
                  <div class="col-4">
                    <mat-label class="matlabel" style="font-weight: 600;">Appointment Type : </mat-label>
                  </div>
                  <div class="col-8">
                    <div class="switch-field">
                      <input type="radio" id="radio-one" (click)="changeType('app',1,'yes')" [disabled]="appdisable" />
                      <label for="radio-one" [ngClass]="apptTypeFlag ? 'radio_active':'radio_inactive'">New</label>
                      <input type="radio" id="radio-two" (click)="changeType('app',1,'no')" [disabled]="appdisable" />
                      <label for="radio-two"
                        [ngClass]="!apptTypeFlag ? 'radio_active':'radio_inactive'">Follow-up</label>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="container-fluid" style="margin-left: -20px;">
              <table style="width: 100%;">
                <tr>
                  <td colspan="4" style="vertical-align: top !important;width: 24%;">
                    <div class="cover_div" style="padding-bottom: 13px;">
                      <div class="header_lable">Patient</div>
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="row">
                          <div class="col-12">
                            <div class="switch-field">
                              <input type="radio" id="radio-five" (click)="searchType('patientid');" />
                              <label for="radio-five"
                                [ngClass]="typeflag == 'patientid' ? 'radio_active':'radio_inactive'">MR no</label>
                              <input type="radio" id="radio-six" (click)="searchType('name')" />
                              <label for="radio-six"
                                [ngClass]="typeflag == 'name' ? 'radio_active':'radio_inactive'">Name</label>
                              <input type="radio" id="radio-seven" (click)="searchType('mobile')" />
                              <label for="radio-seven"
                                [ngClass]="typeflag == 'mobile' ? 'radio_active':'radio_inactive'">Mobile</label>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="content_cover">
                        <div>
                          <input #myInputField id="focusMRNo filterInput" class="inputStyle2" type="text"
                            [(ngModel)]="patientName" placeholder={{searchtext}}
                            (keyup)="getPatientListByName(patientName)">
                          <!-- (keyup)="tableFilter(patientName)" -->
                          <div class="scrollbar" #scrollContainer (scroll)="onScroll($event)">
                            <div class="scrollbar-inner">
                              <table *ngIf="patientList.length">
                                <tbody>
                                  <tr *ngFor="let person of filteredArr; let i = index"
                                    (click)='retrieveClient(person)'>
                                    <td style="font-size: 12px;text-align: left;">{{ person.full_name }} <br /> {{
                                      person.mobile }} <br />
                                      {{person.patient_id}}</td>
                                    <!-- <td style="font-size: 12px;text-align: left;">{{person.patient_id}}</td> -->
                                  </tr>

                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td colspan="8" style="vertical-align: top !important;">
                    <div class="cover_div" style="padding-bottom: 13px;">
                      <div class="header_lable">Personal Details</div>
                      <div class="content_cover">

                        <div class="row">
                          <div class="col-2">
                            <img src="{{this.profileImg}}" onerror="this.src='../../../assets/img/default.jpg';"
                              class="img-fluid z-depth-1 rounded-circle mr-1 profileimage">
                          </div>
                          <div class="col-10">
                            <div class="row">
                              <div class="col-3">
                                <p class="textformat"><span class="fontweight_normal">MR no:</span> {{this.mrno}}</p>
                              </div>
                              <div class="col-3">
                                <p class="textformat"><span class="fontweight_normal">Name:</span> {{this.clientname}}
                                </p>
                              </div>
                              <div class="col-3">
                                <p class="textformat"><span class="fontweight_normal">DOB:</span> {{this.clntDOB}}</p>
                              </div>
                              <div class="col-3">
                                <p class="textformat"><span class="fontweight_normal">Age:</span> {{clntAge}} </p>
                              </div>
                              <div class="col-3">
                                <p class="textformat"><span class="fontweight_normal">Gender:</span> {{clntGender}} </p>
                              </div>
                              <div class="col-3">
                                <p class="textformat"><span class="fontweight_normal">Mobile:</span>
                                  {{this.clntContNumber}}</p>
                              </div>
                              <div class="col-3">
                                <p class="textformat"><span class="fontweight_normal"> Emergency contact 1:</span>
                                  {{this.clntEmergNumber}}</p>
                              </div>
                              <div class="col-3">
                                <p class="textformat"><span class="fontweight_normal"> Emergency contact 2:</span>
                                  {{this.clntEmergNumber1}}</p>
                              </div>
                              <div class="col-3">
                                <p class="textformat"><span class="fontweight_normal">Aadhar Number:</span>
                                  {{this.aadharNumber}}</p>
                              </div>
                              <div class="col-3">
                                <p class="textformat"><span class="fontweight_normal">Whatsapp Number:</span>
                                  {{this.whatsappNumber}}</p>
                              </div>
                              <!-- <div class="col-3">
                                <p class="textformat">   Symptoms: {{this.clntSymptom}}</p>
                              </div> -->


                              <div class="col-3">
                                <p class="textformat"><span class="fontweight_normal"> Address 1:</span>
                                  {{this.clntAddress1}}</p>
                              </div>
                              <div class="col-3">
                                <p class="textformat"><span class="fontweight_normal"> Address 2:</span>
                                  {{this.clntAddress2}}</p>
                              </div>
                              <div class="col-3">
                                <p class="textformat"><span class="fontweight_normal"> Zipcode:</span>
                                  {{this.clntZipcode}} </p>
                              </div>
                              <div class="col-3">
                                <p class="textformat"><span class="fontweight_normal"> Location:</span>
                                  {{this.clntLocation}} </p>
                              </div>
                              <div class="col-3">
                                <p class="textformat"><span class="fontweight_normal"> District / City:</span>
                                  {{this.clntCity}} </p>
                              </div>
                              <div class="col-3">
                                <p class="textformat"><span class="fontweight_normal"> State:</span> {{this.clntState}}
                                </p>
                              </div>
                              <div class="col-3">
                                <p class="textformat"><span class="fontweight_normal"> Country:</span>
                                  {{this.clntCountry}}</p>
                              </div>
                              <div class="col-3">
                                <p class="textformat"><span class="fontweight_normal"> Channel:</span>
                                  {{this.ref_source}}</p>
                              </div>
                              <div class="col-3">
                                <p class="textformat"><span class="fontweight_normal"> Referred by:</span>
                                  {{this.referredDoctor}} </p>
                              </div>
                              <!-- <div class="col-3">
                                <p class="textformat">   Visit purpose: {{this.visit_purpose}}</p>
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="cover_div" style="padding-bottom: 13px;">
                      <div class="header_lable">
                        Appointment details
                      </div>
                      <div class="content_cover">
                        <div class="row">
                          <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5" style="margin:0px"
                              [hidden]="hidePattype">
                              <div class="row">
                                <div class="col-3">
                                  <mat-label class="matlabel" style="font-weight: 600;">Client Type : </mat-label>
                                </div>
                                <div class="col-8">
                                  <div class="switch-field">
                                    <input type="radio" id="radio-three" (click)="changeType('client',1,'yes')" />
                                    <label for="radio-three" [ngClass]="clientTypeFlag ? 'radio_active':'radio_inactive'">New</label>
                                    <input type="radio" id="radio-four" (click)="changeType('client',1,'no')" />
                                    <label for="radio-four"
                                      [ngClass]="!clientTypeFlag ? 'radio_active':'radio_inactive'">Existing</label>
                                  </div>
                                </div>
                              </div>
                            </div> -->

                          <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4" style="margin: 0px;">
                            <div class="row">
                              <div class="col-5">
                                <mat-label class="matlabel" style="font-weight: 600;">Appointment type : </mat-label>
                              </div>
                              <div class="col-7">
                                <div class="switch-field">
                                  <input type="radio" id="radio-one" (click)="changeType('app',1,'yes')"
                                    [disabled]="appdisable" />
                                  <label for="radio-one"
                                    [ngClass]="apptTypeFlag ? 'radio_active':'radio_inactive'">New</label>
                                  <input type="radio" id="radio-two" (click)="changeType('app',1,'no')"
                                    [disabled]="appdisable" />
                                  <label for="radio-two"
                                    [ngClass]="!apptTypeFlag ? 'radio_active':'radio_inactive'">Follow-up</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row">

                          <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                            <mat-label class="matlabel">Specialization<br>
                              <select id="mandatory_specfication" class="ipcss widthappt" [(ngModel)]="splName"
                                (change)="getDoctorList(splName)">
                                <option *ngFor="let spl of splList" value="{{spl.spl_name}}">{{spl.spl_name}}</option>
                              </select>
                            </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                            <mat-label class="matlabel">Doctor<br>
                              <select class="ipcss widthappt" [(ngModel)]="doctorId" required
                                (change)="getDoctorDetails()">
                                <option *ngFor="let doctor of doctorList" value="{{doctor.prov_id}}">{{doctor.docname}}
                                </option>
                              </select>
                            </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-5 col-lg-3 col-xl-2">
                            <mat-label class="matlabel">Date
                              <input type="date" class="ipcss_date widthbillcreate" id="appt_date" min="{{currentDate}}"
                                (change)="selectAppointmentDate(appointmentDate)" required [(ngModel)]="appointmentDate"
                                #matInput style="background: #fff;" pattern="\d{1,2}/\d{1,2}/\d{4}">
                            </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" *ngIf="availableFlag">
                            <mat-label class="matlabel">Visiting session<br>
                              <select class="ipcss widthappt" [(ngModel)]="visitSession"
                                (change)="changeVisitSession(visitSession, appointmentDate)" required>
                                <option *ngFor="let session of visitingSessionData" value="{{session.description}}">
                                  {{session.description}}</option>
                              </select>
                            </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" *ngIf="!appTypeTokenFlag">
                            <mat-label class="matlabel">Appointment Time
                              <select required class="ipcss widthappt" [(ngModel)]="visitTime" required
                                *ngIf="!appTypeTokenFlag">
                                <option *ngFor="let time of timeList" value="{{time.time}}">{{time.time}}</option>
                              </select>
                            </mat-label>
                          </div>
                          <div class="col-12" *ngIf="appTypeTokenFlag">
                            <mat-label class="matlabel">
                              <p *ngIf="appTypeTokenFlag">Appointment type : Token</p>
                            </mat-label>
                          </div>
                          <div class="col-12" *ngIf="nonAvailableFlag">
                            <mat-label *ngIf="nonAvailableFlag" class="matlabel">Doctor not available at this time,
                              choose
                              another date to proceed</mat-label>
                          </div>

                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                            <mat-label class="matlabel">Visit purpose<br>
                              <select class="ipcss widthappt" [(ngModel)]="visit_purpose"
                                (change)="changevisit(visit_purpose)">
                                <option *ngFor="let visit of purposeofvisit" value="{{visit.prov_id}}">
                                  {{visit.description}}
                                </option>
                              </select>
                            </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" [hidden]="covidFlag">
                            <mat-label class="matlabel">Complaints
                              <input type="text" class="ipcss noappt_width" [(ngModel)]="clntSymptom"
                                (keyup)="getSymptoms()" matInput />
                              <div class="auto_complete_text" *ngIf="symptomList.length != 0">
                                <ul *ngFor="let symptom of symptomList">
                                  <li>
                                    <a (click)="selectSymptom(symptom)">{{symptom}}</a>
                                  </li>
                                </ul>
                              </div>
                            </mat-label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="cover_div" style="padding-bottom: 13px;" [hidden]="pay_flag">
                      <div class="header_lable">
                        Payments
                      </div>
                      <div class="content_cover">
                        <div class="row align-items-center">



                          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="pay_flag">
                            <mat-label class="matlabel">Payment type<br>
                              <select class="ipcss " [(ngModel)]="payType" (change)="changePayType()"
                                disableOptionCentering>
                                <option *ngFor="let pay of payTypeList" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
                              </select>
                            </mat-label>
                          </div>
                          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="insurFlag">
                            <mat-label class="matlabel" [hidden]="insurFlag">Insurer<br>
                              <select required class="ipcss " [(ngModel)]="insurerId" disableOptionCentering>
                                <option *ngFor="let ins of insurerList" value={{ins.insurer_id}}>{{ins.insurer_desc}}
                                </option>
                              </select>
                            </mat-label>
                          </div>
                          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="cardFlag">
                            <mat-label class="matlabel">Card No <br>
                              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                            </mat-label>
                          </div>
                          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="cardFlag">
                            <mat-label class="matlabel">Holder name<br>
                              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
                            </mat-label>
                          </div>
                          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom"
                            [hidden]="transactionFlag">
                            <mat-label class="matlabel">Transaction ID<br>
                              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionId" />
                            </mat-label>
                          </div>
                          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="chequeFlag">
                            <mat-label class="matlabel">Cheque No <br>
                              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                            </mat-label>
                          </div>
                          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="chequeFlag">
                            <mat-label class="matlabel">Bank Name <br>
                              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
                            </mat-label>
                          </div>
                          <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3"></div>

                        </div>
                      </div>
                    </div>
                    <div class="cover_div" style="margin-top: 15px;">
                      <div class="header_lable">
                        Vitals & Systemic Examination
                      </div>
                      <div class="content_cover">
                        <div class="container  p-0"
                          style="margin-top: 2px;margin-bottom: 2px;margin-left: 0px;margin-right: 0px;">
                          <div class="row">
                            <p style=""><span style="font-weight: 550;">Vitals :</span></p>
                          </div>

                          <div class="row">
                            <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                              <div class="row">
                                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                                  <mat-label class="matlabel">Height
                                    <input class="ipcss noappt_width" (change)="calculateCalories()"
                                      [(ngModel)]="height"
                                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput />

                                  </mat-label>
                                </div>


                                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                                  <mat-label class="matlabel">Measure<br>
                                    <select class="ipcss widthch1 noappt_width nopad" [(ngModel)]="hmeasure"
                                      (change)="calculateCalories()">
                                      <option value="cms">cms</option>
                                      <option value="inch">inch</option>
                                    </select>
                                  </mat-label>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                              <div class="row">
                                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                                  <mat-label class="matlabel">Weight<br>
                                    <input class="ipcss widthch1 noappt_width" (change)="calculateCalories()"
                                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                      [(ngModel)]="weight" matInput />
                                  </mat-label>
                                </div>
                                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                                  <mat-label class="matlabel">Measure<br>
                                    <select class="ipcss widthch1 noappt_width nopad" [(ngModel)]="wmeasure"
                                      (change)="calculateCalories()">
                                      <option value="kgs">kgs</option>
                                      <option value="lbs">lbs</option>
                                    </select>
                                  </mat-label>
                                </div>
                              </div>
                            </div>

                            <!-- onkeypress="return event.charCode >= 48 && event.charCode <= 57" -->

                            <div class="col-6 col-sm-4 col-md-1 col-lg-1 col-xl-1">
                              <mat-label class="matlabel">BMI
                                <input class="ipcss " [(ngModel)]="bmi" disabled matInput />
                              </mat-label>
                            </div>


                            <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-1">
                              <mat-label class="matlabel">BMR<br>
                                <input class="ipcss " [(ngModel)]="bmr" disabled matInput />
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-3" [hidden]="bloodPressureFlag">
                              <mat-label class="matlabel">Blood pressure</mat-label>
                              <div class="row" [hidden]="bloodPressureFlag">
                                <div class=" col-6" [hidden]="bloodPressureFlag">

                                  <input type="text" [hidden]="bloodPressureFlag" class="ipcss widthch1"
                                    placeholder="mm Hg" maxlength="3" [(ngModel)]="BP01"
                                    [ngStyle]="{'color': siscolorstyle ? 'red' : 'black'}"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                    (input)="sistol(BP01)">
                                </div>
                                <div class="col-6" [hidden]="bloodPressureFlag">
                                  <input type="text" [hidden]="bloodPressureFlag" class="ipcss widthch1"
                                    placeholder="mm Hg" maxlength="3" [(ngModel)]="BP02"
                                    [ngStyle]="{'color': diocolorstyle ? 'red' : 'black'}"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                    (input)="diostol(BP02)">
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-2">
                              <div class="row">
                                <div class="col-8">
                                  <mat-label class="matlabel">PR<br>
                                    <input matInput class="ipcss " maxlength="25" placeholder="PR" [(ngModel)]="pr_txt" (input)="pulsevalue(pr_txt)" [ngStyle]="{'color': pulsecolorstyle ? 'red' : 'black'}">
                                  </mat-label>
                                </div>
                                <div class="col-4" style="margin-top:26px;padding: 0px;">
                                  <span>/ min</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-2">
                              <mat-label class="matlabel">Temperature</mat-label>
                              <div class="row">
                                <div class=" col-6" >
                                  <input type="number" matInput class="ipcss noappt_width"
                                  [ngStyle]="{'color': colorstyle ? 'red' : 'black'}" [(ngModel)]="temperature"
                                  (input)="tempcolor(temperature)">                          
                                </div>
                                <div class="col-6" >
                                  <select [(ngModel)]="temp_read" class="ipcss widthappt nopad" (ngModelChange)="chnagetemp($event)">
                                    <option value="°C">°C</option>
                                    <option value="F">F</option>
                                  </select>
                                </div>
                              </div>                          
                            </div>
                            <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-2">
                              <div class="row">
                                <div class="col-8">
                                  <mat-label class="matlabel">RR<br>
                                    <input matInput class="ipcss " maxlength="25" placeholder="RR" [(ngModel)]="rr_txt"
                                      (input)="rrvalue(rr_txt)" [ngStyle]="{'color': rrcolorstyle ? 'red' : 'black'}">
                                  </mat-label>
                                </div>
                                <div class="col-4" style="margin-top:26px;padding: 0px;">
                                  <span>/ min</span>
                                </div>
                              </div>
                            </div>
                       
                            <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-2">
                              <div class="row">
                                <div class="col-8">
                                  <mat-label class="matlabel">Spo2<br>
                                    <input matInput class="ipcss " maxlength="25" placeholder="Spo2" [(ngModel)]="spo2"
                                      (input)="spo2value(spo2)" [ngStyle]="{'color': spo2colorstyle ? 'red' : 'black'}">
                                  </mat-label>
                                </div>
                                <div class="col-4" style="margin-top:26px;padding: 0px;">
                                  <span>%</span>
                                </div>
                              </div>
                            </div>
                            <!-- <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                              <mat-label class="matlabel">General Examination<br>
                                <input matInput class="ipcss " maxlength="25" placeholder="cns" [(ngModel)]="generalexamination">
                              </mat-label>
                            </div> -->
                            <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-2">
                              <div class="row">
                                <div class="col-8">
                                  <mat-label class="matlabel">CBG<br>
                                    <input matInput class="ipcss " maxlength="25" placeholder="CBG"
                                      [(ngModel)]="cbg_txt" (input)="cbgvalue(cbg_txt)"
                                      [ngStyle]="{'color': cbgcolorstyle ? 'red' : 'black'}">
                                  </mat-label>
                                </div>
                                <div class="col-4" style="margin-top:26px;padding: 0px;">
                                  <span>mg/dl</span>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div class="container  p-0"
                          style="margin-top: 11px;margin-bottom: 2px;margin-left: 0px;margin-right: 0px;">
                          <div class="row">
                            <p style=""><span style="font-weight: 550;">Systemic Examination :</span></p>
                          </div>

                          <div class="row">
                    
                          
                            <!-- <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-2">
                              <div class="row">
                                <div class="col-8">
                                  <mat-label class="matlabel">Pulse<br>
                                    <input matInput class="ipcss " placeholder="pulse" [(ngModel)]="pulse"
                                      (input)="pulsevalue(pulse)"
                                      [ngStyle]="{'color': pulsecolorstyle ? 'red' : 'black'}">
                                  </mat-label>
                                </div>
                                <div class="col-4" style="margin-top:26px;padding: 0px;">
                                  <span> bpm</span>
                                </div>
                              </div>
                            </div> -->

                            <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-2">
                              <mat-label class="matlabel">CVS<br>
                                <input matInput class="ipcss " placeholder="CVS" [(ngModel)]="cvs_txt">
                              </mat-label>
                            </div>
                            <!-- newly added rs key -->

                            <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-2">
                              <mat-label class="matlabel">RS<br>
                                <input matInput class="ipcss " maxlength="25" placeholder="RS" [(ngModel)]="rstxt">
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-2">
                              <mat-label class="matlabel">Abdomen<br>
                                <input matInput class="ipcss " placeholder="ABD" maxlength="25" [(ngModel)]="abd_txt">
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-2">
                              <mat-label class="matlabel">CNS<br>
                                <input matInput class="ipcss " maxlength="25" placeholder="CNS" [(ngModel)]="cns_txt">
                              </mat-label>
                            </div>
                            
                            <!-- new keys -->
                          
                           

                    


                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="cover_div" style="padding-bottom: 13px;" *ngIf="test_flag">
                      <div class="header_lable">Tests</div>
                      <div class="content_cover">
                        <div class="table-responsive dig_table_overflow">
                          <table *ngIf="nextvisittestarray.length"
                            class="table table-nowrap table-sm dataTable billlisttable">
                            <thead>
                              <tr>
                                <th> Department </th>
                                <th> Category </th>
                                <th>Test name</th>
                                <th>Price</th>

                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let person of nextvisittestarray; let i = index">
                                <!-- <td style="font-size: 12px; text-align: left !important;">{{
                                      person.doc_name }}</td> -->
                                <td style="font-size: 12px;">{{ person.department }}</td>
                                <td style="font-size: 12px;">{{ person.category }}</td>
                                <td style="font-size: 12px;">{{ person.testname }}</td>
                                <td style="font-size: 12px;">{{ person.price }}</td>

                                <!-- <td style="font-size: 12px; text-align: center;">{{ person.app_type }}</td> -->
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="cover_div" style="padding-bottom: 13px;" *ngIf="previousflag">
                      <div class="header_lable">Previous Visit</div>
                      <div class="content_cover">
                        <div class="table-responsive dig_table_overflow">
                          <table *ngIf="previousvisits.length"
                            class="table table-nowrap table-sm dataTable billlisttable">
                            <thead>
                              <tr>
                                <th>Doctor Name</th>
                                <th>Visiting date</th>
                                <th>Visit purpose</th>
                                <th>Specialization</th>
                                <th>Visit Type</th>

                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let person of previousvisits; let i = index">
                                <td style="font-size: 12px; text-align: left !important;">{{ person.doc_name }}</td>
                                <td style="font-size: 12px;">{{ person.visit_date }}</td>
                                <td style="font-size: 12px;">{{ person.visit_purpose }}</td>
                                <td style="font-size: 12px;">{{ person.specialization_name }}</td>
                                <td style="font-size: 12px; text-align: center;">{{ person.app_type }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>


                  </td>
                </tr>

              </table>
            </div>
          </div>
        </div>

        <div [hidden]="true">
          <div #idprint id="idprint">
            <div class="container-fluid m-5">
              <div class="row">
                <div class="col-6"
                  style="width: 300px; border-style: solid; border-width: thin; border-radius: 10px; border-color: rgb(139, 139, 251); box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; ">
                  <div class="justify-content-center" style="text-align: center;">
                    <img style="width: 125px; height: 125px; border-radius: 72px; margin: 10px;" src={{imgUrl}}>
                    <h1 style="font-size: 16px; letter-spacing: 4px;  color: rgb(139, 139, 251);">
                      {{clntFirstName + ' ' + clntLastName}}</h1>
                  </div>
                  <div class="d-flex align-items-center justify-content-center mt-2" style="letter-spacing: 1px">
                    <div>
                      <p style="font-size: 12px; font-family:Verdana, Geneva, Tahoma, sans-serif; ">Patient Name </p>
                      <p style="font-size: 12px; font-family:Verdana, Geneva, Tahoma, sans-serif; ">MR Number</p>
                      <p style="font-size: 12px; font-family:Verdana, Geneva, Tahoma, sans-serif; ">DOB</p>
                      <p style="font-size: 12px; font-family:Verdana, Geneva, Tahoma, sans-serif; ">Age</p>
                      <p style="font-size: 12px; font-family:Verdana, Geneva, Tahoma, sans-serif; ">Gender</p>
                      <p style="font-size: 12px; font-family:Verdana, Geneva, Tahoma, sans-serif; ">Mobile Number</p>
                    </div>
                    <div class="mx-3">
                      <p style="font-size: 12px;">:</p>
                      <p style="font-size: 12px;">:</p>
                      <p style="font-size: 12px;">:</p>
                      <p style="font-size: 12px;">:</p>
                      <p style="font-size: 12px;">:</p>
                      <p style="font-size: 12px;">:</p>
                    </div>
                    <div style="font-family:Verdana, Geneva, Tahoma, sans-serif; letter-spacing: 1px">
                      <p style="font-size: 12px;">{{clntFirstName + ' ' + clntLastName}}</p>
                      <p style="font-size: 12px;">{{mrNumber}}</p>
                      <p style="font-size:12px;">{{dateformate}}</p>
                      <p style="font-size: 12px;">{{clntAge}}</p>
                      <p style="font-size: 12px;">{{clntGender}}</p>
                      <p style="font-size: 12px;">{{clntContNumber}}</p>
                    </div>
                  </div>
                  <div style="margin: 10px;" class="d-flex align-items-center justify-content-center">
                    <ngx-barcode class="barcode" [bc-value]="mrNumber" [bc-display-value]="displayValue"
                      [bc-line-color]="lineColor" [element-type]="elementType" [format]="format" [bc-width]="width"
                      [bc-height]="barcodeheight" [bc-font-options]="fontOptions" [bc-font]="font"
                      [bc-text-align]="textAlign" [bc-text-position]="textPosition" [bc-text-margin]="textMargin"
                      [bc-font-size]="fontSize" [bc-background]="background" [bc-margin]="margin"
                      [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom" [bc-margin-left]="marginLeft"
                      [bc-margin-right]="marginRight"></ngx-barcode>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card> <br><br><br>
  </div>
</div>