import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate, Time_Formate } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-daybook',
  templateUrl: './daybook.component.html',
  styleUrls: ['./daybook.component.scss']
})

export class DaybookComponent implements OnInit {
  @ViewChild('printDBR') printDBR: ElementRef;
  @ViewChild('printimage') printimage: ElementRef;
  @ViewChild('hospitalwithnamelogo') hospitalwithnamelogo: ElementRef;
  @ViewChild('printusertable') printusertable: ElementRef;
  public userInfo;
  public pharmacyID;
  public hptlClinicId;
  public amount: boolean;
  public print_template;
  public printFontSize:any = "10px";
  public hospital_logo;
  public totalAmount:any = 0;
  public cashAmount:any = 0;
  public cardAmount:any = 0;
  public insureAmount:any = 0;
  public upiAmount:any = 0;
  public discountAmount:any = 0;
  public advanceAmount:any = 0;
  public paidAmount:any = 0;
  public balanceAmount:any = 0;
  public insuranceAmount:any= 0;
  public header: string[];
  public fromDate;
  public toDate;  
  public userdataList:any = []; 
  public dayBookList:any = [];
  public columns = {
    docName: true,
    billType: true,
    discount: true,
    advance: true,
    paid: true,
    balance: true,
    cash: true,
    card: true,
    upi: true,
    insurance: true,
    status: true
  };
  public colspann: number = 4;
  public currenttime;
  public currentDate;
  public presentdate;
  public hosp_name;
  public hosp_addr;
  public hosp_csz;
  public hosp_tel;
  public hospitalcheckbox:boolean=true;
  public diagnosischeckbox:boolean=true;
  public pharmacycheckbox:boolean=true;
  public filterdaybooklist:any=[];
  public userheader:any=[];
  public username: any;
  public selected_filter: any = [];

  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.selected_filter = ['docName', 'billType', 'discount', 'advance', 'paid', 'balance', 'cash', 'card','upi', 'insurance', 'status'];
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    console.log(this.userInfo);
    this.header = ['S.no', 'Bill No', 'User name','Created date', 'Patient Name', 'Doctor','App type','Bill Amt', 'Paid', 'Discount', 'Advance', 'Cash', 'Card', 'UPI','Insurance', 'Balance','Pay type', 'Status', 'Bill type' ];
    this.userheader = ['S.no','User name','Bill Amt', 'advance','Cash','Card','UPI','Cheque','Discount','Insurance','Balance'];
    if (this.userInfo.user_type == 'Admin') {
      this.pharmacyID = this.userInfo.pharma_id;
      this.hptlClinicId = this.userInfo.hptl_clinic_id;
    } else {
      this.pharmacyID = this.userInfo.hospitals[0].pharma_id;
      this.hptlClinicId = this.userInfo.hospitals[0].hptl_clinic_id;
    }
    this.hosp_name = this.userInfo.hptl_name;
    this.hosp_addr = this.userInfo.hospitals[0].address1 + ', ' + this.userInfo.hospitals[0].location_desc + ', ';
    this.hosp_csz = this.userInfo.hospitals[0].city_desc + '- ' + this.userInfo.hospitals[0].zipcode + ', ' + 
                    this.userInfo.hospitals[0].state_desc + '- ' + this.userInfo.hospitals[0].country_desc + '.';
    this.hospital_logo = ipaddress.Ip_with_img_address + this.userInfo.bill_print_logo;
    this.print_template = this.userInfo.bill_print_template;
    this.getCurrentDate();
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("current time", JSON.stringify(obj));
          this.currentDate = obj.current_date;
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          var currenttime1 = obj.current_time.split(":");
          this.currenttime = currenttime1[0];
          this.presentdate = obj.current_date;
          this.getDayBookData();
          console.log('CURRENTDATE',this.presentdate);
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  setTable(data) {
    console.log(data);
    this.columns = {
      docName: false,
      billType: false,
      discount: false,
      advance: false,
      paid: false,
      balance: false,
      cash: false,
      card: false,
      upi: false,
      insurance: false,
      status: false
    };

    for (var i = 0; i < data.length; i++) {
      this.columns[data[i]] = !this.columns[data[i]];
    }
  }

  selectDate(data, value) {
    if (data != null) {
      // this.fromDate = data;
      this.getDayBookData();
    }
  }

  getDayBookData(){
      var hospital,diagnosis,pharma;
      if (this.hospitalcheckbox == true) 
        hospital = '1';
      else 
        hospital = '0';
      
      if (this.diagnosischeckbox == true) 
        diagnosis = '1';
      else 
        diagnosis = '0';

      if (this.pharmacycheckbox == true) 
        pharma = '1';
      else 
        pharma = '0';
      var send_data = {
          hptl_clinic_id: this.hptlClinicId,
          from_date: this.fromDate,
          to_date: this.toDate,
          hospital: hospital,
          diagnosis: diagnosis,
          pharma: pharma
      };
      console.log(send_data);
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + 'misr/gdbd', send_data,
        { headers: headers }).subscribe(
          response => {
            var data = response.json();
            var user_wise_data = data.userwise_details;
            console.log(JSON.stringify(data));
            this.userdataList = [];
            this.dayBookList = [];
            this.totalAmount = 0;
            this.cashAmount = 0;
            this.cardAmount = 0;
            this.insureAmount = 0;
            this.upiAmount = 0;
            this.discountAmount = 0;
            this.advanceAmount = 0;
            this.paidAmount = 0;
            this.balanceAmount = 0;
            this.insuranceAmount = 0;
            if (data != null) {
              if(data.userwise_details != undefined){
                for (var j = 0; j < user_wise_data.length; j++) {
                  console.log("user name=====>>" + user_wise_data[j].user_name)
                  if (user_wise_data[j].user_name != undefined && user_wise_data[j].user_name != null) {
                    if (user_wise_data[j].cash == undefined || user_wise_data[j].cash == null) 
                      user_wise_data[j].cash = 0;                
                    if (user_wise_data[j].card == undefined || user_wise_data[j].card == null) 
                      user_wise_data[j].card = 0;                
                    if (user_wise_data[j].upi == undefined || user_wise_data[j].upi == null) 
                      user_wise_data[j].upi = 0;              
                    if (user_wise_data[j].insurance == undefined || user_wise_data[j].insurance == null) 
                      user_wise_data[j].insurance = 0;
                    if (user_wise_data[j].balance == undefined || user_wise_data[j].balance == null) 
                      user_wise_data[j].balance = 0;
                    if (user_wise_data[j].discount == undefined || user_wise_data[j].discount == null) 
                      user_wise_data[j].discount = 0;
                    if (user_wise_data[j].cheque == undefined || user_wise_data[j].cheque == null) 
                      user_wise_data[j].cheque = 0;
                    
                    this.userdataList.push({
                      sno: j + 1,
                      username: user_wise_data[j].user_name,
                      // bill: encrypt_decript.Decript(user_wise_data[j].bill_amount),
                      bill: parseFloat(user_wise_data[j].total_amount),
                      cash: parseFloat(user_wise_data[j].cash),
                      card: parseFloat(user_wise_data[j].card),
                      upi: parseFloat(user_wise_data[j].upi),
                      cheque: parseFloat(user_wise_data[j].cheque),
                      discount: parseFloat(user_wise_data[j].discount),
                      insurance: parseFloat(user_wise_data[j].insurance),
                      balance: parseFloat(user_wise_data[j].balance)
                    })
                  }
                }
                
                if (this.userdataList.length != 0) {
                  for (var u = 0; u < this.userdataList.length; u++) {
                    this.userdataList[u].sno =  u+1;
                  }
                }
              }
              
              if (data.charge_details != undefined) {
                for (var i = 0; i < data.charge_details.length; i++) {
                  var name;
                  if (data.charge_details[i].middle_name != undefined) 
                    name = encrypt_decript.Decript(data.charge_details[i].first_name) + " " + encrypt_decript.Decript(data.charge_details[i].middle_name) + " " + encrypt_decript.Decript(data.charge_details[i].last_name);
                  else if (data.charge_details[i].last_name != undefined) {
                    if (data.charge_details[i].last_name != undefined)
                      name = encrypt_decript.Decript(data.charge_details[i].first_name) + " " + encrypt_decript.Decript(data.charge_details[i].last_name);
                    else 
                      name = encrypt_decript.Decript(data.charge_details[i].first_name);
                  } else if (data.charge_details[i].first_name != undefined)
                    name = encrypt_decript.Decript(data.charge_details[i].first_name);
                  else 
                    name = '';
                  
                  if (data.charge_details[i].cash == undefined || data.charge_details[i].cash == null) 
                    data.charge_details[i].cash = 0;
                  if (data.charge_details[i].card == undefined || data.charge_details[i].card == null) 
                    data.charge_details[i].card = 0;
                  if (data.charge_details[i].upi == undefined || data.charge_details[i].upi == null) 
                    data.charge_details[i].upi = 0;
                  if (data.charge_details[i].insurance == undefined || data.charge_details[i].insurance == null) 
                    data.charge_details[i].insurance = 0;
                  if (data.charge_details[i].balance == undefined || data.charge_details[i].balance == null) 
                    data.charge_details[i].balance = 0;
                  if (data.charge_details[i].discount == undefined || data.charge_details[i].discount == null) 
                    data.charge_details[i].discount = 0;
                  
                  var bill_amount = data.charge_details[i].bill_amount != undefined ? encrypt_decript.Decript(data.charge_details[i].bill_amount) : 0;

                  if(data.charge_details[i].user_name != undefined)
                    data.charge_details[i].user_name = data.charge_details[i].user_name;
                  else
                    data.charge_details[i].user_name = "";

                  var created_date_time = '';
                  if (data.charge_details[i].created_date != undefined && data.charge_details[i].created_time != undefined) {
                    created_date_time = Date_Formate(data.charge_details[i].created_date)+" "+Time_Formate(data.charge_details[i].created_time);
                  } else if (data.charge_details[i].created_date != undefined) {
                    created_date_time = Date_Formate(data.charge_details[i].created_date);
                  } else if (data.charge_details[i].created_time != undefined) 
                    created_date_time = Time_Formate(data.charge_details[i].created_time);

                  var advance = data.charge_details[i].advance != undefined ? data.charge_details[i].advance : 0;

                  this.dayBookList.push({
                    sno: i + 1,
                    billing_id: data.charge_details[i].billing_id,
                    user_name:data.charge_details[i].user_name,
                    created_date: created_date_time,
                    patient_name: name,
                    doc_name: data.charge_details[i].doc_name,
                    apptype: data.charge_details[i].app_type,
                    bill_amount: parseFloat(bill_amount),
                    paid_amount: parseFloat(data.charge_details[i].paid_amount),
                    discount: parseFloat(data.charge_details[i].discount),
                    advance: parseFloat(advance),
                    cash: parseFloat(data.charge_details[i].cash),
                    card: parseFloat(data.charge_details[i].card),
                    upi: parseFloat(data.charge_details[i].upi),
                    insurance: parseFloat(data.charge_details[i].insurance),
                    balance: parseFloat(data.charge_details[i].balance),
                    pay_type: data.charge_details[i].pay_type,
                    paid: data.charge_details[i].paid_flag,
                    bill_type: data.charge_details[i].bill_type,
                  });

                  if (bill_amount != undefined) 
                    this.totalAmount += Math.round(bill_amount);
                  if (data.charge_details[i].discount != undefined)
                    this.discountAmount+= parseFloat(data.charge_details[i].discount);
                  if (data.charge_details[i].advance != undefined)
                    this.advanceAmount+= parseFloat(data.charge_details[i].advance);
                  if (data.charge_details[i].paid_amount != undefined) 
                    this.paidAmount+= parseFloat(data.charge_details[i].paid_amount);
                  if (data.charge_details[i].balance != undefined)
                    this.balanceAmount+= parseFloat(data.charge_details[i].balance);
                  if (data.charge_details[i].cash != undefined) 
                    this.cashAmount+= parseFloat(data.charge_details[i].cash);
                  if (data.charge_details[i].card != undefined) 
                    this.cardAmount+= parseFloat(data.charge_details[i].card);
                  if (data.charge_details[i].upi != undefined) 
                    this.insureAmount+= parseFloat(data.charge_details[i].upi);
                  if (data.charge_details[i].upi != undefined) 
                    this.upiAmount+= parseFloat(data.charge_details[i].upi);
                  if (data.charge_details[i].insurance != undefined) 
                    this.insuranceAmount+= parseFloat(data.charge_details[i].insurance);
                }
              }
            }
          }, error => { 
            this.toastr.error(Message_data.defaultErr);
          });
  }

  check_hospital_depart(value,type) {
    this.getDayBookData(); 
  }

  check_diag_depart(value,type) {
    this.getDayBookData();
  }

  check_pharma_depart(value,type) {
    this.getDayBookData();
  }

  getUserbasedDetails(data) {
    console.log('Hospital checkbox:', this.hospitalcheckbox);
    console.log('Diagnosis checkbox:', this.diagnosischeckbox);
    console.log('Pharmacy checkbox:', this.pharmacycheckbox);
    console.log('User data:', data);
    this.filterdaybooklist = [];
    if (!this.hospitalcheckbox && !this.diagnosischeckbox && !this.pharmacycheckbox) {
      this.filterdaybooklist = [];
      this.username = data.username;
    } else {
      this.username = data.username;
      this.filterdaybooklist = this.dayBookList.filter(item => {
          const usernameMatch = item.user_name.toLowerCase().includes(data.username.toLowerCase());
          const noCheckboxChecked = !this.hospitalcheckbox && !this.diagnosischeckbox && !this.pharmacycheckbox;
          const hospitalMatch = this.hospitalcheckbox && item.bill_type === 'hospital';
          const diagnosisMatch = this.diagnosischeckbox && item.bill_type === 'diagnosis';
          const pharmacyMatch = this.pharmacycheckbox && item.bill_type === 'pharma';
          return usernameMatch && (noCheckboxChecked || hospitalMatch || diagnosisMatch || pharmacyMatch);
      });
      console.log('Filtered list:', this.filterdaybooklist);
      if (this.filterdaybooklist.length != 0) {
        var sno = 1;
        for (var i = 0; i < this.filterdaybooklist.length; i++) {
          this.filterdaybooklist[i].sno = sno;
          sno += 1;
        }
      }  
    }        
  }
 
  print_area() {
    this.amount = true;
    let printContents, popupWin, totalamt, cashamt, cardamt, insureamt, upiamt, usertable, creditAmt, logo, hospitaldetails, discountamt;
    if (this.dayBookList.length != 0) {
      logo = document.getElementById('printimage').innerHTML;
      hospitaldetails = this.hospitalwithnamelogo.nativeElement.innerHTML;
      if (this.print_template != undefined && this.print_template == "banner") {
        this.amount = false;
        printContents = this.printDBR.nativeElement.innerHTML;
        usertable = this.printusertable.nativeElement.innerHTML;
        popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
        popupWin.document.open();
        // Writing HTML content to the new window
        popupWin.document.write(`
          <html>
            <head>
              <title>Day_book Report</title>
              <!-- Bootstrap CSS -->
              <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
              <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
      
              <!-- Custom Styles -->
              <style>
                @page { size: landscape || portrait; padding: 20px; }
                
                @media print and (orientation: landscape) {
                  .table {
                    width: 100%;
                  }
                }
      
                @media print and (orientation: portrait) {
                  .table {
                    width: auto;
                  }
                }
      
                th {
                  padding: 0%;
                }
      
                .table {
                  padding: 5% !important;
                  border: 1px solid #c7c7e9bf !important;
                  border-radius: 10px !important;
                  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
                  font-family: Segoe UI;
                  font-size: 12px; 
                }
      
                * {
                  font-size: ${this.printFontSize} !important; 
                }
              </style>
            </head>
      
            <body onload="window.print(); window.onfocus = function() { window.close(); }">
              <table style="border: 0; width: 100%; margin: 20px;">  
                <thead>
                  <tr>
                    ${logo}
                  </tr>
                </thead>             
                
                <tfoot> 
                  <tr>  
                    <td width="100%">  
                      <table style="border: 0; width: 100%;">  
                        <tr>  
                          <td style="width: 100%;">&nbsp;</td>  
                        </tr>  
                      </table>  
                    </td>  
                  </tr> 
                </tfoot> 
      
                <tbody>  
                  <tr>  
                    <td width="100%"> 
                      <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table" style="width: 95%;">
                        <thead>
                          <tr>
                            <th colspan="3">${totalamt}</th>
                            <th colspan="2">${cashamt}</th>
                            <th colspan="2">${cardamt}</th>
                            <th colspan="2">${upiamt}</th>
                            <th colspan="2">${insureamt}</th>
                            <th colspan="2">${discountamt}</th>
                            <th colspan="2">${creditAmt}</th>
                          </tr>
                        </thead>
                      </table>
      
                      <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
                        ${printContents}
                      </table>
      
                      <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table" style="margin-top: 15px; margin-left: 25px; width: 95%;">
                        ${usertable}
                      </table>
                    </td>  
                  </tr> 
                </tbody>  
              </table>
            </body>
          </html>
        `);
        popupWin.document.close();
      } else if (this.print_template != undefined && this.print_template == "logowithname") {
        this.amount = false;
        printContents = this.printDBR.nativeElement.innerHTML;
        usertable = this.printusertable.nativeElement.innerHTML;
        popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
        popupWin.document.open();
        // Writing HTML content to the new window
        popupWin.document.write(`
          <html>
            <head>
              <title>Day_book Report</title>
              
              <!-- Bootstrap CSS -->
              <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
              <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
      
              <!-- Custom Styles -->
              <style>
                @page { size: landscape || portrait; }
                
                @media print and (orientation: landscape) {
                  .table {
                    width: 100%;
                  }
                }
      
                @media print and (orientation: portrait) {
                  .table {
                    width: auto;
                  }
                }
      
                th {
                  padding: 0%;
                }
      
                .table {
                  padding: 5% !important;
                  border: 1px solid #c7c7e9bf !important;
                  border-radius: 10px !important;
                  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
                  font-family: Segoe UI;
                  font-size: 12px; 
                }
              </style>
            </head>
      
            <body onload="window.print(); window.onfocus = function() { window.close(); }">
              <table style="border: 0; width: 100%; margin: 10px;">  
                <thead>
                  <tr>
                    ${logo}
                  </tr>
                  <tr>
                    <td>
                      ${hospitaldetails}
                    </td>
                  </tr>
                </thead>             
                
                <tfoot> 
                  <tr>  
                    <td width="100%">  
                      <table style="border: 0; width: 100%;">  
                        <tr>  
                          <td style="width: 100%;">&nbsp;</td>  
                        </tr>  
                      </table>  
                    </td>  
                  </tr> 
                </tfoot> 
      
                <tbody>  
                  <tr>  
                    <td width="100%"> 
                      <table class="table table-striped bordered align-middle mb-1 bg-white tbl px-0" id="table">
                        <thead>
                          <tr>
                            <th colspan="3">${totalamt}</th>
                            <th colspan="2">${cashamt}</th>
                            <th colspan="2">${cardamt}</th>
                            <th colspan="2">${upiamt}</th>
                            <th colspan="2">${insureamt}</th>
                          </tr>
                        </thead>
                      </table>
      
                      <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
                        ${printContents}
                      </table>
      
                      <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table" style="margin-top: 50px;">
                        ${usertable}
                      </table>
                    </td>  
                  </tr> 
                </tbody>  
              </table>
            </body>
          </html>
        `);
        popupWin.document.close();
      } else {
        this.amount = false;
        printContents = this.printDBR.nativeElement.innerHTML;
        usertable = this.printusertable.nativeElement.innerHTML;
        popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
        popupWin.document.open();
        // Writing HTML content to the new window
        popupWin.document.write(`
          <html>
            <head>
              <title>Day_book Report</title>
              
              <!-- Bootstrap CSS -->
              <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
              <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
      
              <!-- Custom Styles -->
              <style>
                @page { size: landscape || portrait; padding: 20px; }
                
                @media print and (orientation: landscape) {
                  .table {
                    width: 100%;
                  }
                }
      
                @media print and (orientation: portrait) {
                  .table {
                    width: auto;
                  }
                }
      
                th {
                  padding: 0%;
                }
      
                .table {
                  padding: 1% !important;
                  border: 1px solid #c7c7e9bf !important;
                  border-radius: 10px !important;
                  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
                  font-family: Segoe UI;
                  font-size: 12px; 
                }
      
                * {
                  font-size: ${this.printFontSize} !important; 
                }
              </style>
            </head>
      
            <body onload="window.print(); window.onfocus = function() { window.close(); }">
              <table style="border: 0; width: 98%; margin-left: auto; margin-right: auto;">  
                <thead>
                  <tr>
                    <td><div style="height: 130px;"></div></td>        
                  </tr>
                </thead>             
                
                <tfoot> 
                  <tr>  
                    <td width="100%">  
                      <table style="border: 0; width: 100%;">  
                        <tr>  
                          <td style="width: 100%;">&nbsp;</td>  
                        </tr>  
                      </table>  
                    </td>  
                  </tr> 
                </tfoot> 
      
                <tbody>  
                  <tr>  
                    <td width="100%"> 
                      <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table" style="margin-top: 15px; margin-left: 25px; width: 95%;">
                        ${usertable}
                      </table>
      
                      <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table" style="margin-top: 10px;">
                        ${printContents}
                      </table>
                    </td>  
                  </tr> 
                </tbody>  
              </table> 
            </body>
          </html>
        `);
        popupWin.document.close();
      }  
    }
  }

  getExcelData(type) {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('day book');
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 10;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
    worksheet.getColumn(12).width = 20;

    var headerRow = worksheet.addRow(this.header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    });

    for (let x1 of this.dayBookList) {
      let x2 = Object.keys(x1);
      let temp = [];
      for (let y of x2) {
        temp.push(x1[y])
      }
      let chilrow = worksheet.addRow(temp)
      let salesamt = chilrow.getCell(5);
      salesamt.alignment = {
        vertical: 'middle',
        horizontal: 'right'
      }
    }

    var totaldata = ['', '', '', '', '', '', '', 'Total amount', this.totalAmount];
    let cashrow = worksheet.addRow(totaldata);
    let cashval = cashrow.getCell(8);
    cashval.font = {
      bold: true,
      color: { argb: '000000' },
      size: 12
    }

    let cashamt = cashrow.getCell(9);
    cashamt.alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }
    cashamt.font = {
      bold: true,
      color: { argb: '000000' },
      size: 12
    }

    let paidamt = cashrow.getCell(10);
    paidamt.alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }

    let balamt = cashrow.getCell(11);
    balamt.alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }

    var discountdata = ['', '', '', '', '', '', '', 'Discount', this.discountAmount];
    let cardrow = worksheet.addRow(discountdata);
    let cardval = cardrow.getCell(8);
    cardval.font = {
      bold: true,
      color: { argb: '000000' },
      size: 12
    }

    let cardamt = cardrow.getCell(9);
    cardamt.alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }
    cardamt.font = {
      bold: true,
      color: { argb: '000000' },
      size: 12
    }

    var advancedata = ['', '', '', '', '', '', '', 'Advance', this.advanceAmount];
    let advancerow = worksheet.addRow(advancedata);
    let advanceval = advancerow.getCell(8);
    advanceval.font = {
      bold: true,
      color: { argb: '000000' },
      size: 12
    }

    let advanceamt = advancerow.getCell(9);
    advanceamt.alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }
    advanceamt.font = {
      bold: true,
      color: { argb: '000000' },
      size: 12
    }

    var paiddata = ['', '', '', '', '', '', '', 'Paid', this.paidAmount];
    let insrow = worksheet.addRow(paiddata);
    let insval = insrow.getCell(8);
    insval.font = {
      bold: true,
      color: { argb: '000000' },
      size: 12
    }

    let insamt = insrow.getCell(9);
    insamt.alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }
    insamt.font = {
      bold: true,
      color: { argb: '000000' },
      size: 12
    }

    var balancedata = ['', '', '', '', '', '', '', 'Balance', this.balanceAmount];
    let upirow = worksheet.addRow(balancedata);
    let upival = upirow.getCell(8);
    upival.font = {
      bold: true,
      color: { argb: '000000' },
      size: 12
    }

    let upiamt = upirow.getCell(9);
    upiamt.alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }
    upiamt.font = {
      bold: true,
      color: { argb: '000000' },
      size: 12
    }

    var finaldatacash = ['', '', '', '', '', '', '', 'Cash', this.cashAmount];
    let cash_amountrow = worksheet.addRow(finaldatacash);
    let cash_amountval = cash_amountrow.getCell(8);
    cash_amountval.font = {
      bold: true,
      color: { argb: '000000' },
      size: 12
    }

    let cash_amountamt = cash_amountrow.getCell(9);
    cash_amountamt.alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }
    cash_amountamt.font = {
      bold: true,
      color: { argb: '000000' },
      size: 12
    }

    var finaldatacard = ['', '', '', '', '', '', '', 'Card', this.cardAmount];
    let card_amountrow = worksheet.addRow(finaldatacard);
    let card_amountval = card_amountrow.getCell(8);
    card_amountval.font = {
      bold: true,
      color: { argb: '000000' },
      size: 12
    }

    let card_amountamt = card_amountrow.getCell(9);
    card_amountamt.alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }
    card_amountamt.font = {
      bold: true,
      color: { argb: '000000' },
      size: 12
    }

    var finaldataupi = ['', '', '', '', '', '', '', 'UPI', this.upiAmount];
    let finalrow = worksheet.addRow(finaldataupi);
    let totalval = finalrow.getCell(8);
    totalval.font = {
      bold: true,
      color: { argb: '000000' },
      size: 12
    }

    let totalamt = finalrow.getCell(9);
    totalamt.alignment = {
      vertical: 'middle',
      horizontal: 'right'
    }
    totalamt.font = {
      bold: true,
      color: { argb: '000000' },
      size: 12
    }

    var emptyRow = worksheet.addRow(" ");
    var emptyRowsec = worksheet.addRow(" ");
    var headerRow = worksheet.addRow(this.userheader);
    emptyRow.eachCell(() => { })
    emptyRowsec.eachCell(() => { })
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    });

    for (let x4 of this.userdataList) {
      let x5 = Object.keys(x4);
      let temp = [];
      for (let y of x5) {
        temp.push(x4[y]);
      }
      let chilrow = worksheet.addRow(temp);
      let salesamt = chilrow.getCell(5);
      salesamt.alignment = {
        vertical: 'middle',
        horizontal: 'right'
      }
    }

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "Day_book" + '-' + new Date().valueOf() + '.xlsx');
    });
  }
}




