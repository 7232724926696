import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Time_Formate } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';

@Component({
  selector: 'app-inpatient-reports',
  templateUrl: './inpatient-reports.component.html',
  styleUrls: ['./inpatient-reports.component.scss']
})

export class InpatientReportsComponent implements OnInit {
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  public selected_filter: any = [];
  public userInfo;
  public hptlClinicId;
  public printbutton: boolean = true;
  public print_template;
  public hospital_logo;
  public printlogostyle;
  public fromDate;
  public toDate;
  public noData: boolean;
  public hasData: boolean;
  public columns = {
    mrNo: true,
    wardBed: true,
    admDateTime: true,
    ageGender: true,
    mobileNo: true,
    docName: true,
    billAmt: true,
    billstatus: true
  };
  public currenttime;
  public currentDate;
  public presentdate;
  public inpatient_list: any[];
  public all_charges_list: any[];
  public charges_list: any[];
  public count;
  selectedDuration: string = 'select';
  public forcoverdiv: boolean = true;
  public backbutton: boolean = false;
  public ishide: boolean = false;

  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.selected_filter = ['mrNo', 'wardBed', 'admDateTime', 'ageGender', 'mobileNo', 'docName', 'billAmt','billstatus'];
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.printbutton = true;
    if (this.userInfo.user_type == 'Admin') 
      this.hptlClinicId = this.userInfo.hptl_clinic_id;          
    else  
      this.hptlClinicId = this.userInfo.hospitals[0].hptl_clinic_id;
    this.hospital_logo = ipaddress.Ip_with_img_address + this.userInfo.bill_print_logo;
    this.print_template = this.userInfo.bill_print_template;
    this.printlogostyle = this.userInfo.bill_print_logo_style;
    this.getCurrentDate();
  }

  todisplaycoverdiv() {
    this.forcoverdiv = true;
    
    this.backbutton = false;
    this.ishide = false;
    this.inpatient_list=[]
    this.hasData = true;
  }


  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log(JSON.stringify(obj))
          this.currentDate = obj.current_date;
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          var currenttime1 = obj.current_time.split(":");
          this.currenttime = currenttime1[0]
          this.presentdate = obj.current_date;
          console.log('CURRENT_DATE', this.presentdate);
          this.getInpatientReportData();
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  updateFromDate() {
    const today = new Date();
    const selectedDuration = parseInt(this.selectedDuration);

    if (!isNaN(selectedDuration)) {
      const fromDate = new Date(today);
      fromDate.setDate(today.getDate() - selectedDuration);
      this.fromDate = fromDate.toISOString().split('T')[0];
      this.toDate = today.toISOString().split('T')[0];
    }
    this.getInpatientReportData()
  }

  getInpatientReportData() {
    var send_data = {
      from_date:this.fromDate,
      to_date:this.toDate,
      hptl_clinic_id: this.hptlClinicId,
    };
    console.log("SEND_DATA", send_data);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "misr/gibr", send_data,
      { headers: headers }).subscribe(
        data => {
          var dataval = data.json();
          console.log(dataval);
          var inpatients = dataval.inpatients;
          console.log("inpatients", JSON.stringify(inpatients));
          this.count = '';
          this.inpatient_list = [];
          var charge_details = [];
          this.all_charges_list = [];
          if (inpatients != undefined && inpatients.length != 0) {
            this.count = inpatients.length;
            for (var i = 0; i < inpatients.length; i++) {
              var patientName = "-";
              if (inpatients[i].middle_name != null && inpatients[i].middle_name != undefined) 
                patientName = encrypt_decript.Decript(inpatients[i].first_name) + " " + encrypt_decript.Decript(inpatients[i].middle_name) + " " + encrypt_decript.Decript(inpatients[i].last_name);
              else if (inpatients[i].last_name != null && inpatients[i].last_name != undefined) 
                patientName = encrypt_decript.Decript(inpatients[i].first_name) + " " + encrypt_decript.Decript(inpatients[i].last_name);
              else 
                patientName = encrypt_decript.Decript(inpatients[i].first_name);
              
              var doctorName = "-";
              if (inpatients[i].dr_first_name != undefined && inpatients[i].dr_last_name != undefined) 
                doctorName = 'Dr.'+ inpatients[i].dr_first_name + ' ' + inpatients[i].dr_last_name;
              else if (inpatients[i].dr_first_name != undefined) 
                doctorName = 'Dr.'+ inpatients[i].dr_first_name;
              
              var gender = "-";
              if (inpatients[i].gender != undefined) {
                gender = encrypt_decript.Decript(inpatients[i].gender);
                gender = gender[0];
                if (gender != undefined && gender[0] == 'T') 
                  gender = 'Tgen';            
              }

              var mobile = "-";
              if (inpatients[i].mobile != undefined) 
                mobile = encrypt_decript.Decript(inpatients[i].mobile);

              var admDatendTime = '-',get_date = '';
              if (inpatients[i].admission_date != undefined && inpatients[i].admission_time != undefined) {
                get_date = inpatients[i].admission_date.toString().split('-');
                admDatendTime = get_date[2] + "-" + get_date[1] + "-" + get_date[0]+" "+Time_Formate(inpatients[i].admission_time);
              } else if (inpatients[i].admission_date != undefined) {
                get_date = inpatients[i].admission_date.toString().split('-');
                admDatendTime = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
              } else if (inpatients[i].admission_time != undefined) 
                admDatendTime = Time_Formate(inpatients[i].admission_time);
              
              var ward_bed = '-';
              if (inpatients[i].ward_name != undefined && inpatients[i].bed_no != undefined) 
                ward_bed = inpatients[i].ward_name + ' ' + inpatients[i].bed_no;
              else if (inpatients[i].ward_name != undefined) 
                ward_bed = inpatients[i].ward_name;
              else if (inpatients[i].bed_no != undefined) 
                ward_bed = inpatients[i].bed_no;

              this.inpatient_list.push({
                sno: i + 1,
                mr_no: inpatients[i].mr_no,
                patient_name: patientName,
                doctor_name: doctorName,
                inp_hosp_id: inpatients[i].inp_hosp_id,
                inpatient_id: inpatients[i].inpatient_id,
                specialization: inpatients[i].specialization,
                bed_no: inpatients[i].bed_no,
                ward_name: inpatients[i].ward_name,
                age: inpatients[i].age,
                bill_amount: inpatients[i].bill_amount,
                no_of_days: inpatients[i].no_of_days,
                gender: gender,
                mobile: mobile,
                admDatendTime: admDatendTime,
                ward_bed: ward_bed,
                status: inpatients[i].bill_status
              })

              console.log("Bill_details",inpatients[i].bill_details);
              if (inpatients[i].bill_details != undefined && inpatients[i].bill_details.length != 0) {             
                charge_details = inpatients[i].bill_details;                
                for (var j = 0; j < charge_details.length; j++) {
                  this.all_charges_list.push({
                    inp_hosp_id: inpatients[i].inp_hosp_id,                 
                    charge_desc: charge_details[j].charge_desc,
                    charge_base: charge_details[j].charge_base,
                    amount: charge_details[j].amount,
                    quantity: charge_details[j].quantity,
                    total_amount: charge_details[j].total_amount
                  })
                }
              }
            }
            this.forcoverdiv = false;
                this.backbutton = true;
                this.ishide = true;
          }else{
            this.inpatient_list=[]
            this.noData = false;
              this.hasData = true;
          }          
        },
        error => { });
  }

  getChargeDetails(user) {
    this.charges_list = [];
    if (this.all_charges_list != undefined && this.all_charges_list.length != 0) {
      var s = 1;
      for (var i = 0; i < this.all_charges_list.length; i++) { 
        if (this.all_charges_list[i].inp_hosp_id == user.inp_hosp_id) {
          this.charges_list.push({
            sno: s,
            charge_desc: this.all_charges_list[i].charge_desc,
            charge_base: this.all_charges_list[i].charge_base,
            amount: this.all_charges_list[i].amount,
            quantity: this.all_charges_list[i].quantity,
            total_amount: this.all_charges_list[i].total_amount
          })
          s++;
        }
      }
      console.log('charges_list',this.charges_list);
    }    
  }

  setTable(data) {
    console.log(data)
    this.columns = {
      mrNo: false,
      wardBed: false,
      admDateTime: false,
      ageGender: false,
      mobileNo: false,
      docName: false,
      billAmt: false,
      billstatus: false
    };

    for (var i = 0; i < data.length; i++) {
      this.columns[data[i]] = !this.columns[data[i]];
    }
  }

  print_area() {
    var margin_top;
    let printContents, popupWin;
    if (this.inpatient_list.length != 0) {
      if (this.print_template != undefined && this.print_template == 'banner') {
        printContents = this.printbanner.nativeElement.innerHTML;
        margin_top = '0px';
      } else {
        printContents = this.printnoheader.nativeElement.innerHTML;
        margin_top = '122px';
      }
      popupWin = window.open(
        '',
        '_blank',
        'top=20,left=10,height=100%,width=auto'
      );
      popupWin.document.open();
      popupWin.document.write(`
      <html>
        <head>
          <title>Reports</title>
          <link rel="stylesheet" media="screen" href="">
          <style>                        
            @page {
              size: auto;
              margin-top: ${margin_top};
              margin-right: 0px;
              margin-left: 0px;
              margin-bottom: 0px;
              overflow: visible;
            } 
            * {
                font-size: 12px !important;
                line-height: 1 !important;
                page-break-inside: always;
                overflow: visible;
              }                                     
            @media print {   
              body {
                height: auto; /* Allow body height to adjust dynamically */
              }          
              .page-break {
                page-break-before: always; /* or page-break-after: always; */
                page-break-inside: always;
              }
            }           
            .address_visibility {
              visibility: hidden;
            }        
            table.report-container {
              page-break-after: always;
            }
            thead.report-header {
              display: table-header-group;
            }
            tfoot.report-footer {
              display: table-footer-group;
            }
            .print:last-child {
              page-break-after: auto;
            }
            .alignRight {
              text-align: right;
            }
            .col-print-1 {width:8%;  float:left;}
            .col-print-2 {width:16%; float:left;}
            .col-print-3 {width:25%; float:left;}
            .col-print-4 {width:33%; float:left;}
            .col-print-5 {width:42%; float:left;}
            .col-print-6 {width:50%; float:left;}
            .col-print-7 {width:58%; float:left;}
            .col-print-8 {width:66%; float:left;}
            .col-print-9 {width:75%; float:left;}
            .col-print-10 {width:83%; float:left;}
            .col-print-11 {width:92%; float:left;}
            .col-print-12 {width:100%; float:left;}
          </style>
        </head>
        <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
      </html>`);
      popupWin.document.close();
    }
  }  
}
