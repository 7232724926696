import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from '../../ipaddress' ;import { Message_data } from '../../../assets/js/Message_data';
import { Date_Formate } from '../../../assets/js/common.js';
import { Helper_Class } from 'src/app/helper_class';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss']
})
export class PatientDetailsComponent implements OnInit {
  public webcamImage: WebcamImage = null;
  public webcamImage2: WebcamImage = null;
  public thisnota: boolean = false;
  public is_prof_base64;
  public is_id_base64;
  public thisshowa: boolean =true;
  public camImage2;
  public base64_2;
  public imgUrl_2: string;
  public uploadidproof;
  public imageflag;
  public profileImg;
  public whatsappNumber;
  public aadharNumber;
  public referredDoctorListData;
  public referredDoctorList;
  public referredDoctor;
  public ref_source;
  public language;
  public bloodGroup: string;
  public primlanguage: string;
  public ref_sourcearray=[];
  public clntSal;
  public clientId;
  public clntFirstName;
  public clntMiddleName;
  public clntLastName;
  public clntDOB;
  public clntAge;
  public clntContNumber;
  public clntEmergNumber;
  public clntEmergNumber1;
  public clntGender;
  public clntFatherOrGuardName;
  public clntAddress1;
  public clntAddress2;
  public clntZipcode;
  public clntLocation;
  public clntLocationId;
  private locationNameByUrl: string;
  private cityUrl: string;
  private stateUrl: string;
  private countryUrl: string;
  private currentDateUrl: string;
  private zipcode_url: string;
  private ageUrl:string;
  private salUrl: string;
  private paturl:string;
  private updurl:string;
  public clntLocationList:any=[];
  public clntCityList:any=[];
  public clntStateList:any=[];
  public clntSalList:any=[];
  public clntCityId;
  public clntCity;
  public clntStateId;
  public clntState;
  public clntCountryList;
  public clntCountry;
  public clntCountryId;
  public currentDatetime;
  public currentTime;
  public ageRead: boolean = false;
  public ageDisabledFlag: boolean;
  private clientid:string;
  private relid:string;
  private subrelid:string;
  private upd_data;
  public clientdata;
  public bloodGroupTestArray: any = [];
  public languageDataArray: any = [];
  public userInfo;public hospId;



  public thisnot: boolean = false;
  public thisshow: boolean = false;
  public camImage;
  public base64;
  public imgUrl;
  public imgUrll: any;
  public styleOne: boolean = true;
  public imgUrl2;


  constructor(public frontdeskservice: MenuViewService, public http: HttpClient, public toastr: ToastrService) {
    this.locationNameByUrl = ipaddress.getIp + "usercontroller/locbyname";
    this.ageUrl = ipaddress.getIp + "adm/ddif";
    this.cityUrl = ipaddress.getIp + "gen/city";
    this.stateUrl = ipaddress.getIp + "gen/state";
    this.countryUrl = ipaddress.getIp + "gen/count";
    this.currentDateUrl = ipaddress.getIp + "adm/curdate";
    this.salUrl = ipaddress.getIp + "gen/sal";
    this.zipcode_url = ipaddress.getIp + "usercontroller/locbyzipcode";
    this.paturl = ipaddress.getIp + "usercontroller/clientdet";
    this.updurl = ipaddress.getIp + "usercontroller/ucbr";
    this.ageRead = false;
    this.ageDisabledFlag = false;
  }

  ngOnInit(): void {
    this.base64="default";
    this.userInfo = Helper_Class.getInfo()
    this.hospId = this.userInfo.hptl_clinic_id;
    //this.diagId = this.userInfo.diag_center_id;
    this.imgUrll = "../../assets/img/default.jpg";
    this.imgUrl=  "../../assets/img/default.jpg";
    this.imgUrl2 = "../../assets/img/default.jpg";
    this.clientdata = Helper_Class.getClientData();
    this.clientId = this.clientdata.client_id;
    this.relid = this.clientdata.relation_id;
    this.subrelid = this.clientdata.sub_rel_id;
    this.getCurrentDate();
    this.get_Language_Data('0');
    this.get_Bloodgroup_Data('0');
    // this.getReferral();
    
  }

  getSalutations() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.salUrl, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if (obj != undefined) {
          for (var i = 0; i < obj.salutations.length; i++) {
            this.clntSalList.push({
              sal_id: obj.salutations[i].salutation_id,
              sal_desc: obj.salutations[i].salutation_desc,
            });
          }
        //  this.clntSal = "select";
         
          //this.changeSalutation(this.clntSal);
          this.getPatientDetails(this.clientdata);
        }
      });
  }

  changeSalutation(e) { 
 

    if (e == "1" || e == "6")
      this.clntGender = "Male";
    else if (e == "2" || e == "3" || e == "4" || e == "7")
      this.clntGender = "Female";
    else if (e == "5")
      this.clntGender = "Transgender";
    else
      this.clntGender = "";
  }

  selectDob(e) {
    this.ageRead = true;
    this.clntDOB = e;
    if (e != null)
      this.ageDisabledFlag = true;

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.ageUrl, {
      dobdate: Date_Formate(this.clntDOB),
      curdate: Date_Formate(this.currentDatetime),
    }, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if (obj != undefined)
          this.clntAge = obj.value;
      });
  }

  changeGender(e) {
    if (this.clntAge != undefined && this.clntAge != null && this.clntAge != "") {
      if (e == "Male") {
        if (this.clntAge <= 15)
          this.clntSal = "6";
        else
          this.clntSal = "1";
      } else if (e == "Female") {
        if (this.clntAge <= 15)
          this.clntSal = "7";
        else
          this.clntSal = "2";
      } else if (e == "Transgender") {
        this.clntSal = "5";
      } else {
        this.clntSal = "";
      }

    } else {
      if (e == "Male") {
        this.clntSal = "1";
      } else if (e == "Female") {
        this.clntSal = "2";
      } else if (e == "Transgender") {
        this.clntSal = "5";
      } else {
        this.clntSal = "";
      }
    }
  }

  zipcode_change() {
    if (this.clntZipcode.length > 4) {
      this.clntLocationList = [];
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.zipcode_url, {
        zipcode: this.clntZipcode,
      }, { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data))
          if (obj.locations != null && obj.locations.length != 0) {
            if (obj.locations.length == 1) {
              this.clntLocationId = obj.locations[0].location_id;
              this.clntLocation = obj.locations[0].description;
              this.clntZipcode = obj.locations[0].pincode;
              this.getCities(this.clntLocation, 0);
            } else {
              this.clntLocationList = obj.locations;
              console.log("../location" + JSON.stringify(this.clntLocationList))
            }
          }
        },
        error => {
          this.toastr.error("Unable to fetch location details, please try later");
        }
      )
    } else {
      this.clntLocationList = [];
    }
  }

  changeLocation(locat_desc) {
    this.clntLocation = locat_desc;
    this.clntLocationList = [];

    if (this.clntLocation != null && this.clntLocation.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.locationNameByUrl, {
        name: this.clntLocation,
        //city_id: this.clntCityId,
      }, { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.locations != null && dataval.locations.length != 0)
            this.clntLocationList = dataval.locations;
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
    } else {
      this.clntLocationList = [];
    }
  }

  selectLocation(location) {
    this.clntLocation = location;
    for (var i = 0; i < this.clntLocationList.length; i++) {
      if (this.clntLocationList[i].location_id == location.location_id) {
        this.clntLocationId = this.clntLocationList[i].location_id;
        this.clntLocation = this.clntLocationList[i].description;
        this.clntZipcode = this.clntLocationList[i].pincode;
      }
    }
    this.clntLocationList = [];
    this.getCities(this.clntLocation, '0');
  }

  getCities(location, flag) {
    this.clntCityList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl, { loc_name: this.clntLocation, zipcode: this.clntZipcode },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.cities != null) {
            this.clntCityList = dataval.cities;
            if (this.clntCityList.length == 1) {
              for (var i = 0; i < this.clntCityList.length; i++) {
                if (this.clntCityList[i].city_id == this.clntCityId) {
                  this.clntCityId = this.clntCityList[i].city_id;
                  this.clntCity = this.clntCityList[i].city_desc;
                  this.getStates(this.clntCityId, 0)

                  break;
                } else {
                  this.clntCityId = this.clntCityList[0].city_id;
                  this.clntCity = this.clntCityList[0].city_desc;
                  this.getStates(this.clntCityId, 0)
                }
              }

            } else {
              this.clntCityId = this.clntCityList[0].city_id;
              this.clntCity = this.clntCityList[0].city_desc;
              this.getStates(this.clntCityId, 0)
            }
          }
        });
  }

  changeCity(clnt_city, flag) {
    this.clntLocationList = [];
    for (var i = 0; i < this.clntCityList.length; i++) {
      if (this.clntCityList[i].city_desc == clnt_city) {
        this.clntCityId = this.clntCityList[i].district_id;
        this.clntCity = this.clntCityList[i].city_desc;
        this.getStates(this.clntCityId, 0)
      }
    }
  }

  getStates(city, flag) {
    this.clntStateList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.stateUrl, { city_id: city },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.states != null) {
            this.clntStateList = dataval.states;
            if (this.clntStateList.length == 1) {
              for (var i = 0; i < this.clntStateList.length; i++) {
                if (this.clntStateList[i].state_id == this.clntStateId) {
                  this.clntStateId = this.clntStateList[i].state_id;
                  this.clntState = this.clntStateList[i].state_desc;
                  this.getCountries(this.clntStateId, '0');
                  break;
                } else {
                  this.clntStateId = this.clntStateList[0].state_id;
                  this.clntState = this.clntStateList[0].state_desc;
                  this.getCountries(this.clntStateId, '0');
                }
              }
              
            } else {
              this.clntStateId = this.clntStateList[0].state_id;
              this.clntState = this.clntStateList[0].state_desc;
              this.getCountries(this.clntStateId, '0');
            }
          }
        });
  }

  changeState(clnt_state, flag) {
    for (var i = 0; i < this.clntStateList.length; i++) {
      if (this.clntStateList[i].stage_desc == clnt_state) {
        this.clntStateId = this.clntStateList[i].state_id;
        this.clntState = this.clntStateList[i].stage_desc;
        this.getCountries(this.clntStateId, '0');
      }
    }
  }
  
  getCountries(stateid, flag) {
    this.clntCountryList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.countryUrl, { state_id: stateid }, { headers: headers }).subscribe(
      data => {
        this.clntCountryList = [];
        var dataval = JSON.parse(JSON.stringify(data));
        if (dataval.countries != null) {
          this.clntCountryList = dataval.countries;
          for (var c = 0; c < this.clntCountryList.length; c++) {
            if (this.clntCountryList[c].country_id == this.clntCountryId) {
              this.clntCountry = this.clntCountryList[c].country_desc;
              this.clntCountryId = this.clntCountryList[c].country_id;
              //this.getStates(this.clntCountryId, flag);
              break;
            }
          }
        }
      },
      error => {
        this.toastr.error(Message_data.unableToFetchData);
      });
  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.currentDateUrl, { country: "IN" }, { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data));
        this.currentTime = dataval.current_time;//time basiiss
        this.currentDatetime = dataval.current_date;
      //  this.currentDateUrl 
      this.getreferencesorce()
     
      },
      error => { });
  }

  fnameToUpper() {
    if (this.clntFirstName != null)
      this.clntFirstName = this.clntFirstName.toLocaleUpperCase();
  }

  mnameToUpper() {
    if (this.clntMiddleName != undefined)
      this.clntMiddleName = this.clntMiddleName.toLocaleUpperCase();
  }

  lnameToUpper() {
    if (this.clntLastName != undefined)
      this.clntLastName = this.clntLastName.toLocaleUpperCase();
  }

  getPatientDetails(data){
    var send_data={
      client_id: data.client_id,
      relation_id:data.relation_id,
      relationship:data.relationship_name,
      sub_rel_id:data.sub_rel_id,
      country:"IN",
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.paturl,send_data, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if (obj != undefined) {
          console.log("CLIENT DATA "+JSON.stringify(obj))
       
          if(obj.salutation != undefined){
           // this.clntSal= obj.salutation;
            if(this.clntSalList.length != 0){
              var sameItem = this.clntSalList.filter((x) => { return x.sal_id == obj.salutation })
              this.clntSal =sameItem[0].sal_id
            }
            //this.changeSalutation(this.clntSal);
          }
          this.clntFirstName = encrypt_decript.Decript(obj.first_name);
          if(obj.middle_name != undefined){
            this.clntMiddleName = encrypt_decript.Decript(obj.middle_name);
          }
          if(obj.profile_image != ""){
            if(obj.profile_image == "default"){
              this.thisnot = true;
              this.imgUrll = "../../assets/img/default.jpg";
              this.imgUrl=  "../../assets/img/default.jpg";
              this.base64="default"
            }else{
              var image=ipaddress.Ip_with_img_address+obj.profile_image;
              this.thisnot = true;
              this.imgUrll = ipaddress.Ip_with_img_address + obj.profile_image;
              this.imgUrl = ipaddress.Ip_with_img_address + obj.profile_image;
              this.base64=this.imgUrl
            }
           

          }
          if(obj.aadhar_url != undefined){
            if(obj.aadhar_url != ""){

            
            var check=obj.aadhar_url.split(".");
            var check1 =check[1]
            if(check1 == "pdf"){
              this.imageflag=false;
              var image1=ipaddress.Ip_with_img_address+obj.aadhar_url
              this.uploadidproof = ipaddress.Ip_with_img_address + obj.aadhar_url;
              this.imgUrl2 = ipaddress.Ip_with_img_address + obj.aadhar_url;
              this.base64_2=this.imgUrl2
            }else{
              this.imageflag=true;
              var image1=ipaddress.Ip_with_img_address+obj.aadhar_url
              this.uploadidproof = ipaddress.Ip_with_img_address + obj.aadhar_url;
              this.imgUrl2 = ipaddress.Ip_with_img_address + obj.aadhar_url;
              this.base64_2=this.imgUrl2
            }
          }
         

          }
          if(obj.last_name != undefined){
            this.clntLastName = encrypt_decript.Decript(obj.last_name);
          }
          if(obj.referred_doctor != undefined){
            this.referredDoctor = (obj.referred_doctor);
          }
          //referred_doctor
          if(obj.gender != undefined){
            this.clntGender = encrypt_decript.Decript(obj.gender);
            // this.changeGender(this.clntGender);
          }

          if(obj.dob != null){
            this.clntDOB = obj.dob;
          }

          if(obj.age != undefined){
            this.clntAge = obj.age;
          }

          if(obj.mobile != undefined){
            this.clntContNumber = encrypt_decript.Decript(obj.mobile);
          }
          if(obj.emerg_contact1 != undefined){
            this.clntEmergNumber = encrypt_decript.Decript(obj.emerg_contact1);
          }
          if(obj.emerg_contact2 != undefined){
            this.clntEmergNumber1 =encrypt_decript.Decript( obj.emerg_contact2);
          }
          if(obj.whatsapp_number != undefined){
            this.whatsappNumber = encrypt_decript.Decript(obj.whatsapp_number);
          }
          if(obj.SSN_PAN_number != undefined){
            this.aadharNumber = encrypt_decript.Decript(obj.SSN_PAN_number);
          }
          //referred_by
          if(obj.ref_source != undefined){
            var source= obj.ref_source;
            if(this.ref_sourcearray.length != 0){
              for(var j=0 ;j <this.ref_sourcearray.length; j++){
                  if(this.ref_sourcearray[j].lead_type_id == obj.ref_source){
                    this.ref_source =this.ref_sourcearray[j].lead_type_id;
                  }
              }
            
            }
          }
          if(obj.ref_source != undefined){
            var source= obj.ref_source;
            if(this.ref_sourcearray.length != 0){
              for(var j=0 ;j <this.ref_sourcearray.length; j++){
                  if(this.ref_sourcearray[j].lead_type_id == obj.ref_source){
                    this.ref_source =this.ref_sourcearray[j].lead_type_id;
                  }
              }
            
            }
          }
          if(obj.blood_group != undefined){
            this.bloodGroup = obj.blood_group;
            this.get_Bloodgroup_Data(this.bloodGroup)
          }
          if(obj.language != undefined){
            this.primlanguage = obj.language;
            this.get_Language_Data(this.primlanguage)
          }
          if(obj.address1 != undefined){
            this.clntAddress1 = encrypt_decript.Decript(obj.address1);
          }
          if(obj.address2 != undefined){
            this.clntAddress2 = encrypt_decript.Decript(obj.address2);
          }
          if(obj.zipcode != undefined){
            this.clntZipcode= encrypt_decript.Decript(obj.zipcode);
          }
          if(obj.location != undefined){
            this.clntLocation = obj.location_desc;
            this.clntLocationId = obj.location;
            this.getCities(this.clntLocationId,0);
            this.clntCity = obj.city_desc;
            this.clntCityId = obj.city;
            
            this.clntState = obj.state_desc;
            this.clntStateId = obj.state;

            this.clntCountry = obj.country_desc;
            this.clntCountryId = obj.country;
          }      
        }
      });
  
  }

  updatePatientDetails(){
    var profile_image_is_prof_base64 = this.isEncryptedImagePath(this.base64);
    var profile_image_is_id_base64 = this.isEncryptedImagePathidproof(this.base64_2);
    var emgContNo, emgContNo1, aadharNo, whatsappNo;

    if (this.aadharNumber != null && this.aadharNumber != undefined)
        aadharNo = encrypt_decript.Encript(this.aadharNumber).toString();

      if (this.whatsappNumber != null && this.whatsappNumber != undefined)
        whatsappNo = encrypt_decript.Encript(this.whatsappNumber).toString();

      if (this.clntEmergNumber != null && this.clntEmergNumber != undefined)
        emgContNo = encrypt_decript.Encript(this.clntEmergNumber).toString();

      if (this.clntEmergNumber1 != null && this.clntEmergNumber1 != undefined)
        emgContNo1 = encrypt_decript.Encript(this.clntEmergNumber1).toString();
    var senddadta={
      profile_image: this.base64,
      id_proof: this.base64_2,
      client_reg_id:this.clientId,
      relation_id:this.relid,
      sub_rel_id:this.subrelid,
      salutation:this.clntSal,
      first_name:this.clntFirstName,
      middle_name:this.clntMiddleName,
      last_name:this.clntLastName,
      dob:this.clntDOB,
      age:this.clntAge,
      mobile:this.clntContNumber,
      gender:this.clntGender,
      address1:this.clntAddress1,
      address2:this.clntAddress2,
      location:this.clntLocationId,
      city:this.clntCityId,
      state:this.clntStateId,
      country:this.clntCountryId,
      zipcode:this.clntZipcode,
      ref_source:this.ref_source,
      SSN_PAN_number: aadharNo,
      whatsapp_number: whatsappNo,
      emerg_contact1: emgContNo,
      emerg_contact2: emgContNo1,
      referred_by: this.referredDoctor,///04-03-2024
      language:this.primlanguage,
     // id_proof: this.base64_2,
     blood_group:this.bloodGroup,
     is_prof_base64:profile_image_is_prof_base64,
     is_id_base64:profile_image_is_id_base64
    //  ref_by: this.referredDoctor,
    }
    console.log("check data -- "+JSON.stringify(senddadta))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.updurl,senddadta, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        console.log("retrival ---"+JSON.stringify(obj))
        if (obj.key == "1") {
          this.toastr.success(obj.result);
          this.frontdeskservice.sendMessage("patient_list");
        } else {
          this.toastr.error("**");
        }
      });
  }

  back(){
    this.frontdeskservice.sendMessage("patient_list");
  }
  // selectDob(e) {
  //   this.clntDOB = e;
  //   this.ageRead = true;
  //   if(e != null)
  //   this.ageDisabledFlag = true;
  //   var headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(this.ageUrl, {
  //     dobdate: Date_Formate(this.clntDOB),
  //     curdate: Date_Formate(this.currentDatetime),
  //     spl: this.splId,
  //   }, { headers: headers }).subscribe(
  //   data => {
  //     var obj = JSON.parse(JSON.stringify(data));
  //     if (obj != undefined)
  //     this.clntAge = obj.value;
  //   });
  // }
  
  ChangeAge() {
    if (this.clntAge != "") {
      this.clntDOB = null;
    } else {
    }
    var dateval = this.currentDatetime.split('-');
    this.clntDOB = parseInt(dateval[0]) - parseInt(this.clntAge) + '-01-01';
  //  this.disable_dob = false;
  }
  get_Bloodgroup_Data(retrivel_data) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "bd/bgroup/", { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data));
        if (dataval.blood_groups != null) {
          this.bloodGroupTestArray = dataval.blood_groups;
          if (retrivel_data != "null" && retrivel_data != null) {
            for (var t = 0; t < this.bloodGroupTestArray.length; t++) {
              if (this.bloodGroupTestArray[t].blood_group_id == retrivel_data) {
                this.bloodGroup = this.bloodGroupTestArray[t];
                break;
              }
            }
          }
        }
      }, error => { });
  }

  get_Language_Data(retrivel_data) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "usercontroller/lng/", { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data));
        if (dataval.languages != null) {
          this.languageDataArray = dataval.languages;
          if (retrivel_data != "0") {
            for (var s = 0; s < this.languageDataArray.length; s++) {
              if (this.languageDataArray[s].language_id == retrivel_data) {
                this.language = this.languageDataArray[s];
                break;
              }
            }
          }
        }
      }, error => { });
  }
  getreferencesorce(){
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "gen/glc", { headers: headers }).subscribe(
      data => {
        this.ref_sourcearray=[];
        var obj = JSON.parse(JSON.stringify(data));
        console.log("get ref source --"+JSON.stringify(obj))
        if (obj.lead_channels.length != undefined) {
          for (var i = 0; i < obj.lead_channels.length; i++) {
            this.ref_sourcearray.push({
              lead_type_id: obj.lead_channels[i].channel_id,
              lead_desc: obj.lead_channels[i].channel_desc,
            });
          }
          this.getSalutations();
          // this.ref_source = "select";
          // this.changeref(this.ref_source);
        }
      });
  }
  changeref(e){
    this.ref_source=e
  }
  
  getReferral(e) {// Refer doctor list
    this.referredDoctor = e.target.value.toString();
    this.referredDoctorList = [];
    this.referredDoctorListData = [];
    var dname = this.checkData(this.referredDoctor);
    if (dname != "" && dname.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'search/docbyhns/',
        {
          hospital_id: this.hospId,
          doc_name: dname
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));

            this.referredDoctorListData = obj.doctors;

            if (this.referredDoctorListData != undefined) {
              for (var i = 0; i < this.referredDoctorListData.length; i++) {
                var docname = "";
                docname = this.referredDoctorListData[i].middle_name != undefined ? this.referredDoctorListData[i].first_name + " " + this.referredDoctorListData[i].middle_name + " " + this.referredDoctorListData[i].last_name : this.referredDoctorListData[i].first_name + " " + this.referredDoctorListData[i].last_name;
                this.referredDoctorList.push(docname);
              }
            }
            this.referredDoctorList = this.referredDoctorList.filter(function (el) {
              return el.toLowerCase().indexOf(dname.toLowerCase()) > -1;
            }.bind(this));
          },
          error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    } else {
      this.referredDoctorList = [];
    }
  }

  selectReferredDoctor(item) {
    this.referredDoctor = item;
    this.referredDoctorList = [];
  }
  checkData(value) {
    var data = "";
    if (value != undefined && value != null && value != "" && value != "undefined" && value != "null")
      data = value;
    else
      data = "";
    return data;
  }
  validateAadhar() {
    if (this.aadharNumber != undefined && this.aadharNumber.length != 12) {
      this.toastr.error("Enter valid Aadhar number")
    }
  }

  validatewhatsapp() {
    if (this.whatsappNumber != undefined && this.whatsappNumber.length != 10) {
      this.toastr.error("Enter valid whatsapp number")
    }
  }
  
  private trigger: Subject<void> = new Subject<void>();
  triggerSnapshot(): void {
    this.trigger.next();
  }
  

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  // //  /profile_image,is_prof_base64,id_proof,is_id_base64

  handleImage(webcamImage: WebcamImage): void {
    this.thisshow = true;
    this.thisnot = false;
   

    console.info('Saved webcam image', webcamImage);
    this.webcamImage = webcamImage;
    this.camImage = new Image();
    this.camImage.src = this.webcamImage.imageAsDataUrl;
    const maxWidth = 800; // Adjust as needed
    const maxHeight = 300; // Adjust as needed
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Calculate the new width and height while preserving aspect ratio
    let newWidth = this.camImage.width;
    let newHeight = this.camImage.height;
    if (newWidth > maxWidth || newHeight > maxHeight) {
        const aspectRatio = newWidth / newHeight;
        if (newWidth > maxWidth) {
            newWidth = maxWidth;
            newHeight = newWidth / aspectRatio;
        }
        if (newHeight > maxHeight) {
            newHeight = maxHeight;
            newWidth = newHeight * aspectRatio;
        }
    }

    // Set canvas dimensions to the new dimensions
    canvas.width = newWidth;
    canvas.height = newHeight;

    // Draw the image on the canvas with the new dimensions
    context.drawImage(this.camImage, 0, 0, newWidth, newHeight);

    // Convert the canvas content back to a data URL
    const resizedDataUrl = canvas.toDataURL('image/jpeg', 0.7); // Adjust the quality (0.0 - 1.0) as needed

    // Set the resized image as the source for the camImage
    this.camImage = new Image();
    this.camImage.src = resizedDataUrl;
    // Reset imgUrl and base64 when handling webcam image
    // this.imgUrl = '';
    // this.base64 = '';
    
    // Update base64 and imgUrl only if webcamImage is defined
    if (this.webcamImage) {
      this.base64 = this.webcamImage.imageAsBase64;
      this.imgUrl = this.webcamImage.imageAsDataUrl;
      this.is_prof_base64="1"
    }
  }
  
  handleInputChange(e): void {
    this.thisnot = true;
    this.thisshow = false;   
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
  
    if (!file.type.match(pattern)) {
      this.toastr.error('Invalid format');
      return;
    }
  
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  
  _handleReaderLoaded(e): void {
    const reader = e.target;
    const base64 = reader.result.split(',')[1];
    this.imgUrl = reader.result;
    this.imgUrll = reader.result;
    this.base64 = base64;

    console.log(this.imgUrl);
    console.log(base64);
  }
  
  private trigger2: Subject<void> = new Subject<void>();
  triggerSnapshot2(): void {
    this.trigger2.next();
  }
  handleImage2(webcamImage2: WebcamImage): void {
  
    console.info('Saved webcam image', webcamImage2);
    this.webcamImage2 = webcamImage2;
    this.camImage.src = this.webcamImage2.imageAsDataUrl;
    this.imgUrl_2 = '';
    this.base64_2 = '';
    const maxWidth = 800; // Adjust as needed
    const maxHeight = 300; // Adjust as needed
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Calculate the new width and height while preserving aspect ratio
    let newWidth = this.camImage.width;
    let newHeight = this.camImage.height;
    if (newWidth > maxWidth || newHeight > maxHeight) {
        const aspectRatio = newWidth / newHeight;
        if (newWidth > maxWidth) {
            newWidth = maxWidth;
            newHeight = newWidth / aspectRatio;
        }
        if (newHeight > maxHeight) {
            newHeight = maxHeight;
            newWidth = newHeight * aspectRatio;
        }
    }

    // Set canvas dimensions to the new dimensions
    canvas.width = newWidth;
    canvas.height = newHeight;

    // Draw the image on the canvas with the new dimensions
    context.drawImage(this.camImage, 0, 0, newWidth, newHeight);

    // Convert the canvas content back to a data URL
    const resizedDataUrl = canvas.toDataURL('image/jpeg', 0.7); // Adjust the quality (0.0 - 1.0) as needed

    // Set the resized image as the source for the camImage
    this.camImage = new Image();
    this.camImage.src = resizedDataUrl;
    // Reset imgUrl and base64 when handling webcam image
    // this.imgUrl = '';
    // this.base64 = '';
    
    // Update base64 and imgUrl only if webcamImage is defined
    if (this.webcamImage) {
      console.log('url', this.webcamImage2.imageAsBase64)
      this.base64_2 = this.webcamImage2.imageAsBase64;
      this.imgUrl_2 = this.webcamImage2.imageAsDataUrl;
      this.is_id_base64="1"
    } 
  }

  public get triggerObservable2(): Observable<void> {
    return this.trigger2.asObservable();
  }
  changeidproofimage(){
    // this.imgUrl_2 = '';
    // this.base64_2 = '';
    this.thisnota = true;
    this.thisshowa = false;
    this.is_prof_base64="1";
  }
  handleInputChange1(e): void {
    this.thisnota = false;
    this.thisshowa = true;
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
  
    if (!file.type.match(pattern)) {
      this.toastr.error('Invalid format');
      return;
    }
  
    reader.onload = this._handleReaderLoaded1.bind(this);
    reader.readAsDataURL(file);
  }
  
  _handleReaderLoaded1(e): void {
    const reader = e.target;
    const base64_2 = reader.result.split(',')[1];
    this.imgUrl_2 = reader.result;
    this.imgUrl2 = reader.result;
    this.base64_2 = base64_2;
    // this.is_prof_base64="1"  
    console.log(this.imgUrl);
    console.log(base64_2);
  }
  isEncryptedImagePath(path: string) {
    /// Check if the path ends with a file extension
    if (/\.(jpg|jpeg|png|gif)$/i.test(path)) {
     
      return '0'; // Regular image path
  }

  // Check if the path looks like a base64-encoded string
  if (/^[A-Za-z0-9+/]+={0,2}$/.test(path)) {
   
      return '1'; // Encrypted image path
  }

  // Unknown path format
  return '-1';
}
isEncryptedImagePathidproof(path: string) {
  /// Check if the path ends with a file extension
  if (/\.(jpg|jpeg|png|gif)$/i.test(path)) {
    return '0'; // Regular image path
}

// Check if the path looks like a base64-encoded string
if (/^[A-Za-z0-9+/]+={0,2}$/.test(path)) {
    return '1'; // Encrypted image path
}

// Unknown path format
return '-1';
}
removeFile(value) {
  if(value == "image"){
   // this.fileRemoved = true;
    this.thisshow=false;
    this.thisnot=false;
    this.imgUrll = "../../../assets/img/default.jpg";
    this.imgUrl=  "../../../assets/img/default.jpg";
  }else{
    //this.fileRemoved = true;
    this.thisshowa=false;
    this.thisnota=false;
    this.imgUrl2 = "../../../assets/img/default.jpg";
  }

}

}
  